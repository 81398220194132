import React, { ReactElement, useEffect, useState, ChangeEvent, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryData } from '../../models/absorb/country';
import { ProvinceData } from '../../models/absorb/province';
import { fetchCountryList, fetchProvincesList } from '../../services/region';
import { InputBox } from './InputBox';
import { errorHandler } from '../../utils/helper';
import { ABSORB_VENUE_LOCATION } from '../../constants/absorb';
import { useSearchContext } from '../../contexts/searchContext';
import { KEY_ENTER } from '../../constants/common';

export function VenueLocationFilter(): ReactElement {
  const { t } = useTranslation();

  const { advancedFilter, setAdvancedFilter, removeAdvancedFilter } = useSearchContext();

  const [country, setCountry] = useState(advancedFilter.country ? advancedFilter.country.toString() : '');
  const [province, setProvince] = useState(advancedFilter.province ? advancedFilter.province.toString() : '');
  const [city, setCity] = useState(advancedFilter.city ? advancedFilter.city.toString() : '');
  const [provinces, setProvinces] = useState<ProvinceData[]>([]);
  const [countries, setCountries] = useState<CountryData[]>([]);

  useEffect(() => {
    fetchCountryList().then(setCountries).catch(errorHandler);
  }, []);

  useEffect(() => {
    if (country) {
      setAdvancedFilter({ ...advancedFilter, country });
    } else if (advancedFilter) {
      setAdvancedFilter({ ...advancedFilter, country: '', province: '' });
    }
  }, [country]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (province) {
      setAdvancedFilter({ ...advancedFilter, province });
    } else if (advancedFilter) {
      setAdvancedFilter({ ...advancedFilter, province: '', country: '' });
    }
  }, [province]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (country) {
      fetchProvincesList(country).then(setProvinces).catch(errorHandler);
    }
  }, [country]);

  function onChangeCountry(e: ChangeEvent<HTMLSelectElement>) {
    setCountry(e.target.value);
    setProvince('');
    setCity('');
  }

  function onKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === KEY_ENTER) {
      updateAdvancedFilterCity(city);
    }
  }

  function closeVenueLocation() {
    removeAdvancedFilter(ABSORB_VENUE_LOCATION);
  }

  function clearLocationData() {
    setCountry('');
    setProvinces([]);
    setProvince('');
    setCity('');
    updateAdvancedFilterCity('');
  }

  function updateAdvancedFilterCity(newCity: string) {
    setAdvancedFilter({ ...advancedFilter, city: newCity });
  }

  return (
    <div className="filter-panel-module__filter_panel___2xlm8 filter-panel__filter_panel">
      <button
        type="button"
        className="icon-button-module__btn___Zb89b filter-panel-module__remove_button___1970P filter-panel__remove_button"
      >
        <div className="icon icon-x-circle" title={t(ABSORB_VENUE_LOCATION)} onClick={closeVenueLocation} />
        <span className="accessibility-text-module__off_screen_text___FSqhy">
          {t('Remove')} {t('VenueLocation')} {t('Filter')}
        </span>
      </button>
      <label className="venue-location-filter-module__title___3CLX9 venue-location-filter__title">
        {t('VenueLocation')}:
      </label>
      <label className="dropdown-module__label___3z4NX dropdown__label">{t('Country')}</label>
      <div className="dropdown-module__field___5TFai venue-location-filter-module__dropdown___2ImEE venue-location-filter__country_dropdown">
        <select name="country_selection" id="country_selection" value={country} onChange={onChangeCountry}>
          <option value="disabled" hidden>
            {t('SelectCountry')}
          </option>
          {countries &&
            countries.map((countryData: CountryData) => (
              <option key={countryData.countryCode} value={countryData.countryCode}>
                {countryData.name}
              </option>
            ))}
        </select>
        <span className="dropdown-module__dropdown_arrow___9etxZ dropdown__arrow icon icon-arrow-down" />
      </div>
      <label className="dropdown-module__label___3z4NX dropdown__label">{t('EditLearnerProvince')}</label>
      <div className="dropdown-module__field___5TFai venue-location-filter-module__dropdown___2ImEE venue-location-filter__province_dropdown">
        <select
          name="province_selection"
          id="province_selection"
          value={province}
          onChange={(e) => setProvince(e.target.value)}
        >
          <option value="disabled" hidden>
            {t('SelectProvince')}
          </option>
          {provinces &&
            provinces.map((proviceData: ProvinceData) => (
              <option key={proviceData.name} value={proviceData.name}>
                {proviceData.name}
              </option>
            ))}
        </select>
      </div>
      <div className="venue-location-filter-module__input_container___2fjhq">
        <InputBox
          label={t('City')}
          placeholder={t('SearchCity')}
          value={city}
          onChangeHandleInput={(e) => setCity(e.target.value)}
          onChangeHandleCrossButton={() => {
            setCity('');
            updateAdvancedFilterCity('');
          }}
          onKeyPress={onKeyPress}
        />
      </div>
      <label className="venue-location-filter-module__clear_link___1Jc33 venue-location-filter__clear_all_btn">
        <button
          type="button"
          className="icon-button-module__btn___Zb89b venue-location-filter-module__clear_all_btn___1WRvd filter-panel__remove_button"
          title={t('ClearLocation')}
          onClick={clearLocationData}
        >
          <div className="icon icon-x-circle" />
          <span className="accessibility-text-module__off_screen_text___FSqhy">{t('ClearLocation')}</span>
        </button>
        {t('ClearLocation')}
      </label>
    </div>
  );
}
