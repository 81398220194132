import { apiRequest } from '../utils/axios';
import { CountryData } from '../models/absorb/country';
import { ProvinceData } from '../models/absorb/province';

export async function fetchCountryList(): Promise<CountryData[]> {
  return apiRequest('GET', '/countries').then((data) => data.countries);
}

export async function fetchProvincesList(province: string): Promise<ProvinceData[]> {
  return apiRequest('GET', `/provinces/${province}`).then((data) => data.provinces);
}
