import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalResourceData } from '../models/absorb/resources';
import { getResourceIcon } from '../utils/helper';
import { TagList } from './TagList';

interface Props {
  resourceData: GlobalResourceData;
  fetchResourceUrl: (data: { id: string }) => void;
}

export function ResourcesDetailView({ resourceData, fetchResourceUrl }: Props): ReactElement {
  const { t } = useTranslation();

  function getResourceIconClassName(fileType: string | undefined) {
    return `${getResourceIcon(fileType)} icon panel-module__item_icon___Bw_O_ panel__icon`;
  }

  function onClickResource(id: string) {
    const data = {
      id,
    };

    if (fetchResourceUrl) {
      fetchResourceUrl(data);
    }
  }

  return (
    <div className="panel-module__panel___2njx7 panel__panel">
      <div className="panel-module__panel_header_focus___NaHeg">
        <div className="panel-module__panel_header___fkAzr panel-module__panel_header_link___j5xTN panel-module__news_panel_header___17wOm panel__panel_header">
          <div className="panel-module__panel_title___2Xe9e">
            <div className="panel-module__thumbnail___1UH4o">
              <span
                className={getResourceIconClassName(resourceData.fileType)}
                title={t('Other')}
                onClick={() => onClickResource(resourceData.id)}
              />
            </div>
            <div className="panel-module__title_info_wrapper___3brXS panel-module__news_title_info_wrapper___JrcOs">
              <div className="panel-module__title_wrapper___26Xj2 panel-module__news_title_wrapper___3lUT5">
                <div
                  title={resourceData.name}
                  className="panel-module__name___3Vd6B panel__header_name"
                  onClick={() => onClickResource(resourceData.id)}
                >
                  {resourceData.name}
                </div>
              </div>
              <div className="panel-module__news_action_wrapper___b0v5l">
                <div className="panel-module__type___3-i-s panel__header_type">{t('Resource')}</div>
              </div>

              <div className="panel-module__news_panel_actions___7JPUE">
                <div className="action-button-module__container___2O6X7 panel-module__action_btn___QJ-s_">
                  <button
                    type="button"
                    aria-disabled="false"
                    aria-label={resourceData.name}
                    className={`action-button-module__btn___3zkzb btn ${
                      resourceData.fileType === 'PNG' ? 'action-button-module__btn_disabled___2bOlE btn__disabled' : ''
                    }`}
                  >
                    <div
                      className="action-button-module__title___1uZt_"
                      onClick={() => onClickResource(resourceData.id)}
                    >
                      {t('Open')}
                    </div>
                    <span aria-live="polite" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(resourceData.description || (resourceData.tags && resourceData.tags.length > 0)) && (
        <div className="panel-module__panel_body___3vUWN panel__panel_body">
          {resourceData.description && (
            <div className="panel-module__panel_main___2poim">
              <div className="panel-module__panel_description___3xfj7 panel__panel_description">
                <div className="sanitized_html">{resourceData.description}</div>
              </div>
            </div>
          )}
          {resourceData.tags.length > 0 && (
            <div className="panel-module__list_wrapper___2heyx">
              <div className="tag-list-module__tags___O_Obx resource-panel-module__tags___YsJ3V">
                <div className="tag-list-module__title___3nUy0 resource-panel-module__tags_title___2jFsK tag-list__title">
                  {t('Tags')}:
                </div>
                <TagList tags={resourceData.tags} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
