/* eslint-disable array-callback-return */
import React, { ReactElement, useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogCourseData } from '../models/absorb/catalog';
import { DashboardContainerData, DashboardTileData } from '../models/absorb/dashboard';
import { useProfileContext } from '../contexts/profileContext';
import { DashboardLayout } from '../components/DashboardLayout';
import { LoadingIndicator } from '../components/Spinner';
import { DASHBOARD_RIBBON_POSITION, SearchSwitch, SortBy } from '../constants/common';
import { SortCoursesBy } from '../constants/courses';
import { fetchDashboard } from '../services/dashboard';
import { fetchFeaturedCourses, fetchMyCourses, fetchResumeCourse } from '../services/course';
import { fetchMyCatalogCourses } from '../services/catalog';
import { errorHandler, getSearchSwitch } from '../utils/helper';
import { CourseRibbon } from '../components/CourseRibbon';
import { DashboardTile } from '../components/DashboardTile';
import { AbsorbTileType, AlignmentType, RibbonType } from '../constants/absorb';
import { PageTitle } from '../components/PageTitle';
import './DashboardPage.css';

export function DashboardPage(): ReactElement {
  const { t } = useTranslation();

  const { profile } = useProfileContext();

  const [dashboardData, setDashboardData] = useState<DashboardContainerData[]>([]);
  const [myCourseData, setMyCourseData] = useState<CatalogCourseData[]>([]);
  const [catalogData, setCatalogData] = useState<CatalogCourseData[]>([]);
  const [featuredCourses, setFeaturedCourses] = useState<CatalogCourseData[]>([]);
  // const [recommendedCourses, setRecommendedCourses] = useState<CatalogCourseData[]>([]); TODO: phase 2
  const [resumableData, setResumableData] = useState<CatalogCourseData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResumeCoursesLoading, setIsResumeCoursesLoading] = useState(false);
  const [isMyCoursesLoading, setIsMyCoursesLoading] = useState(false);
  const [isCatalogLoading, setIsCatalogLoading] = useState(false);
  const [isFeaturedCoursesLoading, setIsFeaturedCoursesLoading] = useState(false);
  //  const [isRecommendedCoursesLoading, setIsRecommendedCoursesLoading] = useState(false); TODO: phase 2

  useEffect(() => {
    setIsLoading(true);

    fetchDashboard()
      .then(setDashboardData)
      .catch(errorHandler)
      .finally(() => setIsLoading(false));

    getResumeCourses();
    getCourseDetails();
    getCatalogDetails();
    getFeaturedCourses();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: phase 2

  // useEffect(() => {
  //   SPECIAL CASE: fetchRecommendedCourses must be called after fetchCatalogDetails
  //                 as it has a filter dependency on catalogData
  //   fetchRecommendedCourses();
  // }, [catalogData]);  eslint-disable-line react-hooks/exhaustive-deps

  function getResumeCourses() {
    setIsResumeCoursesLoading(true);
    fetchResumeCourse({
      showCompleted: getSearchSwitch(SearchSwitch.CourseCompletedSwitch),
      _sort: `${SortCoursesBy.Ispinned},${SortCoursesBy.EnrolledDate}`,
    })
      .then(setResumableData)
      .catch(errorHandler)
      .finally(() => setIsResumeCoursesLoading(false));
  }

  function getCourseDetails() {
    setIsMyCoursesLoading(true);

    fetchMyCourses({
      showCompleted: getSearchSwitch(SearchSwitch.CourseCompletedSwitch),
      _sort: `${SortCoursesBy.Ispinned},${SortCoursesBy.EnrolledDate}`,
    })
      .then((data) => setMyCourseData(data.courses))
      .catch(errorHandler)
      .finally(() => setIsMyCoursesLoading(false));
  }

  function getCatalogDetails() {
    setIsCatalogLoading(true);
    fetchMyCatalogCourses({
      showCompleted: getSearchSwitch(SearchSwitch.CourseCompletedSwitch),
      _sort: SortBy.EnrollmentDate,
    })
      .then((data) => setCatalogData(data.courses))
      .catch(errorHandler)
      .finally(() => setIsCatalogLoading(false));
  }

  function getFeaturedCourses() {
    setIsFeaturedCoursesLoading(true);
    fetchFeaturedCourses({
      showCompleted: getSearchSwitch(SearchSwitch.CourseCompletedSwitch),
      _sort: `${SortCoursesBy.Ispinned},${SortCoursesBy.EnrolledDate}`,
    })
      .then(setFeaturedCourses)
      .catch(errorHandler)
      .finally(() => setIsFeaturedCoursesLoading(false));
  }

  //  TODO: phase 2

  // async function fetchRecommendedCourses() {
  //   if (recommendedData.recommended) {
  //     const recommendedTags = recommendedData.recommended.tags;

  //     setIsRecommendedCoursesLoading(true);
  //     fetchCourseTags()
  //       .then((tags) => {
  //         if (tags.length) {
  //           const filteredTags: string[] = [];

  //           tags.forEach((tag: TagsData) => {
  //             recommendedTags.forEach((rTag) => {
  //               if (rTag.subModule === tag.name) {
  //                 filteredTags.push(tag.id);
  //               }
  //             });
  //           });
  //           setRecommendedCourses(catalogData.filter((course) => course.enrollmentStatus === null));
  //         }
  //       })
  //       .catch(errorHandler)
  //       .finally(() => setIsRecommendedCoursesLoading(false));
  //   }
  // }

  return (
    <DashboardLayout>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="private-dashboard-module__dashboard___1ezZM dashboard__dashboard">
          <PageTitle />
          {dashboardData.map((container: DashboardContainerData) => {
            return (
              <Fragment key={container.id}>
                {!container.name && container.tiles && (
                  <div className="private-dashboard-module__tile_wrapper___C_0ZU private-dashboard-module__no_top_padding___fEvA2 dashboard__tile_no_padding dashboard__tile_wrapper">
                    <div className="dashboard-container-module__section___2OZXP dashboard-container__section dashboard-tile-container-margin">
                      {container.tiles.map((tile: DashboardTileData) => {
                        if (tile.type !== AbsorbTileType.Welcome) {
                          return (
                            <DashboardTile key={tile.id} dashboardTile={tile} resumeableCourse={resumableData[0]} />
                          ); // index 0 here because the resume dashboard tile holds only the one last used course to resume
                        }
                        return <Fragment key={tile.id} />;
                      })}
                    </div>
                  </div>
                )}
                {container.tiles && container.tiles.length === 1 && container.tiles[0].type === AbsorbTileType.Welcome && (
                  <div className="private-dashboard-module__tile_wrapper___C_0ZU dashboard__tile_wrapper">
                    {container.name && (
                      <h1 className="dashboard-container-module__name___am6Wz dashboard-container__name">
                        {container.name}
                      </h1>
                    )}
                    <div className="dashboard-container-module__section___2OZXP dashboard-container__section">
                      {container.tiles.map((tile: DashboardTileData) => {
                        return (
                          <div
                            key={tile.id}
                            className="welcome-dashboard-tile-module__tile___2ZqML welcome-dashboard-tile-module__tile_large___3557x dashboard-tile-wrapper__wrapper_large welcome-dashboard-tile-module__welcome_tile___2zdXw tile__welcome welcome-dashboard-tile dashboard-tile-wrapper__wrapper"
                            style={{
                              backgroundImage: `url("${encodeURI(tile.backgroundImageUri || '')}")`,
                              backgroundPosition: 'center center',
                              backgroundSize: 'cover',
                            }}
                          >
                            <div
                              className={`welcome-dashboard-tile-module__welcome_banner___2MDyq welcome-dashboard-tile__welcome_banner ${
                                tile.foregroundImageAlignment === AlignmentType.Left
                                  ? 'welcome-dashboard-tile-module__welcome_left___Xsijv'
                                  : ''
                              } ${
                                tile.foregroundImageAlignment === AlignmentType.Centered
                                  ? 'welcome-dashboard-tile-module__welcome_center___pwEcS'
                                  : ''
                              } ${
                                tile.foregroundImageAlignment === AlignmentType.Right
                                  ? 'welcome-dashboard-tile-module__welcome_right___3-ZrU'
                                  : ''
                              }`}
                            >
                              <h1 className="welcome-dashboard-tile-module__username___2cp73">
                                {t('Welcome')} {profile?.firstName} {profile?.lastName}
                              </h1>
                              <div className="welcome-dashboard-tile-module__welcome_message___10TW8">
                                {t('WelcomeMessage')}
                              </div>
                            </div>
                            <div className="welcome-dashboard-tile-module__foreground_image___enFKJ welcome-dashboard-tile__foreground_image" />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {container.ribbon &&
                  container.ribbon.ribbonType === RibbonType.Resume &&
                  (isResumeCoursesLoading || resumableData.length > 0) &&
                  (isResumeCoursesLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div
                      className={`private-dashboard-module__ribbon_wrapper___2Giid ${
                        container.position > DASHBOARD_RIBBON_POSITION
                          ? 'private-dashboard-module__no_top_padding___fEvA2'
                          : ''
                      } dashboard__ribbon`}
                    >
                      <CourseRibbon
                        courseData={resumableData}
                        linkTitle={t('ResumeCourses')}
                        link="/my-courses/?_sort=RecentActivity"
                      />
                    </div>
                  ))}

                {container.ribbon &&
                  container.ribbon.ribbonType === RibbonType.MyCourses &&
                  (isMyCoursesLoading || myCourseData.length > 0) &&
                  (isMyCoursesLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div
                      className={`private-dashboard-module__ribbon_wrapper___2Giid ${
                        container.position > DASHBOARD_RIBBON_POSITION
                          ? 'private-dashboard-module__no_top_padding___fEvA2'
                          : ''
                      } dashboard__ribbon`}
                    >
                      <CourseRibbon courseData={myCourseData} linkTitle={t('MyCourses')} link="/my-courses" />
                    </div>
                  ))}

                {container.ribbon &&
                  (container.ribbon.ribbonType === RibbonType.catalog ||
                    container.ribbon.ribbonType === RibbonType.MyCatalog) &&
                  (isCatalogLoading || catalogData.length > 0) &&
                  (isCatalogLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div
                      className={`private-dashboard-module__ribbon_wrapper___2Giid ${
                        container.position > DASHBOARD_RIBBON_POSITION
                          ? 'private-dashboard-module__no_top_padding___fEvA2'
                          : ''
                      } dashboard__ribbon`}
                    >
                      <CourseRibbon courseData={catalogData} linkTitle={t('Catalog')} link="/catalog" />
                    </div>
                  ))}

                {container.ribbon &&
                  container.ribbon.ribbonType === RibbonType.Featured &&
                  (isFeaturedCoursesLoading || featuredCourses.length > 0) &&
                  (isFeaturedCoursesLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div
                      className={`private-dashboard-module__ribbon_wrapper___2Giid ${
                        container.position > DASHBOARD_RIBBON_POSITION
                          ? 'private-dashboard-module__no_top_padding___fEvA2'
                          : ''
                      } dashboard__ribbon`}
                    >
                      <CourseRibbon courseData={featuredCourses} linkTitle={t('FeaturedCourses')} link="/my-courses" />
                    </div>
                  ))}
              </Fragment>
            );
          })}
          {/* TODO : phase 2 */}
          {/* {(isRecommendedCoursesLoading || recommendedCourses.length > 0) && (
            <>
              {isRecommendedCoursesLoading ? (
                <LoadingIndicator />
              ) : (
                <CourseRibbon courseData={recommendedCourses} linkTitle="RecommendedCourses" link="/my-courses" />
              )}
            </>
          )} */}
        </div>
      )}
    </DashboardLayout>
  );
}
