import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessagesContext } from '../contexts/messagesContext';
import { MessageData } from '../models/absorb/message';
import { convertToTimeAgoDate, parsedHtml } from '../utils/helper';
import { useModalContext } from '../contexts/modalContext';
import { PageTitle } from '../components/PageTitle';

interface Props {
  messageData: MessageData;
}

export function MessageModal({ messageData }: Props): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();
  const { messageRead, fetchHeaderMessagesData, fetchHeaderMessageCount, fetchMessagesData } = useMessagesContext();

  useEffect(() => {
    if (messageData && !messageData.isRead) {
      messageRead(messageData.id, { read: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchHeaderMessagesData(true);
    fetchHeaderMessagesData(false);

    fetchHeaderMessageCount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function onCloseModalButtonClick() {
    closeModal();
    fetchMessagesData(false, 0);
    fetchMessagesData(true, 0);
  }

  return (
    <div
      className="modal-module__container___sfedc modal__container"
      role="dialog"
      aria-label={t('Message')}
      aria-modal="true"
    >
      <PageTitle title={t('Message')} />
      <div className="modal-module__blocker___231AR modal__blocker" />
      <div className="modal-module__modal_container___3tUB2 modal__modal_container">
        <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
          <button
            type="button"
            className="icon-button-module__btn___Zb89b modal-module__close_btn___1yWvZ modal__close_btn"
            title={t('Close')}
            onClick={onCloseModalButtonClick}
          >
            <div className="icon icon-x-thin" />
            <span className="accessibility-text-module__off_screen_text___FSqhy">
              {t('Close')} {t('Message')}
            </span>
          </button>
          <div className="modal-module__modal___11QTd modal__modal modal-module__modal_default_size___2ui9v">
            <div className="message-module__container___m8Fpi">
              <div className="message-module__header___33KJ_">
                <div className="message-module__subject_container___d9FeE">
                  <h1 className="message-module__subject___emAAX message__subject">
                    {parsedHtml(messageData.subject)}
                  </h1>
                  <div
                    title={new Date(messageData.sendDate).toDateString()}
                    className="message-module__date___1k9uA message__date"
                  >
                    {convertToTimeAgoDate(messageData.sendDate)}
                  </div>
                </div>
                <div className="message-module__sender_info___3_zxD">
                  <div className="message-module__user_wrapper___3ub8R">
                    <div className="message-module__user_avatar___10jJh message__avatar">
                      <span className="icon icon-profile message-module__no_avatar___2-r-z message__no_avatar" />
                      {messageData.isPriority && (
                        <span className="message-module__high_priority___1RFe2 icon icon-exclamation-mark-small message__high_priority" />
                      )}
                    </div>
                    <div className="message__sender">
                      {messageData.isPriority ? t('PriorityMessages') : t('SystemMessages')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="message-module__body___2BvkP message__body sanitized_html">
                {parsedHtml(messageData.message)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
