import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { onFetchResourceUrl } from '../services/resources';
import { errorHandler, getResourceIcon } from '../utils/helper';
import { GlobalResourceData } from '../models/absorb/resources';
import { ExpandableDescription } from './ExpandableDescription';

interface Props {
  globalResourceData: GlobalResourceData;
}

export function GlobalResourceDetail({ globalResourceData }: Props): ReactElement {
  const { t } = useTranslation();

  function openResourceURL() {
    onFetchResourceUrl({ id: globalResourceData.id })
      .then((url) => {
        window.open(url, '_blank');
      })
      .catch(errorHandler);
  }

  return (
    <div className="panel-module__panel___2njx7 panel__panel">
      <div className="panel-module__panel_header_focus___NaHeg">
        <div
          role="button"
          className="panel-module__panel_header___fkAzr panel-module__panel_header_link___j5xTN panel-module__news_panel_header___17wOm panel__panel_header"
          onClick={() => openResourceURL()}
        >
          <div className="panel-module__panel_title___2Xe9e">
            <div className="panel-module__thumbnail___1UH4o">
              <span
                className={`${getResourceIcon(
                  globalResourceData.fileType
                )} icon panel-module__item_icon___Bw_O_ panel__icon`}
                title={t(`${globalResourceData.fileType}`)}
              />
            </div>
            <div className="panel-module__title_info_wrapper___3brXS panel-module__news_title_info_wrapper___JrcOs">
              <div className="panel-module__title_wrapper___26Xj2 panel-module__news_title_wrapper___3lUT5">
                <div title={globalResourceData.name} className="panel-module__name___3Vd6B panel__header_name">
                  {globalResourceData.name}
                </div>
              </div>
              <div className="panel-module__news_action_wrapper___b0v5l">
                <div className="panel-module__type___3-i-s panel__header_type">{t('Resource')}</div>
              </div>
              <div className="panel-module__news_panel_actions___7JPUE">
                <div className="action-button-module__container___2O6X7 panel-module__action_btn___QJ-s_">
                  <button
                    type="button"
                    aria-disabled="false"
                    aria-label={globalResourceData.name}
                    className="action-button-module__btn___3zkzb btn"
                  >
                    <div className="action-button-module__title___1uZt_">{t('Open')}</div>
                    <span aria-live="polite" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {globalResourceData.name && globalResourceData.description && (
        <div className="panel-module__panel_body___3vUWN panel__panel_body">
          <div className="panel-module__panel_main___2poim">
            <ExpandableDescription itemName={globalResourceData.name} description={globalResourceData.description} />
          </div>
        </div>
      )}
    </div>
  );
}
