import { apiRequest } from '../utils/axios';
import { SearchFilterParams, SearchResultsData } from '../models/absorb/search';
import { SEARCH_DEFAULT_PAGE_OFFSET, TAG_LIMIT } from '../constants/common';
import { TagData } from '../models/absorb/tags';

export async function fetchGlobalSearchResults(
  searchFilter: SearchFilterParams,
  offset = SEARCH_DEFAULT_PAGE_OFFSET
): Promise<SearchResultsData> {
  const url = formatSearchUrl(`/search?_offset=${offset}&`, searchFilter);

  return apiRequest('GET', url);
}

function formatSearchUrl(baseUrl: string, searchFilter: SearchFilterParams): string {
  let url = baseUrl;
  const data = JSON.parse(JSON.stringify(searchFilter));

  Object.keys(data).forEach((key) => {
    if (data[key]) {
      url += `${url === baseUrl ? '' : '&'}${key}=${data[key]}`;
    }
  });

  return url;
}

export async function fetchGlobalSearchTags(searchTerm: string): Promise<TagData[]> {
  return apiRequest('GET', `/search/tags?term=${searchTerm}&_limit=${TAG_LIMIT}`).then((data) => data.tags);
}
