export const DEFAULT_LANGUAGE = 'en';
export const TERMS_AND_CONDITIONS_ONLY_SUPPORTED_LANGUAGE = 'en';

export const API_RESULT_LIMIT = 8;
export const PRINT_TRANSCRIPT_LIMIT = 20;
export const COURSES_PER_PAGE = 20;
export const RESOURCES_PER_PAGE = 20;
export const TIME_AGO_THRESHOLD_FOR_SHOWING_DATE = 6;
export const WEBVIEW_BREAKPOINT = 769;
export const MINIMUM_INPUT_CHARS = '1';
export const KEY_ESCAPE = 'Escape';
export const KEY_ENTER = 'Enter';
export const USER_EXISTS_ERROR_MESSAGE = 'Username already exists';
export const RESET_PASSWORD_ABSORB_DEFAULT_URL_HASH_PATH = '#/resetpassword/';
export const RESET_PASSWORD_PROPER_PATH = '/reset-password/';
export const DELAY_TO_CLEAR_IN_PROGRESS_STATE = 2500;
export const COPY_TO_CLIPBOARD_RESET_DELAY = 1000;
export const BASE_10 = 10;
export const SEARCH_DEFAULT_PAGE_OFFSET = 0;
export const VIDEO_LESSON_PROGRESS_UPDATE_FREQUENCY = 5500;
export const TAG_LIMIT = 1000;
export const INVALID_COUPON = 'CouponNotValid';
export const COMPLETED_PROGRESS = 100;
export const EXPANDABLE_DESCRIPTION_LIMIT = 80;
export const ROOT_CATEGORY = 'Root';
export const FIRST_URL_INDEX = 1;
export const SECOND_URL_INDEX = 2;
export const THIRD_INDEX = 3;
export const MINIMUM_USERNAME_CHARS = 6;
export const MINIMUM_PASSWORD_CHARS = 8;
export const DASHBOARD_RIBBON_POSITION = 2;

export const STRUCTURED_LEARNING_TAG = '3612621e-f098-4982-a5b7-5edec6de94d5';
// structured learning tag in sandbox
// export const STRUCTURED_LEARNING_TAG = 'bbbfd485-1827-4bd6-a9a3-17dbf0430421';

export const SECOND_CURRICULUM_GROUP_MONTHS_TO_WAIT = 6;
export const SUBSEQUENT_CURRICULUM_GROUP_MONTHS_TO_WAIT = 3;
export const CURRICULUM_GROUP_COUNTER_INITIAL_VALUE = 0;
export const CURRICULUM_GROUP_COUNTER_SECOND_GROUP = 1;

const DEFAULT_USER_ACTIVITY_TIMEOUT_MS = '3600000';
const REACT_APP_USER_ACTIVITY_TIMEOUT: string =
  process.env.REACT_APP_USER_ACTIVITY_TIMEOUT || DEFAULT_USER_ACTIVITY_TIMEOUT_MS;
export const USER_ACTIVITY_TIMEOUT_MS = parseInt(REACT_APP_USER_ACTIVITY_TIMEOUT, 10);

export const LEARNER_TOKEN_IS_NO_LONGER_VALID = 'Learner Token is no longer valid';
export const COURSE_NOT_FOUND_PARAMS = 'courseNotFound=true';

export const LOW_PRIORITY_MESSAGES_UNREAD_LIMIT = 5;
export const LOW_PRIORITY_MESSAGES_UNREAD_START_INDEX = 0;
export const MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const DATE_FORMAT = 'DD';
export const MONTH_DAY_YEAR_FORMAT = 'LL';
export const HOUR_MINUTE_FORMAT = 'h:mm A';

export enum LocalStorageItem {
  UserId = 'userId',
  Token = 'token',
  Languages = 'languages',
  LanguageCode = 'languageCode',
  I18nData = 'i18nData',
  BrandingData = 'brandingData',
  DepartmentThemeData = 'departmentThemeData',
}

export enum ProfileField {
  Username = 'Username',
  FirstName = 'FirstName',
  LastName = 'LastName',
  EmailAddress = 'EmailAddress',
  MiddleName = 'MiddleName',
  Password = 'Password',
  VerifyPassword = 'VerifyPassword',
}

export enum SortBy {
  CourseName = 'courseName',
  CompletionDate = 'completionDate',
  EducationalCredits = 'educationalCredits',
  EnrollmentStatus = 'enrollmentStatus',
  EnrollmentDate = 'enrollmentDate',
  ExamScore = 'examScore',
}

export enum ProfileTab {
  UserProfile = 'UserProfile',
  Messages = 'Messages',
  MyActivity = 'MyActivity',
}

export enum ViewOptionPage {
  MyCourse = 'MyCourse',
  Curriculum = 'Curriculum',
  Catalog = 'Catalog',
  Resources = 'Resources',
  SearchResults = 'SearchResults',
  CourseDetails = 'CourseDetails',
}

export enum DisplayMessages {
  ErrorLoadingWebsiteText = 'ThereWasAnErrorLoadingTheWebsitePleaseTryAgain',
  NoResultsMainText = 'NoResultsMainText',
  NoResultsSubText = 'NoResultsSubText',
}

export enum CourseDetailClickType {
  Button = 'Button',
  Link = 'Link',
}

export enum PosterImageVariables {
  Percentage = 0.975,
  TabletBreakPoint = 668,
  MobileViewPadding = 40,
  DesktopViewPadding = 80,
  DivisionRatio = 2.4,
  MaxWidth = 720,
  MinWidth = 280,
  MaxHeight = 300,
  MinHeight = 117,
}

export enum CircularProgressBarWidth {
  TopViewWidth = '128px',
  SidebarWidth = '55%',
}

export enum SearchSwitch {
  CourseCompletedSwitch = 'CourseCompletedSwitch',
  CourseCategorySwitch = 'CourseCategorySwitch',
  CatalogCategorySwitch = 'CatalogCategorySwitch',
  ResourceCategorySwitch = 'ResourceCategorySwitch',
}

export const isOnGlobalSearchPage = (): boolean => window.location.pathname.includes('/global-search');
export const isOnResourcesPage = (): boolean => window.location.pathname.includes('/resources');
export const isOnMyCoursesPage = (): boolean => window.location.pathname.includes('/my-courses');
export const isOnCatalogPage = (): boolean => window.location.pathname.includes('/catalog');
