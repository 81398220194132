import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  activeTab: string | string[];
  tabName: string;
  onSelectedTab: (headertext: string) => void;
}

export function UserProfileTab({ activeTab, tabName, onSelectedTab }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div
      className={`tab-list-module__tab___1ZDk5 ${
        activeTab === tabName ? 'tab-list-module__tab_active___1lSYg tab-list__tab_active' : ''
      } tab-list__tab`}
      id={tabName}
      onClick={() => onSelectedTab(tabName)}
    >
      <button type="button" className="tab-list-module__tab_btn___31NOt tab-list__tab_btn" aria-current="page">
        <span className="accessibility-text-module__off_screen_text___FSqhy">{t(tabName)}</span>
        <span aria-hidden="true">{t(tabName)}</span>
      </button>
    </div>
  );
}
