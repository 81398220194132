import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useSearchContext } from '../../contexts/searchContext';
import { Slider } from './Slider';

export function ShowCategoriesSwitch(): ReactElement {
  const { t } = useTranslation();

  const {
    searchSwitches,
    searchFilter,
    setCategory,
    setSearchSwitches,
    resetPageOffset,
    setSearchSwitch,
    setSearchFilter,
  } = useSearchContext();

  function onToggleCategoriesSwitch() {
    setSearchSwitches({ ...searchSwitches, showCategories: !searchSwitches.showCategories });
    setSearchSwitch((!searchSwitches.showCategories).toString());

    if (!searchSwitches.showCategories === false) {
      setCategory('');
      resetPageOffset();
      setSearchFilter({ ...searchFilter, showCategories: false });
    }
  }

  return (
    <Slider
      label={t('ShowCategories')}
      isChecked={searchSwitches.showCategories}
      toggleCheckbox={() => onToggleCategoriesSwitch()}
    />
  );
}
