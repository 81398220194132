import React, { ReactElement } from 'react';
import { AbsorbResourceType } from '../constants/absorb';
import { CatalogCourseData } from '../models/absorb/catalog';
import { FaqResourceData, GlobalResourceData } from '../models/absorb/resources';
import { SearchResultData } from '../models/absorb/search';
import { FaqList } from './FaqList';
import { GlobalResourceList } from './GlobalResourceList';
import { ListHeaders } from './ListHeaders';
import { ListViewRow } from './ListViewRow';

interface Props {
  searchResults: SearchResultData[];
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function ResourceList({ searchResults, updatePinnedStatus }: Props): ReactElement {
  return (
    <ListHeaders>
      {searchResults.map((searchResult) => {
        if (searchResult.resourceType === AbsorbResourceType.GlobalResource) {
          return (
            <GlobalResourceList
              key={searchResult.resource.id}
              globalResourceData={searchResult.resource as GlobalResourceData}
            />
          );
        }

        if (searchResult.resourceType === AbsorbResourceType.Course) {
          return (
            <ListViewRow
              key={searchResult.resource.id}
              catalogData={searchResult.resource as CatalogCourseData}
              updatePinnedStatus={updatePinnedStatus}
            />
          );
        }

        if (searchResult.resourceType === AbsorbResourceType.Faq) {
          return <FaqList key={searchResult.resource.id} faqResourceData={searchResult.resource as FaqResourceData} />;
        }

        return null;
      })}
    </ListHeaders>
  );
}
