import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayContentView } from '../constants/courses';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

interface LessonOptionViewsButtonProps {
  showViewOptions: boolean;
  contentView: DisplayContentView;
  onChangeContentView: (viewChange: DisplayContentView, requiresPurchase: boolean) => void;
  onChangeContentViewClick: () => void;
  setShowViewOptions: (show: boolean) => void;
  courseRequiresPurchase?: boolean;
}

export function LessonOptionViewsButton({
  showViewOptions,
  contentView,
  onChangeContentView,
  onChangeContentViewClick,
  setShowViewOptions,
  courseRequiresPurchase,
}: LessonOptionViewsButtonProps): ReactElement {
  const { t } = useTranslation();

  const viewOptionRef = useRef(null);
  useOnClickOutside(viewOptionRef, handleOutsideClicks);

  function handleOutsideClicks() {
    setShowViewOptions(false);
  }

  return (
    <div className="view-options-module__view_options___3-fnT online-course-module__view_options___3FMMc">
      <button
        type="button"
        className="view-options-module__btn___o29AD view-options__btn"
        title={t('ChooseView')}
        aria-label={t('ChooseView')}
        aria-describedby="chooseViewDescribedBy16"
        aria-expanded="false"
        onClick={() => setShowViewOptions(!showViewOptions)}
      >
        <span
          className={contentView === DisplayContentView.Detailed ? 'icon icon-view-detailed' : 'icon icon-view-list'}
        />
      </button>
      {showViewOptions && (
        <div className="view-options-module__container___13ZzL view-options__container">
          <div className="view-options-module__options____1XHW view-options__options" role="menu">
            <button
              type="button"
              className={`icon-button-module__btn___Zb89b view-options-module__option___3gz7Z view-options__option view-options__option_detailed ${
                contentView === DisplayContentView.Detailed ? 'view-options__option_active' : ''
              }`}
              title={t('DetailView')}
              role="menuitem"
              onClick={() => onChangeContentView(DisplayContentView.Detailed, courseRequiresPurchase || false)}
            >
              <div className="icon icon-view-detailed" />
              <span className="accessibility-text-module__off_screen_text___FSqhy">{t('DetailView')}</span>
            </button>
            <button
              type="button"
              className={`icon-button-module__btn___Zb89b view-options-module__option___3gz7Z view-options__option view-options__option_list ${
                contentView === DisplayContentView.List ? 'view-options__option_active' : ''
              }`}
              title={t('ListView')}
              role="menuitem"
              onClick={() => onChangeContentView(DisplayContentView.List, courseRequiresPurchase || false)}
            >
              <div className="icon icon-view-list" />
              <span className="accessibility-text-module__off_screen_text___FSqhy">{t('ListView')}</span>
            </button>
          </div>
        </div>
      )}

      <span id="chooseViewDescribedBy16" className="accessibility-text-module__off_screen_text___FSqhy">
        {t(contentView)} {t('Selected')}
      </span>

      {showViewOptions && (
        <div
          className="view-options-module__overlay___1JSFl view-options__overlay"
          onClick={onChangeContentViewClick}
        />
      )}
    </div>
  );
}
