import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AbsorbTileType } from '../constants/absorb';
import { CatalogCourseData } from '../models/absorb/catalog';
import { DashboardTileData } from '../models/absorb/dashboard';
import { constructCourseTypePath, tileProperties } from '../utils/helper';

interface Props {
  dashboardTile: DashboardTileData;
  resumeableCourse: CatalogCourseData;
}

export function DashboardTile({ dashboardTile, resumeableCourse }: Props): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function getTitle() {
    return dashboardTile.title ? dashboardTile.title : tileProperties(dashboardTile.type).title;
  }

  function getIconClass() {
    return tileProperties(dashboardTile.type).icon;
  }

  function getTileDestination() {
    if (dashboardTile.type === AbsorbTileType.Resume && resumeableCourse) {
      return constructCourseTypePath(resumeableCourse.courseType, resumeableCourse.id);
    }
    if (dashboardTile.href) {
      return dashboardTile.href;
    }
    return tileProperties(dashboardTile.type).destination;
  }

  function getTileDescriptions() {
    if (dashboardTile.type === AbsorbTileType.Resume && resumeableCourse && resumeableCourse.name) {
      return resumeableCourse.name;
    }
    if (dashboardTile.name) {
      return dashboardTile.name;
    }
    return tileProperties(dashboardTile.type).description;
  }

  function tileContents() {
    return (
      <>
        {dashboardTile.customIconUri ? (
          <div className="dashboard-tile-icon__icon_medium dashboard-tile-icon-module__medium_custom___Maq5R dashboard-tile-icon-module__icon_custom___6H5xP dashboard-tile-icon__icon">
            <img src={dashboardTile.customIconUri} alt="" aria-hidden="true" />
          </div>
        ) : (
          <div
            className={`dashboard-tile-icon__icon_medium dashboard-tile-icon-module__medium___2p7C9 icon ${getIconClass()} dashboard-tile-icon__icon`}
          />
        )}
        <h2
          className="dashboard-tile-title-module__title___1jGXC dashboard-tile-title-module__title_medium___1pw3b dashboard-tile-title__title_medium dashboard-tile-title__title"
          title={dashboardTile.title ? getTitle() : t(getTitle())}
        >
          {dashboardTile.title ? getTitle() : t(getTitle())}
        </h2>
        <div
          className="dashboard-tile-description-module__description___1zDzo dashboard-tile-description-module__description_medium___2nD-J dashboard-tile-description__description_medium dashboard-tile-description__description"
          title={
            (dashboardTile.type === AbsorbTileType.Resume && resumeableCourse && resumeableCourse.name) ||
              dashboardTile.name
              ? getTileDescriptions()
              : t(getTileDescriptions())
          }
        >
          {(dashboardTile.type === AbsorbTileType.Resume && resumeableCourse && resumeableCourse.name) ||
            dashboardTile.name
            ? getTileDescriptions()
            : t(getTileDescriptions())}
        </div>
      </>
    );
  }

  return dashboardTile.href ? (
    <div className="dashboard-tile-wrapper-module__tile___1_5Jt dashboard-tile-wrapper-module__tile_medium___3GfiY dashboard-tile-wrapper__wrapper_medium tile__hyperlink hyperlink-dashboard-tile-module__tile_wrapper___3L1nr dashboard-tile-wrapper__wrapper">
      <a className="hyperlink-dashboard-tile-module__link___11L3-" href={dashboardTile.href} target="_self">
        {tileContents()}
      </a>
    </div>
  ) : (
    <div
      className="dashboard-tile-wrapper-module__tile___1_5Jt dashboard-tile-wrapper-module__tile_medium___3GfiY dashboard-tile-wrapper__wrapper_medium dashboard-tile-wrapper-module__clickable___3fWZE dashboard-tile-wrapper__clickable tile__catalog dashboard-tile-module__tile_wrapper___1Ci1O dashboard-tile-wrapper__wrapper"
      role="button"
      onClick={() => navigate(getTileDestination())}
    >
      {tileContents()}
    </div>
  );
}
