import React, { ReactElement } from 'react';
import { useSearchContext } from '../contexts/searchContext';
import { CategoriesData } from '../models/absorb/catalog';

interface Props {
  catalogCategory: CategoriesData;
}

export function Category({ catalogCategory }: Props): ReactElement {
  const { setCategory, resetPageOffset } = useSearchContext();

  function onCategoryClick() {
    setCategory(catalogCategory.id);
    resetPageOffset();
  }

  return (
    <div className="category-list-module__category___24F1r category-list__category" onClick={onCategoryClick}>
      <div title={catalogCategory.name} className="category-list-module__title___2Qd25 category-list__category_title">
        <div className="icon icon-folder category-list-module__folder___x1YAZ category-list__folder" />
        <div className="category-list-module__title_text___1VIHN">{catalogCategory.name}</div>
      </div>
      <span className="pill-module__pill___1PAoi category-list-module__count___IMihu pill__pill">
        <span className="accessibility-text-module__off_screen_text___FSqhy">{catalogCategory.itemCount}</span>
        <span className="label-primary" aria-hidden="true">
          {catalogCategory.itemCount}
        </span>
      </span>
    </div>
  );
}
