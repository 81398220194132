import React, { ReactElement, useState, useEffect, ChangeEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../components/DashboardLayout';
import { LoadingIndicator } from '../components/Spinner';
import { ResetPasswordData } from '../models/absorb/password';
import { resetPassword, checkResetPasswordTokenValidity } from '../services/profile';
import { doesPasswordsMatchByLength, errorHandler } from '../utils/helper';
import { REGEX_PASSWORD_VALIDATION } from '../constants/regex';
import { useBrandingContext } from '../contexts/brandingContext';
import { SpinnerButton } from '../components/SpinnerButton';
import { PageTitle } from '../components/PageTitle';
import './ResetPasswordPage.css';

export function ResetPasswordPage({ explicitUriToken }: { explicitUriToken?: string }): ReactElement {
  const { urlToken: urlTokenParam } = useParams();
  const urlToken = explicitUriToken || urlTokenParam || '';
  const { t } = useTranslation();

  const { loginPageSettings } = useBrandingContext();

  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordErrorLatch, setPasswordErrorLatch] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [confirmPasswordErrorLatch, setConfirmPasswordErrorLatch] = useState(false);
  const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);
  const [validForm, setValidForm] = useState(false);


  useEffect(() => {
    setIsLoading(true);
    checkResetPasswordTokenValidity(urlToken)
      .then(() => { })
      .catch((err) => {
        setShowErrorPopup(true);
        errorHandler(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [urlToken]);

  useEffect(() => {
    if (passwordErrorLatch) {
      validatePassword();
    }

    if (confirmPasswordErrorLatch) {
      validateConfirmPassword();
    }

    setValidForm(REGEX_PASSWORD_VALIDATION.test(password) && password === confirmPassword);
  }, [password, confirmPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPasswordErrorLatch(passwordErrorLatch || passwordError !== '');
  }, [passwordError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setConfirmPasswordErrorLatch(confirmPasswordErrorLatch || confirmPasswordError !== '');
  }, [confirmPasswordError]); // eslint-disable-line react-hooks/exhaustive-deps

  function validatePassword() {
    setPasswordError(REGEX_PASSWORD_VALIDATION.test(password) ? '' : 'MinimumPassword');
  }

  function validateConfirmPassword() {
    updateConfirmPasswordError(doesPasswordsMatchByLength(password, confirmPassword));
  }

  function onChangeConfirmPassword(e: ChangeEvent<HTMLInputElement>) {
    const tempConfirmPassword = e.target.value;
    if (!confirmPasswordErrorLatch) {
      updateConfirmPasswordError(doesPasswordsMatchByLength(password, tempConfirmPassword));
    }
    setConfirmPassword(tempConfirmPassword);
  }

  function updateConfirmPasswordError(identical: boolean) {
    setConfirmPasswordError(identical ? '' : 'PasswordsNotMatch');
  }

  function onSubmit() {
    const data: ResetPasswordData = {
      NewPassword: password,
    };

    setResetPasswordInProgress(true);
    resetPassword(urlToken, data)
      .then(() => {
        setShowSuccessPopup(true);
      })
      .catch((err) => {
        setShowErrorPopup(true);
        errorHandler(err);
      })
      .finally(() => {
        setResetPasswordInProgress(false);
      });
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('ResetPassword')} />
      <div
        className="reset-password-module__reset_password___13G-k reset-password__reset_password"
        style={{
          backgroundImage: `url("${encodeURI(loginPageSettings?.backgroundImageUrl || '')}")`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <div className="reset-password-module__form_container___EibNL reset-password__form_container">
          {!isLoading && !showSuccessPopup && !showErrorPopup && (
            <div className="reset-password-module__form___2oyy0">
              <h1 className="reset-password-module__title___20Ptf">{t('ResetPassword')}</h1>
              <div className="reset-password-module__subtitle___xmhtE">
                {`${t('ResetPasswordDescription')} ${t('MediumPasswordHint')}`}
              </div>
              <form>
                <div className="redux-form-input-module__field___LP6sO reset-password-module__input___20pxp">
                  <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                    <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                      {t('NewPassword')}
                    </label>
                    <div
                      className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                      aria-hidden="true"
                    >
                      <span className="accessibility-text-module__off_screen_text___FSqhy"> {t('Required')} </span>
                      <span aria-hidden="true">{t('Required')}</span>
                    </div>
                  </div>
                  <input name="username" id="hiddenusername" type="text" autoComplete="username" />
                  <input
                    name="password"
                    type="password"
                    value={password}
                    onBlur={validatePassword}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                  />
                  <div className="redux-form-input-field__errors" id="errorDiv2">
                    {passwordError && (
                      <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                        {t(passwordError)}
                      </span>
                    )}
                  </div>
                </div>
                <div className="redux-form-input-module__field___LP6sO reset-password-module__input___20pxp">
                  <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                    <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                      {t('ConfirmPassword')}
                    </label>
                    <div
                      className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                      aria-hidden="true"
                    >
                      <span className="accessibility-text-module__off_screen_text___FSqhy"> {t('Required')} </span>
                      <span aria-hidden="true">{t('Required')}</span>
                    </div>
                  </div>
                  <input
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={onChangeConfirmPassword}
                    autoComplete="current-password"
                  />
                  <div className="redux-form-input-field__errors" id="errorDiv4">
                    <span
                      id="reset-password-confirmation"
                      className="redux-form-input-field-module__error_message___196Wq form-field__error_message"
                    >
                      {t(confirmPasswordError)}
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn reset-password-module__btn___1YT3x reset-password__btn button-module__btn___1lXcC"
                  disabled={!validForm}
                  onClick={onSubmit}
                >
                  {resetPasswordInProgress ? <SpinnerButton /> : t('ResetPassword')}
                </button>
              </form>
              <div aria-live="assertive" />
              <div className="forgot-password-module__login_wrapper___1a7af">
                <Link className="forgot-password-module__login_link___13sR2 forgot-password__login_link" to="/">
                  {t('BackToLogin')}
                </Link>
              </div>
            </div>
          )}
          {showSuccessPopup && (
            <div className="reset-password-module__form___2oyy0">
              <div className="reset-password-module__hide_focus_border___1XsLc">
                <div aria-live="assertive">
                  <div className="form-info-panel-module__success_message___32u7j">
                    {t('ResetPasswordSuccessBlurb')}
                  </div>
                </div>
                <div className="forgot-password-module__login_wrapper___1a7af">
                  <Link className="forgot-password-module__login_link___13sR2 forgot-password__login_link" to="/">
                    {t('BackToLogin')}
                  </Link>
                </div>
              </div>
            </div>
          )}
          {showErrorPopup && (
            <div className="reset-password-module__form___2oyy0">
              <div className="reset-password-module__hide_focus_border___1XsLc">
                <div aria-live="assertive">
                  <div className="form-info-panel-module__error_messages___3YBAs form__error_messages">
                    <p className="form-info-panel-module__error_message___3XNfo form__error_message">
                      {t('ResetPasswordTokenExpired')}
                    </p>
                  </div>
                </div>
                <div className="forgot-password-module__login_wrapper___1a7af">
                  <Link className="forgot-password-module__login_link___13sR2 forgot-password__login_link" to="/">
                    {t('BackToLogin')}
                  </Link>
                </div>
              </div>
            </div>
          )}
          {isLoading && <LoadingIndicator />}
        </div>
      </div>
    </DashboardLayout>
  );
}
