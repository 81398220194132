import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationModal } from '../modals/LocalizationModal';
import { useLanguageContext } from '../contexts/languageContext';
import { PrivacyModal } from '../modals/PrivacyModal';
import { AppVersionModal } from '../modals/AppVersionModal';
import { useBrandingContext } from '../contexts/brandingContext';
import './Footer.css';
import { parsedHtml } from '../utils/helper';
import { useModalContext } from '../contexts/modalContext';

export function Footer(): ReactElement {
  const { t } = useTranslation();

  const { showModal } = useModalContext();
  const { footer } = useBrandingContext();
  const { language } = useLanguageContext();

  return (
    <footer className="footer-module__container___28tLP footer__wrapper">
      <div className="footer-module__footer___2vz5r footer__footer">
        <div className="language-button-module__language_selector___X67Ay language-button__language_selector">
          <button
            type="button"
            className="icon-button-module__btn___Zb89b language-button-module__language_btn___2Vqwt language-button__language_btn"
            title={t('ChooseYourLanguage')}
            onClick={() => showModal(<LocalizationModal />)}
          >
            <div className="icon icon-globe" />
            <span className="accessibility-text-module__off_screen_text___FSqhy">{t('ChooseYourLanguage')}</span>
            <span className="language-button-module__language_abbreviation___3-P1M language-button__language_abbreviation body-font">
              {language}
            </span>
          </button>
        </div>

        {(footer?.customTextLeft || footer?.customTextCentre || footer?.customTextRight) && (
          <div className="footer-module__custom_footer___3mS_h footer__custom_footer">
            <div
              className={`footer__custom_text footer__custom_text_left sanitized_html ${
                footer?.customTextLeft
                  ? 'footer-module__custom_text___3ZYJx'
                  : 'footer-module__custom_text_empty___2feTQ'
              }`}
            >
              {parsedHtml(footer?.customTextLeft || '')}
            </div>
            {parsedHtml(footer?.customTextCentre)}
            <div
              className={`footer__custom_text footer__custom_text_right sanitized_html ${
                footer?.customTextRight
                  ? 'footer-module__custom_text___3ZYJx'
                  : 'footer-module__custom_text_empty___2feTQ'
              }`}
            >
              {parsedHtml(footer?.customTextRight || '')}
            </div>
          </div>
        )}
        {footer?.privacyPolicyEnabled && (
          <button
            type="button"
            className="footer-module__privacy_policy_btn___QkdSr footer__privacy_policy_btn"
            title={t('PrivacyPolicy')}
            aria-label={t('PrivacyPolicy')}
            onClick={() => showModal(<PrivacyModal />)}
          >
            {t('PrivacyPolicy')}
          </button>
        )}
        <button
          type="button"
          className="icon-button-module__btn___Zb89b footer-module__copyright___1gYSr footer__copyright"
          title={process.env.REACT_APP_VERSION}
          onClick={() => showModal(<AppVersionModal />)}
        >
          <div className="icon-endeavor" />
          <span className="accessibility-text-module__off_screen_text___FSqhy">{process.env.REACT_APP_VERSION}</span>
        </button>
      </div>
    </footer>
  );
}
