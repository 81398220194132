import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../constants/common';
import { useProfileContext } from '../contexts/profileContext';
import { PageTitle } from '../components/PageTitle';

export function ProfilePage(): ReactElement {
  const { t } = useTranslation();

  const { profile, profileFields } = useProfileContext();

  const [middleName, setMiddleName] = useState('');

  useEffect(() => {
    const data = profileFields.filter((field) => field.name === ProfileField.MiddleName);

    if (data.length) {
      setMiddleName(data[0].value);
    }
  }, [profileFields]);

  return (
    <div className="read-only-form-module__fields_wrapper___ErDj-">
      <PageTitle title={t('UserProfile')} />
      <h1 className="read-only-form-module__header___1Yf-h read-only-form__header">{t('UserProfile')}</h1>
      <div className="read-only-form-module__field_group___20pZS read-only-form__field_group">
        <div className="read-only-form-value-field-module__field___mQKi5">
          <div className="read-only-form-value-field-module__label___hUg3Z read-only-form-value-field__label">
            {t('FirstName')}
          </div>
          <div className="read-only-form-value-field-module__value___3KGzm read-only-form-value-field__value">
            {profile?.firstName}
          </div>
        </div>
        <div className="read-only-form-value-field-module__field___mQKi5">
          <div className="read-only-form-value-field-module__label___hUg3Z read-only-form-value-field__label">
            {t('MiddleName')}
          </div>
          <div className="read-only-form-value-field-module__value___3KGzm read-only-form-value-field__value">
            {middleName}
          </div>
        </div>
        <div className="read-only-form-value-field-module__field___mQKi5">
          <div className="read-only-form-value-field-module__label___hUg3Z read-only-form-value-field__label">
            {t('LastName')}
          </div>
          <div className="read-only-form-value-field-module__value___3KGzm read-only-form-value-field__value">
            {profile?.lastName}
          </div>
        </div>
        <div className="read-only-form-value-field-module__field___mQKi5">
          <div className="read-only-form-value-field-module__label___hUg3Z read-only-form-value-field__label">
            {t('Email')}
          </div>
          <div className="read-only-form-value-field-module__value___3KGzm read-only-form-value-field__value">
            {profile?.emailAddress}
          </div>
        </div>
      </div>
    </div>
  );
}
