import React, { ReactElement } from 'react';
import { COMPLETED_PROGRESS } from '../constants/common';

interface Props {
  progress: number;
}

export function LinearProgressIndicator({ progress = 0 }: Props): ReactElement {
  const percentageCompleted = Math.floor(progress);

  return (
    <div
      className={`progress-bar-module__fill___JVvXF progress-bar__fill ${
        percentageCompleted === COMPLETED_PROGRESS && 'progress-bar__fill_success'
      }`}
      style={{ width: `${percentageCompleted}%` }}
    />
  );
}
