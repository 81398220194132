import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  errorText: string;
  subErrorText?: string;
}

export function MessageDisplayView({ errorText, subErrorText }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="courses-module__courses_container___3BeBC">
      <div className="courses-module__no_results_container___me8LI courses__no_results_container">
        <div className="courses-module__no_results_content___1vLob courses__no_results_content">
          <div className="courses-module__no_results_header_text___1ad3j courses__no_results_header_text">
            {t(errorText)}
          </div>
          {subErrorText && (
            <div className="search-results-module__no_results_sub_text___2h2aa search-results__no_results_sub_text">
              {t(subErrorText)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
