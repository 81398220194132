import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SideNav } from './SideNav';
import { SearchBar } from './SearchBar';
import { Messages } from './Messages';
import { useLoginContext } from '../contexts/loginContext';
import { useShoppingContext } from '../contexts/shoppingContext';
import { useBrandingContext } from '../contexts/brandingContext';
import { useMessagesContext } from '../contexts/messagesContext';
import { useProfileContext } from '../contexts/profileContext';
import { useSearchContext } from '../contexts/searchContext';
import { CART_ICON_UNSELECTED, CART_ICON_SELECTED } from '../constants/styling';
import { isOnGlobalSearchPage, WEBVIEW_BREAKPOINT } from '../constants/common';
import { CatalogCourseData } from '../models/absorb/catalog';
import { CartItemSideView } from './CartItemSideView';
import { formatToDollar } from '../utils/currencyCodes';
import { termsAndLoginRedirectStatus } from '../utils/helper';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import './Header.css';

interface Icons {
  search?: boolean;
  cart?: boolean;
  messages?: boolean;
  menu?: boolean;
}

export function Header(): ReactElement {
  const location = useLocation();
  const { t } = useTranslation();

  const termsAndConditionsPage = location.pathname === '/terms-and-conditions';

  const { loggedIn, termsAndConditions, checkLearnerToken } = useLoginContext();
  const { cartData, goToFirstStep, getCartSubTotal } = useShoppingContext();
  const { logoImageUrl } = useBrandingContext();
  const { profile } = useProfileContext();
  const { messagesHeaderCount } = useMessagesContext();
  const { searchTerm } = useSearchContext();

  const [showLoginModal] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [messagesVisible, setMessagesVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= WEBVIEW_BREAKPOINT);

  const menuBarRef = useRef(null);
  const searchBarRef = useRef(null);

  useOnClickOutside(menuBarRef, handleOutsideClicks);
  useOnClickOutside(searchBarRef, handleOutsideClicksSearch);

  useEffect(() => {
    if (loggedIn) {
      setSearchVisible(searchTerm !== '');
    }
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.addEventListener('mouseup', checkLearnerToken);
    return () => document.removeEventListener('mouseup', checkLearnerToken);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function handleScreenResize() {
      setIsMobileView(window.innerWidth <= WEBVIEW_BREAKPOINT);
    }

    window.addEventListener('resize', handleScreenResize);
    return () => window.removeEventListener('resize', handleScreenResize);
  }, []);

  function handleOutsideClicks() {
    handleClickOutSideHeaderMenu({ search: searchVisible });
  }

  function handleOutsideClicksSearch() {
    handleClickOutSideHeaderMenu({ search: isOnGlobalSearchPage() });
  }

  function handleClickOutSideHeaderMenu({ search, cart, messages, menu }: Icons): void {
    setSearchVisible(search || false);
    setCartVisible(cart || false);
    setMessagesVisible(messages || false);
    setMenuVisible(menu || false);
  }

  return (
    <header className="header-module__header_wrapper___36MSU header__wrapper">
      {(menuVisible || messagesVisible || showLoginModal || (cartVisible && cartData.length !== 0)) && (
        <div className="app-module__menu_blocker___FkcBE app__menu_blocker" />
      )}
      <div className="header-module__header___3Ap-_ header-module__header_shadow___1HILB header__header">
        <Link
          to={termsAndLoginRedirectStatus(loggedIn, termsAndConditions?.currentUserMustAcceptTermsAndConditions)}
          target="_self"
          title="KREW | IADC"
          className="header__brand_logo header-module__brand_logo___1lP3u"
        >
          <img src={logoImageUrl || ''} title="KREW | IADC" alt="KREW | IADC" />
        </Link>

        <div className="header-module__menu_container___1MZIW header__menu_container">
          {searchVisible && (
            <div
              ref={searchBarRef}
              className={
                isMobileView
                  ? 'header-module__search_menu_mobile___6lj04'
                  : 'header-module__search_menu___3SStL header__search_menu'
              }
              role="none"
            >
              <SearchBar />
            </div>
          )}
          {loggedIn && !termsAndConditionsPage && !termsAndConditions?.currentUserMustAcceptTermsAndConditions && (
            <div ref={menuBarRef} className="header-module__menu___1BzhK header__menu">
              <nav aria-label="Utility">
                <ul>
                  {!searchVisible && (
                    <li>
                      <button
                        type="button"
                        className="icon-button-module__btn___Zb89b header-module__btn___2r7Qs header__btn"
                        title={t('Search')}
                        onClick={() => handleClickOutSideHeaderMenu({ search: true })}
                      >
                        <div className="icon icon-lens" />
                        <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Search')}</span>
                      </button>
                    </li>
                  )}
                  {searchVisible && isMobileView && (
                    <li>
                      <button
                        type="button"
                        className="icon-button-module__btn___Zb89b header-module__btn___2r7Qs header__btn"
                        title={t('Search')}
                        onClick={() => handleClickOutSideHeaderMenu({ search: true })}
                      >
                        <div className="icon icon-lens" />
                        <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Search')}</span>
                      </button>
                    </li>
                  )}
                  <li>
                    {cartData.length !== 0 && (
                      <button
                        type="button"
                        onClick={() => handleClickOutSideHeaderMenu({ cart: !cartVisible })}
                        className={cartVisible ? CART_ICON_SELECTED : CART_ICON_UNSELECTED}
                        title={t('ShoppingCart')}
                        aria-describedby="header-shopping-cart-item-count"
                      >
                        <div className="icon icon-cart" />
                        <span className="accessibility-text-module__off_screen_text___FSqhy">{t('ShoppingCart')}</span>
                        <span className="pill-module__pill___1PAoi header-module__total_count___3nRmL menu__total_count body-font pill__pill">
                          <span
                            id="header-shopping-cart-item-count"
                            className="accessibility-text-module__off_screen_text___FSqhy"
                          >
                            {t('ShoppingCartItemCountAccessibilityText').replace('{0}', cartData.length.toString())}
                          </span>
                          <span className={cartVisible ? 'message-modal__icon' : 'label-primary'} aria-hidden="true">
                            {cartData.length}
                          </span>
                        </span>
                      </button>
                    )}
                    {cartVisible && cartData.length !== 0 && (
                      <div className="menu-module__menu___3FOuc menu__menu" onClick={(e) => e.stopPropagation()}>
                        <div className="shopping-cart-menu-module__top___oAJL8 shopping-cart-menu__top">
                          <Link
                            className="link-button-module__link___3tT4y shopping-cart-menu__shopping_cart_link btn"
                            title={t('ViewShoppingCart')}
                            to="/cart"
                            onClick={() => {
                              handleClickOutSideHeaderMenu({ cart: false });
                              goToFirstStep();
                            }}
                          >
                            {t('ViewShoppingCart')}
                          </Link>
                          <div className="shopping-cart-menu-module__subtotal___PD0mX">
                            <div className="shopping-cart-menu-module__subtotal_title___11BOQ">
                              {t('PurchaseSubtotal')}
                            </div>
                            <div className="shopping-cart-menu-module__subtotal_price___wzBk5 shopping-cart-menu__subtotal_price">
                              {formatToDollar(getCartSubTotal())}
                            </div>
                          </div>
                        </div>
                        {cartData.map((cart: CatalogCourseData, index: number) => (
                          <CartItemSideView key={cart.id} cartItem={cart} index={index} />
                        ))}
                      </div>
                    )}
                  </li>
                  <li>
                    <button
                      type="button"
                      className={
                        messagesVisible
                          ? 'icon-button-module__btn___Zb89b header-module__btn___2r7Qs header-module__messages_btn___1cTcH header__btn header__messages_link header-module__btn_selected___AqqXV header__btn_selected'
                          : 'icon-button-module__btn___Zb89b header-module__btn___2r7Qs header-module__messages_btn___1cTcH header__btn header__messages_link'
                      }
                      title={t('Messages')}
                      aria-expanded="false"
                      aria-haspopup="true"
                      aria-describedby="header-message-item-count"
                      onClick={() => handleClickOutSideHeaderMenu({ messages: !messagesVisible })}
                    >
                      <div className="icon icon-mail" />
                      <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Messages')}</span>
                      <span className="pill-module__pill___1PAoi header-module__total_count___3nRmL menu__total_count body-font pill__pill">
                        {messagesHeaderCount !== 0 && (
                          <span
                            id="header-message-item-count"
                            className="accessibility-text-module__off_screen_text___FSqhy"
                          >
                            {t('UnreadPriorityMessageCountAccessibilityText').replace(
                              '{0}',
                              messagesHeaderCount ? messagesHeaderCount.toString() : '0'
                            )}
                          </span>
                        )}
                        {messagesHeaderCount !== 0 && (
                          <span
                            className={messagesVisible ? 'message-modal__icon' : 'label-primary'}
                            aria-hidden="true"
                          >
                            {messagesHeaderCount}
                          </span>
                        )}
                      </span>
                    </button>
                    {messagesVisible && <Messages setMessagesVisible={setMessagesVisible} />}
                  </li>
                  <li>
                    <Link
                      className={`header-module__avatar_link___1td9P header__btn header__profile_link ${
                        profile?.avatar ? '' : 'icon icon-profile'
                      }`}
                      title={t('UserProfile')}
                      to="/profile"
                      onClick={() => handleClickOutSideHeaderMenu({})}
                    >
                      {profile?.avatar ? (
                        <img
                          src={profile ? profile.avatar : ''}
                          alt={`${profile.emailAddress}`}
                          className="header-module__avatar___1yVPC menu__avatar"
                        />
                      ) : (
                        <></>
                      )}
                    </Link>
                  </li>
                </ul>
              </nav>
              <nav aria-label="Primary">
                <ul>
                  <li>
                    <button
                      type="button"
                      className={
                        menuVisible
                          ? 'icon-button-module__btn___Zb89b header-module__btn___2r7Qs header-module__btn_menu___1mO8K header__btn header__btn_menu  header-module__btn_selected___AqqXV header__btn_selected'
                          : 'icon-button-module__btn___Zb89b header-module__btn___2r7Qs header-module__btn_menu___1mO8K header__btn header__btn_menu'
                      }
                      title={t('Menu')}
                      aria-expanded="true"
                      onClick={() => handleClickOutSideHeaderMenu({ menu: !menuVisible })}
                    >
                      <div className="icon icon-hamburger" />
                      <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Menu')}</span>
                    </button>
                    {menuVisible && <SideNav handleClickOutSideHeaderMenu={handleClickOutSideHeaderMenu} />}
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
