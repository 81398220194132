import React, { ReactElement, useState, useEffect } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { CircularProgressBarWidth } from '../constants/common';

interface Props {
  totalCompleted: number;
  totalRequired: number;
  enrollmentStatus?: string;
  progress?: number;
  showProgressInTopView?: boolean;
  courseType?: string;
}

export function RadialProgressIndicatorLarge({
  totalCompleted,
  totalRequired,
  enrollmentStatus,
  progress,
  showProgressInTopView,
  courseType,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [stateText, setStateText] = useState(
    enrollmentStatus === 'Complete' ? 'Completed' : enrollmentStatus || 'NotStarted'
  );

  useEffect(() => {
    if (progress) {
      setCompletedPercentage(Math.round(progress));
    }
  }, [progress]);

  useEffect(() => {
    if (completedPercentage === 0 && progress) {
      setStateText('NotStarted');
    } else if (completedPercentage > 0 && completedPercentage < 100) {
      setStateText('InProgress');
    } else if (completedPercentage === 100) {
      setStateText('Completed');
    }
  }, [completedPercentage, progress]);

  return (
    <div
      className={`course-progress-module__course_progress___25zVV course-progress__course_progress ${
        showProgressInTopView
          ? 'online-course-module__course_progress_mobile___aLBPR'
          : 'course-sidebar-module__course_progress_desktop___3R-rz'
      }`}
    >
      <div className="course-progress-module__title_container___2TE5n">
        <h2 className="course-progress-module__title___1-C5D course-progress__title body-font">
          <span className="accessibility-text-module__off_screen_text___FSqhy">
            {t('CourseProgress')} {t(stateText)}
          </span>
          <span aria-hidden="true">{t(stateText)}</span>
        </h2>
        <div className="course-progress__sub_title">
          {`${totalCompleted}/${totalRequired}`} {courseType ? t('Courses') : ''}
        </div>
      </div>
      <div className="course-progress-module__graphic___3GjQV">
        <div
          style={{
            width: showProgressInTopView
              ? CircularProgressBarWidth.TopViewWidth
              : CircularProgressBarWidth.SidebarWidth,
          }}
        >
          <CircularProgressbar
            value={completedPercentage}
            styles={buildStyles({
              strokeLinecap: 'butt',
              textSize: '16px',
              pathTransitionDuration: 0.5,
              pathColor: completedPercentage < 100 ? '#AB192D' : '#8CC555',
              textColor: '#daddde',
              trailColor: '#A9A9A9',
              backgroundColor: '#daddde',
            })}
          />
        </div>
        <div className="course-progress-module__percent___2Q3CM course-progress__percent">{`${completedPercentage}%`}</div>
      </div>
    </div>
  );
}
