import React, { ReactElement, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ABSORB_VENDOR } from '../../constants/absorb';
import { KEY_ENTER } from '../../constants/common';
import { useSearchContext } from '../../contexts/searchContext';
import { SearchFilterParamType } from '../../models/absorb/search';
import { InputBox } from './InputBox';

export function VendorFilter(): ReactElement {
  const { t } = useTranslation();

  const { advancedFilter, setAdvancedFilter, removeAdvancedFilter } = useSearchContext();

  const [vendorData, setVendorData] = useState(advancedFilter[ABSORB_VENDOR]);

  function clearVendorData() {
    setVendorData('');
    removeAdvancedFilter(ABSORB_VENDOR);
  }

  function onKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === KEY_ENTER) {
      updateAdvancedFilter(vendorData);
    }
  }

  function updateAdvancedFilter(filterData: SearchFilterParamType) {
    setAdvancedFilter({ ...advancedFilter, [ABSORB_VENDOR]: filterData });
  }

  return (
    <div className="filter-panel-module__filter_panel___2xlm8 filter-panel__filter_panel">
      <button className="icon-button-module__btn___Zb89b filter-panel-module__remove_button___1970P filter-panel__remove_button">
        <div className="icon icon-x-circle" title={t(ABSORB_VENDOR)} onClick={clearVendorData} />
        <span className="accessibility-text-module__off_screen_text___FSqhy">
          {t('Remove')} {t('Vendor')} {t('Filter')}
        </span>
      </button>
      <InputBox
        label={t('Vendor')}
        placeholder={t('Vendor')}
        value={vendorData as string}
        onChangeHandleInput={(e) => setVendorData(e.target.value)}
        onChangeHandleCrossButton={() => {
          setVendorData('');
          updateAdvancedFilter('');
        }}
        onKeyPress={onKeyPress}
      />
    </div>
  );
}
