import React, { ReactElement } from 'react';
import { BillingDetails } from '../components/BillingDetails';
import { Summary } from '../components/Summary';
import { useShoppingContext } from '../contexts/shoppingContext';
import { ShoppingCartContainer } from '../components/ShoppingCartContainer';
// import {TitleToPage} from '../components/TitleToEachPage';

export function CheckoutPage(): ReactElement {
  const { goToPrevStep, goToNextStep, setBillingDetailsState } = useShoppingContext();

  return (
    <ShoppingCartContainer>
      <div className="cart-account-module__content___3DAOj flex-direction-col">
        <BillingDetails
          goToPrevStep={() => goToPrevStep('/cart')}
          goToNextStep={() => goToNextStep('/cart/payment')}
          storeBillingDetails={setBillingDetailsState}
        />
        <Summary />
      </div>
    </ShoppingCartContainer>
  );
}
