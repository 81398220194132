import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFilterFlags } from '../../models/absorb/search';

import { CheckBox } from './CheckBox';

interface Props {
  courseFilter: SearchFilterFlags;
  update: (filterFlags: SearchFilterFlags) => void;
}

export function CourseFilterCheckBoxesList({ courseFilter, update }: Props): ReactElement {
  const { t } = useTranslation();

  function handleCheckBox(key: string) {
    update({ ...courseFilter, [key]: !courseFilter[key] });
  }

  return (
    <>
      {Object.keys(courseFilter).map((key) => (
        <li key={key}>
          <CheckBox
            label={t(key)}
            value={key}
            isDynamicChecked
            isChecked={courseFilter[key]}
            handleCheckbox={() => handleCheckBox(key)}
          />
        </li>
      ))}
    </>
  );
}
