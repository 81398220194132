import React, { ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useShoppingContext } from '../contexts/shoppingContext';
import { useLoginContext } from '../contexts/loginContext';
import { useMessagesContext } from '../contexts/messagesContext';
import { isAdmin, useProfileContext } from '../contexts/profileContext';
import { ProfileTab } from '../constants/common';

const KREW_ADMIN_URL = process.env.REACT_APP_KREW_ADMIN_URL || '';

interface Props {
  handleClickOutSideHeaderMenu: (key: { menu: boolean }) => void;
}

export function SideNav({ handleClickOutSideHeaderMenu }: Props): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { signOut } = useLoginContext();
  const { cartData } = useShoppingContext();
  const { profile } = useProfileContext();
  const { messagesHeaderCount } = useMessagesContext();

  function onLogout() {
    signOut();
    handleOnSideNavItemClick();
  }

  function getHeading(): string {
    let headerText = t('LoggedInAs');

    if (headerText.includes('{1}') && headerText.includes('{2}')) {
      headerText = headerText.replace('{1}', '');
      headerText = headerText.replace('{2}', '');
    }

    return headerText;
  }

  function getHeadingWithName(): string {
    let headerText = t('LoggedInAs');
    const firstName = profile?.firstName ? profile.firstName : '';
    const lastName = profile?.lastName ? profile.lastName : '';

    if (headerText.includes('{1}') && headerText.includes('{2}')) {
      headerText = headerText.replace('{1}', firstName);
      headerText = headerText.replace('{2}', lastName);
    }

    return headerText;
  }

  function getCartAccessibilityText(): string {
    let cartAccessibilityText = t('ShoppingCartItemCountAccessibilityText');

    if (cartAccessibilityText.includes('{0}')) {
      cartAccessibilityText = cartAccessibilityText.replace('{0}', '');
    }

    return cartAccessibilityText;
  }

  function handleOnSideNavItemClick() {
    handleClickOutSideHeaderMenu({ menu: false });
  }

  return (
    <div className="menu-module__menu___3FOuc menu__menu">
      <ul className="navigation-menu-module__navigation_menu___2V0Cz">
        <li className="navigation-menu-module__profile___3yp8I navigation-menu__profile">
          <div className="navigation-menu-module__profile_user___2Q6EW">
            {profile?.avatar ? (
              <div
                className="navigation-menu-module__avatar_link___2Wb9E icon navigation-menu__avatar_link"
                onClick={() => navigate('/profile')}
              >
                <img
                  className="navigation-menu-module__avatar_menu___2HxpL navigation-menu__avatar_menu"
                  src={profile ? profile.avatar : ''}
                  alt="profile"
                />
              </div>
            ) : (
              <div
                className="navigation-menu-module__avatar_link___2Wb9E icon icon-profile navigation-menu__avatar_link"
                onClick={() => navigate('/profile')}
              />
            )}
            <div className="navigation-menu-module__username_container___2qUkP" aria-hidden="true">
              <div
                title={getHeadingWithName()}
                className="navigation-menu-module__logged_in_as___iVg52 navigation-menu__logged_in_as"
              >
                {getHeading()}
              </div>
              <div className="navigation-menu-module__username___1q3Zt navigation-menu__username">
                {profile?.firstName} {profile?.lastName}
              </div>
            </div>
          </div>
        </li>
        {cartData.length > 0 && (
          <li aria-hidden="true">
            <Link
              className="navigation-menu-module__link___2wAi6 navigation-menu-module__hide_on_non_mobile___17mde navigation-menu__link"
              to="/cart"
            >
              <div className="navigation-menu-module__messages_wrapper___8Jho1">
                <div>
                  <div className="icon icon-shopping-cart navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
                  <span>{t('ShoppingCart')}</span>
                </div>
                <span className="pill-module__pill___1PAoi menu__total_count pill__pill">
                  <span
                    id="navigation-menu-shopping-cart-count"
                    className="accessibility-text-module__off_screen_text___FSqhy"
                  >
                    {' '}
                    {cartData.length}
                    {getCartAccessibilityText()}{' '}
                  </span>
                  {/* TODO: E1-544 Setting the color like this here is a temporary workaround since the inherited
                    color from Absorb from the pill__pill class incorrectly sets the text color to be the same
                    as the background color. If the color attribute is removed from the pill__pill class, then this
                    line should no longer be necessary */}
                  {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                  <span style={{ color: getComputedStyle(document.querySelector('body')!).color }} aria-hidden="true">
                    {cartData.length}
                  </span>
                </span>
              </div>
            </Link>
          </li>
        )}
        <li aria-hidden="true">
          <Link
            className="navigation-menu-module__link___2wAi6 navigation-menu-module__hide_on_non_mobile___17mde navigation-menu__link"
            to={`/profile?tab=${ProfileTab.Messages}`}
          >
            <div className="navigation-menu-module__messages_wrapper___8Jho1">
              <div>
                <div className="icon icon-mail navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
                <span>{t('Messages')}</span>
              </div>
              {messagesHeaderCount !== 0 && (
                <span className="pill-module__pill___1PAoi menu__total_count pill__pill">
                  <span
                    id="navigation-menu-message-item-count"
                    className="accessibility-text-module__off_screen_text___FSqhy"
                  >
                    {messagesHeaderCount}
                  </span>
                  {/* TODO: E1-544 Setting the color like this here is a temporary workaround since the inherited
                    color from Absorb from the pill__pill class incorrectly sets the text color to be the same
                    as the background color. If the color attribute is removed from the pill__pill class, then this
                    line should no longer be necessary */}
                  {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                  <span style={{ color: getComputedStyle(document.querySelector('body')!).color }} aria-hidden="true">
                    {messagesHeaderCount}
                  </span>
                </span>
              )}
            </div>
          </Link>
        </li>
        <li>
          <Link
            className="navigation-menu-module__link___2wAi6 navigation-menu__link"
            to="/dashboard"
            onClick={handleOnSideNavItemClick}
          >
            <div className="icon icon-dashboard navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
            <span>{t('Dashboard')}</span>
          </Link>
        </li>
        <li>
          <Link
            className="navigation-menu-module__link___2wAi6 navigation-menu__link"
            to="/my-courses"
            onClick={handleOnSideNavItemClick}
          >
            <div className="icon icon-courses navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
            <span>{t('MyCourses')}</span>
          </Link>
        </li>
        <li>
          <Link
            className="navigation-menu-module__link___2wAi6 navigation-menu__link"
            to="/catalog"
            onClick={handleOnSideNavItemClick}
          >
            <div className="icon icon-book navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
            <span>{t('Catalog')}</span>
          </Link>
        </li>
        <li>
          <Link
            className="navigation-menu-module__link___2wAi6 navigation-menu__link"
            to="/resources"
            onClick={handleOnSideNavItemClick}
          >
            <div className="icon icon-binder navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
            <span>{t('Resources')}</span>
          </Link>
        </li>
        {/*
          // TODO: E1-518 restore this when Calendar is implemented
          <li>
            <Link
              className="navigation-menu-module__link___2wAi6 navigation-menu__link"
              to="#/courses/?viewType=Calendar"
            >
              <div className="icon icon-calendar navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon"/>
              <span>
                {t('Calendar')}
              </span>
            </Link>
          </li> */}
        <li>
          <Link
            className="navigation-menu-module__link___2wAi6 navigation-menu__link"
            to="/transcript"
            onClick={handleOnSideNavItemClick}
          >
            <div className="icon icon-transcript navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
            <span>{t('Transcript')}</span>
          </Link>
        </li>
        <li>
          <Link
            className="navigation-menu-module__link___2wAi6 navigation-menu__link"
            to="/profile"
            onClick={handleOnSideNavItemClick}
          >
            <div className="icon icon-profile navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
            <span>{t('UserProfile')}</span>
          </Link>
        </li>
        {isAdmin(profile?.username) ? (
          <li>
            <button
              className="navigation-menu-module__link___2wAi6 navigation-menu__link"
              onClick={() => {
                document.location.href = KREW_ADMIN_URL;
              }}
            >
              <div className="icon icon-admin-gear navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
              {t('Admin')}
            </button>
          </li>
        ) : null}
        <li>
          <button className="navigation-menu-module__link___2wAi6 navigation-menu__link" onClick={onLogout}>
            <div className="icon icon-logout-arrow navigation-menu-module__link_icon___2PF1Q navigation-menu__link_icon" />
            {t('LogOff')}
          </button>
        </li>
      </ul>
    </div>
  );
}
