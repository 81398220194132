import React, { ReactElement } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { CARD_ELEMENT_OPTIONS } from '../constants/styling';
import './CardSection.css';

interface Props {
  changeHandler: (e: StripeCardElementChangeEvent) => void;
}

export function CardSection({ changeHandler }: Props): ReactElement {
  return (
    <>
      <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label" />
      <CardElement options={CARD_ELEMENT_OPTIONS} onChange={changeHandler} />
    </>
  );
}
