import { ReactElement } from 'react';

interface Props {
  condition: boolean;
  children: ReactElement;
  wrapper: (children: ReactElement) => ReactElement;
}

export function ConditionalWrapper({ condition, children, wrapper }: Props): ReactElement {
  return condition ? wrapper(children) : children;
}
