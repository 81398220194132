import React, { ReactElement } from 'react';
import { SearchResultData } from '../models/absorb/search';
import { ResourceDetail } from './ResourceDetail';

interface Props {
  searchResults: SearchResultData[] | undefined;
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function SearchResultDetailedView({ searchResults, updatePinnedStatus }: Props): ReactElement {
  return (
    <div className="seach-results__panels_container">
      {searchResults &&
        searchResults.map((searchResult: SearchResultData) => {
          return (
            <ResourceDetail
              key={searchResult.resource.id}
              resource={searchResult}
              updatePinnedStatus={updatePinnedStatus}
            />
          );
        })}
    </div>
  );
}
