import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AbsorbCourseType } from '../constants/absorb';
import { CatalogCourseData } from '../models/absorb/catalog';
import { ExpandableDescription } from './ExpandableDescription';
import { TagList } from './TagList';

interface Props {
  courseData: CatalogCourseData;
}

export function CourseDescription({ courseData }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      {courseData.tags.length > 0 && (
        <div className="panel-module__list_wrapper___2heyx">
          <div className="tag-list-module__tags___O_Obx online-course-panel-module__tags___1bfQF">
            <div className="tag-list-module__title___3nUy0 online-course-panel-module__tags_title___v77pZ tag-list__title">
              {t('Tags')}:
            </div>
            <TagList tags={courseData.tags} />
          </div>
        </div>
      )}
      {courseData.name && courseData.description && (
        <div
          className={
            courseData.courseType !== AbsorbCourseType.InstructorLedCourse ? 'panel-module__panel_main___2poim' : ''
          }
        >
          <ExpandableDescription itemName={courseData.name} description={courseData.description} />
        </div>
      )}
    </>
  );
}
