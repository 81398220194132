export enum ShoppingCartStep {
  One = 1,
  Two = 2,
  Three = 3,
}

export const MAX_DECIMAL_PLACES = 2;

export const CENTS_PER_DOLLAR = 100;
export const US_DOLLAR_CURRENCY = 'USD';

export const SALES_TAX_RATE_PERCENT = 8.25;
export const SALES_TAX_RATE = 0.0825;
export const SALES_TAX_MULTIPLIER = 1 + SALES_TAX_RATE;
