import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DashboardLayout } from '../components/DashboardLayout';
import { CourseCard } from '../components/CourseCard';
import { CourseList } from '../components/CourseList';
import { CourseDetail } from '../components/CourseDetail';
import { SideFilter } from '../components/SideFilter';
import { LoadMoreActionButton } from '../components/LoadMoreActionButton';
import { ViewOptionsButton } from '../components/ViewOptionsButton';
import { MessageDisplayView } from '../components/MessageDisplayView';
import { LoadingIndicator } from '../components/Spinner';
import { fetchMyCourses, fetchMyCourseCategories } from '../services/course';
import { CategoriesData, CatalogCourseData } from '../models/absorb/catalog';
import { useBrandingContext } from '../contexts/brandingContext';
import { useSearchContext } from '../contexts/searchContext';
import { useContentViewContext } from '../contexts/contentViewContext';
import { COURSES_PER_PAGE, ROOT_CATEGORY, ViewOptionPage, DisplayMessages } from '../constants/common';
import { DisplayContentView, SortCoursesBy } from '../constants/courses';
import { errorHandler } from '../utils/helper';
import './MyCoursesPage.css';
import { Category } from '../components/Category';
import { Breadcrumb } from '../components/Breadcrumb';
import { PageTitle } from '../components/PageTitle';

export function MyCoursesPage(): ReactElement {
  const { categoryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { brandingCoursesSettings } = useBrandingContext();
  const {
    currentSearchUri,
    previousSearchUri,
    searchFilter,
    pageOffset,
    sortBy,
    setDisableFilter,
    resetPageOffset,
    incrementPageOffset,
    setSortBy,
  } = useSearchContext();
  const { contentView, setContentView, setContentViewOnPage, getContentViewOnPage } = useContentViewContext();

  const [showViewOptions, setShowViewOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showRefineSearch, setShowRefineSearch] = useState(true);
  const [courseCategories, setCourseCategories] = useState<CategoriesData[]>([]);
  const [selectedCategoryData, setSelectedCategoryData] = useState<CategoriesData>();
  const [moreIsLoading, setMoreIsLoading] = useState(false);
  const [totalCourses, setTotalCourses] = useState(0);
  const [myCourseData, setMyCourseData] = useState<CatalogCourseData[]>([]);

  const sort = `${SortCoursesBy.Ispinned},${sortBy || SortCoursesBy.EnrolledDate},${SortCoursesBy.Name}`;
  const selectedCategory = categoryId ?? (searchFilter.showCategories && ROOT_CATEGORY);

  useEffect(() => {
    if (currentSearchUri.includes('/my-courses')) {
      setContentView(getContentViewOnPage(ViewOptionPage.MyCourse) as DisplayContentView);

      if (!moreIsLoading) {
        setIsLoading(true);
        setDisableFilter(true);
      }
      fetchMyCourses(
        {
          _sort: sort,
          _limit: COURSES_PER_PAGE,
          ...searchFilter,
          category: selectedCategory,
        },
        pageOffset
      )
        .then((data) => {
          setMyCourseData(pageOffset ? [...myCourseData, ...data.courses] : data.courses);
          setTotalCourses(data.totalItems);
        })
        .catch(errorHandler)
        .finally(() => {
          setIsLoading(false);
          setDisableFilter(false);
          setMoreIsLoading(false);
        });
    }
  }, [currentSearchUri, pageOffset, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentSearchUri.includes('/my-courses')) {
      fetchMyCourseCategories({
        _sort: sort,
        ...searchFilter,
        parent: ROOT_CATEGORY,
      })
        .then(setCourseCategories)
        .catch(errorHandler);
    }
  }, [currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filteredCategory = courseCategories.find((categoryItem) => categoryItem.id === categoryId);
    setSelectedCategoryData(filteredCategory);
  }, [courseCategories, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  function onChangeContentView(viewChange: DisplayContentView) {
    setContentView(viewChange);
    setContentViewOnPage(ViewOptionPage.MyCourse, viewChange);
    setShowViewOptions(false);
  }

  function loadMore() {
    setMoreIsLoading(true);
    incrementPageOffset();
  }

  function onChangeFilter(filterValue: SortCoursesBy) {
    resetPageOffset();
    setMyCourseData([]);
    setSortBy(filterValue);
  }

  function updatePinnedStatus(isPinned: boolean, courseId: string) {
    const coursesToUpdate = [...myCourseData];
    const index = coursesToUpdate.findIndex((course) => course.id === courseId);
    if (index > -1) {
      coursesToUpdate[index].isPinned = isPinned;
      setMyCourseData(coursesToUpdate);
    }
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('MyCourses')} />
      <div className="catalog-module__container___1shwX">
        <div aria-live="assertive" role="status" />
        <div
          className="banner-module__banner___IJO0Z banner__banner"
          style={{
            backgroundImage: `url("${encodeURI(brandingCoursesSettings?.myCoursesAndCatalog?.bannerUri || '')}")`,
          }}
        >
          {selectedCategoryData && (
            <button
              type="button"
              aria-label={t('MyCourses')}
              className="icon icon-back-arrow banner-back-button-module__button___czAfx banner__back_arrow"
              title={t('MyCourses')}
              onClick={() => navigate(previousSearchUri)}
            />
          )}
          <h1 className="banner-title-module__title___1mbDk banner__title">
            {selectedCategoryData?.name || t('MyCourses')}
          </h1>
        </div>
        <div className="catalog-module__catalog___3V16B">
          <div className="catalog-module__header___CV89E">
            <div
              className={`filter-toggle-module__filter_toggle___zOXqM filter-toggle__filter_toggle ${showRefineSearch
                ? ''
                : 'filter-toggle-module__filter_toggle_hidden___3cC5a filter-toggle__filter_toggle_hidden'
                }`}
            >
              <button
                type="button"
                className="icon-button-module__btn___Zb89b filter-toggle-module__filter_toggle_btn___1itNb filter-toggle__filter_toggle_btn"
                title={t('HideFilters')}
                onClick={() => setShowRefineSearch(!showRefineSearch)}
              >
                <div className={`icon ${showRefineSearch ? 'icon-filter-minus' : 'icon-filter-plus'}`} />
                <span className="accessibility-text-module__off_screen_text___FSqhy">
                  {showRefineSearch ? t('HideFilters') : ''}
                </span>
                <span className="filter-toggle-module__filter_toggle_message___1ucsU filter-toggle__message body-font">
                  {showRefineSearch ? t('HideRefineSearch') : ''}
                </span>
              </button>
            </div>

            {selectedCategoryData && categoryId && (
              <Breadcrumb
                breadcrumbTitle="MyCourses"
                selectedCategoryData={selectedCategoryData}
                categoryId={categoryId}
              />
            )}

            <div className="catalog-module__header_controls___1UVuX">
              <div className="catalog-module__header_controls___1UVuX">
                <div className="dropdown-module__field___5TFai catalog-module__sort_dropdown___PhEJQ catalog__sort_dropdown">
                  <select
                    name="CatalogSortDropDown"
                    id="CatalogSortDropDown"
                    aria-label="Sort By"
                    value={sortBy}
                    onChange={(e) => onChangeFilter(e.target.value as SortCoursesBy)}
                  >
                    <option value="-recentactivitydate">{t('RecentActivity')}</option>
                    <option value="name">{t('Alphabetical')}</option>
                    <option value="-ismandatory">{t('Mandatory')}</option>
                    <option value="-creationdate">{t('Newest')}</option>
                    <option value="-expirydate">{t('ExpiryDate')}</option>
                  </select>
                  <span className="dropdown-module__dropdown_arrow___9etxZ dropdown__arrow icon icon-arrow-down" />
                </div>
                <ViewOptionsButton
                  showViewOptions={showViewOptions}
                  contentView={contentView}
                  onChangeContentView={onChangeContentView}
                  setShowViewOptions={setShowViewOptions}
                />
                <span id="chooseViewDescribedBy10" className="accessibility-text-module__off_screen_text___FSqhy">
                  {t('CardView')} {t('Selected')}
                </span>
              </div>
            </div>
          </div>
          <div className="catalog-module__content___2vysm">
            <div className="catalog-module__content_wrapper___2EG6P catalog__content_wrapper">
              {
                // eslint-disable-next-line no-nested-ternary
                isLoading ? (
                  <div className="catalog-module__loader_wrapper___1L0o-">
                    <LoadingIndicator />
                  </div>
                ) : myCourseData && myCourseData.length === 0 ? (
                  <MessageDisplayView errorText={DisplayMessages.NoResultsMainText} />
                ) : (
                  <>
                    {courseCategories.length > 0 && searchFilter.showCategories && !categoryId && (
                      <div className="category-list-module__categories_wrapper___YHLXw">
                        {courseCategories.map((courseCategory) => (
                          <Category key={courseCategory.id} catalogCategory={courseCategory} />
                        ))}
                      </div>
                    )}
                    <div className="catalog-module__courses_container___1jzcE">
                      {contentView === DisplayContentView.Cards && (
                        <div className="catalog-module__cards_container___35rgq catalog__cards_container">
                          {myCourseData &&
                            myCourseData.map((course: CatalogCourseData) => (
                              <div
                                key={course.id}
                                className="card-carousel-module__card___vOE4F carousel__card course-card-margin"
                              >
                                <CourseCard cardData={course} updatePinnedStatus={updatePinnedStatus} />
                              </div>
                            ))}
                        </div>
                      )}
                      {contentView === DisplayContentView.Detailed && (
                        <div className="catalog__panels_container">
                          {myCourseData &&
                            myCourseData.map((course: CatalogCourseData) => (
                              <CourseDetail
                                key={course.id}
                                courseData={course}
                                updatePinnedStatus={updatePinnedStatus}
                              />
                            ))}
                        </div>
                      )}
                      {contentView === DisplayContentView.List && (
                        <CourseList catalogData={myCourseData} updatePinnedStatus={updatePinnedStatus} />
                      )}
                      {myCourseData && myCourseData.length < totalCourses ? (
                        <LoadMoreActionButton
                          currentItemsCount={myCourseData.length}
                          totalItemsCount={totalCourses}
                          itemsAreLoading={moreIsLoading}
                          fetchMoreFunction={loadMore}
                        />
                      ) : null}
                    </div>
                  </>
                )
              }
            </div>
            {showRefineSearch && <SideFilter />}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
