import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useLoginContext } from '../contexts/loginContext';
import { useBrandingContext } from '../contexts/brandingContext';
import { DashboardLayout } from '../components/DashboardLayout';
import { createNewUser } from '../services/profile';
import { SignUpUserData } from '../models/absorb/user';
import { LoginData } from '../models/absorb/login';
import { UserType } from '../constants/absorb';
import * as scope from '../constants/absorb';
import * as messages from '../constants/messages';
import { DELAY_TO_CLEAR_IN_PROGRESS_STATE, MINIMUM_INPUT_CHARS, USER_EXISTS_ERROR_MESSAGE } from '../constants/common';
import { REGEX_EMAIL_VALIDATION, REGEX_PASSWORD_VALIDATION } from '../constants/regex';
import { SpinnerButton } from '../components/SpinnerButton';
import { PageTitle } from '../components/PageTitle';

export function SignUpPage(): ReactElement {
  const { t } = useTranslation();

  const { signIn } = useLoginContext();
  const { loginPageSettings } = useBrandingContext();

  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [userExists, setUserExists] = useState(false);
  const [signUpInProgress, setSignUpInProgress] = useState(false);

  function onSubmit() {
    if (!validate()) {
      return;
    }

    const userData: SignUpUserData = {
      FirstName: firstName,
      LastName: lastName,
      EmailAddress: email,
      Password: password,
    };

    setSignUpInProgress(true);
    createNewUser(userData)
      .then(() => {
        const data: LoginData = {
          username: email,
          password,
          accessMode: scope.ACCESS_MODE,
          persist: false,
          scope: [UserType.Learner, UserType.Admin],
        };

        signIn(data);
        setTimeout(() => setSignUpInProgress(false), DELAY_TO_CLEAR_IN_PROGRESS_STATE);
      })
      .catch((error) => {
        if (
          error.response.status === StatusCodes.FORBIDDEN &&
          error.response.data.message === USER_EXISTS_ERROR_MESSAGE
        ) {
          setUserExists(true);
          setSignUpInProgress(false);
        }
      });
  }

  function validate() {
    return (
      firstName &&
      lastName &&
      REGEX_EMAIL_VALIDATION.test(email) &&
      REGEX_PASSWORD_VALIDATION.test(password) &&
      confirmPassword &&
      password === confirmPassword
    );
  }

  function onChangeFirstName(value: string) {
    if (!value) {
      setFirstNameError(messages.MINIMUM_CHAR);
    }
    setFirstName(value);
  }

  function onChangeLastName(value: string) {
    if (!value) {
      setLastNameError(messages.MINIMUM_CHAR);
    }
    setLastName(value);
  }

  function onChangeEmail(value: string) {
    setEmailError(REGEX_EMAIL_VALIDATION.test(value) ? '' : messages.INVALID_EMAIL);
    setEmail(value);
  }

  function onChangePassword(value: string) {
    setPasswordError(REGEX_PASSWORD_VALIDATION.test(value) ? '' : messages.PASSWORD_HINT);
    if (confirmPassword && value) {
      setConfirmPasswordError(confirmPassword === value ? '' : messages.PASSWORD_MISMATCH);
    }
    setPassword(value);
  }

  function onChangeConfirmPassword(value: string) {
    if (password && value) {
      setConfirmPasswordError(password === value ? '' : messages.PASSWORD_MISMATCH);
    }
    setConfirmPassword(value);
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('SignUp')} />
      <div
        className="signup-form-module__signup___vp46l signup-form__signup"
        style={{
          backgroundImage: `url("${encodeURI(loginPageSettings?.backgroundImageUrl || '')}")`,
          backgroundPosition: 'bottom left',
          backgroundSize: 'cover',
        }}
      >
        <div className="signup-form-module__form_container___3ym-m signup-form__form_container">
          <div className="signup-form-module__form___1Zz8G signup-form__form">
            <div className="signup-form-module__form_wrapper___2clji">
              <div className="profile-form-module__profile_form_container___24Wwl">
                {userExists ? (
                  <>
                    <h1 className="forgot-password-module__title___7V-kf forgot-password__title">
                      {t('UniqueUsernameFailure')}
                    </h1>
                    <div className="forgot-password-module__subtitle___1JI6a forgot-password__subtitle">
                      {t('UsernameExistsError').replace('{1}', email)}
                    </div>
                  </>
                ) : (
                  <form className="profile-form-module__form___1wsMc" onSubmit={onSubmit}>
                    <h1 className="profile-form-module__title___tAoGd profile-form__title">{t('SignUp')}</h1>
                    <div className="profile-form-module__field_group___2IOMB profile-form__field_group">
                      <div className="profile-form-module__fields___3z5zs profile-form__fields">
                        <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                          <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                            <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                              {t('FirstName')}
                            </label>
                            <div
                              className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                              aria-hidden="true"
                            >
                              <span className="accessibility-text-module__off_screen_text___FSqhy">
                                {t('Required')}
                              </span>
                              <span aria-hidden="true">{t('Required')}</span>
                            </div>
                          </div>
                          <input
                            name="FirstName"
                            id="reduxFormInputField8"
                            type="text"
                            step="any"
                            aria-required="true"
                            aria-describedby="errorDiv9"
                            value={firstName}
                            onChange={(e) => onChangeFirstName(e.target.value)}
                          />
                          {!firstName && (
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv9">
                              <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                                {t(firstNameError).replace('{0}', MINIMUM_INPUT_CHARS)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                          <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                            <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                              {t('LastName')}
                            </label>
                            <div
                              className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                              aria-hidden="true"
                            >
                              <span className="accessibility-text-module__off_screen_text___FSqhy">
                                {t('Required')}
                              </span>
                              <span aria-hidden="true">{t('Required')}</span>
                            </div>
                          </div>
                          <input
                            name="LastName"
                            id="reduxFormInputField12"
                            type="text"
                            step="any"
                            aria-required="true"
                            aria-describedby="errorDiv13"
                            value={lastName}
                            onChange={(e) => onChangeLastName(e.target.value)}
                          />
                          {!lastName && (
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv13">
                              <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                                {t(lastNameError).replace('{0}', MINIMUM_INPUT_CHARS)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                          <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                            <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                              {t('Email')}
                            </label>
                            <div
                              className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                              aria-hidden="true"
                            >
                              <span className="accessibility-text-module__off_screen_text___FSqhy">
                                {t('Required')}
                              </span>
                              <span aria-hidden="true">{t('Required')}</span>
                            </div>
                          </div>
                          <input
                            name="EmailAddress"
                            id="reduxFormInputField14"
                            type="text"
                            step="any"
                            aria-required="true"
                            aria-describedby="errorDiv15"
                            value={email}
                            onChange={(e) => onChangeEmail(e.target.value)}
                            autoComplete="username"
                          />
                          {emailError && (
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv15">
                              <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                                {t(emailError)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                          <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                            <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                              {t('Password')}
                            </label>
                            <div
                              className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                              aria-hidden="true"
                            >
                              <span className="accessibility-text-module__off_screen_text___FSqhy">
                                {t('Required')}
                              </span>
                              <span aria-hidden="true">{t('Required')}</span>
                            </div>
                          </div>
                          <input
                            name="Password"
                            id="reduxFormInputField16"
                            type="password"
                            step="any"
                            aria-required="true"
                            aria-describedby="errorDiv17"
                            value={password}
                            onChange={(e) => onChangePassword(e.target.value)}
                            autoComplete="new-password"
                          />
                          {passwordError && (
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv17">
                              <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                                {t(passwordError)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                          <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                            <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                              {t('EditLearnerVerifyPassword')}
                            </label>
                            <div
                              className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                              aria-hidden="true"
                            >
                              <span className="accessibility-text-module__off_screen_text___FSqhy">
                                {t('Required')}
                              </span>
                              <span aria-hidden="true">{t('Required')}</span>
                            </div>
                          </div>
                          <input
                            name="VerifyPassword"
                            id="reduxFormInputField18"
                            type="password"
                            step="any"
                            aria-required="true"
                            aria-describedby="errorDiv19"
                            value={confirmPassword}
                            onChange={(e) => onChangeConfirmPassword(e.target.value)}
                            autoComplete="new-password"
                          />
                          {confirmPasswordError && (
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv19">
                              <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                                {t(confirmPasswordError)}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="profile-form-module__btn_group___1CsPB">
                      <button
                        type="button"
                        className="btn forgot-password-module__btn___JzHZt forgot-password__btn button-module__btn___1lXcC"
                        title={t('SignUp')}
                        aria-label={t('SignUp')}
                        aria-describedby="editProfileSubmitButtonDescribedById7"
                        disabled={!validate()}
                        onClick={onSubmit}
                      >
                        {signUpInProgress ? <SpinnerButton /> : t('SignUp')}
                      </button>
                    </div>
                  </form>
                )}
                <div id="editProfileSubmitButtonDescribedById7" aria-live="assertive" />
              </div>
            </div>
            <div className="signup-form-module__login_wrapper___JswlL">
              <Link className="signup-form-module__login_link___26LNJ signup-form__login_link" to="/">
                {t('BackToLogin')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
