import React, { ReactElement, useState, useEffect } from 'react';
import {
  fetchHeaderMessages,
  fetchMessageCount,
  fetchMessages,
  updateReadAllMessage,
  updateReadMessage,
} from '../services/messages';
import { MessageData, MessageReadData } from '../models/absorb/message';
import { errorHandler } from '../utils/helper';
import { ContextProps, createContext } from '../utils/contextHelper';
import { useLoginContext } from './loginContext';

interface MessagesContextState {
  highPriorityMessagesTotalItems?: number;
  lowPriorityMessagesTotalItems?: number;
  highPriorityMessages?: MessageData[];
  lowPriorityMessages?: MessageData[];
  highPriorityMessagesUnread?: MessageData[];
  lowPriorityMessagesUnread?: MessageData[];
  messagesHeaderCount?: number;
  readMessageSuccess?: boolean;
  readAllMessagesSuccess?: boolean;
  fetchHeaderMessages: (priority: boolean) => void;
  fetchMessagesData: (priority: boolean, offset: number) => Promise<void>;
  fetchHeaderMessagesData: (priority: boolean) => void;
  fetchHeaderMessageCount: () => void;
  messageRead: (id: string, readData: MessageReadData) => Promise<void>;
  removeMessage: (message: MessageData) => void;
  messageReadAll: () => void;
}

const [Provider, useMessagesContext] = createContext<MessagesContextState>(module.filename);

export { useMessagesContext };

export function MessagesProvider({ children }: ContextProps): ReactElement {
  const { loggedIn } = useLoginContext();

  const [highPriorityMessages, setHighPriorityMessages] = useState<MessageData[]>([]);
  const [highPriorityMessagesUnread, setHighPriorityMessagesUnread] = useState<MessageData[]>();
  const [highPriorityMessagesTotalItems, setHighPriorityMessagesTotalItems] = useState(0);
  const [lowPriorityMessages, setLowPriorityMessages] = useState<MessageData[]>([]);
  const [lowPriorityMessagesUnread, setLowPriorityMessagesUnread] = useState<MessageData[]>();
  const [lowPriorityMessagesTotalItems, setLowPriorityMessagesTotalItems] = useState(0);
  const [messagesHeaderCount, setMessagesHeaderCount] = useState(0);
  const [readMessageSuccess, setReadMessageSuccess] = useState<boolean>();
  const [readAllMessagesSuccess, setReadAllMessagesSuccess] = useState<boolean>();

  useEffect(() => {
    if (loggedIn && !highPriorityMessagesUnread) {
      fetchHeaderMessageCount();
    }
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchMessagesData(highPriority: boolean, offset: number) {
    try {
      const { messages, totalItems } = await fetchMessages(highPriority, offset);

      if (highPriority) {
        setHighPriorityMessages(offset ? [...highPriorityMessages, ...messages] : messages);
        setHighPriorityMessagesTotalItems(totalItems);
      } else {
        setLowPriorityMessages(offset ? [...lowPriorityMessages, ...messages] : messages);
        setLowPriorityMessagesTotalItems(totalItems);
      }
    } catch (e) {
      errorHandler(String(e));
    }
  }

  async function fetchHeaderMessagesData(priority: boolean) {
    try {
      const messagesData = await fetchHeaderMessages(priority);

      if (priority) {
        setHighPriorityMessagesUnread(messagesData.messages);
        setHighPriorityMessagesTotalItems(messagesData.totalItems);
      } else {
        setLowPriorityMessagesUnread(messagesData.messages);
        setLowPriorityMessagesTotalItems(messagesData.totalItems);
      }
    } catch (e) {
      errorHandler(String(e));
    }
  }

  async function fetchHeaderMessageCount() {
    try {
      const count = await fetchMessageCount();
      setMessagesHeaderCount(count);
    } catch (e) {
      errorHandler(String(e));
    }
  }

  async function messageRead(id: string, readData: MessageReadData) {
    try {
      const read = await updateReadMessage(readData, id);
      setReadMessageSuccess(read);
    } catch (e) {
      errorHandler(String(e));
    }
  }

  async function removeMessage({ id, isPriority }: MessageData) {
    await messageRead(id, { read: true });

    if (!isPriority && lowPriorityMessagesUnread) {
      setLowPriorityMessagesUnread(
        lowPriorityMessagesUnread.filter((lowPriorityMessage) => lowPriorityMessage.id !== id)
      );
    }

    if (isPriority && highPriorityMessagesUnread) {
      setHighPriorityMessagesUnread(
        highPriorityMessagesUnread.filter((highPriorityMessage) => highPriorityMessage.id !== id)
      );
    }
  }

  async function messageReadAll() {
    try {
      await updateReadAllMessage();
      setReadAllMessagesSuccess(true);
      setReadMessageSuccess(false);
    } catch (e) {
      errorHandler(String(e));
      setReadAllMessagesSuccess(false);
    }
  }

  return (
    <Provider
      value={{
        highPriorityMessagesTotalItems,
        highPriorityMessages,
        highPriorityMessagesUnread,
        lowPriorityMessagesTotalItems,
        lowPriorityMessages,
        lowPriorityMessagesUnread,
        messagesHeaderCount,
        readMessageSuccess,
        readAllMessagesSuccess,
        fetchHeaderMessages,
        fetchMessagesData,
        fetchHeaderMessagesData,
        fetchHeaderMessageCount,
        messageRead,
        removeMessage,
        messageReadAll,
      }}
    >
      {children}
    </Provider>
  );
}
