import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PROCTOR_U_CUSTOM_FIELD_INDEX } from '../constants/absorb';
import { useProfileContext } from '../contexts/profileContext';
import { InstructorLedSessionData } from '../models/absorb/instructorLed';
import { StartRecordPlusResult } from '../models/proctorU/startRecordPlus';
import { EndRecordPlusResult } from '../models/proctorU/endRecordPlus';
import { startRecordPlus, endRecordPlus } from '../services/proctorU';
import { errorHandler } from '../utils/helper';
import {
  createStartRecordPlusRequest,
  createEndRecordPlusRequest,
  isRecordPlusDepartment,
} from '../utils/proctoruHelper';

interface Props {
  instructorLedSession: InstructorLedSessionData;
}

export function SessionItem({ instructorLedSession }: Props): ReactElement {
  const { t } = useTranslation();
  const { profile, profileFields } = useProfileContext();

  const [startRecordPlusResult, setStartRecordPlusResult] = useState<StartRecordPlusResult>();
  const [endRecordPlusResult, setEndRecordPlusResult] = useState<EndRecordPlusResult>();
  const [endExamMessaging, setEndExamMessaging] = useState<string>();

  useEffect(() => {
    // ignore response and use existing url
    if (window.localStorage.getItem('recordPlusUrl')) {
      const recordPlusUrl = window.localStorage.getItem('recordPlusUrl') || '';
      if (recordPlusUrl.length > 0) {
        window.open(recordPlusUrl, '_blank');
      } else {
        setEndExamMessaging('Stored Record+ url is empty');
      }
    } else {
      // use the response and store it
      // eslint-disable-next-line no-lonely-if
      if (startRecordPlusResult?.url?.length && startRecordPlusResult.url.length > 0) {
        window.localStorage.setItem('recordPlusUrl', startRecordPlusResult.url);
        window.localStorage.setItem('recordPlusReservationUuid', startRecordPlusResult.reservation_uuid);
        window.open(startRecordPlusResult.url, '_blank');
      }
    }
  }, [startRecordPlusResult]);

  useEffect(() => {
    if (endRecordPlusResult?.status === 'fulfilled') {
      // eslint-disable-next-line no-console
      console.log('End Recording: ', endRecordPlusResult.status);
      return;
    }
    // eslint-disable-next-line no-console
    console.log('End Recording (warning): ', endRecordPlusResult?.status);
  }, [endRecordPlusResult]);

  function onStartExam() {
    if (!profile) {
      return;
    }

    // if (window.localStorage.getItem('recordPlusUrl') && window.localStorage.getItem('recordPlusReservationUuid')) {
    if (window.localStorage.getItem('recordPlusUrl')) {
      const recordPlusResult = {
        url: window.localStorage.getItem('recordPlusUrl') || '',
        reservation_uuid: window.localStorage.getItem('recordPlusReservationUuid') || '',
      };
      setStartRecordPlusResult(recordPlusResult);
    } else {
      startRecordPlus(createStartRecordPlusRequest(profile, instructorLedSession))
        .then((result) => {
          setStartRecordPlusResult(result);
        })
        .catch(errorHandler);
    }
  }

  function onEndExam() {
    if (window.localStorage.getItem('recordPlusReservationUuid')) {
      endRecordPlus(createEndRecordPlusRequest(window.localStorage.getItem('recordPlusReservationUuid') || ''))
        .then((result) => {
          setEndRecordPlusResult(result);
          setEndExamMessaging('Examination ended successfully');
        })
        .catch(errorHandler);
    }
  }

  return (
    <div className="class-list-module__class_wrapper___1LqK1">
      <div className="class-list-module__class_header___3FBcA">
        <div className="class-list-module__header_info_wrapper___9Kaw6">
          <div className="class-list-module__graphic_single_class___ECj2-">
            <div className="radial-progress-bar__radial_progress_bar class-list-module__progress_bar___3WgSs">
              {/* TODO: this progress bar should show when a session is completed,
                        but because this will not happen, it can stay like this for now */}
              <svg className="radial-progress-bar-module__progress___1yaJ2" viewBox="0 0 128 128">
                <circle
                  className="radial-progress-bar-module__bar___28rAY radial-progress-bar__bar"
                  cx="64"
                  cy="64"
                  fill="none"
                  r="60"
                  shapeRendering="geometricPrecision"
                  strokeWidth="8"
                />
                <circle
                  className="radial-progress-bar-module__fill___1kdP1 radial-progress-bar__fill"
                  cx="64"
                  cy="64"
                  fill="none"
                  r="60"
                  shapeRendering="geometricPrecision"
                  strokeDasharray="376.99111843077515"
                  strokeDashoffset="376.99111843077515"
                  strokeWidth="8"
                />
              </svg>
            </div>
            <div className="class-list-module__percent___3C4kA" />
          </div>
          <div className="class-list-module__title_container_with_progress___1QP8Q">
            <div className="class-list-module__class_time___3Fsn5 class-list__class_time">{`${moment(
              instructorLedSession.currentClass.localStartDate
            ).format('h:mm A')} - ${moment(instructorLedSession.currentClass.localEndDate).format('h:mm A')}`}</div>
            <div className="class-list-module__class_timezone___cYDL1 class-list__class_timezone">{`(${instructorLedSession.currentClass.timeZoneShort})`}</div>
          </div>
        </div>
        <div className="class-list-module__calendar_icon_container___P6kbi">
          <div className="class-list-module__class_calendar_icon___Z1MrC">
            <span className="accessibility-text-module__off_screen_text___FSqhy">{` ${moment(
              instructorLedSession.currentClass.localStartDate
            ).format('LL')} `}</span>
            <div aria-hidden="true" className="calendar-icon-module__date_icon___3Qzni">
              <div className="calendar_icon__month_year calendar-icon-module__month_year___2zEoJ">
                {moment(instructorLedSession.currentClass.localStartDate).format('MMMM YYYY')}
              </div>
              <div className="calendar-icon-module__date___3Dy_l calendar_icon__date">
                {moment(instructorLedSession.currentClass.localStartDate).format('DD')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="class-list-module__class_body___QO1fV">
        <div className="class-list-module__class_row___1FYCA">
          <div className="class-list-module__class_row_title___l9XzC class-list__class_row_title">
            {`${t('Location')} :`}
          </div>
          <div className="class-list-module__class_row_value___1tKqW class-list__venue">
            {
              // eslint-disable-next-line no-nested-ternary
              profile && isRecordPlusDepartment(profile.departmentName) ? (
                <div className="class-list-module__venue_wrap___2VbSt">
                  <button
                    type="button"
                    style={{ float: 'left', width: '200px' }}
                    className="href-button-module__btn___Z9emG btn session-details-module__enroll_button___3MRPY session__add_to_calendar_button"
                    title="Start Exam"
                    onClick={() => onStartExam()}
                  >
                    Start Exam
                  </button>
                  <button
                    type="button"
                    style={{ float: 'left', width: '200px', marginLeft: '20px' }}
                    className="href-button-module__btn___Z9emG btn session-details-module__enroll_button___3MRPY session__add_to_calendar_button"
                    title="End Exam"
                    onClick={() => onEndExam()}
                  >
                    End Exam
                  </button>
                  <div
                    style={{
                      float: 'left',
                      width: '200px',
                      marginLeft: '20px',
                      color: 'darkred',
                      fontSize: '15px',
                      marginTop: '5px',
                      marginBottom: 'auto',
                    }}
                  >
                    {endExamMessaging}
                  </div>
                </div>
              ) : instructorLedSession.currentClass.venue.name.includes('ProctorU') ? (
                <div className="class-list-module__venue_wrap___2VbSt">
                  {`${instructorLedSession.currentClass.venue.name} - `}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={profileFields[PROCTOR_U_CUSTOM_FIELD_INDEX]?.value}
                  >
                    {profileFields[PROCTOR_U_CUSTOM_FIELD_INDEX]?.value}
                  </a>
                </div>
              ) : (
                <div className="class-list-module__venue_wrap___2VbSt">
                  {`${instructorLedSession.currentClass.venue.name} - `}
                  <a target="_blank" rel="noopener noreferrer" href={instructorLedSession.currentClass.meetingUrl}>
                    {instructorLedSession.currentClass.meetingUrl}
                  </a>
                </div>
              )
            }
          </div>
        </div>
        <div className="class-list-module__class_row___1FYCA">
          <div className="class-list-module__class_row_title___l9XzC class-list__class_row_title">
            {`${t('Starts')}:`}
          </div>
          <div className="class-list-module__class_row_value___1tKqW class-list__class_start_date">
            {`${moment(instructorLedSession.currentClass.localStartDate).format('LLL')} ${
              instructorLedSession.currentClass.timeZoneShort
            }`}
          </div>
        </div>
        <div className="class-list-module__class_row___1FYCA">
          <div className="class-list-module__class_row_title___l9XzC class-list__class_row_title">
            {`${t('Ends')}:`}
          </div>
          <div className="class-list-module__class_row_value___1tKqW class-list__class_end_date">
            {`${moment(instructorLedSession.currentClass.localEndDate).format('LLL')} ${
              instructorLedSession.currentClass.timeZoneShort
            }`}
          </div>
        </div>
      </div>
    </div>
  );
}
