import React, { ReactElement, useState, useEffect, KeyboardEvent } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShoppingCartContainer } from '../components/ShoppingCartContainer';
import { ShoppingCartPreviousStepButton } from '../components/ShoppingCartPreviousStepButton';
import { EmptyCart } from '../components/EmptyCart';
import { CatalogCourseData } from '../models/absorb/catalog';
import { useShoppingContext } from '../contexts/shoppingContext';
import { WITH_DEFAULT_THUMBNAIL_CLASS, WITHOUT_DEFAULT_THUMBNAIL_CLASS } from '../constants/styling';
import { CENTS_PER_DOLLAR, SALES_TAX_RATE_PERCENT } from '../constants/shoppingCart';
import { INVALID_COUPON, KEY_ENTER } from '../constants/common';
import { formatToDollar } from '../utils/currencyCodes';
import { constructCourseTypePath } from '../utils/helper';
import './CartPage.css';
import { PageTitle } from '../components/PageTitle';

export function CartPage(): ReactElement {
  const { t } = useTranslation();
  const { cartData, goToNextStep, getCartSubTotal, getCartTax, getCartTotal, removeFromCart } = useShoppingContext();

  const [cartListData, setCartListData] = useState<CatalogCourseData[]>([]);
  const [applyCouponButtonDisabled, setApplyCouponButtonDisabled] = useState('');
  const [couponErrorMessage, setCouponErrorMessage] = useState('');
  const [cartListDataAvailable, setCartListDataAvailable] = useState(false);

  const couponDisabled = applyCouponButtonDisabled.trim() === '';

  useEffect(() => {
    if (cartData.length < 1) {
      setCartListDataAvailable(false);
    } else {
      setCartListData(cartData);
      setCartListDataAvailable(true);
    }
  }, [cartData, cartData.length]);

  function removeItem(index: number) {
    const listData: CatalogCourseData[] = [...cartListData];
    removeFromCart(listData, index);
  }

  function onKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === KEY_ENTER && !couponDisabled) {
      setCouponErrorMessage(INVALID_COUPON);
    }
  }

  return (
    <ShoppingCartContainer>
      <PageTitle title={t('ShoppingCart')} />
      <ShoppingCartPreviousStepButton />
      {!cartListDataAvailable && <EmptyCart />}
      {cartListDataAvailable && (
        <div className="cart-review__content">
          <div
            className="sortable-table-module__table___2epWu sortable-table__table cart-review-module__table___2XZoc"
            role="table"
          >
            <div role="rowgroup">
              <div
                className="sortable-table-module__table_row_header___hu7Ms sortable-table__table_row_header"
                role="row"
              >
                <div
                  className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item cart-review-module__col_course___2O5jD"
                  role="columnheader"
                >
                  <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                    {t('Item')}
                  </span>
                </div>
                <div
                  className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item cart-review-module__col_subtotal___19O6W"
                  role="columnheader"
                >
                  <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                    {t('Price')}
                  </span>
                </div>
              </div>
            </div>
            {cartListData.map((cartList: CatalogCourseData, index: number) => (
              <div key={cartList.id} role="rowgroup">
                <div className="sortable-table-module__table_row_container___8gbRc">
                  <div
                    className="sortable-table-module__table_row___3N1yN sortable-table__table_row cart-review-module__row___3Wbtv"
                    role="row"
                  >
                    <div
                      className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item cart-review-module__col_course___2O5jD"
                      role="cell"
                    >
                      <div className="cart-course-cell-module__container___3UxQM">
                        <div
                          className={cartList.imageUrl ? WITHOUT_DEFAULT_THUMBNAIL_CLASS : WITH_DEFAULT_THUMBNAIL_CLASS}
                        >
                          {cartList.imageUrl && (
                            <img
                              src={cartList.imageUrl}
                              className="thumbnail-module__img___3IYNh"
                              alt={cartList.name}
                            />
                          )}
                        </div>

                        <div className="cart-course-cell-module__details___1z4jJ">
                          <Link
                            className="cart-course-cell-module__name___3lcZ5 cart-course-cell-module__name_link___1rQgW cart-course-cell__name_link"
                            to={constructCourseTypePath(cartList.courseType, cartList.id)}
                          >
                            {cartList.name}
                          </Link>
                          <div className="cart-course-cell-module__type___d2GiG cart-course-cell__type">
                            {t(cartList.courseType)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item cart-review-module__col_subtotal___19O6W"
                      role="cell"
                    >
                      <div className="cart-review-module__subtotal___UA7v4">
                        <div className="cart-review-module__price___2ghx4 cart-review__price">
                          {formatToDollar(cartList.price * CENTS_PER_DOLLAR)}
                        </div>
                        <button
                          type="button"
                          className="icon-button-module__btn___Zb89b cart-review-module__remove_btn___2g9ht cart-review_remove_btn btn"
                          title={`${t('Remove')} ${cartList.name}`}
                          onClick={() => removeItem(index)}
                        >
                          <div className="icon icon-x-thin" />
                          <span className="accessibility-text-module__off_screen_text___FSqhy">
                            {`${t('Remove')} ${cartList.name}`}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="cart-review-module__bottom___1Y0Er">
            <div id="coupon_code_header" className="coupon-input-module__coupon_header___3GRwy">
              {t('CouponCode')}
            </div>
            <div className="coupon-input-module__container___NvErW cart-review-module__coupon_input___1WcjU">
              <div className="coupon-input-module__input_container___14yq2">
                <div className="text-input-module__field___2btL- coupon-input-module__input___3M5-J">
                  <input
                    name="code"
                    id="code"
                    type="text"
                    step="any"
                    aria-describedby="coupon_code_errors"
                    aria-labelledby="coupon_code_header"
                    value={applyCouponButtonDisabled}
                    onChange={(e) => {
                      setApplyCouponButtonDisabled(e.target.value);
                      setCouponErrorMessage('');
                    }}
                    onKeyPress={(e) => onKeyPress(e)}
                  />
                </div>
              </div>
              <button
                type="button"
                className={`btn coupon-input-module__submit_btn___HMZLQ button-module__btn___1lXcC
                ${couponDisabled ? 'btn__disabled' : ''}`}
                disabled={couponDisabled}
                title={t('Apply')}
                aria-label={t('Apply')}
                onClick={() => setCouponErrorMessage(INVALID_COUPON)}
              >
                {t('Apply')}
              </button>
              <div
                aria-live="assertive"
                id="coupon_code_errors"
                className="coupon-input-module__errors_wrapper___1EAU_"
              >
                <div className="coupon-input-module__error___3alu2 coupon-input__error">{t(couponErrorMessage)}</div>
              </div>
            </div>
            <div className="cart-review-module__total_container___VXxex">
              <div className="shopping-cart-total-module__container___qjscv cart-review-module__total___3-hmw">
                <div className="shopping-cart-total-module__item___3vgt8 shopping-cart-total-module__subtotal_item___YZocw">
                  <div className="shopping-cart-total-module__item_title___1lcd0 cart-total__subtotal_title">
                    {t('SubTotal')}
                  </div>
                  <div className="shopping-cart-total-module__item_value___gp2KS cart-total__subtotal_value">
                    {formatToDollar(getCartSubTotal())}
                  </div>
                </div>
                <div className="shopping-cart-total-module__item___3vgt8 shopping-cart-total-module__total_item___1soCK">
                  <div className="shopping-cart-total-module__item_title___1lcd0">
                    {`${t('Tax')} (${SALES_TAX_RATE_PERCENT}%)`}
                  </div>
                  <div className="shopping-cart-total-module__item_value___gp2KS cart-total__total_value">
                    {formatToDollar(getCartTax())}
                  </div>
                </div>
                <div className="shopping-cart-total-module__item___3vgt8 shopping-cart-total-module__total_item___1soCK">
                  <div className="shopping-cart-total-module__item_title___1lcd0">{t('ReceiptTotal')}</div>
                  <div className="shopping-cart-total-module__item_value___gp2KS cart-total__total_value">
                    {formatToDollar(getCartTotal())}
                  </div>
                </div>
              </div>
              <button
                type="button"
                title={t('ContinueToCheckout')}
                className="btn cart-review-module__submit_btn___3Pz_U cart-review_submit_btn button-module__btn___1lXcC"
                onClick={() => goToNextStep('/cart/checkout')}
              >
                {t('ContinueToCheckout')}
              </button>
            </div>
          </div>
        </div>
      )}
    </ShoppingCartContainer>
  );
}
