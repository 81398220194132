import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useShoppingContext } from '../contexts/shoppingContext';
import { formatToDollar } from '../utils/currencyCodes';
import { CatalogCourseData } from '../models/absorb/catalog';
import './Summary.css';
import { CENTS_PER_DOLLAR, SALES_TAX_RATE_PERCENT } from '../constants/shoppingCart';

export function Summary(): ReactElement {
  const { t } = useTranslation();

  const { cartData, goToFirstStep, getCartSubTotal, getCartTax, getCartTotal } = useShoppingContext();

  return (
    <div className="cart-account-module__content_cart___3vclY">
      <div className="shopping-cart-summary-module__shopping_cart_summary___1ik43 cart-account-module__cart_summary___2f11e">
        <div className="shopping-cart-summary-module__header___2T0XY">
          <div className="shopping-cart-summary-module__header_title___36FtP">{t('Summary')}</div>
          <a
            className="shopping-cart-summary-module__edit_link___2WpuI shopping-cart-summary__edit_link"
            aria-label={`${t('Edit')} ${t('ShoppingCart')}`}
            href="# "
            onClick={goToFirstStep}
          >
            {t('Edit')}
          </a>
        </div>
        {cartData.map((cart: CatalogCourseData) => (
          <div key={cart.id} className="shopping-cart-summary-module__summary___jbbqR">
            <div className="shopping-cart-summary-module__item___26arO shopping-cart-summary__item">
              <div
                className="shopping-cart-summary-module__item_name___2e6yH shopping-cart-summary__item_name"
                title={cart.name}
              >
                {cart.name}
              </div>
            </div>
            <div className="shopping-cart-summary-module__item_price___wf9Py shopping-cart-summary__item_price">
              {formatToDollar(cart.price * CENTS_PER_DOLLAR)}
            </div>
          </div>
        ))}
      </div>
      <div className="shopping-cart-total-module__container___qjscv">
        <div className="shopping-cart-total-module__item___3vgt8 shopping-cart-total-module__subtotal_item___YZocw">
          <div className="shopping-cart-total-module__item_title___1lcd0 cart-total__subtotal_title">
            {t('SubTotal')}
          </div>
          <div className="shopping-cart-total-module__item_value___gp2KS cart-total__subtotal_value">
            {formatToDollar(getCartSubTotal())}
          </div>
        </div>
        <div className="shopping-cart-total-module__item___3vgt8 shopping-cart-total-module__subtotal_item___YZocw">
          <div className="shopping-cart-total-module__item_title___1lcd0 cart-total__subtotal_title">
            {`${t('Tax')} (${SALES_TAX_RATE_PERCENT}%)`}
          </div>
          <div className="shopping-cart-total-module__item_value___gp2KS cart-total__subtotal_value">
            {formatToDollar(getCartTax())}
          </div>
        </div>
        <div className="shopping-cart-total-module__item___3vgt8 shopping-cart-total-module__total_item___1soCK">
          <div className="shopping-cart-total-module__item_title___1lcd0">{t('Total')}</div>
          <div className="shopping-cart-total-module__item_value___gp2KS cart-total__total_value">
            {formatToDollar(getCartTotal())}
          </div>
        </div>
      </div>
    </div>
  );
}
