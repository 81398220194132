import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CurriculumEnrollmentData } from '../models/absorb/curriculum';

interface Props {
  curriculumGroupEnrollment: CurriculumEnrollmentData;
}

export function CurriculumGroupHeaderIcon({ curriculumGroupEnrollment }: Props): ReactElement {
  const { t } = useTranslation();

  function getTitleText(): string {
    // TODO: Need to determine the rest of the possible cases here.
    if (curriculumGroupEnrollment?.isLocked) {
      return t('Locked');
    }

    if (curriculumGroupEnrollment?.isComplete) {
      return t('Completed');
    }

    if (curriculumGroupEnrollment?.progress > 0) {
      return t('InProgress');
    }

    return t('NotStarted');
  }

  function renderIsLocked() {
    return (
      <div
        title={getTitleText()}
        className="curriculum-group-progress-module__graphic___3VXKP curriculum-group-progress__graphic"
      >
        <div className="icon icon-lock-closed curriculum-group-module__lock_icon___3thJS curriculum-group__lock_icon" />
      </div>
    );
  }

  function renderIsComplete() {
    return (
      <div className="curriculum-group-progress__group_progress curriculum-group-progress-module__completed___2reHU">
        <div
          title={getTitleText()}
          className="curriculum-group-progress-module__graphic___3VXKP curriculum-group-progress__graphic"
        >
          <div className="radial-progress-bar__radial_progress_bar curriculum-group-progress-module__progress_bar___2jmc9">
            <svg className="radial-progress-bar-module__progress___1yaJ2" viewBox="0 0 128 128">
              <circle
                className="radial-progress-bar-module__bar___28rAY radial-progress-bar__bar"
                cx="64"
                cy="64"
                fill="none"
                r="60"
                shapeRendering="geometricPrecision"
                strokeWidth="8"
              />
              <circle
                className="radial-progress-bar-module__fill___1kdP1 radial-progress-bar__fill radial-progress-bar-module__fill_success___1IycP radial-progress-bar__fill_success"
                cx="64"
                cy="64"
                fill="none"
                r="60"
                shapeRendering="geometricPrecision"
                strokeDasharray="376.99111843077515"
                strokeDashoffset="0"
                strokeWidth="8"
              />
            </svg>
          </div>
          <div className="curriculum-group-progress-module__percent___2EnQF icon icon-check-mark curriculum-group-progress__progress_complete" />
        </div>
      </div>
    );
  }

  function renderDefault() {
    return (
      <div className="curriculum-group-progress__group_progress curriculum-group-progress-module__not_completed___uFfpI">
        <div
          title={getTitleText()}
          className="curriculum-group-progress-module__graphic___3VXKP curriculum-group-progress__graphic"
        >
          <div className="radial-progress-bar__radial_progress_bar curriculum-group-progress-module__progress_bar___2jmc9">
            <svg className="radial-progress-bar-module__progress___1yaJ2" viewBox="0 0 128 128">
              <circle
                className="radial-progress-bar-module__bar___28rAY radial-progress-bar__bar"
                cx="64"
                cy="64"
                fill="none"
                r="60"
                shapeRendering="geometricPrecision"
                strokeWidth="8"
              />
              <circle
                className="radial-progress-bar-module__fill___1kdP1 radial-progress-bar__fill"
                cx="64"
                cy="64"
                fill="none"
                r="60"
                shapeRendering="geometricPrecision"
                strokeDasharray="376.99111843077515"
                strokeDashoffset="376.99111843077515"
                strokeWidth="8"
              />
            </svg>
          </div>
          <div className="curriculum-group-progress-module__percent___2EnQF curriculum-group-progress-module__icon_not_started___3sl4i icon icon-play curriculum-group-progress__progress_not_started" />
        </div>
      </div>
    );
  }

  function renderCurriculumGroupEnrollmentIcon() {
    if (curriculumGroupEnrollment?.isLocked) {
      return renderIsLocked();
    }

    if (curriculumGroupEnrollment?.isComplete) {
      return renderIsComplete();
    }

    return renderDefault();
  }

  return (
    <div className="curriculum-group-module__progress___EkS2b">
      {/* TODO: Need the rest of the possible icons */}
      {renderCurriculumGroupEnrollmentIcon()}
    </div>
  );
}
