import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseChaptersData } from '../models/absorb/course';
import { DisplayContentView } from '../constants/courses';
import { LessonOptionViewsButton } from './LessonOptionViewsButton';
import { LessonCard } from './LessonCard';
import { parsedHtml } from '../utils/helper';
import { LessonList } from './LessonList';
import { PosterImage } from './PosterImage';

interface Props {
  showViewOptions: boolean;
  contentView: DisplayContentView;
  modalContentView: DisplayContentView;
  courseDescription: string;
  courseRequiresPurchase: boolean;
  posterImageUrl?: string;
  courseDetails?: CourseChaptersData[];
  onChangeContentView: (id: DisplayContentView, requiresPurchase: boolean) => void;
  onChangeContentViewClick: () => void;
  openPlayer: (id: string) => void;
}

export function CourseDetailCourseView({
  showViewOptions,
  contentView,
  modalContentView,
  courseDescription,
  courseRequiresPurchase,
  posterImageUrl,
  courseDetails,
  onChangeContentView,
  onChangeContentViewClick,
  openPlayer,
}: Props): ReactElement {
  const { t } = useTranslation();
  const viewType = courseRequiresPurchase ? modalContentView : contentView;

  return (
    <div className="online-course__content">
      {posterImageUrl && <PosterImage posterImageUrl={posterImageUrl} />}
      <div className="course-description-module__description_container___ooKcP course-description__description_container">
        <div className="expandable-text-module__container___2kVAW">{parsedHtml(courseDescription)}</div>
      </div>

      <LessonOptionViewsButton
        showViewOptions={showViewOptions}
        contentView={viewType}
        courseRequiresPurchase={courseRequiresPurchase}
        onChangeContentView={onChangeContentView}
        onChangeContentViewClick={onChangeContentViewClick}
        setShowViewOptions={onChangeContentViewClick}
      />

      <div className="online-course-syllabus-module__content_header___1c8_p">
        <h2 className="online-course-syllabus-module__content_title___32_Qr online-course-syllabus__content_title">
          {t('CourseContent')}
        </h2>
      </div>

      {courseDetails &&
        (viewType === DisplayContentView.Detailed
          ? courseDetails.map((courseChapter: CourseChaptersData) => (
              <LessonCard
                key={courseChapter.id}
                courseChapter={courseChapter}
                openPlayer={openPlayer}
                courseRequiresPurchase={courseRequiresPurchase}
              />
            ))
          : courseDetails.map((courseChapter: CourseChaptersData) => (
              <LessonList
                key={courseChapter.id}
                courseChapter={courseChapter}
                openPlayer={openPlayer}
                courseRequiresPurchase={courseRequiresPurchase}
              />
            )))}
    </div>
  );
}
