import React, { ReactElement } from 'react';
import { CatalogCourseData } from '../models/absorb/catalog';
import { SearchResultData } from '../models/absorb/search';
import { AbsorbResourceType } from '../constants/absorb';
import { FaqResourceData, GlobalResourceData } from '../models/absorb/resources';
import { FaqDetail } from './FaqDetail';
import { CourseDetail } from './CourseDetail';
import { GlobalResourceDetail } from './GlobalResourceDetail';

interface Props {
  resource: SearchResultData;
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function ResourceDetail({ resource, updatePinnedStatus }: Props): ReactElement {
  return (
    <>
      {resource.resourceType === AbsorbResourceType.GlobalResource && (
        <GlobalResourceDetail globalResourceData={resource.resource as GlobalResourceData} />
      )}

      {resource.resourceType === AbsorbResourceType.Course && (
        <CourseDetail courseData={resource.resource as CatalogCourseData} updatePinnedStatus={updatePinnedStatus} />
      )}
      {resource.resourceType === AbsorbResourceType.Faq && (
        <FaqDetail faqResourceData={resource.resource as FaqResourceData} />
      )}
    </>
  );
}
