import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  children: ReactNode;
}

export function ListHeaders({ children }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="catalog-module__list_container___ZeiRg">
      <div className="sortable-table-module__table___2epWu sortable-table__table" role="table">
        <div role="rowgroup">
          <div className="sortable-table-module__table_row_header___hu7Ms sortable-table__table_row_header" role="row">
            <div
              className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item course-list-module__name_column___1upGg"
              role="columnheader"
            >
              <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                {t('Name')}
              </span>
            </div>
            <div
              className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item search-result-list-items-module__type_column___2EUKC"
              role="columnheader"
            >
              <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                {t('Type')}
              </span>
            </div>
            <div
              className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item course-list-module__rating_column___22LQj"
              role="columnheader"
            >
              <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                {t('Rating')}
              </span>
            </div>
            <div
              className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item course-list-module__actions_column___1u6Zx"
              role="columnheader"
            >
              <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09" />
            </div>
          </div>
        </div>
        <div role="rowgroup">
          <div className="sortable-table-module__table_row_container___8gbRc">{children}</div>
        </div>
      </div>
    </div>
  );
}
