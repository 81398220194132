import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  children: ReactElement;
  lessonName: string;
  closeCoursePlayer: () => void;
}

export function CoursePlayerLessonWrapper({ children, lessonName, closeCoursePlayer }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div
      className="lesson-player-module__container___DnFI7 lesson-player__container"
      role="dialog"
      aria-modal="true"
      area-label={lessonName}
      style={{ position: 'fixed' }}
    >
      {/* <div className="lesson-player-module__top_bar___1gO8m lesson-player__top_bar" style={{ position: 'absolute' }}>
        <div className="lesson-player-module__top_bar_title___3lT7k">{lessonName}</div>
        <button type="button" className="icon-button-module__btn___Zb89b" title="Close" onClick={closeCoursePlayer}>
          <div className="icon icon-x-thin lesson-player-module__close_btn___1SlvO lesson-player__close_btn" />
          <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Close')}</span>
        </button>
      </div> */}

      <div
        className="lessonPlayerCloseButtonHolder"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          fontWeight: 'bold',
          padding: '14px',
          width: '25px',
        }}
      >
        <div className="lessonPlayerCloseButtonContainer" />
        <button
          type="button"
          className="lessonPlayerCloseButton"
          style={{
            zIndex: 1,
            width: '23px',
            height: '23px',
            position: 'absolute',
            top: 0,
            right: 2,
            padding: '23px',
            backgroundColor: '#AB192D',
            borderLeft: '4px solid #ffffff',
            borderBottom: '4px solid #ffffff',
            borderRadius: '0 0 0 25px',
          }}
          title={t('Close')}
          onClick={closeCoursePlayer}
        >
          <div className="lessonPlayerCloseButtonIcon" />
        </button>
      </div>
      {children}
    </div>
  );
}
