import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { EXPANDABLE_DESCRIPTION_LIMIT } from '../constants/common';
import { parsedHtml } from '../utils/helper';

interface Props {
  itemName: string;
  description: string;
}

export function ExpandableDescription({ itemName, description }: Props): ReactElement {
  const { t } = useTranslation();

  const elementRef = useRef<HTMLDivElement>(null);
  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const [descriptionVisible, setDescriptionVisible] = useState(false);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    };
  }, [descriptionHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleResize() {
    if (elementRef.current) {
      setDescriptionHeight(elementRef.current.clientHeight);
    }
  }

  return (
    <div className="panel-module__panel_description___3xfj7 panel__panel_description">
      <div
        className="expandable-text-module__container___2kVAW panel-module__description___3oKEP panel__description"
        ref={elementRef}
      >
        {descriptionHeight > EXPANDABLE_DESCRIPTION_LIMIT && (
          <button
            type="button"
            className="icon-button-module__btn___Zb89b expandable-text-module__toggle_btn___1ZTu2 expandable-text__toggle_btn"
            title={
              descriptionVisible
                ? t('CollapseDescriptionAccessibilityText').replace('{0}', itemName)
                : t('ExpandDescriptionAccessibilityText').replace('{0}', itemName)
            }
            onClick={() => {
              setDescriptionVisible(!descriptionVisible);
            }}
          >
            {descriptionVisible ? <div className="icon icon-arrow-up" /> : <div className="icon icon-arrow-down" />}
            <span className="accessibility-text-module__off_screen_text___FSqhy" />
          </button>
        )}
        <div
          className={`expandable-text__content_container expandable-text-module__content_container___1fcz4 ${
            descriptionVisible
              ? 'expandable-text-module__content_container_expanded___2eWnT'
              : 'expandable-text-module__content_container_collapsed___3tdss'
          }`}
          aria-hidden={descriptionVisible}
        >
          <div className="sanitized_html">{parsedHtml(description)}</div>
          {!descriptionVisible && (
            <div className="expandable-text-module__content_overlay___1yrtg expandable-text__content_overlay panel-module__description_overlay___1D4mp panel__description_overlay" />
          )}
        </div>
      </div>
    </div>
  );
}
