import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../components/DashboardLayout';
import { LoadingIndicator } from '../components/Spinner';
import { useBrandingContext } from '../contexts/brandingContext';
import { fetchFaqs } from '../services/faqs';
import { FaqData, FaqResponseData } from '../models/absorb/faqs';
import { FaqDetailView } from '../components/FaqDetailView';
import { errorHandler } from '../utils/helper';
import { API_RESULT_LIMIT } from '../constants/common';
import { LoadMoreActionButton } from '../components/LoadMoreActionButton';
import { PageTitle } from '../components/PageTitle';

export function FaqsPage(): ReactElement {
  const { t } = useTranslation();

  const { brandingCoursesSettings } = useBrandingContext();

  const [offset, setOffset] = useState(0);
  const [faqsData, setFaqsData] = useState<FaqData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFaqsLoading, setIsFaqsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const limit = API_RESULT_LIMIT;
    fetchFaqs(limit, offset)
      .then((response: FaqResponseData) => {
        if (response.offset === 0) {
          setFaqsData(response.faqs);
        } else {
          setFaqsData((faqs) => [...faqs, ...response.faqs]);
        }
        setTotalItems(response.totalItems);
        setIsFaqsLoading(false);
      })
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }, [offset]);

  function fetchMoreFaqs() {
    setIsFaqsLoading(true);
    setOffset(offset + 1);
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('FAQs')} />
      <div className="faq-module__container___MzxWJ faq-module__container_without_modal___2WEb6">
        <div aria-live="assertive" role="status" />
        <div
          className="banner-module__banner___IJO0Z banner__banner"
          style={{
            backgroundImage: `url("${encodeURI(brandingCoursesSettings?.myCoursesAndCatalog?.bannerUri || '')}")`,
          }}
        >
          <h1 className="banner-title-module__title___1mbDk banner__title">{t('Faq')}</h1>
        </div>
        <div className="faq-module__faq___1dadM faq__faq">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            faqsData &&
            faqsData.length && (
              <>
                {faqsData.map((faq: FaqData) => (
                  <FaqDetailView key={faq.id} faqData={faq} />
                ))}
                {totalItems && faqsData.length < totalItems && (
                  <LoadMoreActionButton
                    currentItemsCount={faqsData.length}
                    totalItemsCount={totalItems}
                    itemsAreLoading={isFaqsLoading}
                    fetchMoreFunction={fetchMoreFaqs}
                  />
                )}
              </>
            )
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
