import React, { ReactElement, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMessagesContext } from '../contexts/messagesContext';
import { MessageData } from '../models/absorb/message';
import {
  LOW_PRIORITY_MESSAGES_UNREAD_LIMIT,
  LOW_PRIORITY_MESSAGES_UNREAD_START_INDEX,
  ProfileTab,
} from '../constants/common';
import { MessageList } from './MessageList';

interface Props {
  setMessagesVisible: Dispatch<SetStateAction<boolean>>;
}

export function Messages({ setMessagesVisible }: Props): ReactElement {
  const { t } = useTranslation();

  const {
    fetchHeaderMessagesData,
    lowPriorityMessagesUnread,
    highPriorityMessagesUnread,
    highPriorityMessagesTotalItems,
    lowPriorityMessagesTotalItems,
  } = useMessagesContext();

  const [showSystemMessage, setShowSystemMessage] = useState(false);
  const [showPriorityMessage, setShowPriorityMessage] = useState(true);

  useEffect(() => {
    if (!highPriorityMessagesUnread) {
      fetchHeaderMessagesData(true);
    }
  }, [highPriorityMessagesUnread]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!lowPriorityMessagesUnread) {
      fetchHeaderMessagesData(false);
    }
  }, [lowPriorityMessagesUnread]); // eslint-disable-line react-hooks/exhaustive-deps

  function onPriorityMessageClick() {
    setShowPriorityMessage(!showPriorityMessage);
    setShowSystemMessage(false);
  }

  function onSystemMessageClick() {
    setShowSystemMessage(!showSystemMessage);
    setShowPriorityMessage(false);
  }

  return (
    <div className="menu-module__menu___3FOuc menu__menu">
      <button
        className={`messages-menu-module__panel_header___1ZxlB messages-menu__panel messages-menu__panel_priority ${
          showPriorityMessage ? 'messages-menu__panel_active' : ''
        }`}
        onClick={onPriorityMessageClick}
      >
        {t('PriorityMessages')}
        <span className="pill-module__pill___1PAoi messages-menu-module__message_count_pill___3U_Rm pill__pill">
          <span className="message-modal__icon" aria-hidden="true">
            {highPriorityMessagesTotalItems || 0}
          </span>
        </span>
      </button>
      {showPriorityMessage &&
        (highPriorityMessagesUnread && highPriorityMessagesUnread.length !== 0 ? (
          highPriorityMessagesUnread.map((highPriorityMessageUnread: MessageData) => (
            <div key={`high${highPriorityMessageUnread.id}`}>
              <MessageList message={highPriorityMessageUnread} />
            </div>
          ))
        ) : (
          <div className="messages-menu-module__no_messages___3QlcS">{t('MessagesNoItems')}</div>
        ))}
      <button
        className={`messages-menu-module__panel_header___1ZxlB messages-menu__panel messages-menu__panel_low ${
          showSystemMessage ? 'messages-menu__panel_active' : ''
        }`}
        onClick={onSystemMessageClick}
      >
        {t('SystemMessages')}
        <span className="pill-module__pill___1PAoi messages-menu-module__message_count_pill___3U_Rm pill__pill">
          <span className="message-modal__icon" aria-hidden="true">
            {lowPriorityMessagesTotalItems || 0}
          </span>
        </span>
      </button>
      {showSystemMessage &&
        (lowPriorityMessagesUnread && lowPriorityMessagesUnread.length !== 0 ? (
          lowPriorityMessagesUnread
            .slice(LOW_PRIORITY_MESSAGES_UNREAD_START_INDEX, LOW_PRIORITY_MESSAGES_UNREAD_LIMIT)
            .map((lowPriorityMessageUnread: MessageData) => (
              <div key={`high${lowPriorityMessageUnread.id}`}>
                <MessageList message={lowPriorityMessageUnread} />
              </div>
            ))
        ) : (
          <div className="messages-menu-module__no_messages___3QlcS">{t('MessagesNoItems')}</div>
        ))}
      <Link
        className="link-button-module__link___3tT4y messages-menu-module__inbox_btn___2diiX messages-menu__inbox_btn btn"
        to={`/profile?tab=${ProfileTab.Messages}`}
        title={t('ViewAllMessages')}
        onClick={() => setMessagesVisible(false)}
      >
        {t('ViewAllMessages')}
      </Link>
    </div>
  );
}
