import React, { ReactElement, ReactNode } from 'react';
import { useLoginContext } from '../contexts/loginContext';
import { DashboardLayout } from './DashboardLayout';
import { CartSubHeader } from './CartSubHeader';
import { EmptyCart } from './EmptyCart';
import { CartSteps } from './CartSteps';
import { ShoppingCartPreviousStepButton } from './ShoppingCartPreviousStepButton';

interface Props {
  children: ReactNode;
}

export function ShoppingCartContainer({ children }: Props): ReactElement {
  const { loggedIn } = useLoginContext();

  return (
    <DashboardLayout>
      <div role="main" className="app-module__content___-i9XR app__content">
        <div className="cart-review-module__cart_review___3i9iH">
          <div aria-live="assertive" role="status" />
          <CartSubHeader />
          <CartSteps />
          {!loggedIn && (
            <>
              <ShoppingCartPreviousStepButton />
              <EmptyCart />
            </>
          )}
          {children}
        </div>
      </div>
    </DashboardLayout>
  );
}
