import React, { ReactElement } from 'react';
import { isOnGlobalSearchPage, isOnMyCoursesPage, isOnResourcesPage, isOnCatalogPage } from '../constants/common';
import { AdvancedFilter } from './SideFilter/AdvancedFilter';
import { CourseNameFilter } from './SideFilter/CourseNameFilter';
import { CourseRatingFilter } from './SideFilter/CourseRatingFilter';
import { EntitiesFilter } from './SideFilter/EntitiesFilter';
import { ResourceNameFilter } from './SideFilter/ResourceNameFilter';
import { InstructorNameFilter } from './SideFilter/InstructorNameFilter';
import { ShowCategoriesSwitch } from './SideFilter/ShowCategoriesSwitch';
import { ShowCompletedSwitch } from './SideFilter/ShowCompletedSwitch';
import { TagFilter } from './SideFilter/TagFilter';
import { useSearchContext } from '../contexts/searchContext';

export function SideFilter(): ReactElement {
  const { instructor } = useSearchContext();

  return (
    <div
      role="search"
      aria-label="Advanced Filtering"
      className="filter-panel-module__filters_container___1RE5F filter-panel__filters_container"
    >
      {!isOnGlobalSearchPage() && <ShowCategoriesSwitch />}
      {isOnMyCoursesPage() && <ShowCompletedSwitch />}
      {!isOnResourcesPage() && <EntitiesFilter />}
      {isOnGlobalSearchPage() && <CourseRatingFilter />}
      {(isOnMyCoursesPage() || isOnCatalogPage()) && <CourseNameFilter />}
      {isOnCatalogPage() && instructor && <InstructorNameFilter />}
      {isOnResourcesPage() && <ResourceNameFilter />}
      {isOnResourcesPage() && <TagFilter />}
      {!isOnResourcesPage() && <AdvancedFilter />}
    </div>
  );
}
