import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../contexts/modalContext';
import { ShareCourseModal } from '../modals/ShareCourseModal';

interface Props {
  courseId: string;
  courseName: string;
  courseType: string;
}

export function ShareCourseButton({ courseId, courseName, courseType }: Props): ReactElement {
  const { t } = useTranslation();

  const { showModal } = useModalContext();

  return (
    <button
      type="button"
      className="icon-button-module__btn___Zb89b course-options__option course-options-module__option___1x_R5 course-options__option_share"
      title={t('ShareAccessibilityText').replace('{0}', courseName)}
      role="menuitem"
      onClick={(e) => {
        showModal(<ShareCourseModal courseId={courseId} courseType={courseType} />);
        e.stopPropagation();
      }}
    >
      <div className="icon icon-share" />
      <span className="accessibility-text-module__off_screen_text___FSqhy">{` ${courseName} `}</span>
    </button>
  );
}
