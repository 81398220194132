import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '../../contexts/searchContext';
import { CheckBox } from './CheckBox';

export function EntityFilterCheckBoxes(): ReactElement {
  const { t } = useTranslation();

  const { otherEntities, setOtherEntities } = useSearchContext();

  function handleCheckBox(key: string) {
    setOtherEntities({ ...otherEntities, [key]: !otherEntities[key] });
  }

  function translate(key: string): string {
    const lookup: { [key: string]: string } = {
      Lesson: t('Lesson').replace('{0}', ''),
      GlobalResource: t('Resources'),
      NewsArticle: t('News'),
      Poll: t('Polls'),
      Faq: t('FAQs'),
    };

    return lookup[key] || t('NotFound');
  }

  return (
    <>
      {Object.keys(otherEntities).map((key) => (
        <li key={key}>
          <CheckBox
            label={translate(key)}
            value={key}
            isDynamicChecked
            isChecked={otherEntities[key]}
            handleCheckbox={() => handleCheckBox(key)}
          />
        </li>
      ))}
    </>
  );
}
