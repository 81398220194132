import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogCourseData } from '../models/absorb/catalog';
import { formatToDollar } from '../utils/currencyCodes';
import { WITH_DEFAULT_THUMBNAIL_CLASS, WITHOUT_DEFAULT_THUMBNAIL_CLASS } from '../constants/styling';
import { useShoppingContext } from '../contexts/shoppingContext';
import { CENTS_PER_DOLLAR } from '../constants/shoppingCart';

interface Props {
  cartItem: CatalogCourseData;
  index: number;
}

export function CartItemSideView({ cartItem, index }: Props): ReactElement {
  const { t } = useTranslation();

  const { removeFromCart, cartData } = useShoppingContext();

  function removeItem(itemIndex: number) {
    removeFromCart([...cartData], itemIndex);
  }

  return (
    <div className="shopping-cart-menu-item-module__course___37Qma shopping-cart-menu-item__item">
      <div className={cartItem.imageUrl ? WITHOUT_DEFAULT_THUMBNAIL_CLASS : WITH_DEFAULT_THUMBNAIL_CLASS}>
        {cartItem.imageUrl && (
          <img src={cartItem.imageUrl} className="thumbnail-module__img___3IYNh" alt={cartItem.name} />
        )}
      </div>
      <div className="shopping-cart-menu-item-module__details___1ojz3">
        <div
          className="shopping-cart-menu-item-module__name___3N4ig shopping-cart-menu-item__name"
          title={cartItem.name}
        >
          {cartItem.name}
        </div>
        <div className="shopping-cart-menu-item-module__type___1eLeX shopping-cart-menu-item__type">
          {t(cartItem.courseType)}
        </div>
        <div className="shopping-cart-menu-item-module__price___26Cr8 shopping-cart-menu-item__price">
          {formatToDollar(cartItem.price * CENTS_PER_DOLLAR)}
        </div>
      </div>
      <button
        type="button"
        className="icon-button-module__btn___Zb89b shopping-cart-menu-item-module__remove_btn___bX5kw shopping-cart-menu-item__remove_btn"
        title={`${t('Remove')} ${cartItem.name}`}
        onClick={() => removeItem(index)}
      >
        <div className="icon icon-x-thin" />
        <span className="accessibility-text-module__off_screen_text___FSqhy">{`${t('Remove')} ${cartItem.name}`}</span>
      </button>
    </div>
  );
}
