import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLoginContext } from '../contexts/loginContext';
import { useProfileContext } from '../contexts/profileContext';
import { useBrandingContext } from '../contexts/brandingContext';
import { DashboardLayout } from '../components/DashboardLayout';
import { parsedHtml } from '../utils/helper';
import { LoadingIndicator } from '../components/Spinner';
import { SpinnerButton } from '../components/SpinnerButton';
import { PageTitle } from '../components/PageTitle';

export function TermsAndConditionsPage(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    termsAndConditions,
    signOutInProgress,
    acceptTermsAndConditionsInProgress,
    retrieveTermsAndConditions,
    acceptTermsAndConditions,
    signOut,
  } = useLoginContext();
  const { profile } = useProfileContext();
  const { brandingCoursesSettings } = useBrandingContext();

  useEffect(() => {
    if (termsAndConditions === undefined) {
      retrieveTermsAndConditions();
    } else if (termsAndConditions.currentUserMustAcceptTermsAndConditions) {
      navigate('/dashboard');
    }
  }, [termsAndConditions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardLayout>
      <PageTitle title={t('NoAcceptedTerms')} />
      <div
        className="banner-module__banner___IJO0Z banner__banner"
        style={{
          backgroundImage: `url("${encodeURI(brandingCoursesSettings?.myCoursesAndCatalog?.bannerUri || '')}")`,
        }}
      >
        <h1 className="banner-title-module__title___1mbDk banner__title">{profile?.departmentName}</h1>
      </div>
      <div className="terms-and-conditions-container">
        <div className="terms-and-conditions-module__title___19iLL terms-and-conditions__title">
          {t('TermsAndConditions')}
        </div>
        <div className="terms-and-conditions-module__text_wrapper___1Jtwz">
          {termsAndConditions?.currentUserMustAcceptTermsAndConditions ? (
            <div className="terms-and-conditions-module__text___gseh6 terms-and-conditions__text">
              {parsedHtml(termsAndConditions.termsAndConditions?.text)}
            </div>
          ) : (
            <LoadingIndicator />
          )}
          <div className="terms-and-conditions-module__buttons___pureY">
            <button
              type="button"
              className="btn terms-and-conditions-module__accept_button___2blsg terms-and-conditions__accept_button accent_color button-module__btn___1lXcC"
              title={t('IAgreeTermsAndConditions')}
              aria-label={t('IAgreeTermsAndConditions')}
              disabled={!termsAndConditions} /* Disables agree if TaC haven't loaded yet */
              onClick={acceptTermsAndConditions}
            >
              {acceptTermsAndConditionsInProgress ? <SpinnerButton /> : t('IAgree')}
            </button>
            <button
              type="button"
              className="btn terms-and-conditions-module__reject_button___3A2NN terms-and-conditions__reject_button accent_color button-module__btn___1lXcC"
              title={t('IDisagreeTermsAndConditions')}
              aria-label={t('IDisagreeTermsAndConditions')}
              onClick={signOut}
              disabled={!termsAndConditions}
            >
              {signOutInProgress ? <SpinnerButton /> : t('Disagree')}
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
