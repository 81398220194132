import React, { ReactElement, useState, useEffect } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import { InstructorLedSessionData } from '../models/absorb/instructorLed';
import { useProfileContext } from '../contexts/profileContext';
import {
  COMET_APPID,
  COMET_REGION,
  COMET_ID,
  COMET_LOAD_DELAY,
  COMET_CLOUD_USER_ID,
  COMET_USERID_LENGTH,
  COMET_UI_LAUNCHER,
  MINIMUM_INSTRUCTOR_NAME_LENGTH,
} from '../constants/cometChat';

interface Props {
  instructorLedSession?: InstructorLedSessionData;
}

export function ChatWidget({ instructorLedSession }: Props): ReactElement {
  const { profile } = useProfileContext();

  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [instructorName, setInstructorName] = useState('');

  const userId = localStorage.getItem('userId');
  const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(COMET_REGION).build();
  const cloudUserId = COMET_CLOUD_USER_ID;

  useEffect(() => {
    setUserName(profile?.username ?? '');
    setFullName(`${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`);
  }, [profile]);

  useEffect(() => {
    if (
      (instructorLedSession?.instructors[0].fullName ?? '').length > 0 &&
      instructorName.length < MINIMUM_INSTRUCTOR_NAME_LENGTH
    ) {
      setInstructorName(instructorLedSession?.instructors[0].fullName ?? '');
    }
  }, [instructorLedSession?.instructors]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (instructorName.length >= MINIMUM_INSTRUCTOR_NAME_LENGTH) {
      CometChatDeployment();
    }
  }, [instructorName]); // eslint-disable-line react-hooks/exhaustive-deps

  async function CometChatDeployment() {
    const result = await InstructorLookup();
    if (result === 'INSTRUCTOR' || result.length === COMET_USERID_LENGTH) {
      CometChatInterface();
    }
  }

  async function InstructorLookup() {
    let result = '';
    if (fullName === instructorName) {
      return 'INSTRUCTOR';
    }
    await CometChat.init(COMET_APPID, appSetting);
    await CometChat.login(cloudUserId, COMET_ID).then(async () => {
      const searchKey = instructorName;
      const usersRequest = new CometChat.UsersRequestBuilder()
        .setLimit(1)
        .setSearchKeyword(searchKey ?? '')
        .build();

      await usersRequest.fetchNext().then((userList) => {
        userList.forEach((item) => {
          if (item.getUid().length === COMET_USERID_LENGTH) {
            localStorage.setItem('instructorId', item.getUid());
            result = item.getUid();
          }
        });
      });
    });

    return result;
  }

  function CometChatInterface() {
    CometChat.init(COMET_APPID, appSetting)
      .then(async () => {
        await CometChat.login(userId, COMET_ID)
          .then(() => {})
          .catch(async () => {
            const currentUser = new CometChat.User(userId);
            currentUser.setName(fullName);
            await CometChat.createUser(currentUser, COMET_ID ?? '').then((newUser) => {
              CometChat.login(newUser, COMET_ID);
            });
          });
      })
      .then(() => {
        CometChatScriptDrop();
      });
  }

  function CometChatScriptDrop() {
    localStorage.setItem('cometChatAppId', COMET_APPID ?? '');
    localStorage.setItem('cometChatRegion', COMET_REGION ?? '');
    localStorage.setItem('cometChatId', COMET_ID ?? '');
    if (fullName === instructorName && userName.length > 0) {
      localStorage.setItem('cometChatWidgetId', `${process.env.REACT_APP_COMETCHAT_WIDGET_INSTRUCTOR}`);
      localStorage.setItem('cometChatHeight', '700px');
      localStorage.setItem('cometChatWidth', '800px');
    } else {
      localStorage.setItem('cometChatWidgetId', `${process.env.REACT_APP_COMETCHAT_WIDGET_PARTICIPANT}`);
      localStorage.setItem('cometChatHeight', '450px');
      localStorage.setItem('cometChatWidth', '400px');
    }

    const chat = document.createElement('script');
    if (process.env.REACT_APP_COMETCHAT_SRC_HOST === 'local') {
      chat.src = `${process.env.REACT_APP_COMETCHAT_SRC_LOCAL}`;
    } else {
      chat.src = `${process.env.REACT_APP_COMETCHAT_SRC_REMOTE}`;
    }
    chat.async = true;
    chat.defer = true;
    document.body.appendChild(chat);

    const script = document.createElement('script');
    script.src = `../../${COMET_UI_LAUNCHER}`;
    script.async = true;
    script.defer = true;

    setTimeout(() => {
      document.body.appendChild(script);
    }, COMET_LOAD_DELAY);
  }

  return <div></div>;
}
