import React, { ReactElement, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurriculumGroupData } from '../models/absorb/curriculum';
import { DisplayContentView } from '../constants/courses';
import { CurriculumGroupHeaderIcon } from './CurriculumGroupHeaderIcon';

interface Props {
  curriculumGroup: CurriculumGroupData;
  contentView: DisplayContentView;
  showCourses: boolean;
  setShowCourses: Dispatch<SetStateAction<boolean>>;
}

export function CurriculumGroupHeader({
  curriculumGroup,
  contentView,
  showCourses,
  setShowCourses,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [groupToggleText, setGroupToggleText] = useState('');

  useEffect(() => {
    setGroupToggleText(
      t(showCourses ? 'CollapseButtonAccessibilityText' : 'ExpandButtonAccessibilityText').replace(
        '{0}',
        curriculumGroup.name
      )
    );
  }, [showCourses]); // eslint-disable-line react-hooks/exhaustive-deps

  function getOfCoursesText() {
    return t('OfCourses')
      .replace('{0}', (curriculumGroup.enrollment?.completedCourseCount || 0).toString())
      .replace('{1}', (curriculumGroup.requiredCourseCount || 0).toString());
  }

  return (
    <div
      className={`curriculum-group-module__group_header___1iHSh ${
        contentView === DisplayContentView.Detailed ? 'curriculum-group-module__group_header_no_border' : ''
      }`}
    >
      <CurriculumGroupHeaderIcon curriculumGroupEnrollment={curriculumGroup.enrollment} />
      <div className="curriculum-group-module__header_info___2COwv">
        <div className="curriculum-group-module__header_content___1Z9kX">
          <div title={curriculumGroup.name} className="curriculum-group-module__title___YqjtX curriculum-group__title">
            {curriculumGroup.name}
          </div>
          {curriculumGroup.enrollment !== null && (
            <div title={getOfCoursesText()} className="curriculum-group__course_count">
              {getOfCoursesText()}
            </div>
          )}
          <button
            type="button"
            className="icon-button-module__btn___Zb89b curriculum-group-module__toggle_btn___3yMuF curriculum-group__toggle_btn"
            title={groupToggleText}
            onClick={() => setShowCourses(!showCourses)}
          >
            {showCourses ? <div className="icon icon-arrow-up" /> : <div className="icon icon-arrow-down" />}
            <span className="accessibility-text-module__off_screen_text___FSqhy">{groupToggleText}</span>
          </button>
        </div>
        {curriculumGroup.enrollment !== null && (
          <div className="curriculum-group__group_notice">
            {t('CurriculaGroupNotice').replace('{1}', (curriculumGroup.requiredCourseCount || 0).toString())}
          </div>
        )}
      </div>
    </div>
  );
}
