import React, { ReactElement } from 'react';
import { useSearchContext } from '../../contexts/searchContext';
import { CourseRating } from '../CourseRating';
import './CourseRatingRow.css';

interface Props {
  courseRating: number;
  checked: boolean;
}

export function CourseRatingRow({ courseRating, checked }: Props): ReactElement {
  const { disableFilter, setCourseRating } = useSearchContext();

  return (
    <label className="radio-module__label___2dAtI course-rating-filter-module__radio___1noPs radio__label">
      <input
        className="radio-module__input___3JWeW radio__input rating"
        type="radio"
        name="courseRatingFilterRadioGroup"
        value={courseRating}
        checked={checked}
        disabled={disableFilter}
        onChange={() => setCourseRating(courseRating)}
      />
      <div
        className={`radio-module__radio___1J40k radio-module__square_radio___2vn2f radio__square_indicator ${
          disableFilter ? 'disable-radio' : ''
        }`}
      />
      <CourseRating selectedStars={courseRating} isCourseRatingFilter disabled />
    </label>
  );
}
