import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalResourceData } from '../models/absorb/resources';
import { onFetchResourceUrl } from '../services/resources';
import { errorHandler, getResourceIcon } from '../utils/helper';

interface Props {
  globalResourceData: GlobalResourceData;
}

export function GlobalResourceCard({ globalResourceData }: Props): ReactElement {
  const { t } = useTranslation();

  function openResourceURL() {
    onFetchResourceUrl({ id: globalResourceData.id })
      .then((url) => {
        window.open(url, '_blank');
      })
      .catch(errorHandler);
  }

  return (
    <div className="card-module__card___Y3Vej card__card" onClick={() => openResourceURL()}>
      <div className="card-top-module__card_top___1iJXr card-top__card_top card-top-module__card_top_link___15N9A">
        <span className="accessibility-text-module__off_screen_text___FSqhy">` ${globalResourceData.name}`</span>
        <div
          className={`${getResourceIcon(
            globalResourceData.fileType
          )} icon thumbnail-module__container___14C5O card-module__thumbnail___pvU81 card__thumbnail thumbnail-module__no_image___1ASug thumbnail__container`}
        />
      </div>
      <div className="progress-bar-module__container___315yd progress-bar__bar">
        <div className="progress-bar-module__fill___JVvXF progress-bar__fill" />
      </div>
      <div className="card-module__card_bottom___2pcXZ card__card_bottom">
        <button className="card-module__name___1kcxc resource-card__name" title={globalResourceData.name}>
          {globalResourceData.name}
        </button>
        <div className="card-module__type___3Pm9I card__type" title={t('Resource')}>
          {t('Resource')}
        </div>
        <div className="card-module__card_action___1qfit resource-card__open_link">
          <div className="action-button-module__container___2O6X7">
            <button type="button" aria-disabled="false" className="action-button-module__btn___3zkzb btn">
              <div className="action-button-module__title___1uZt_">Open</div>
              <span aria-live="polite" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
