import React, { ReactElement } from 'react';
import { SearchResultData } from '../models/absorb/search';
import { ResourceCard } from './ResourceCard';

interface Props {
  searchResults: SearchResultData[] | undefined;
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}
export function SearchResultCardView({ searchResults, updatePinnedStatus }: Props): ReactElement {
  return (
    <div className="search-results-module__cards_container___1WrCM seach-results__cards_container">
      {searchResults &&
        searchResults.map((searchResult: SearchResultData) => (
          <div key={searchResult.resource.id} className="search-results-module__card___1wTVo">
            <ResourceCard resource={searchResult} updatePinnedStatus={updatePinnedStatus} />
          </div>
        ))}
    </div>
  );
}
