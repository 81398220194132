import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalResourceData } from '../models/absorb/resources';
import { getResourceIcon } from '../utils/helper';

interface Props {
  resourceData: GlobalResourceData;
  fetchResourceUrl?: (data: { id: string }) => void;
}

export function ResourcesCardView({ resourceData, fetchResourceUrl }: Props): ReactElement {
  const { t } = useTranslation();

  function getResourceIconClassName(fileType: string | undefined) {
    return `${getResourceIcon(
      fileType
    )} thumbnail-module__container___14C5O icon card-module__thumbnail___pvU81 card__thumbnail thumbnail-module__no_image___1ASug thumbnail__container`;
  }

  function onClickResource(id: string) {
    const data = {
      id,
    };

    if (fetchResourceUrl) {
      fetchResourceUrl(data);
    }
  }

  return (
    <div className="resources-module__card___34VFN">
      <div className="card-module__card___Y3Vej resource-card__card" onClick={() => onClickResource(resourceData.id)}>
        <div className="card-top-module__card_top___1iJXr resource-card-module__card_top___1U61a card-top__card_top card-top-module__card_top_link___15N9A">
          <span title={t('Resource')} className="accessibility-text-module__off_screen_text___FSqhy">
            {resourceData.name}
          </span>
          <div className={getResourceIconClassName(resourceData.fileType)} aria-hidden="true" />
        </div>
        <div className="card-module__card_bottom___2pcXZ resource-card__card_bottom">
          <div title={resourceData.name} className="card-module__name___1kcxc resource-card__name">
            {resourceData.name}
          </div>
          <div className="card-module__type___3Pm9I card__type">{t('Resource')}</div>
          <div className="card-module__card_action___1qfit resource-card__open_link">
            <div className="action-button-module__container___2O6X7">
              <button
                type="button"
                aria-disabled="false"
                aria-label={resourceData.name}
                className={
                  resourceData.fileType === 'PNG'
                    ? 'action-button-module__btn___3zkzb btn action-button-module__btn_disabled___2bOlE btn__disabled'
                    : 'action-button-module__btn___3zkzb btn'
                }
              >
                <div className="action-button-module__title___1uZt_" onClick={() => onClickResource(resourceData.id)}>
                  {t('Open')}
                </div>
                <span aria-live="polite" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
