import { LanguageData, LanguageTermsData } from '../models/absorb/language';
import { publicApiRequest } from '../utils/axios';

export async function fetchLanguages(): Promise<LanguageData[]> {
  return publicApiRequest('GET', '/languages').then((data) => data.languages);
}

export async function fetchLocalization(languageCode: string): Promise<LanguageTermsData[]> {
  return publicApiRequest('GET', `/terms/${languageCode}`).then((data) => data.terms);
}
