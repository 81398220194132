import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ABSORB_TAGS } from '../constants/absorb';
import { useModalContext } from '../contexts/modalContext';
import { useSearchContext } from '../contexts/searchContext';
import { TagData } from '../models/absorb/tags';

interface Props {
  tags: TagData[];
}

export function TagList({ tags }: Props): ReactElement {
  const navigate = useNavigate();

  const { closeModal } = useModalContext();
  const {
    advancedFilter,
    setAdvancedFilterOptions,
    setAdvancedFilter,
    isOnPageWithSearchCapabilities,
  } = useSearchContext();

  function onTagClick(tagId: string) {
    const selectedTags = advancedFilter[ABSORB_TAGS] !== undefined ? `${advancedFilter[ABSORB_TAGS]},${tagId}` : tagId;
    setAdvancedFilterOptions([ABSORB_TAGS]);
    setAdvancedFilter({
      ...advancedFilter,
      [ABSORB_TAGS]: selectedTags,
    });
    closeModal();

    if (!isOnPageWithSearchCapabilities()) {
      navigate(`/catalog?tags=${tagId}`);
    }
  }

  return (
    <div className="tag-list-module__tag_list___mFmG2">
      {tags.map((tag: TagData) => (
        <button
          key={tag.id}
          type="button"
          className="tag-list-module__tag_link___3U3ZX tag-list__tag_link"
          onClick={() => {
            onTagClick(tag.id);
          }}
        >
          {tag.name}
        </button>
      ))}
    </div>
  );
}
