import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ABSORB_TAGS, ABSORB_VENDOR, ABSORB_VENUE_TYPE, ABSORB_VENUE_LOCATION } from '../../constants/absorb';
import { useSearchContext } from '../../contexts/searchContext';
import { AdvancedFilterItem } from './AdvancedFilterItem';

export function AdvancedFilter(): ReactElement {
  const { t } = useTranslation();

  const { advancedFilterOptions, setAdvancedFilterOptions } = useSearchContext();
  const options: string[] = [ABSORB_TAGS, ABSORB_VENDOR, ABSORB_VENUE_TYPE, ABSORB_VENUE_LOCATION];

  function onSelect(filterType: string) {
    setAdvancedFilterOptions([...advancedFilterOptions, filterType]);
  }

  function areAllFiltersDisplayed() {
    return options.length === advancedFilterOptions.length;
  }

  return (
    <>
      {advancedFilterOptions.map((filterType) => (
        <AdvancedFilterItem key={filterType} filterType={filterType} />
      ))}
      {areAllFiltersDisplayed() ? null : (
        <>
          <label className="filter-picker-module__title___1IAi9 filter-picker__title">{t('AdvancedFilter')}:</label>
          <div className="dropdown-module__field___5TFai filter-picker-module__dropdown___2T3Fw">
            <select name="filter_picker" id="filter_picker" value="default" onChange={(e) => onSelect(e.target.value)}>
              <option value="disabled">{t('SelectFilter')}</option>
              {options.map((option: string) =>
                advancedFilterOptions.includes(option) ? null : (
                  <option key={option} value={option}>
                    {t(option)}
                  </option>
                )
              )}
            </select>
            <span className="dropdown-module__dropdown_arrow___9etxZ dropdown__arrow icon icon-arrow-down" />
          </div>
        </>
      )}
    </>
  );
}
