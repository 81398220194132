import React, { ReactElement, useState } from 'react';
import { CatalogCourseData } from '../models/absorb/catalog';
import { CurriculumGroupData } from '../models/absorb/curriculum';
import { CurriculumGroupHeader } from '../components/CurriculumGroupHeader';
import { DisplayContentView } from '../constants/courses';
import { ListViewRow } from '../components/ListViewRow';
import { CourseDetail } from '../components/CourseDetail';
import './CurriculumGroupView.css';

interface Props {
  curriculumGroup: CurriculumGroupData;
  curriculumRequiresPurchase: boolean;
  contentView: DisplayContentView;
}

export function CurriculumGroupView({ curriculumGroup, curriculumRequiresPurchase, contentView }: Props): ReactElement {
  const [showCourses, setShowCourses] = useState(true);
  const [curriculumGroupData, setCurriculumGroupData] = useState<CurriculumGroupData>(curriculumGroup);

  function getIsEnrollmentLockedStatus(course: CatalogCourseData) {
    if (curriculumGroup.enrollment === null) {
      if (
        (!course.isPartOfCurriculumEnrollment && course.canSelfEnroll) ||
        (course.isPartOfCurriculumEnrollment && !course.canSelfEnroll) ||
        course.enrolled
      ) {
        return false;
      }
    }

    if (!curriculumGroup.enrollment?.isLocked) {
      if (
        (course.isPartOfCurriculumEnrollment && course.canSelfEnroll) ||
        (!course.isPartOfCurriculumEnrollment && course.canSelfEnroll)
      ) {
        return false;
      }
    }

    return true;
  }

  function updatePinnedStatus(isPinned: boolean, courseId: string) {
    const coursesToUpdate = [...curriculumGroupData.courses];
    const index = coursesToUpdate.findIndex((course) => course.id === courseId);
    if (index > -1) {
      coursesToUpdate[index].isPinned = isPinned;
      setCurriculumGroupData(curriculumGroupData);
    }
  }

  return (
    <div className="curriculum-group-module__group_container___1M-An curriculum-group-module__group_container_list_view___31PL0">
      <CurriculumGroupHeader
        curriculumGroup={curriculumGroup}
        contentView={contentView}
        showCourses={showCourses}
        setShowCourses={setShowCourses}
      />
      {showCourses && (
        <div
          className={`curriculum-group__panels_container ${
            contentView === DisplayContentView.Detailed ? 'curriculum-group-module__panels_container___kb77y' : ''
          }`}
        >
          {curriculumGroup.courses.map((course: CatalogCourseData) =>
            contentView === DisplayContentView.Detailed ? (
              <CourseDetail
                key={course.id}
                courseData={course}
                isEnrollmentLocked={getIsEnrollmentLockedStatus(course)}
                curriculumRequiresPurchase={curriculumRequiresPurchase}
                updatePinnedStatus={updatePinnedStatus}
              />
            ) : (
              <ListViewRow
                key={course.id}
                catalogData={course}
                isEnrollmentLocked={curriculumGroup?.enrollment === null ? false : curriculumGroup.enrollment.isLocked}
                curriculumRequiresPurchase={curriculumRequiresPurchase}
                updatePinnedStatus={updatePinnedStatus}
              />
            )
          )}
        </div>
      )}
    </div>
  );
}
