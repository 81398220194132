import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { errorHandler, getTimeZone, getTimeZoneAbbreviation, getTimeSeconds, getTimeShortDate } from '../utils/helper';
import { DashboardLayout } from '../components/DashboardLayout';
import { LoadingIndicator } from '../components/Spinner';
import { fetchMyEnrollments } from '../services/course';
import { TranscriptData, TranscriptResponseData } from '../models/absorb/transcripts';
import { useBrandingContext } from '../contexts/brandingContext';
import { useProfileContext } from '../contexts/profileContext';
import { API_RESULT_LIMIT, SortBy } from '../constants/common';
import { LoadMoreActionButton } from '../components/LoadMoreActionButton';
import { TranscriptAssessments } from '../components/TranscriptAssessments';
import { PageTitle } from '../components/PageTitle';
import './TranscriptPage.css';

export function TranscriptsPage(): ReactElement {
  const { t } = useTranslation();

  const { brandingCoursesSettings } = useBrandingContext();
  const { profile } = useProfileContext();

  const componentRef = useRef(null);

  const [sortBy, setSortBy] = useState<string>(SortBy.CourseName);
  const [enrollmentData, setEnrollmentData] = useState<TranscriptData[]>([]);
  const [ascendingOrder, setAscendingOrder] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isMyEnrollmentsLoading, setIsMyEnrollmentsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const limit = API_RESULT_LIMIT;
    const sort = ascendingOrder ? sortBy : `-${sortBy}`;
    fetchMyEnrollments(limit, sort, offset)
      .then((response: TranscriptResponseData) => {
        if (response.offset === 0) {
          setEnrollmentData(response.enrollments);
        } else {
          setEnrollmentData((enrollments) => [...enrollments, ...response.enrollments]);
        }
        setTotalItems(response.totalItems);
        setIsMyEnrollmentsLoading(false);
      })
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }, [ascendingOrder, sortBy, offset]);

  function setSortingParams(columnName: string) {
    setOffset(0);
    if (sortBy === columnName) {
      setAscendingOrder(!ascendingOrder);
    } else {
      setAscendingOrder(true);
    }
    setSortBy(columnName);
  }

  function getHeading() {
    let headerText = t('TranscriptFor');
    if (headerText.includes('{1}') && headerText.includes('{2}')) {
      headerText = headerText.replace('{1}', profile ? profile.firstName : '');
      headerText = headerText.replace('{2}', profile ? profile.lastName : '');
    } else {
      headerText = `Transcript For ${profile?.firstName} ${profile?.lastName}`;
    }
    return headerText;
  }

  function fetchMoreTranscript() {
    setIsMyEnrollmentsLoading(true);
    setOffset(offset + 1);
  }

  function getClassName(sortType: string): string {
    const defaultClass = 'icon icon-arrow-right sortable-table-module__sort___JC2rs sortable-table__sort_none';
    const sortedOrderClass = ascendingOrder
      ? 'icon icon-arrow-up sortable-table-module__sort___JC2rs sortable-table__sort_ascending'
      : 'icon icon-arrow-down sortable-table-module__sort___JC2rs sortable-table__sort_descending';

    const lookup: { [key: string]: string } = {
      [sortType]: sortedOrderClass,
    };

    return lookup[sortBy] || defaultClass;
  }

  function getTimesDisplayTranslation(): string {
    let timesText = t('TimesDisplayedIn');

    if (timesText.includes('{0}')) {
      timesText = timesText.replace('{0}', '');
    }

    return timesText;
  }

  return (
    <DashboardLayout>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div ref={componentRef}>
          <PageTitle title={t('Transcript')} />
          <div
            className="banner banner-module__banner___IJO0Z transcript-module__banner___fadeJ banner__banner"
            style={{
              backgroundImage: `url("${encodeURI(brandingCoursesSettings?.myCoursesAndCatalog?.bannerUri || '')}")`,
            }}
          >
            <div className="banner-title-module__group___3Ih2M">
              <h1 className="banner-title-module__title___1mbDk transcript-module__banner_title___1B9yD banner__title">
                {getHeading()}
              </h1>
            </div>
            <div className="main-container-share print-transcript">
              {enrollmentData && (
                <Link
                  title={t('PrintTranscript')}
                  className="link-button-module__link___3tT4y transcript-module__print_btn___1YpoI banner__btn btn"
                  to="/print-transcript"
                >
                  {t('PrintTranscript')}
                </Link>
              )}
            </div>
          </div>
          <>
            <div className="transcript-module__profile___DQkZq transcript__profile">
              <div className="transcript-module__avatar_container___jOdN2 transcript__avatar_container">
                {profile?.avatar ? (
                  <img
                    className="my-profile-module__avatar___1KG5b my-profile-module__avatar_placeholder___pKWYk my-profile__avatar my-profile__avatar_placeholder"
                    src={profile ? profile.avatar : ''}
                    alt="profile"
                  />
                ) : (
                  <div className="icon icon-man my-profile-module__avatar___1KG5b my-profile-module__avatar_placeholder___pKWYk my-profile__avatar my-profile__avatar_placeholder" />
                )}
              </div>
              <div>
                <div className="transcript-module__profile_field___1c68s transcript__profile_field transcript__profile_field_email">
                  <div className="icon icon-at transcript-module__profile_field_icon___FWfsx transcript-module__profile_field_icon_email___6rPXU transcript__profile_field_icon" />
                  <div className="transcript__profile_field_title">{t('EmailAddress')}:</div>
                  <div className="transcript-module__profile_field_value___1KBbu transcript__profile_field_value">
                    {profile && profile.emailAddress}
                  </div>
                </div>
                <div className="transcript-module__profile_field___1c68s transcript__profile_field transcript__profile_field_department">
                  <div className="icon icon-departments transcript-module__profile_field_icon___FWfsx transcript-module__profile_field_icon_department___2hruL transcript__profile_field_icon" />
                  <div className="transcript__profile_field_title">{t('Department')}:</div>
                  <div className="transcript-module__profile_field_value___1KBbu transcript__profile_field_value">
                    {profile && profile.departmentName}
                  </div>
                </div>
                <div className="transcript-module__profile_field___1c68s transcript__profile_field transcript__profile_field_credits">
                  <div className="icon icon-hat transcript-module__profile_field_icon___FWfsx transcript-module__profile_field_icon_credits___qXA_e transcript__profile_field_icon" />
                  <div className="transcript__profile_field_title">{t('Credits')}:</div>
                  <div className="transcript-module__profile_field_value___1KBbu transcript__profile_field_value">
                    {profile && profile.totalEducationalCredits}
                  </div>
                </div>
              </div>
            </div>

            {enrollmentData.length > 0 && (
              <div className="transcript-module__body___1sCOa transcript__body">
                <div id="enrollments" className="transcript-module__enrollments___3LKWc transcript__enrollments">
                  {profile && <TranscriptAssessments email={profile.emailAddress} />}
                  <div className="transcript-module__header_container___Exguq">
                    <h2 className="transcript-module__header___3DDfH anchor transcript__header">{t('Courses')}</h2>
                  </div>
                  <div className="sortable-table-module__table___2epWu sortable-table__table" role="table">
                    <div role="rowgroup">
                      <div
                        className="sortable-table-module__table_row_header___hu7Ms sortable-table__table_row_header"
                        role="row"
                      >
                        <div
                          className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_name_col___bh2jb"
                          role="columnheader"
                        >
                          <button
                            className="sortable-table-column-header-module__table_row_header_item_button___2jk00"
                            onClick={() => setSortingParams(SortBy.CourseName)}
                          >
                            {t('CourseTitle')}
                            <span className={getClassName(SortBy.CourseName)} />
                          </button>
                        </div>
                        <div
                          className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_status_col___27sBA"
                          role="columnheader"
                        >
                          <button
                            className="sortable-table-column-header-module__table_row_header_item_button___2jk00"
                            onClick={() => setSortingParams(SortBy.EnrollmentStatus)}
                          >
                            {t('Status')}
                            <span className={getClassName(SortBy.EnrollmentStatus)} />
                          </button>
                        </div>
                        <div
                          className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_score_col___KMO2B"
                          role="columnheader"
                        >
                          <button
                            className="sortable-table-column-header-module__table_row_header_item_button___2jk00"
                            onClick={() => setSortingParams(SortBy.ExamScore)}
                          >
                            {t('Score')}
                            <span className={getClassName(SortBy.ExamScore)} />
                          </button>
                        </div>
                        <div
                          className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_enrollement_date_col___3J2F1"
                          role="columnheader"
                        >
                          <button
                            className="sortable-table-column-header-module__table_row_header_item_button___2jk00"
                            onClick={() => setSortingParams(SortBy.EnrollmentDate)}
                          >
                            {t('EnrollmentDate')}
                            <span className={getClassName(SortBy.EnrollmentDate)} />
                          </button>
                        </div>
                        <div
                          className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_completion_date_col___3yZ8U"
                          role="columnheader"
                        >
                          <button
                            className="sortable-table-column-header-module__table_row_header_item_button___2jk00"
                            onClick={() => setSortingParams(SortBy.CompletionDate)}
                          >
                            {t('CompletionDate')}
                            <span className={getClassName(SortBy.CompletionDate)} />
                          </button>
                        </div>
                        <div
                          className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_credits_col___3FKKZ"
                          role="columnheader"
                        >
                          <button
                            className="sortable-table-column-header-module__table_row_header_item_button___2jk00"
                            onClick={() => setSortingParams(SortBy.EducationalCredits)}
                          >
                            {t('Credits')}
                            <span className={getClassName(SortBy.EducationalCredits)} />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div role="rowgroup">
                      {enrollmentData.map((enrollment: TranscriptData) => (
                        <div key={enrollment.id} className="sortable-table-module__table_row_container___8gbRc">
                          <div
                            className="sortable-table-module__table_row___3N1yN sortable-table__table_row transcript__row"
                            role="row"
                          >
                            <div
                              className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_name_col___bh2jb"
                              role="cell"
                            >
                              <div className="transcript-module__cell_header___QsLhO1">
                                <span className="transcript-module__cell_header___QsLhO">{t('CourseTitle')}:</span>
                                {enrollment.courseName}
                              </div>
                            </div>
                            <div
                              className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_status_col___27sBA"
                              role="cell"
                            >
                              <span className="transcript-module__cell_header___QsLhO">{t('Status')}:</span>
                              <div
                                className={
                                  enrollment.enrollmentStatus === 'Complete'
                                    ? 'transcript-module__chip___OoMuz chip__chip chip__success'
                                    : 'transcript-module__chip___OoMuz chip__chip'
                                }
                              >
                                {t(enrollment.enrollmentStatus)}
                              </div>
                            </div>
                            <div
                              className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_score_col___KMO2B"
                              role="cell"
                            >
                              {enrollment.examScore ? (
                                <>
                                  <span className="transcript-module__cell_header___QsLhO">{t('Score')}</span>
                                  {`${enrollment.examScore}%`}
                                </>
                              ) : null}
                            </div>
                            <div
                              className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_enrollement_date_col___3J2F1"
                              role="cell"
                            >
                              <div>
                                <span className="transcript-module__cell_header___QsLhO">{t('EnrollmentDate')}:</span>
                                <span className="transcript-module__datetime___2dc83">
                                  {getTimeShortDate(enrollment.enrollmentDate)}
                                </span>
                                <span className="transcript-module__datetime___2dc83">
                                  {getTimeSeconds(enrollment.enrollmentDate)}
                                </span>
                              </div>
                            </div>
                            <div
                              className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_completion_date_col___3yZ8U"
                              role="cell"
                            >
                              <div>
                                {enrollment.completionDate ? (
                                  <>
                                    <span className="transcript-module__cell_header___QsLhO">
                                      {t('CompletionDate')}:
                                    </span>
                                    <span className="transcript-module__datetime___2dc83">
                                      {getTimeShortDate(enrollment.completionDate)}
                                    </span>
                                    <span className="transcript-module__datetime___2dc83">
                                      {getTimeSeconds(enrollment.completionDate)}
                                    </span>
                                  </>
                                ) : null}
                              </div>
                            </div>
                            <div
                              className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_credits_col___3FKKZ"
                              role="cell"
                            >
                              {enrollment.educationalCredits ? (
                                <>
                                  <span className="transcript-module__cell_header___QsLhO">{t('Credits')}</span>
                                  {enrollment.educationalCredits}
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="transcript-module__page_size_btn_wrapper___2SOIz">
                      {totalItems && enrollmentData.length < totalItems && (
                        <LoadMoreActionButton
                          currentItemsCount={enrollmentData.length}
                          totalItemsCount={totalItems}
                          itemsAreLoading={isMyEnrollmentsLoading}
                          fetchMoreFunction={fetchMoreTranscript}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <span className="transcript-module__time_zone___U1A9M">
                  {getTimesDisplayTranslation()} (UTC{getTimeZone()}) {getTimeZoneAbbreviation()} (
                  {Intl.DateTimeFormat().resolvedOptions().timeZone})
                </span>
              </div>
            )}
          </>
        </div>
      )}
    </DashboardLayout>
  );
}
