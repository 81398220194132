import React, { ReactElement, useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';

interface Props {
  label: string;
  placeholder: string;
  value?: string;
  className?: string;
  onChangeHandleInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeHandleCrossButton?: (crossButton: boolean) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export function InputBox({
  label,
  placeholder,
  value,
  className,
  onChangeHandleInput,
  onChangeHandleCrossButton,
  onKeyPress = () => {},
}: Props): ReactElement {
  const [crossButton, setCrossButton] = useState(false);

  useEffect(() => {
    setCrossButton(!!value);
  }, [value]);

  function handleCrossButton() {
    if (onChangeHandleCrossButton) {
      onChangeHandleCrossButton(!crossButton);
    }
  }

  return (
    <div className={className}>
      <label className="text-filter-module__title___2zlYI text-filter__title">{`${label}:`}</label>
      <div className="text-filter-module__input_container___3VpOp">
        <div className="text-input-module__field___2btL- text-filter-module__text_input___2rq1e text-filter__text_input">
          <input
            className="text-filter-module__input___3HeCm"
            type="text"
            step="any"
            placeholder={placeholder}
            value={value}
            onChange={onChangeHandleInput}
            onKeyPress={onKeyPress}
          />
        </div>
        <button
          type="button"
          className="icon-button-module__btn___Zb89b text-filter-module__submit_btn___1uLSe text-filter__submit_btn"
          title={label}
          onClick={handleCrossButton}
        >
          <div className={crossButton ? 'icon icon-x-thick' : 'icon icon-arrow-right-go'} />
          <span className="accessibility-text-module__off_screen_text___FSqhy">{label}</span>
        </button>
      </div>
    </div>
  );
}
