import React, { ReactElement } from 'react';
import { ABSORB_TAGS, ABSORB_VENDOR, ABSORB_VENUE_LOCATION, ABSORB_VENUE_TYPE } from '../../constants/absorb';
import { VenueLocationFilter } from './VenueLocationFilter';
import { VenueTypeFilter } from './VenueTypeFilter';
import { VendorFilter } from './VendorFilter';
import { TagFilter } from './TagFilter';

interface Props {
  filterType: string;
}

export function AdvancedFilterItem({ filterType }: Props): ReactElement {
  return (
    <>
      {filterType === ABSORB_TAGS && <TagFilter />}
      {filterType === ABSORB_VENDOR && <VendorFilter />}
      {filterType === ABSORB_VENUE_TYPE && <VenueTypeFilter />}
      {filterType === ABSORB_VENUE_LOCATION && <VenueLocationFilter />}
    </>
  );
}
