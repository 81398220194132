import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { constructCourseTypePath, convertToTimeAgoDate, errorHandler, getCourseIcon } from '../utils/helper';
import { fetchUserActivity } from '../services/activityFeed';
import { MyActivityData, ActivityResponse } from '../models/absorb/myActivity';
import { API_RESULT_LIMIT } from '../constants/common';
import { LoadingIndicator } from '../components/Spinner';
import { LoadMoreActionButton } from '../components/LoadMoreActionButton';
import { PageTitle } from '../components/PageTitle';
import './MyActivityPage.css';

export function MyActivityPage(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState('published');
  const [ascendingOrder, setAscendingOrder] = useState(false);
  const [myActivityData, setMyActivityData] = useState<MyActivityData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isActivityLoading, setIsActivityLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const limit = API_RESULT_LIMIT;
    const sort = ascendingOrder ? sortBy : `-${sortBy}`;
    let mounted = true;

    fetchUserActivity(limit, sort, offset)
      .then((response: ActivityResponse) => {
        if (mounted) {
          if (response.offset === 0) {
            setMyActivityData(response.activities);
          } else {
            setMyActivityData((activities) => [...activities, ...response.activities]);
          }
          setTotalItems(response.totalItems);
          setIsActivityLoading(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        errorHandler(error);
        setIsLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [ascendingOrder, sortBy, offset]);

  function setSortingParams(columnName: string) {
    setOffset(0);
    if (sortBy === columnName) {
      setAscendingOrder(!ascendingOrder);
    } else {
      setAscendingOrder(true);
    }
    setSortBy(columnName);
  }

  function fetchMoreActivity() {
    setIsActivityLoading(true);
    setOffset(offset + 1);
  }

  return (
    <div className="my-activity-module__list_container___hIuOl my-activity__list_container">
      <PageTitle title={t('MyActivity')} />
      <div className="my-activity-module__page_size_btn_wrapper___2g9TX">
        <div className="sortable-table-module__table___2epWu sortable-table__table" role="table">
          <div role="rowgroup">
            <div
              className="sortable-table-module__table_row_header___hu7Ms sortable-table__table_row_header"
              role="row"
            >
              <div
                className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item my-activity-module__type_column___3pJKZ my-activity__type_column"
                role="columnheader"
                aria-hidden="true"
              >
                <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                  {t('Type')}
                </span>
              </div>
              <div
                className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item my-activity-module__summary_column___3USec my-activity__summary_column"
                role="columnheader"
              >
                <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                  {t('Summary')}
                </span>
              </div>
              <div
                className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item my-activity-module__published_column___3kTfB my-activity__published_column"
                role="columnheader"
                onClick={() => setSortingParams('published')}
              >
                <button
                  className="sortable-table-column-header-module__table_row_header_item_button___2jk00"
                  title="Click here to sort by Date Ascending."
                >
                  {t('Date')}
                  <span
                    className={
                      ascendingOrder
                        ? 'icon icon-arrow-up sortable-table-module__sort___JC2rs sortable-table__sort_ascending'
                        : 'icon icon-arrow-down sortable-table-module__sort___JC2rs sortable-table__sort_descending'
                    }
                  />
                </button>
              </div>
            </div>
          </div>

          {
            // eslint-disable-next-line no-nested-ternary
            isLoading ? (
              <LoadingIndicator />
            ) : myActivityData && myActivityData.length ? (
              <>
                <div className="sortable-table-module__table_row_container___8gbRc">
                  {myActivityData.map((activity: MyActivityData) => (
                    <div
                      key={activity.id}
                      className="sortable-table-module__table_row___3N1yN sortable-table__table_row my-activity-module__activity_row___2LLkL"
                      role="row"
                    >
                      <div
                        className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item my-activity-module__type_column___3pJKZ my-activity__type_column"
                        role="cell"
                        aria-hidden="true"
                      >
                        <div className="my-activity-module__activity_info___3JHqV">
                          <span
                            className={`my-activity-module__activity_type_icon___3DRHX icon ${getCourseIcon(
                              activity.object.type
                            )}  my-activity__activity_type_icon`}
                          />
                        </div>
                      </div>
                      <div
                        className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item my-activity-module__summary_column___3USec my-activity__summary_column"
                        role="cell"
                      >
                        <button
                          className="my-activity-module__activity_summary___3XzaD my-activity__activity_summary"
                          onClick={() =>
                            navigate(constructCourseTypePath(activity.object.type, activity.object.id))
                          }
                        >
                          {activity.summary}
                        </button>
                      </div>
                      <div
                        className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item my-activity-module__published_column___3kTfB my-activity__published_column"
                        title={new Date(activity.published).toDateString()}
                        role="cell"
                      >
                        <span className="my-activity__date">{convertToTimeAgoDate(activity.published)}</span>
                      </div>
                    </div>
                  ))}
                </div>
                {totalItems && myActivityData.length < totalItems && (
                  <LoadMoreActionButton
                    currentItemsCount={myActivityData.length}
                    totalItemsCount={totalItems}
                    itemsAreLoading={isActivityLoading}
                    fetchMoreFunction={fetchMoreActivity}
                  />
                )}
              </>
            ) : (
              <div
                aria-label={t('LoadMore')}
                className="my-activity-module__no_activity___18DnG my-activity__no_activity"
              >
                {t('NoActivity')}
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
