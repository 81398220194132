export const CART_ICON_UNSELECTED =
  'icon-button-module__btn___Zb89b header-module__btn___2r7Qs header-module__shopping_cart_btn___3faWP header__btn header__shopping_cart';
export const CART_ICON_SELECTED = `${CART_ICON_UNSELECTED} header-module__btn_selected___AqqXV header__btn_selected`;

// Status Icons
export const STATUS_ICON_SHOPPING_CART = 'icon-shopping-cart';
export const STATUS_ICON_SHOPPING_CART_ADDED = 'icon-shopping-cart-added';
export const STATUS_ICON_COURSE_ENROLL = 'icon-plus-circle';
export const STATUS_ICON_COURSE_START = 'icon-play-circle';
export const STATUS_ICON_COURSE_RESUME = 'icon-resume';
export const STATUS_ICON_COURSE_COMPLETE = 'icon-check-mark';

export const DEFAULT_CART_STEP_CLASS = 'steps-module__step___2lKsX steps__step';
export const ACTIVE_CART_STEP_CLASS = `${DEFAULT_CART_STEP_CLASS} steps-module__current___1qvXv steps__step_current`;
export const COMPLETED_CART_STEP_CLASS = `${DEFAULT_CART_STEP_CLASS} steps-module__complete___2vkPR steps__step_complete`;
export const BUTTON_DISABLED_CLASS =
  'btn cart-shipping-module__submit_btn___1ektB cart-shipping__submit_btn button-module__btn___1lXcC btn__disabled';
export const BUTTON_ENABLED_CLASS =
  'btn cart-shipping-module__submit_btn___1ektB cart-shipping__submit_btn button-module__btn___1lXcC';
export const WITH_DEFAULT_THUMBNAIL_CLASS =
  'thumbnail-module__container___14C5O cart-course-cell-module__thumbnail___3AzzU icon icon-online-course thumbnail-module__no_image___1ASug thumbnail__container';
export const WITHOUT_DEFAULT_THUMBNAIL_CLASS =
  'thumbnail-module__container___14C5O cart-course-cell-module__thumbnail___3AzzU icon icon-online-course thumbnail__container';

const INVALID_ICON_COLOR = '#e44555';

export const CARD_ELEMENT_OPTIONS = {
  style: {
    invalid: {
      iconColor: INVALID_ICON_COLOR,
    },
  },
};
