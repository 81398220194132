import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { isOnGlobalSearchPage } from '../../constants/common';
import { CourseFilterCheckBoxes } from './CourseFilterCheckBoxes';
import { EntityFilterCheckBoxes } from './EntityFilterCheckBoxes';

export function EntitiesFilter(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="filter-panel-module__filter_panel___2xlm8 filter-panel__filter_panel">
      <label className="course-type-filter-module__title___3lUSx course-type-filter__title">
        {isOnGlobalSearchPage() ? t('FilterBy') : t('CourseType')}
      </label>
      <ul className="course-type-filter-module__courses___2-LYO">
        <CourseFilterCheckBoxes />
        {isOnGlobalSearchPage() && <EntityFilterCheckBoxes />}
      </ul>
    </div>
  );
}
