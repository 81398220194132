import React, { ReactElement } from 'react';
import './SpinnerButton.css';

export function SpinnerButton(): ReactElement {
  return (
    <div className="updated_loader_container">
      <div className="updated_button_module__loader" />
    </div>
  );
}
