import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE } from '../constants/common';

function getParsedi18nData() {
  const i18nData = localStorage.getItem('i18nData');
  if (i18nData) {
    return JSON.parse(i18nData);
  }
  return { en: { translation: {} } };
}

function getLanguageCode() {
  const languageCode = localStorage.getItem('languageCode');
  if (languageCode) {
    return languageCode;
  }
  return DEFAULT_LANGUAGE;
}

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  defaultNS: 'translation',
  resources: getParsedi18nData(),
  fallbackLng: DEFAULT_LANGUAGE,
  lng: getLanguageCode(),
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // React already safes from xss
  },
});

export default i18n;
