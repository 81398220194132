import React, { ReactElement, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryData } from '../models/absorb/country';
import { ProvinceData } from '../models/absorb/province';
import './SelectInput.css';

interface Props {
  labelName: string;
  name: string;
  id: string;
  selectedValue: string;
  errorId: string;
  type: string;
  countryData?: CountryData[];
  provinceData?: ProvinceData[];
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export function SelectInput({
  labelName,
  name,
  id,
  selectedValue,
  errorId,
  type,
  countryData,
  provinceData,
  disabled = false,
  onChange,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="redux-form-select-module__field___3I129 cart-shipping-module__field___Lisyr">
      <div className="redux-form-select-module__label_wrapper___YVyB_">
        <label className="redux-form-select-module__label___1Zosh redux-form-select__label" htmlFor={id}>
          {labelName}
        </label>
        <div className="redux-form-select-module__required_label___1mPRZ redux-form-select__label" aria-hidden="true">
          <span className="accessibility-text-module__off_screen_text___FSqhy">
            {t(disabled ? 'disabled' : 'Required')}
          </span>
          <span aria-hidden="true">{t(disabled ? 'disabled' : 'Required')}</span>
        </div>
      </div>
      <div className="redux-form-select-module__select_wrapper___1K45d">
        <select
          className="select-disabled-white-background"
          name={name}
          id={id}
          aria-describedby={errorId}
          onChange={onChange}
          value={selectedValue}
          disabled={disabled}
        >
          <option value="disabled" hidden>
            {type}
          </option>
          {countryData?.map((country: CountryData) => (
            <option key={country.countryCode} value={country.countryCode}>
              {country.name}
            </option>
          ))}
          {provinceData?.map((province: ProvinceData) => (
            <option key={province.name} value={province.name}>
              {province.name}
            </option>
          ))}
        </select>
        <div aria-live="assertive" id={errorId} />
        <span className="redux-form-select-module__dropdown_arrow___SbFw6 icon icon-arrow-down dropdown__arrow" />
      </div>
    </div>
  );
}
