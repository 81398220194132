import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../contexts/languageContext';
import { useModalContext } from '../contexts/modalContext';
import { LanguageData } from '../models/absorb/language';

export function LocalizationModal(): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();
  const { language, languages, setLanguage } = useLanguageContext();

  function onLanguageSelect(code: string) {
    setLanguage(code);

    closeModal();
  }

  function onCloseModalButtonClick() {
    closeModal();
  }

  return (
    <div
      className="modal-module__container___sfedc modal__container"
      role="dialog"
      aria-label="Inbox"
      aria-modal="true"
    >
      <div className="modal-module__blocker___231AR modal__blocker" />
      <div className="modal-module__modal_container___3tUB2 modal__modal_container">
        <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
          <button
            type="button"
            className="icon-button-module__btn___Zb89b modal-module__close_btn___1yWvZ modal__close_btn"
            title={t('Close')}
            onClick={onCloseModalButtonClick}
          >
            <div className="icon icon-x-thin" />
            <span className="accessibility-text-module__off_screen_text___FSqhy">
              {t('Close')} {t('ChooseYourLanguage')}
            </span>
          </button>
          <div className="modal-module__modal___11QTd modal__modal modal-module__modal_default_size___2ui9v">
            <div className="languages-module__container___1ut2M">
              <div className="languages-module__header___1EIyK languages__header">{t('ChooseYourLanguage')}</div>
              <div className="languages-module__languages___3Hv2k">
                {languages.map((languageData: LanguageData) =>
                  language && languageData.abbreviation === language ? (
                    <button
                      key={languageData.abbreviation}
                      type="button"
                      disabled
                      className="languages-module__language_btn___3_5d1 languages-module__selected___19Sfz languages__language_btn"
                      onClick={() => onLanguageSelect(languageData.abbreviation)}
                    >
                      {languageData.nativeName}
                    </button>
                  ) : (
                    <button
                      key={languageData.abbreviation}
                      type="button"
                      className="languages-module__language_btn___3_5d1 languages__language_btn"
                      onClick={() => onLanguageSelect(languageData.abbreviation)}
                    >
                      {languageData.nativeName}
                    </button>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
