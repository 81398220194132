import React, { ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '../components/DashboardLayout';
import { useBrandingContext } from '../contexts/brandingContext';
import { SECOND_URL_INDEX } from '../constants/common';
import { errorHandler } from '../utils/helper';
import { fetchInstructorLedDetails, fetchInstructorLedSessions } from '../services/instructorLed';
import { fetchMyCourseEnrollment } from '../services/course';
import { InstructorLedDetailsData, InstructorLedSessionsData } from '../models/absorb/instructorLed';
import { CourseEnrollmentData } from '../models/absorb/course';
import { ShareCourseButton } from '../components/ShareCourseButton';
import { PinCourseButton } from '../components/PinCourseButton';
import { AbsorbEnrollmentStatus } from '../constants/absorb';
import { RadialProgressIndicatorLarge } from '../components/RadialProgressIndicatorLarge';
import { SessionHeader } from '../components/SessionHeader';
import { SessionItem } from '../components/SessionItem';
import { ExpandableDescription } from '../components/ExpandableDescription';
import { useCourseAccessErrorRedirect } from '../hooks/useCourseAccessErrorRedirect';
import { LoadingIndicator } from '../components/Spinner';
import { PageTitle } from '../components/PageTitle';
import './InstructorLedPage.css';
import { ChatWidget } from '../components/ChatWidget';

export function InstructorLedPage(): ReactElement {
  const { t } = useTranslation();
  const location = useLocation();

  const path = location.pathname;
  const selectedCoursePath = path.split('/');
  // TODO: fix potential buffer overrun, URL_INDEX naming, passing in string that was manipulated outside the component
  const courseId = selectedCoursePath[SECOND_URL_INDEX];
  const { brandingCoursesSettings } = useBrandingContext();

  const [loadingInProgress, setLoadingInProgress] = useState(true);
  const [instructorLedDetails, setInstructorLedDetails] = useState<InstructorLedDetailsData>();
  const [instructorLedSessions, setInstructorLedSessions] = useState<InstructorLedSessionsData>();
  const [myCourseEnrollment, setMyCourseEnrollment] = useState<CourseEnrollmentData>();
  const [setCourseAccessError] = useCourseAccessErrorRedirect();

  useEffect(() => {
    fetchInstructorLedDetails(courseId)
      .then((instructorLedDetailsData) => {
        setInstructorLedDetails(instructorLedDetailsData);

        Promise.all([
          fetchInstructorLedSessions(courseId).then(setInstructorLedSessions),
          fetchMyCourseEnrollment(courseId).then(setMyCourseEnrollment),
        ])
          .catch(errorHandler)
          .finally(() => setLoadingInProgress(false));
      })
      .catch((err) => {
        errorHandler(err);
        setLoadingInProgress(false);
        setCourseAccessError(true);
      });
  }, [courseId]); // eslint-disable-line react-hooks/exhaustive-deps

  function updatePinnedStatus() {
    if (instructorLedDetails) {
      setInstructorLedDetails({ ...instructorLedDetails, isPinned: !instructorLedDetails.isPinned });
    }
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('InstructorLedCourse')} />
      <div className="instructor-led-course-module__container___3v2Aw">
        <div aria-live="assertive" role="status" />
        <div
          className="course-detail-header-module__container___1uS5v course-detail-header__container"
          style={{
            backgroundImage: `url("${encodeURI(brandingCoursesSettings?.coursesDetails?.bannerUri || '')}")`,
          }}
        >
          <div className="course-detail-header-module__section_left___tdoyN">
            {instructorLedDetails ? (
              <div className="course-evaluation-module__question_title___CbTGT">
                <h1
                  className="course-detail-header-module__name___3GuSs course-detail-header__name"
                  title={instructorLedDetails.name}
                >
                  {instructorLedDetails.name}
                </h1>
                <div className="course-detail-header-module__type___3rQql">{t(instructorLedDetails.courseType)}</div>
              </div>
            ) : null}
          </div>
          <div className="course-options-module__course_options___1vuRm" role="menu">
            {instructorLedDetails && (
              <ShareCourseButton
                courseId={instructorLedDetails.id}
                courseType={instructorLedDetails.courseType}
                courseName={instructorLedDetails.name || ''}
              />
            )}
            {instructorLedDetails && (
              <PinCourseButton updatePinnedStatus={updatePinnedStatus} instructorLedDetails={instructorLedDetails} />
            )}
          </div>
        </div>
        <div className="course-alerts-module__banners___1PhwJ" tabIndex={-1}>
          <div className="course-curricula-status-module__curricula_progress_list___90dJ4" />
        </div>
        <nav>
          <div className="tab-list-module__tabs___39I9A">
            <div className="tab-list-module__tab___1ZDk5 tab-list-module__tab_active___1lSYg tab-list__tab_active tab-list__tab">
              <button type="button" className="tab-list-module__tab_btn___31NOt tab-list__tab_btn" aria-current="page">
                <span className="accessibility-text-module__off_screen_text___FSqhy">{t('CourseContent')}</span>
                <span aria-hidden="true">{t('CourseContent')}</span>
              </button>
            </div>
          </div>
        </nav>
        <div className="instructor-led-course-module__content___2kt6e" aria-hidden="false">
          <div className="instructor-led-course-module__content_left___wfpxJ">
            {loadingInProgress ? (
              <LoadingIndicator />
            ) : (
              <>
                {instructorLedSessions && myCourseEnrollment?.enrollmentStatus && (
                  <RadialProgressIndicatorLarge
                    totalCompleted={
                      instructorLedSessions.sessions.filter(
                        (session) => session.enrollmentStatus === AbsorbEnrollmentStatus.Complete
                      ).length || 0
                    }
                    totalRequired={instructorLedSessions.totalItems}
                    progress={myCourseEnrollment?.progress}
                    enrollmentStatus={myCourseEnrollment?.enrollmentStatus || ''}
                    showProgressInTopView
                  />
                )}
                {instructorLedDetails?.description && (
                  <div className="instructor-led-course-module__course_content___2_pKS instructor-led-course__content">
                    <div className="course-description-module__description_container___ooKcP course-description__description_container">
                      <ExpandableDescription itemName="" description={instructorLedDetails.description || ''} />
                    </div>
                  </div>
                )}
                {instructorLedSessions?.sessions && instructorLedSessions.sessions.length > 0 ? (
                  <div className="session-details-module__wrapper___39_Fd">
                    <div className="session-details-module__title___bUybU session_details__title">{t('MySession')}</div>
                    <SessionHeader
                      instructorLedSession={instructorLedSessions.sessions.find(
                        (session) => session.id === instructorLedDetails?.enrolledSessionId
                      )}
                      instructorLedCourseId={instructorLedDetails?.id}
                    />
                    <div className="class-list-module__class_list___14XYM">
                      {instructorLedSessions.sessions.map((session) => (
                        <SessionItem key={session.id} instructorLedSession={session} />
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <h2 className="instructor-led-course-session-information-module__session_header___2XbZ7">
                      {t('MySession')}
                    </h2>
                    <div className="session-list-module__no_sessions_box___CU9_n session-list__no_sessions_box">
                      {t('NoSessionsFound')}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="instructor-led-course-module__content_right___-LkXf">
            {instructorLedSessions && myCourseEnrollment?.enrollmentStatus && (
              <RadialProgressIndicatorLarge
                totalCompleted={
                  instructorLedSessions.sessions.filter(
                    (session) => session.enrollmentStatus === AbsorbEnrollmentStatus.Complete
                  ).length || 0
                }
                totalRequired={instructorLedSessions.totalItems}
                progress={myCourseEnrollment?.progress}
                enrollmentStatus={myCourseEnrollment?.enrollmentStatus || ''}
              />
            )}
            <div id="chat-widget" className="divChatWidget">
              <ChatWidget
                instructorLedSession={instructorLedSessions?.sessions.find(
                  (session) => session.id === instructorLedDetails?.enrolledSessionId
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
