import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../contexts/modalContext';
import { CourseUploadModal } from '../modals/CourseUploadModal';
import { CourseUploadsData } from '../models/absorb/course';

interface Props {
  courseUploadsData: CourseUploadsData[];
}

export function CourseDetailUploadView({ courseUploadsData }: Props): ReactElement {
  const { t } = useTranslation();

  const { showModal } = useModalContext();

  return (
    <>
      <div className="course-uploads-module__header___2T5Hy">
        <h2 className="course-uploads-module__title___XO1au course-uploads__content_title">{t('Uploads')}</h2>
      </div>
      <div className="expandable-text-module__container___2kVAW course-uploads-module__instructions___2SL4l course-upload__instructions">
        <div
          className="expandable-text__content_container expandable-text-module__content_container___1fcz4"
          aria-hidden="false"
        >
          <div className="sanitized_html">
            <p>{t('ProfileFileTypes')}</p>
          </div>
        </div>
      </div>

      <div className="course-uploads-module__container___1ecO4">
        {courseUploadsData &&
          courseUploadsData.map((courseUpload: CourseUploadsData) => (
            <div
              key={courseUpload.id}
              className="panel-module__panel___2njx7 panel__panel course-uploads-module__upload___3SMlv"
            >
              <div className="panel-module__panel_header_focus___NaHeg">
                <div className="panel-module__panel_header___fkAzr course-upload-module__panel_header___30qUI panel__panel_header">
                  <div className="course-upload-module__icon_container___13C9l course-upload__icon_container" />
                  <div className="panel-module__panel_title___2Xe9e course-upload-module__panel_title___2jlMh course-upload__title">
                    <span className="course-upload__type-label" title={courseUpload.type}>
                      {courseUpload.type}
                    </span>
                  </div>
                  <div className="panel-module__panel_actions___sx8F8 course-upload__actions">
                    <div className="action-button-module__container___2O6X7">
                      <button
                        type="button"
                        aria-disabled="false"
                        aria-label={t('Upload')}
                        className="action-button-module__btn___3zkzb btn"
                      >
                        <div
                          className="action-button-module__title___1uZt_"
                          onClick={() => showModal(<CourseUploadModal />)}
                        >
                          {t('Upload')}
                        </div>
                        <span aria-live="polite" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
