import { apiRequest, publicApiRequest } from '../utils/axios';
import { LoginData, LoginResponseData } from '../models/absorb/login';
import { SignUpUserData } from '../models/absorb/user';
import { ProfileData, ProfileFieldsData } from '../models/absorb/profile';
import { ResetPasswordData } from '../models/absorb/password';
import { TermsAndConditionsData, TermsAndConditionsPutData } from '../models/absorb/termsAndConditions';

export async function userLogin(postData: LoginData): Promise<LoginResponseData> {
  return publicApiRequest('POST', '/authentication', postData);
}

export async function checkResetPasswordTokenValidity(urlToken: string): Promise<void> {
  return publicApiRequest('GET', `/password-resets/${urlToken}`, {});
}

export async function resetPassword(urlToken: string, postData: ResetPasswordData): Promise<void> {
  return publicApiRequest('POST', `/password-resets/${urlToken}`, postData);
}

export async function createNewUser(postData: SignUpUserData): Promise<void> {
  return publicApiRequest('POST', '/user', postData);
}

export async function fetchUserProfile(): Promise<ProfileData> {
  return apiRequest('GET', '/my-profile');
}

export async function fetchProfileData(): Promise<ProfileFieldsData[]> {
  return apiRequest('GET', '/my-profile/fields').then((data) => data.userProfileFields);
}

export async function fetchTermsAndConditions(languageCode: string): Promise<TermsAndConditionsData> {
  return apiRequest('GET', `/my-lms/terms-and-conditions/acceptance?languageAbbreviation=${languageCode}`);
}

export async function acceptSiteTermsAndConditions(putData: TermsAndConditionsPutData): Promise<string> {
  return apiRequest('PUT', '/my-lms/terms-and-conditions/accept', putData);
}

export async function uploadUserAvatar(postData: FormData): Promise<string> {
  return apiRequest('POST', '/legacy-learner-uploads', postData).then((data) => data.fileId);
}

export async function updateUserAvatar(putData: { fileId: string }): Promise<string> {
  return apiRequest('PUT', '/my-profile/avatar', putData).then((data) => data.data.fileId);
}

export async function userLogout(): Promise<void> {
  return apiRequest('DELETE', '/authentication');
}

export async function forgotPassword(postData: {
  emailAddress: string;
  languageId: string;
  username: null;
}): Promise<void> {
  return publicApiRequest('POST', '/password-reset-requests', postData);
}

export async function changePassword(postData: {
  CurrentPassword: string;
  NewPassword: string;
  VerifyNewPassword: string;
}): Promise<void> {
  return apiRequest('POST', '/my-profile/password', postData);
}

export async function userProfileUpdate(postData: ProfileFieldsData[]): Promise<ProfileFieldsData[]> {
  return apiRequest('POST', '/my-profile/fields', postData).then((data) => data.userProfileFields);
}
