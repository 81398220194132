import React, { ReactElement, useState, ChangeEvent, useEffect, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfileContext } from '../contexts/profileContext';
import { BUTTON_ENABLED_CLASS, BUTTON_DISABLED_CLASS } from '../constants/styling';
import { LoadingIndicator } from './Spinner';
import { SelectInput } from './SelectInput';
import { TextInput } from './TextInput';
import { CountryData } from '../models/absorb/country';
import { ProvinceData } from '../models/absorb/province';
import { BillingDetailsStateData } from '../models/stripe/billingDetails';
import { fetchCountryList, fetchProvincesList } from '../services/region';
import { errorHandler } from '../utils/helper';
import { ShoppingCartPreviousStepButton } from './ShoppingCartPreviousStepButton';
import { PageTitle } from './PageTitle';
import './BillingDetails.css';

interface Props {
  goToPrevStep: () => void;
  goToNextStep: () => void;
  storeBillingDetails: (billingDetails: BillingDetailsStateData) => void;
}

export function BillingDetails({ goToPrevStep, goToNextStep, storeBillingDetails }: Props): ReactElement {
  const { t } = useTranslation();

  const { profile } = useProfileContext();

  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useState<CountryData[]>([]);
  const [provinceData, setProvinceData] = useState<ProvinceData[]>([]);
  const [billingDetailState, setBillingDetailState] = useState<BillingDetailsStateData>({
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    email: profile?.emailAddress || '',
    phone: '',
    addressLine1: '',
    countryCode: '',
    provinceCode: '',
    city: '',
    postalCode: '',
  });

  useEffect(() => {
    setIsLoading(true);

    fetchCountryList()
      .then(setCountryData)
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const billingDetailsObj = { ...billingDetailState };
    delete billingDetailsObj.addressLine2;
    const billingDetails = Object.entries(billingDetailsObj);
    const allInputsValid = billingDetails.every((item) => item[1]?.trim() !== '');
    setFormValid(allInputsValid);
  }, [billingDetailState]);

  useEffect(() => {
    if (billingDetailState.countryCode) {
      fetchProvincesList(billingDetailState.countryCode).then(setProvinceData).catch(errorHandler);
    }
  }, [billingDetailState.countryCode]);

  function onTextInputChange(e: ChangeEvent<HTMLInputElement>) {
    setBillingDetailState({ ...billingDetailState, [e.target.name]: e.target.value });
  }

  function onCountryChange(e: ChangeEvent<HTMLSelectElement>) {
    setBillingDetailState({ ...billingDetailState, [e.target.name]: e.target.value, provinceCode: '' });
  }

  function onProvinceChange(e: ChangeEvent<HTMLSelectElement>) {
    setBillingDetailState({ ...billingDetailState, [e.target.name]: e.target.value });
  }

  function onFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    storeBillingDetails(billingDetailState);
    goToNextStep();
  }

  const {
    firstName,
    lastName,
    email,
    phone,
    addressLine1,
    addressLine2,
    countryCode,
    provinceCode,
    city,
    postalCode,
  } = billingDetailState;

  return (
    <div className="cart-shipping-module__content_details___RfKl6">
      <PageTitle title={t('ShippingInformation')} />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <form onSubmit={onFormSubmit}>
          <ShoppingCartPreviousStepButton goBack goToPrevStep={goToPrevStep} />
          <div className="cart-shipping-module__form___3Q7s2 no-backgroundColor">
            <div className="cart-shipping-module__fields___-3vAH">
              <h3 className="cart-shipping-module__fields_header___3IcJs">{t('PersonalInformation')}</h3>

              <TextInput
                labelName={t('FirstName')}
                name="firstName"
                defaultValue={firstName}
                id="reduxFormInputField88"
                errorId="errorDiv89"
                onChange={onTextInputChange}
              />

              <TextInput
                labelName={t('LastName')}
                name="lastName"
                defaultValue={lastName}
                id="reduxFormInputField90"
                errorId="errorDiv91"
                onChange={onTextInputChange}
              />

              <TextInput
                labelName={t('Email')}
                name="email"
                defaultValue={email}
                id="reduxFormInputField92"
                errorId="errorDiv93"
                onChange={onTextInputChange}
              />

              <TextInput
                labelName={t('PhoneNumber')}
                name="phone"
                defaultValue={phone}
                id="reduxFormInputField94"
                errorId="errorDiv95"
                onChange={onTextInputChange}
              />
            </div>
            <div className="cart-shipping-module__fields___-3vAH">
              <h3 className="cart-shipping-module__fields_header___3IcJs">{t('Address')}</h3>

              <TextInput
                labelName={t('Address')}
                name="addressLine1"
                defaultValue={addressLine1}
                id="reduxFormInputField96"
                errorId="errorDiv97"
                onChange={onTextInputChange}
              />

              <TextInput
                labelName={t('EditLearnerAddress2')}
                name="addressLine2"
                defaultValue={addressLine2 || ''}
                required={false}
                id="reduxFormInputField98"
                errorId="errorDiv99"
                onChange={onTextInputChange}
              />

              <SelectInput
                labelName={t('EditLearnerCountry')}
                name="countryCode"
                onChange={onCountryChange}
                countryData={countryData}
                selectedValue={countryCode}
                id="reduxFormSelectField100"
                errorId="errorDiv101"
                type={t('SelectCountry')}
              />

              <SelectInput
                labelName={t('SignUpProvince')}
                name="provinceCode"
                onChange={onProvinceChange}
                provinceData={provinceData}
                selectedValue={provinceCode}
                id="reduxFormSelectField102"
                errorId="errorDiv103"
                type={t('SelectProvince')}
                disabled={provinceData.length === 0 && !provinceCode}
              />

              <TextInput
                labelName={t('City')}
                name="city"
                defaultValue={city}
                id="reduxFormInputField104"
                errorId="errorDiv105"
                onChange={onTextInputChange}
              />

              <TextInput
                labelName={t('EditLearnerPostalCode')}
                name="postalCode"
                defaultValue={postalCode}
                id="reduxFormInputField106"
                errorId="errorDiv107"
                onChange={onTextInputChange}
              />
            </div>
          </div>
          <div className="cart-shipping-module__actions___3yKQF">
            <button
              type="submit"
              title={t('ContinueToCheckout')}
              className={formValid ? BUTTON_ENABLED_CLASS : BUTTON_DISABLED_CLASS}
              disabled={!formValid}
            >
              {t('ContinueToCheckout')}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
