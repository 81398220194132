import React, { ReactElement, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QueryString from 'query-string';
import { MyActivityPage } from './MyActivityPage';
import { ProfilePage } from './ProfilePage';
import { DashboardLayout } from '../components/DashboardLayout';
import { MessagesPage } from './MessagesPage';
import { useProfileContext } from '../contexts/profileContext';
import { useBrandingContext } from '../contexts/brandingContext';
import { EditProfileModal } from '../modals/EditProfileModal';
import { ChangePasswordModal } from '../modals/ChangePasswordModal';
import { UploadImageModal } from '../modals/UploadImageModal';
import { LoadingIndicator } from '../components/Spinner';
import { ProfileTab } from '../constants/common';
import { useModalContext } from '../contexts/modalContext';
import './UserProfilePage.css';
import { UserProfileTab } from '../components/UserProfileTab';

export function UserProfilePage(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { profile, loading } = useProfileContext();
  const { brandingCoursesSettings } = useBrandingContext();
  const { showModal } = useModalContext();

  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [activeTab, setActiveTab] = useState<string | string[]>('');
  const [showExpandableMenu, setShowExpandableMenu] = useState(false);

  useEffect(() => {
    const tab = QueryString.parse(location.search).tab;
    setActiveTab(typeof tab === 'string' ? tab : ProfileTab.UserProfile);
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  function onSelectedTab(newHeaderText: string) {
    navigate(`/profile?tab=${newHeaderText}`);
    setActiveTab(newHeaderText);
    setShowExpandableMenu(false);
  }

  return (
    <DashboardLayout>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div role="main" className="app-module__content___-i9XR app__content">
          <div className="profile-module__container___14zlI">
            <div
              className="banner-module__banner___IJO0Z banner__banner"
              style={{
                backgroundImage: `url("${encodeURI(brandingCoursesSettings?.myCoursesAndCatalog?.bannerUri || '')}")`,
              }}
            >
              <h1 className="banner-title-module__title___1mbDk banner__title">{t(activeTab)}</h1>
            </div>
            <div className="profile-module__profile___2kFsW">
              <nav>
                <div className="browserView">
                  <div className="tab-list-module__tabs___39I9A">
                    <UserProfileTab
                      activeTab={activeTab}
                      tabName={ProfileTab.UserProfile}
                      onSelectedTab={onSelectedTab}
                    />
                    <UserProfileTab activeTab={activeTab} tabName={ProfileTab.Messages} onSelectedTab={onSelectedTab} />
                    <UserProfileTab
                      activeTab={activeTab}
                      tabName={ProfileTab.MyActivity}
                      onSelectedTab={onSelectedTab}
                    />
                  </div>
                </div>
                <div className="mobileView">
                  <div className="expandable-content-module__container___3wK2a tab-list-module__dropdown_container___1MnjF">
                    <button
                      aria-labelledby="expandableContentAccessibilityTextId93"
                      type="button"
                      className="expandable-content-module__toggle_btn___3Dh7i expandable-content__toggle_btn tab-list-module__dropdown_title___2bigI tab-list__tab_active"
                      onClick={() => setShowExpandableMenu(!showExpandableMenu)}
                    >
                      <div className="expandable-content-module__toggle_title___3jtbk expandable-content__title">
                        {activeTab === ProfileTab.UserProfile && t('UserProfile')}
                        {activeTab === ProfileTab.Messages && t('Messages')}
                        {activeTab === ProfileTab.MyActivity && t('MyActivity')}
                      </div>
                      <div className="icon icon-arrow-up expandable-content-module__toggle_icon___2k6ay" />
                    </button>
                    {showExpandableMenu && (
                      <div className="expandable-content-module__content___3sNCH expandable-content__content tab-list-module__dropdown_content___4ODy5">
                        <div
                          className="tab-list-module__dropdown_option___1tlVL tab-list__tab"
                          onClick={() => onSelectedTab(ProfileTab.UserProfile)}
                        >
                          {t('UserProfile')}
                        </div>
                        <div
                          className="tab-list-module__dropdown_option___1tlVL tab-list__tab"
                          onClick={() => onSelectedTab(ProfileTab.Messages)}
                        >
                          {t('Messages')}
                        </div>
                        <div
                          className="tab-list-module__dropdown_option___1tlVL tab-list__tab"
                          onClick={() => onSelectedTab(ProfileTab.MyActivity)}
                        >
                          {t('MyActivity')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </nav>
              <div className="profile-module__tabs_content___uqA2p">
                <div className="profile-module__my_profile___2TTg6">
                  <div className="my-profile-module__my_profile___3G9pf">
                    <div className="my-profile-module__avatar_container___3-bP4">
                      {profile?.avatar ? (
                        <img
                          className="my-profile-module__avatar___1KG5b my-profile-module__avatar_placeholder___pKWYk my-profile__avatar my-profile__avatar_placeholder"
                          src={profile ? profile.avatar : ''}
                          alt="profile"
                        />
                      ) : (
                        <div className="icon icon-man my-profile-module__avatar___1KG5b my-profile-module__avatar_placeholder___pKWYk my-profile__avatar my-profile__avatar_placeholder" />
                      )}
                      {/* TODO: Add this back in when avatar uploading is working
                      <button
                        type="button"
                        className="icon-button-module__btn___Zb89b my-profile-module__camera_placeholder___3akeP my-profile__camera_placeholder"
                        title={t('EditProfilePhoto')}
                        onClick={() => setShowUploadImageModal(true)}
                      >
                        <div className="icon icon-camera"/>
                        <span className="accessibility-text-module__off_screen_text___FSqhy">
                          {t('EditProfilePhoto')}
                        </span>
                      </button>
                      */}
                    </div>
                    {profile && (
                      <>
                        <h2 className="my-profile-module__profile_field___1TYb-">
                          <span
                            className="my-profile-module__profile_field_value_name___1arUM my-profile__profile_field_value_name"
                            title={`${profile.firstName} ${profile.lastName}`}
                          >
                            {`${profile.firstName} ${profile.lastName}`}
                          </span>
                        </h2>
                        <div className="my-profile-module__field___al4RA">
                          <div className="my-profile-module__label___3m7Ym my-profile__profile_field_value_name">
                            {t('Email')}
                          </div>
                          <div
                            className="my-profile-module__value___3hQW9 my-profile__profile_field_value my-profile__profile_field_email"
                            title={profile.emailAddress}
                          >
                            {profile.emailAddress}
                          </div>
                        </div>
                        <div className="my-profile-module__field___al4RA">
                          <div className="my-profile-module__label___3m7Ym my-profile__profile_field_value_name">
                            {t('Department')}
                          </div>
                          <div
                            className="my-profile-module__value___3hQW9 my-profile__profile_field_value my-profile__profile_field_department"
                            title={profile.departmentName}
                          >
                            {profile.departmentName}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="my-profile-module__btn_wrapper___36tDJ my-profile__edit_profile">
                    <button
                      type="button"
                      title={t('EditProfile')}
                      className="btn my-profile-module__btn___2uQQ4 button-module__btn___1lXcC"
                      onClick={() => showModal(<EditProfileModal />)}
                    >
                      {t('EditProfile')}
                    </button>
                  </div>
                  <div className="my-profile-module__btn_wrapper___36tDJ my-profile__edit_password">
                    <button
                      type="button"
                      title={t('UpdatePassword')}
                      className="btn my-profile-module__btn___2uQQ4 my-profile__password_btn button-module__btn___1lXcC"
                      onClick={() => showModal(<ChangePasswordModal />)}
                    >
                      {t('UpdatePassword')}
                    </button>
                  </div>
                </div>
                <div className="profile-module__content___3vuhT profile__content">
                  {activeTab === ProfileTab.UserProfile && <ProfilePage />}
                  {activeTab === ProfileTab.Messages && <MessagesPage />}
                  {activeTab === ProfileTab.MyActivity && <MyActivityPage />}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUploadImageModal && <UploadImageModal closeModal={() => setShowUploadImageModal(false)} />}
    </DashboardLayout>
  );
}
