import { API_RESULT_LIMIT } from '../constants/common';
import { InstructorData, InstructorLedDetailsData, InstructorLedSessionsData } from '../models/absorb/instructorLed';
import { apiRequest } from '../utils/axios';

export async function fetchInstructorLedDetails(courseId: string): Promise<InstructorLedDetailsData> {
  return apiRequest('GET', `/instructor-led-courses/${courseId}`);
}

export async function fetchInstructorLedSessions(
  courseId: string,
  limit = API_RESULT_LIMIT,
  offset = 0
): Promise<InstructorLedSessionsData> {
  return apiRequest('GET', `/instructor-led-courses/${courseId}/sessions?_limit=${limit}&_offset=${offset}`);
}

export async function fetchCalendarLink(courseId: string, sessionId: string): Promise<string> {
  return apiRequest('GET', `/my-course-enrollments/${courseId}/session-enrollments/${sessionId}/ics`);
}

export async function fetchInstructor(instructors: string[]): Promise<InstructorData[]> {
  return apiRequest('POST', '/course-filters/instructors/', { instructorIds: instructors });
}
