import { apiRequest } from '../utils/axios';
import { EndRecordPlusRequest, EndRecordPlusResult } from '../models/proctorU/endRecordPlus';
import { StartRecordPlusRequest, StartRecordPlusResult } from '../models/proctorU/startRecordPlus';

export async function startRecordPlus(data: StartRecordPlusRequest): Promise<StartRecordPlusResult> {
  return apiRequest('POST', '/proctorU/startRecordPlus', data);
}

export async function endRecordPlus(data: EndRecordPlusRequest): Promise<EndRecordPlusResult> {
  return apiRequest('POST', '/proctorU/endRecordPlus', data);
}
