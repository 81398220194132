import { apiRequest } from '../utils/axios';
import { ClientSecret, PaymentCompleteData, PaymentIntentData } from '../models/stripe/clientSecret';

export async function createPaymentIntent(data: PaymentIntentData): Promise<ClientSecret> {
  return apiRequest('POST', '/payment/intent', data);
}

export async function completePaymentAndEnrollCourses(data: PaymentCompleteData): Promise<void> {
  return apiRequest('POST', '/payment/complete', data);
}
