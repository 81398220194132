import React, { ReactElement, useState, ChangeEvent, useEffect, KeyboardEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { forgotPassword } from '../services/profile';
import { REGEX_EMAIL_VALIDATION } from '../constants/regex';
import { DashboardLayout } from '../components/DashboardLayout';
import { KEY_ENTER } from '../constants/common';
import { Modal } from '../modals/Modal';
import { useBrandingContext } from '../contexts/brandingContext';
import { errorHandler } from '../utils/helper';
import { useLanguageContext } from '../contexts/languageContext';
import { SpinnerButton } from '../components/SpinnerButton';
import { PageTitle } from '../components/PageTitle';

export function ForgotPasswordPage(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { language } = useLanguageContext();
  const { loginPageSettings } = useBrandingContext();

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);

  useEffect(() => {
    setValidEmail(REGEX_EMAIL_VALIDATION.test(email));
  }, [email]);

  function onSubmit() {
    const data = {
      emailAddress: email,
      languageId: language,
      username: null,
    };
    setResetPasswordInProgress(true);
    forgotPassword(data)
      .then(() => {
        setShowSuccessMessage(true);
      })
      .catch(errorHandler)
      .finally(() => {
        setResetPasswordInProgress(false);
      });
  }

  function onCloseModal() {
    navigate('/');
  }

  function onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  function onPressEnter(event: KeyboardEvent<HTMLDivElement>) {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === KEY_ENTER) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.key === KEY_ENTER && validEmail) {
      onSubmit();
    }
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('LostYourPassword')} />
      <div
        className="forgot-password-module__forgot_password___tT9hw forgot-password__forgot_password"
        style={{
          backgroundImage: `url("${encodeURI(loginPageSettings?.backgroundImageUrl || '')}")`,
          backgroundPosition: 'bottom left',
          backgroundSize: 'cover',
        }}
      >
        <div className="forgot-password-module__form_container___po2k- forgot-password__form_container">
          {showSuccessMessage ? (
            <div className="modal-module__container___sfedc modal__container">
              <div className="modal-module__blocker___231AR modal__blocker" />
              <div className="modal-module__modal_container___3tUB2 modal__modal_container">
                <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
                  <Modal closeModal={onCloseModal}>
                    <div className="forgot-password-module__form___1yX06 forgot-password__form">
                      <div aria-live="assertive">
                        <h1 className="forgot-password-module__title___7V-kf forgot-password__title">
                          {t('CheckYourInbox')}
                        </h1>
                        <div aria-live="assertive">
                          <div className="form-info-panel-module__success_message___32u7j">
                            {t('PasswordResetLinkHasBeenSentToYouBlurb')}
                          </div>
                        </div>
                      </div>
                      <div className="forgot-password-module__login_wrapper___1a7af">
                        <Link className="forgot-password-module__login_link___13sR2 forgot-password__login_link" to="/">
                          {t('BackToLogin')}
                        </Link>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          ) : (
            <div className="forgot-password-module__form___1yX06 forgot-password__form">
              <h1 className="forgot-password-module__title___7V-kf forgot-password__title">{t('LostYourPassword')}</h1>
              <div className="forgot-password-module__subtitle___1JI6a forgot-password__subtitle">
                {t('ForgotPasswordInstructions')}
              </div>
              <form>
                <div className="redux-form-input-module__field___LP6sO forgot-password-module__input___WzJru">
                  <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                    <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                      {t('EmailAddress')}
                    </label>
                  </div>
                  <input
                    name="emailAddress"
                    id="reduxFormInputField7"
                    type="text"
                    step="any"
                    aria-describedby="errorDiv8"
                    value={email}
                    onChange={onChangeEmail}
                    onKeyDown={onPressEnter}
                  />
                </div>
                <button
                  type="button"
                  className="btn forgot-password-module__btn___JzHZt forgot-password__btn button-module__btn___1lXcC"
                  disabled={!validEmail}
                  onClick={onSubmit}
                >
                  {resetPasswordInProgress ? <SpinnerButton /> : t('ResetPassword')}
                </button>
              </form>
              <div aria-live="assertive" />
              <div className="forgot-password-module__login_wrapper___1a7af">
                <Link className="forgot-password-module__login_link___13sR2 forgot-password__login_link" to="/">
                  {t('BackToLogin')}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
