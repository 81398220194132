import React, { ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TagData } from '../models/absorb/tags';
import { DashboardLayout } from '../components/DashboardLayout';
import {
  CourseChaptersData,
  CourseUploadsData,
  CourseResourcesData,
  CourseEnrollmentData,
  OnlineCourseData,
  CurriculumProgressDetailsData,
} from '../models/absorb/course';
import { CourseDetailCourseView } from '../components/CourseDetailCourseView';
import { CourseDetailResourceView } from '../components/CourseDetailResourceView';
import { CourseDetailUploadView } from '../components/CourseDetailUploadView';
import { useBrandingContext } from '../contexts/brandingContext';
import { useContentViewContext } from '../contexts/contentViewContext';
import { fetchCourseContent } from '../services/courseDetails';
import { fetchMyCourseEnrollment, fetchCurriculumProgress } from '../services/course';
import {
  fetchCourseChapters,
  fetchCourseResources,
  fetchOnlineCourse,
  fetchOnlineCourseTags,
  fetchOnlineCourseUploads,
} from '../services/onlineCourses';
import { fetchLesson } from '../services/lesson';
import { LessonsData, MyLessonData } from '../models/absorb/lessons';
import { DisplayContentView, TabView } from '../constants/courses';
import { errorHandler } from '../utils/helper';
import { CoursePlayerPage } from './CoursePlayerPage';
import { ShareCourseButton } from '../components/ShareCourseButton';
import { PinCourseButton } from '../components/PinCourseButton';
import 'react-circular-progressbar/dist/styles.css';
import { RadialProgressIndicatorLarge } from '../components/RadialProgressIndicatorLarge';
import { CourseCurriculumStatus } from '../components/CourseCurriculumStatus';
import { CourseCardActionButton } from '../components/CourseCardActionButton';
import { CourseRating } from '../components/CourseRating';
import { CatalogCourseData } from '../models/absorb/catalog';
import { fetchMyCatalogCourse } from '../services/catalog';
import { SECOND_URL_INDEX, ViewOptionPage } from '../constants/common';
import { ConditionalWrapper } from '../components/ConditionalWrapper';
import { useCourseAccessErrorRedirect } from '../hooks/useCourseAccessErrorRedirect';
import { ResourceLinks } from '../components/ResourceLinks';
import { TagList } from '../components/TagList';
import { PageTitle } from '../components/PageTitle';

interface Props {
  courseDataForModalPresentation?: CatalogCourseData;
  updateTilePinnedStatus?: (isPinned: boolean, courseId: string) => void;
  isModal?: boolean;
}

export function CourseDetailsPage({
  courseDataForModalPresentation,
  updateTilePinnedStatus,
  isModal = false,
}: Props): ReactElement {
  const { t } = useTranslation();
  const location = useLocation();

  const path = location.pathname;
  const selectedCoursePath = path.split('/');
  // TODO: fix potential buffer overrun, URL_INDEX naming, passing in string that was manipulated outside the component
  const courseId = selectedCoursePath[SECOND_URL_INDEX];
  const courseIdToUse = courseDataForModalPresentation?.id || courseId;

  const { brandingCoursesSettings } = useBrandingContext();
  const {
    contentView,
    modalContentView,
    setModalContentView,
    setContentView,
    setContentViewOnPage,
    getContentViewOnPage,
  } = useContentViewContext();

  const [course, setCourse] = useState<OnlineCourseData>();
  const [catalogCourse, setCatalogCourse] = useState<CatalogCourseData>();
  const [courseUploadsData, setCourseUploadsData] = useState<CourseUploadsData[]>([]);
  const [courseResourcesData, setCourseResourcesData] = useState<CourseResourcesData[]>([]);
  const [courseChapters, setCourseChapters] = useState<CourseChaptersData[]>([]);
  const [courseTags, setCourseTags] = useState<TagData[]>([]);
  const [myCourseEnrollment, setMyCourseEnrollment] = useState<CourseEnrollmentData>();
  const [curriculumProgressDetails, setCurriculumProgressDetails] = useState<CurriculumProgressDetailsData>();
  const [showViewOptions, setShowViewOptions] = useState(false);
  const [lessonData, setLessonData] = useState<MyLessonData>();
  const [nextLessonData, setNextLessonData] = useState<MyLessonData>();
  const [showVideo, setShowVideo] = useState(false);
  const [tabView, setTabView] = useState(TabView.Course);
  const [showCourseContent, setShowCourseContent] = useState(true);
  const [showResourceContent, setShowResourceContent] = useState(false);
  const [showUploadsContent, setShowUploadsContent] = useState(false);
  const [courseUrl, setCourseUrl] = useState('');
  const [isLessonDataLoading, setIsLessonDataLoading] = useState(false);
  const [setCourseAccessError] = useCourseAccessErrorRedirect();

  useEffect(() => {
    if (!isModal) {
      setContentView(getContentViewOnPage(ViewOptionPage.CourseDetails) as DisplayContentView);
    }
  });

  useEffect(() => {
    fetchOnlineCourse(courseIdToUse)
      .then((courseData) => {
        setCourse(courseData);

        Promise.all([
          fetchMyCatalogCourse(courseIdToUse).then(setCatalogCourse),
          fetchMyCourseEnrollment(courseIdToUse).then(setMyCourseEnrollment),
          fetchCourseChapters(courseIdToUse).then(setCourseChapters),
          fetchOnlineCourseUploads(courseIdToUse).then(setCourseUploadsData),
          fetchCourseResources(courseIdToUse).then(setCourseResourcesData),
          fetchOnlineCourseTags(courseIdToUse).then(setCourseTags),
        ]).catch(errorHandler);
      })
      .catch((err) => {
        errorHandler(err);
        setCourseAccessError(true);
      });
    if (courseDataForModalPresentation) {
      setCatalogCourse(courseDataForModalPresentation);
    }
  }, [courseIdToUse, courseDataForModalPresentation, isModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (myCourseEnrollment?.isPartOfCurriculumEnrollment) {
      fetchCurriculumProgress(courseIdToUse).then(setCurriculumProgressDetails);
    }
  }, [myCourseEnrollment, courseIdToUse]);

  useEffect(() => {
    // whenever the course player is closed, need to re-fetch course enrollment, so percentage complete updates in radial progress indicator.
    if (!showVideo) {
      fetchMyCourseEnrollment(courseIdToUse).then(setMyCourseEnrollment);
    }
  }, [courseIdToUse, showVideo]);

  let completedLessons = 0;

  const totalLessons: number =
    courseChapters && courseChapters.length
      ? courseChapters.reduce((a: number, b: { returnedItems: number }) => +a + +b.returnedItems, 0)
      : 0;

  if (courseChapters && courseChapters.length) {
    courseChapters.forEach((chapter: CourseChaptersData) => {
      if (chapter.enrollment?.progress !== null && course !== undefined) {
        completedLessons += chapter.lessons.filter((lesson: LessonsData) => lesson.enrollment?.status === 'Complete')
          .length;
      }
    });
  }

  function updatePinnedStatus() {
    if (course) {
      setCourse({ ...course, isPinned: !course.isPinned });
    }
  }

  function onChangeContentView(viewChange: DisplayContentView) {
    if (isModal) {
      setModalContentView(viewChange);
    } else {
      setContentView(viewChange);
    }

    setContentViewOnPage(ViewOptionPage.CourseDetails, viewChange);
    setShowViewOptions(false);
  }

  function setViewContent(viewChange: TabView) {
    setShowCourseContent(!showCourseContent);
    setShowResourceContent(false);
    setShowUploadsContent(false);
    setTabView(viewChange);
  }

  function setViewContentResource(viewChange: TabView) {
    setShowResourceContent(!showResourceContent);
    setShowCourseContent(false);
    setShowUploadsContent(false);
    setTabView(viewChange);
  }

  function setViewUploads(viewChange: TabView) {
    setShowUploadsContent(!showUploadsContent);
    setShowCourseContent(false);
    setShowResourceContent(false);
    setTabView(viewChange);
  }

  function openVideoPlayer(lessonId: string) {
    setLessonData(undefined);
    setIsLessonDataLoading(true);
    setShowVideo(true);

    const courseWithThisLessonId = courseChapters.find((chapter) =>
      chapter.lessons.find((lesson) => lesson.id === lessonId)
    );
    const lessonIndex = courseWithThisLessonId?.lessons.findIndex((lesson) => lesson.id === lessonId) ?? -1;
    const nextLessonIndex = lessonIndex + 1;

    if (courseWithThisLessonId && lessonIndex !== -1 && nextLessonIndex < courseWithThisLessonId.lessons.length) {
      fetchLesson(courseWithThisLessonId.lessons[nextLessonIndex].id).then(setNextLessonData).catch(errorHandler);
    } else {
      setNextLessonData(undefined);
    }

    fetchLesson(lessonId)
      .then(setLessonData)
      .catch(errorHandler)
      .finally(() => setIsLessonDataLoading(false));

    const extraParams = '&displayTitle=false';
    fetchCourseContent(courseId, lessonId)
      .then((data) => {
        setCourseUrl(data.concat(extraParams));
      })
      .catch(errorHandler);
  }

  return (
    <>
      <PageTitle title={t('OnlineCourse')} />
      {showVideo ? (
        <CoursePlayerPage
          courseUrl={courseUrl}
          isLessonDataLoading={isLessonDataLoading}
          lessonData={lessonData}
          nextLessonData={nextLessonData}
          openCoursePlayer={openVideoPlayer}
          closeCoursePlayer={() => {
            setShowVideo(false);
            fetchCourseChapters(courseId).then(setCourseChapters).catch(errorHandler);
          }}
        />
      ) : (
        <ConditionalWrapper condition={!isModal} wrapper={(children) => <DashboardLayout>{children}</DashboardLayout>}>
          <div className="online-course-module__container___YaVbO">
            <div aria-live="assertive" role="status" />
            <div
              className="course-detail-header-module__container___1uS5v course-detail-header__container"
              style={{
                backgroundImage: `url("${encodeURI(brandingCoursesSettings?.coursesDetails?.bannerUri || '')}")`,
              }}
            >
              <div className="course-detail-header-module__section_left___tdoyN">
                {
                  course ? (
                    <div className="course-evaluation-module__question_title___CbTGT">
                      <h1
                        className="course-detail-header-module__name___3GuSs course-detail-header__name"
                        title={course.name}
                      >
                        {course.name}
                      </h1>
                      <div className="course-detail-header-module__type___3rQql">{t(course.courseType)}</div>
                      {course.isCourseRatingEnabled && (
                        <CourseRating
                          selectedStars={course.myRating}
                          courseId={course.id}
                          subText="RateThisCourse"
                          disabled={catalogCourse && (catalogCourse.price !== null || !catalogCourse.enrolled)}
                        />
                      )}
                    </div>
                  ) : null /* TODO: course could be null because it's invalid. Need to handle "message": "Invalid course type/ID.", "term": "ErrorGeneric" from api call! */
                }
              </div>
              <div>
                {catalogCourse && (catalogCourse.price !== null || catalogCourse.enrollmentStatus === null) && (
                  <div className="action-button-module__container___2O6X7 course-detail-header-module__action_btn___3v1Zn">
                    <CourseCardActionButton cardData={catalogCourse} isIconRequired isModal />
                  </div>
                )}
                <div className="course-options-module__course_options___1vuRm" role="menu">
                  {course && (
                    <ShareCourseButton
                      courseId={course.id}
                      courseType={course.courseType}
                      courseName={course.name || ''}
                    />
                  )}
                  {course && (
                    <PinCourseButton
                      catalogCourseData={catalogCourse}
                      courseData={course}
                      updateTilePinnedStatus={updateTilePinnedStatus}
                      updatePinnedStatus={updatePinnedStatus}
                    />
                  )}
                </div>
              </div>
            </div>
            {curriculumProgressDetails && (
              <CourseCurriculumStatus curriculumProgressDetails={curriculumProgressDetails} />
            )}
            <nav>
              <div className="tab-list-module__tabs___39I9A">
                <div
                  className={`tab-list-module__tab___1ZDk5 tab-list__tab ${
                    showCourseContent ? 'tab-list-module__tab_active___1lSYg tab-list__tab_active' : ''
                  }`}
                >
                  <button
                    type="button"
                    className="tab-list-module__tab_btn___31NOt tab-list__tab_btn"
                    aria-current="page"
                    onClick={() => (tabView === TabView.Course ? null : setViewContent(TabView.Course))}
                  >
                    <span aria-hidden="true">{t('CourseContent')}</span>
                  </button>
                </div>
                {courseResourcesData && courseResourcesData.length !== 0 && !isModal && (
                  <div
                    className={`tab-list-module__tab___1ZDk5 tab-list__tab ${
                      showResourceContent ? 'tab-list-module__tab_active___1lSYg tab-list__tab_active' : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="tab-list-module__tab_btn___31NOt tab-list__tab_btn"
                      aria-current="page"
                      onClick={() => (tabView === TabView.Resource ? null : setViewContentResource(TabView.Resource))}
                    >
                      <span aria-hidden="true">{t('Resources')}</span>
                    </button>
                  </div>
                )}
                {courseUploadsData && courseUploadsData.length !== 0 && !isModal && (
                  <div
                    className={`tab-list-module__tab___1ZDk5 tab-list__tab ${
                      showUploadsContent ? 'tab-list-module__tab_active___1lSYg tab-list__tab_active' : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="tab-list-module__tab_btn___31NOt tab-list__tab_btn"
                      aria-current="page"
                      onClick={() => setViewUploads(TabView.Uploads)}
                    >
                      <span aria-hidden="true">{t('Uploads')}</span>
                    </button>
                  </div>
                )}
              </div>
            </nav>

            <div className="online-course-module__content___C7nkp" aria-hidden="false">
              <div className="online-course-module__content_left___3cqlT">
                {myCourseEnrollment?.enrollmentStatus && (
                  <RadialProgressIndicatorLarge
                    totalCompleted={completedLessons || 0}
                    totalRequired={totalLessons}
                    enrollmentStatus={myCourseEnrollment?.enrollmentStatus || ''}
                    progress={myCourseEnrollment?.progress}
                    showProgressInTopView
                  />
                )}

                {tabView === TabView.Course && (
                  <CourseDetailCourseView
                    showViewOptions={showViewOptions}
                    contentView={contentView}
                    modalContentView={modalContentView}
                    onChangeContentView={onChangeContentView}
                    courseDetails={courseChapters}
                    courseDescription={course && course.description ? course.description : ''}
                    posterImageUrl={course?.posterUris[0]}
                    openPlayer={openVideoPlayer}
                    onChangeContentViewClick={() => setShowViewOptions(!showViewOptions)}
                    courseRequiresPurchase={
                      isModal || (catalogCourse?.price !== null && catalogCourse?.price !== undefined && true)
                    }
                  />
                )}

                {tabView === TabView.Resource && (
                  <CourseDetailResourceView courseResourcesData={courseResourcesData} courseId={courseId} />
                )}

                {tabView === TabView.Uploads && <CourseDetailUploadView courseUploadsData={courseUploadsData} />}
              </div>
              <div className="online-course-module__content_right___YJr4_">
                {myCourseEnrollment?.enrollmentStatus && (
                  <RadialProgressIndicatorLarge
                    totalCompleted={completedLessons || 0}
                    totalRequired={totalLessons}
                    enrollmentStatus={myCourseEnrollment?.enrollmentStatus || ''}
                    progress={myCourseEnrollment?.progress}
                  />
                )}
                {courseResourcesData.length > 0 && <ResourceLinks resources={courseResourcesData} />}
                {courseTags.length > 0 && (
                  <div className="tag-list-module__tags___O_Obx course-sidebar-module__tags___1n_Oi">
                    <h2 className="tag-list-module__title___3nUy0 tag-list__title body-font">{t('Tags')}</h2>
                    <TagList tags={courseTags} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ConditionalWrapper>
      )}
    </>
  );
}
