import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranscriptAssessment } from '../models/wellsharp/assessments';
import { fetchWellsharpData } from '../services/wellsharp';
import { errorHandler, getTimeShortDate } from '../utils/helper';
import './TranscriptAssessments.css';

interface Props {
  email: string;
}

export function TranscriptAssessments({ email }: Props): ReactElement {
  const { t } = useTranslation();

  const [wellsharpData, setWellsharpData] = useState<TranscriptAssessment[]>([]);

  useEffect(() => {
    fetchWellsharpData(email).then(setWellsharpData).catch(errorHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return wellsharpData.length > 0 ? (
    <div className="transcript-module__assessments_section">
      <div className="transcript-module__header_container___Exguq">
        <h2 className="transcript-module__header___3DDfH anchor transcript__header">Assessments</h2>
      </div>
      <div
        className="sortable-table-module__table___2epWu sortable-table__table print-transcript-module__table___3hfgL"
        role="table"
      >
        <div role="rowgroup">
          <div className="sortable-table-module__table_row_header___hu7Ms sortable-table__table_row_header" role="row">
            <div
              className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_name_col___bh2jb"
              role="columnheader"
            >
              <div className="sortable-table-column-header-module__table_row_header_item_button___2jk00">
                {t('Course')}
              </div>
            </div>
            <div
              className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_enrollement_date_col___3J2F1"
              role="columnheader"
            >
              <div className="sortable-table-column-header-module__table_row_header_item_button___2jk00">
                Assessment Date
              </div>
            </div>
            <div
              className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_status_col___27sBA"
              role="columnheader"
            >
              <div className="sortable-table-column-header-module__table_row_header_item_button___2jk00">Stacks</div>
            </div>
            <div
              className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_score_col___KMO2B"
              role="columnheader"
            >
              <div className="sortable-table-column-header-module__table_row_header_item_button___2jk00">
                Knowledge Assessment Score
              </div>
            </div>
            <div
              className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_completion_date_col___3yZ8U"
              role="columnheader"
            >
              <div className="sortable-table-column-header-module__table_row_header_item_button___2jk00">
                Supplement
              </div>
            </div>
            <div
              className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_score_col___KMO2B"
              role="columnheader"
            >
              <div className="sortable-table-column-header-module__table_row_header_item_button___2jk00">
                Supplement Score
              </div>
            </div>
            <div
              className="sortable-table-module__clickable___1ee_0 sortable-table__clickable sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item transcript-module__enrollments_completion_date_col___3yZ8U"
              role="columnheader"
            >
              <div className="sortable-table-column-header-module__table_row_header_item_button___2jk00">
                Certificate ID
              </div>
            </div>
          </div>
        </div>

        <div role="rowgroup">
          {wellsharpData.map((assessment) => (
            <div
              key={assessment.data.assessmentDateTimestamp}
              className="sortable-table-module__table_row_container___8gbRc"
            >
              <div
                className="sortable-table-module__table_row___3N1yN sortable-table__table_row transcript__row"
                role="row"
              >
                <div
                  className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_name_col___bh2jb"
                  role="cell"
                >
                  <div className="transcript-module__cell_header___QsLhO1">
                    <span className="transcript-module__cell_header___QsLhO">{t('Course')}:</span>
                    {assessment.data.course}
                  </div>
                </div>
                <div
                  className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_enrollement_date_col___3J2F1"
                  role="cell"
                >
                  <div>
                    <span className="transcript-module__cell_header___QsLhO">Assessment Date:</span>
                    <span className="transcript-module__datetime___2dc83">
                      {getTimeShortDate(new Date(assessment.data.assessmentDateTimestamp).toISOString())}
                    </span>
                  </div>
                </div>
                <div
                  className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_status_col___27sBA"
                  role="cell"
                >
                  <div>
                    <span className="transcript-module__cell_header___QsLhO">Stacks:</span>
                    {assessment.data.stacks}
                  </div>
                </div>
                <div
                  className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_score_col___KMO2B"
                  role="cell"
                >
                  <div>
                    <span className="transcript-module__cell_header___QsLhO">Knowledge Assessment Score:</span>
                    {assessment.data.knowledgeAssessmentScore}
                  </div>
                </div>
                <div
                  className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_completion_date_col___3yZ8U"
                  role="cell"
                >
                  <div>
                    <span className="transcript-module__cell_header___QsLhO">Supplement:</span>
                    {assessment.data.supplement}
                  </div>
                </div>
                <div
                  className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_score_col___KMO2B"
                  role="cell"
                >
                  <div>
                    <span className="transcript-module__cell_header___QsLhO">Supplement Score:</span>
                    {assessment.data.supplementScore}
                  </div>
                </div>
                <div
                  className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item transcript-module__enrollments_completion_date_col___3yZ8U"
                  role="cell"
                >
                  <div>
                    <span className="transcript-module__cell_header___QsLhO">Certificate ID:</span>
                    {assessment.data.certificateId}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
