import React, { ReactElement, useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as scope from '../constants/absorb';
import * as messages from '../constants/messages';
import { KEY_ENTER, MINIMUM_USERNAME_CHARS, MINIMUM_PASSWORD_CHARS } from '../constants/common';
import { UserType } from '../constants/absorb';
import { DashboardLayout } from '../components/DashboardLayout';
import { LoginData } from '../models/absorb/login';
import { TimeoutModal } from '../modals/TimeoutModal';
import { REGEX_EMAIL_VALIDATION, REGEX_PASSWORD_VALIDATION } from '../constants/regex';
import { SpinnerButton } from '../components/SpinnerButton';
import { useLoginContext } from '../contexts/loginContext';
import { useBrandingContext } from '../contexts/brandingContext';
import { useModalContext } from '../contexts/modalContext';
import { PageTitle } from '../components/PageTitle';

export function LoginPage(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { error, tokenTimedOut, signInInProgress, loggedIn, signIn } = useLoginContext();
  const { loginPageSettings } = useBrandingContext();
  const { showModal } = useModalContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginButtonEnabled, setIsLoginButtonEnabled] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const firstRender = useRef(true);

  useEffect(() => {
    if (loggedIn) {
      navigate('/dashboard');
    }
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tokenTimedOut) {
      showModal(<TimeoutModal />);
    }
  }, [tokenTimedOut]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (emailErrorMessage.length + passwordErrorMessage.length === 0 || email.length + password.length === 0) {
      setIsLoginButtonEnabled(true);
    } else {
      setIsLoginButtonEnabled(false);
    }
  }, [emailErrorMessage, passwordErrorMessage, email, password]);

  let validateInput = true;

  if (firstRender.current) {
    firstRender.current = false;
    validateInput = false;
  }

  function onPressEnter(event: KeyboardEvent<HTMLDivElement>) {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === KEY_ENTER) {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  }

  function onSubmit() {
    if (!validateInput || shouldSubmitButtonBeEnabled(email, password)) {
      const data: LoginData = {
        username: email,
        password,
        accessMode: scope.ACCESS_MODE,
        persist: false,
        scope: [UserType.Learner, UserType.Admin, UserType.Instructor],
      };

      signIn(data);
    }
  }

  function shouldSubmitButtonBeEnabled(emailField: string, passwordField: string) {
    if (
      (emailErrorMessage.length + passwordErrorMessage.length === 0 &&
        emailField.length > 0 &&
        passwordField.length > 0) ||
      emailField.length + passwordField.length === 0
    ) {
      setIsLoginButtonEnabled(true);
      return true;
    }
    setIsLoginButtonEnabled(false);
    return false;
  }

  function onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);

    if (!REGEX_EMAIL_VALIDATION.test(e.target.value)) {
      if (e.target.value.length < MINIMUM_USERNAME_CHARS) {
        setEmailErrorMessage(messages.USERNAME_TOO_SHORT);
      } else {
        setEmailErrorMessage(messages.INVALID_EMAIL);
      }
    } else {
      setEmailErrorMessage('');
    }

    shouldSubmitButtonBeEnabled(e.target.value, password);
  }

  function onChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);

    if (!REGEX_PASSWORD_VALIDATION.test(e.target.value)) {
      if (e.target.value.length < MINIMUM_PASSWORD_CHARS) {
        setPasswordErrorMessage(messages.INVALID_PASSWORD);
      } else {
        setPasswordErrorMessage(messages.PASSWORD_TIP);
      }
    } else {
      setPasswordErrorMessage('');
    }

    shouldSubmitButtonBeEnabled(email, e.target.value);
  }

  return (
    <DashboardLayout>
      <PageTitle />
      <div
        className="login__container login-module__container___3_Ook"
        style={{
          backgroundImage: `url("${encodeURI(loginPageSettings?.backgroundImageUrl || '')}")`,
          backgroundPosition: 'bottom left',
          backgroundSize: 'cover',
        }}
      >
        <div className="login-module__form_container___1pMQa login__form_container">
          <div className="login-form__form">
            <h1 className="login-form-module__title___qGz4t login-form__title" id="login-h3">
              {t('Login')}
            </h1>
            <div className="login-form-module__subtitle___alcNN login-form__subtitle">{t('WelcomeInstructions')}</div>
            <div aria-live="assertive" />
            <form>
              <div className="redux-form-input-module__field___LP6sO login-form-module__input___1BR88">
                {error && <p className="form-info-panel-module__error_messages___3YBAs">{t('InvalidLoginBlurb')}</p>}
                <div>
                  <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                    <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                      {t('Email')}
                    </label>
                  </div>
                  <input
                    name="email"
                    id="reduxFormInputField1"
                    type="text"
                    step="any"
                    aria-describedby="errorDiv2"
                    value={email}
                    onChange={onChangeEmail}
                    autoComplete="username"
                  />
                  <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv2">
                    {email && (
                      <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                        {t(emailErrorMessage).replace('{0}', '1')}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="redux-form-input-module__field___LP6sO login-form-module__input___1BR88">
                <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                  <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                    {t('Password')}
                  </label>
                </div>
                <input
                  name="password"
                  id="reduxFormInputField3"
                  type="password"
                  step="any"
                  aria-describedby="errorDiv4"
                  value={password}
                  onChange={onChangePassword}
                  onKeyDown={onPressEnter}
                  autoComplete="current-password"
                />
                {password && (
                  <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                    {t(passwordErrorMessage).replace('{0}', '1')}
                  </span>
                )}
              </div>
              <button
                type="button"
                className={
                  signInInProgress
                    ? 'btn login-form-module__btn___1UAw- login-form__login_btn button-module__btn___1lXcC btn__disabled'
                    : 'btn login-form-module__btn___1UAw- login-form__login_btn button-module__btn___1lXcC'
                }
                disabled={!isLoginButtonEnabled || signInInProgress}
                onClick={onSubmit}
              >
                {signInInProgress ? <SpinnerButton /> : t('Login')}
              </button>
            </form>
            <div className="login-form-module__forgot_password_wrapper___3G7Og login-form__forgot_password_wrapper">
              <div className="login-form-module__forgot_password___2X71o">
                <Link
                  className="login-form-module__forgot_password_link___30rGG login-form__forgot_password_link"
                  to="/forgot-password"
                >
                  {t('LostYourPassword')}
                </Link>
              </div>
            </div>
            <div className="login-form-module__divider___p6aSO login-form__divider">
              <span className="login-form__divider_text">{t('Or')}</span>
            </div>
            <Link className="link-button-module__link___3tT4y login-form__signup_btn btn" to="/signup">
              {t('SignUp')}
            </Link>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
