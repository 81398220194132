import React, { ReactElement, useState, ChangeEvent, RefObject, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useProfileContext } from '../contexts/profileContext';
import { Modal } from './Modal';

interface Props {
  closeModal: () => void;
}

export function UploadImageModal({ closeModal }: Props): ReactElement {
  const { t } = useTranslation();

  const { imageFileId, uploadPicture, updatePicture } = useProfileContext();

  const [picture, setPicture] = useState('');

  const fileInputRef: RefObject<HTMLInputElement> = createRef();

  function uploadProfilePic(e: ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.files) {
      setPicture(e.currentTarget.files[0].name);
      const formData = new FormData();

      formData.append('name', e.currentTarget.files[0]);
      uploadPicture(formData);
    }
  }

  function savePic() {
    if (!imageFileId) {
      return;
    }

    const fieldData = {
      fileId: imageFileId,
    };

    updatePicture(fieldData);
  }

  function onCloseModalButtonClick() {
    closeModal();
  }

  function handleBtnClick() {
    /* Collecting node-element and performing click */
    if (fileInputRef !== null && fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  }

  return (
    <Modal closeModal={closeModal}>
      <div
        className="modal-module__container___sfedc modal__container"
        role="dialog"
        aria-label={t('ProfilePhoto')}
        aria-modal="true"
      >
        <div className="modal-module__blocker___231AR modal__blocker" />
        <div className="modal-module__modal_container___3tUB2 modal__modal_container">
          <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
            <button
              type="button"
              className="icon-button-module__btn___Zb89b modal-module__close_btn___1yWvZ modal__close_btn"
              onClick={onCloseModalButtonClick}
            >
              <div className="icon icon-x-thin" />
              <span className="accessibility-text-module__off_screen_text___FSqhy">
                {t('Close')} {t('ProfilePhoto')}
              </span>
            </button>
            <div className="modal-module__modal___11QTd modal__modal modal-module__modal_small_size___1dAye">
              <div className="profile-photo-module__container___m966O">
                <div className="profile-photo-module__body___1F18h">
                  <div className="redux-form-file-upload-module__field___1nkiT">
                    <div className="redux-form-image-upload__placeholder_image redux-form-file-upload-module__placeholder_image___3qd1q profile-photo-module__placeholder_image___2Oy8D">
                      <div className="redux-form-file-upload-module__upload_image_overlay___2fMnr redux-form-image-upload__upload_image_overlay">
                        <button
                          className="redux-form-file-upload-module__upload_image_button___2aGge redux-form-image-upload__upload_image_overlay_btn"
                          type="button"
                        >
                          {t('UploadImage')}
                        </button>
                      </div>
                      <span className="icon icon-resource-image redux-form-file-upload-module__image___3UMxo" />
                    </div>
                    <div className="profile-photo-module__size_warning___1h71K profile_photo__size_warning">
                      {t('ProfileMaximumImageSize')}
                    </div>
                    <h1 className="body-font">{t('ProfilePhoto')}</h1>
                    <div className="profile-photo-module__file_type___1BXqq profile_photo__file_type">
                      {t('ProfileFileTypes')}
                    </div>
                    <div className="redux-form-file-upload-module__label_wrapper___3QWCi">
                      <label className="redux-form-file-upload-module__label___3H6Dg redux-form-file-upload__label">
                        {t('Upload')}
                      </label>
                    </div>
                    <div className="redux-form-image-upload__input_field redux-form-file-upload-module__input_field___2xwq5">
                      <Form style={{ display: 'none' }}>
                        <Form.Group controlId="custom-file">
                          <Form.Label>
                            {t('UploadImage')}
                          </Form.Label>
                          <Form.Control
                            type='file'
                            ref={fileInputRef}
                            onChange={uploadProfilePic}
                          />
                        </Form.Group>
                      </Form>

                      <button
                        className="redux-form-image-upload__upload_image_wrapper redux-form-file-upload-module__upload_image_wrapper___5-QeS profile-photo-module__label___2VjAO"
                        type="button"
                        onClick={handleBtnClick}
                      >
                        <div className="redux-form-image-upload__upload_image_text redux-form-file-upload-module__upload_image_text___1E-mZ">
                          {picture || t('UploadImage')}
                        </div>
                        <div className="redux-form-image-upload__upload_image_span redux-form-file-upload-module__upload_image_span___wjzOz">
                          <span className="redux-form-file-upload-module__upload_image_button_text___w0o-k">
                            {t('Browse')}
                          </span>
                          <span className="redux-form-image-upload__upload_image_icon redux-form-file-upload-module__upload_image_icon___2MlEi icon icon-resource-image" />
                        </div>
                      </button>
                      <div aria-live="assertive" className="profile-photo-module__error_message___14Ixe" />
                    </div>
                    <div aria-live="polite" />
                  </div>
                  <input name="removeBackgroundImage" type="hidden" value="false" />
                </div>
                <div className="profile-photo-module__footer___2QbdT profile_photo__footer">
                  <button
                    type="button"
                    className="btn profile-photo-module__save_btn___2b28F profile_photo__submit button-module__btn___1lXcC"
                    title={t('Save')}
                    aria-label="Save Profile Photo"
                    onClick={savePic}
                  >
                    {t('Save')}
                  </button>
                  <button
                    type="button"
                    className="btn profile-photo-module__cancel_btn___3MEDk profile_photo__cancel button-module__btn___1lXcC button-module__cancel___1pWE5 btn__cancel"
                    onClick={onCloseModalButtonClick}
                  >
                    {t('Cancel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
