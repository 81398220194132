import React, { ReactElement, ReactNode, useEffect } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { LoadingIndicator } from './Spinner';
import { MessageDisplayView } from './MessageDisplayView';
import { useBrandingContext } from '../contexts/brandingContext';
import { useModalContext } from '../contexts/modalContext';
import { parsedHtml } from '../utils/helper';
import { DisplayMessages } from '../constants/common';

interface Props {
  children?: ReactNode;
}

export function DashboardLayout({ children }: Props): ReactElement {
  const { modal } = useModalContext();
  const { logoImageUrl, theme, getTheme, isLoading } = useBrandingContext();

  useEffect(() => {
    if (!theme) {
      getTheme();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && (
        <div className="root-module__loader___1iKcH root__loader">
          <LoadingIndicator />
        </div>
      )}

      {!isLoading && theme && logoImageUrl && (
        <div className="root__container">
          <style>{parsedHtml(theme)}</style>
          <div className="app__container">
            <div className="app-module__app___EFhGH app__app">
              <Header />
              <div role="main" className="app-module__content___-i9XR app__content">
                {children}
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}

      {!isLoading && !theme && !logoImageUrl && (
        <MessageDisplayView errorText={DisplayMessages.ErrorLoadingWebsiteText} />
      )}

      {modal || null}
    </>
  );
}
