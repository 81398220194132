import React, { ReactElement, useState, ChangeEvent } from 'react';
import { useSearchContext } from '../../contexts/searchContext';

interface Props {
  label: string;
  value: string;
  isChecked?: boolean;
  isDynamicChecked?: boolean;
  handleCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function CheckBox({ label, value, isChecked, isDynamicChecked, handleCheckbox }: Props): ReactElement {
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const { disableFilter } = useSearchContext();

  function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setCheckBoxChecked(!checkBoxChecked);
    handleCheckbox(e);
  }

  return (
    <label className="checkbox-module__label___3gXzP type-filter__checkbox search-results-type-filter-module__checkbox___2swEN checkbox__label">
      {label}
      <input
        className="checkbox-module__input___rPFou checkbox__input"
        type="checkbox"
        aria-checked="false"
        onChange={onChangeHandler}
        name={label}
        value={value}
        disabled={disableFilter}
        checked={isDynamicChecked ? isChecked : checkBoxChecked}
      />

      <div className="checkbox-module__checkbox___21e4E checkbox__checkbox" />
    </label>
  );
}
