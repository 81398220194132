import React, { ReactElement, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogCourseData } from '../models/absorb/catalog';
import { getCourseIcon } from '../utils/helper';
import { AbsorbCourseType, AbsorbEnrollmentStatus } from '../constants/absorb';
import { CourseRating } from './CourseRating';
import { ShareCourseButton } from './ShareCourseButton';
import { PinCourseButton } from './PinCourseButton';
import './CourseCardActionButton.css';
import { CourseCardActionButton } from './CourseCardActionButton';
import { CourseAction } from './CourseAction';
import { LinearProgressIndicator } from './LinearProgressIndicator';
import { CourseDetailClickType } from '../constants/common';
import { CourseDescription } from './CourseDescription';
import { InstructorLedSession } from './InstructorLedSession';
import './CourseDetail.css';

interface Props {
  courseData: CatalogCourseData;
  isEnrollmentLocked?: boolean;
  curriculumRequiresPurchase?: boolean;
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function CourseDetail({
  courseData,
  isEnrollmentLocked = false,
  curriculumRequiresPurchase = false,
  updatePinnedStatus,
}: Props): ReactElement {
  const { t } = useTranslation();

  const disableEnrollment = isEnrollmentLocked || (curriculumRequiresPurchase && courseData.enrollmentStatus === null);
  const isHoverDisabled = isEnrollmentLocked || curriculumRequiresPurchase;

  const [showOptions, setShowOptions] = useState(false);
  const [enrollmentStatus] = useState(courseData.enrollmentStatus);

  function showActionMenu(e: MouseEvent<HTMLElement>) {
    e.stopPropagation();
    updateShowOptions(!showOptions);
  }

  function updateShowOptions(option: boolean) {
    setShowOptions(option);
  }

  function getCourseIconClassName(courseType: string) {
    return `${getCourseIcon(courseType)} icon panel-module__item_icon___Bw_O_ panel__icon`;
  }

  return (
    <div className="panel-module__panel___2njx7 panel__panel">
      <div className="panel-module__panel_header_focus___NaHeg">
        <CourseAction
          cssClass={`panel-module__panel_header___fkAzr panel-module__panel_header_link___j5xTN ${
            isHoverDisabled && 'panel-module-default_cursor'
          } panel__panel_header`}
          cardData={courseData}
          clickType={CourseDetailClickType.Link}
          courseLocked={disableEnrollment}
          updateTilePinnedStatus={updatePinnedStatus}
        >
          <div className="panel-module__panel_title___2Xe9e">
            <div className="panel-module__thumbnail___1UH4o">
              {courseData.imageUrl ? (
                <img
                  src={courseData.imageUrl}
                  className="panel-module__image___1gWzU panel__image"
                  alt={courseData.name}
                />
              ) : (
                <span className={getCourseIconClassName(courseData.courseType)} />
              )}
              {courseData.courseType === AbsorbCourseType.InstructorLedCourse && courseData.selectedSession === null && (
                <span className="pill-module__pill___1PAoi instructor-led-course-panel-module__available_sessions___1Mumw instructor-led-course-panel__available_sessions">
                  <span className="label-primary" aria-hidden="true">
                    {courseData.sessionCount || '0'}
                  </span>
                </span>
              )}
              {enrollmentStatus === AbsorbEnrollmentStatus.Complete && (
                <div className="hat-module__hat___ZyiQ panel-module__hat___2N1Ko hat__hat">
                  <span className="hat-module__bg___3DXee hat__bg hat-module__bg_success___2GP_d hat__success" />
                  <span className="icon icon-check-mark hat-module__icon___3-vsJ hat__icon" />
                </div>
              )}
            </div>
            <div className="panel-module__title_info_wrapper___3brXS">
              <div className="panel-module__title_wrapper___26Xj2">
                <div
                  className={`panel-module__name___3Vd6B panel__header_name ${
                    isHoverDisabled && 'panel-module-no_text_decoration'
                  }`}
                  title={courseData.name}
                >
                  {courseData.name}
                </div>
                <div className="options-button-module__container___2AYLC panel-module__options___13hfE">
                  {showOptions && (
                    <div className="options-button-module__options_container___1NPht options-button__options_container">
                      <div className="course-options-module__course_options___1vuRm" role="menu">
                        {courseData && (
                          <ShareCourseButton
                            courseId={courseData.id}
                            courseType={courseData.courseType}
                            courseName={courseData.name || ''}
                          />
                        )}
                        {courseData && !disableEnrollment && (
                          <PinCourseButton
                            catalogCourseData={courseData}
                            updatePinnedStatus={updatePinnedStatus}
                            showOptions={showOptions}
                            updateShowOptions={updateShowOptions}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="options-button-module__btn___1mr7i options-button__btn"
                    title={t('CourseOptions')}
                    aria-label={t('CourseOptions')}
                    aria-haspopup="true"
                    onClick={(event) => showActionMenu(event)}
                  >
                    <span className="icon icon-options" />
                  </button>
                  {showOptions && (
                    <div
                      className="options-button-module__options_overlay___1J0nV options-button__options_overlay"
                      onClick={(event) => showActionMenu(event)}
                    />
                  )}
                </div>
              </div>
              <div className="progress-bar-module__container___315yd panel-module__progress_bar___16Xak progress-bar__bar">
                <LinearProgressIndicator progress={courseData.progress} />
              </div>
              <div className="panel-module__action_wrapper___99A4W online-course-panel__action_wrapper">
                <div className="panel-module__type_wrapper___2tSR7">
                  <div className="online-course-panel-module__panel_type___3rUfl online-course-panel__panel_type">
                    <span className="online-course-panel-module__course_type___3zV6s online-course-panel__course_type">
                      {courseData.courseType === AbsorbCourseType.InstructorLedCourse &&
                      courseData.selectedSession !== null
                        ? courseData.selectedSession?.name
                        : t(courseData.courseType)}
                    </span>
                  </div>
                  {courseData.isCourseRatingEnabled && (
                    <CourseRating
                      selectedStars={courseData.myRating || courseData.rating}
                      totalRating={courseData.totalRatings}
                      courseId={courseData.id}
                      disabled={!courseData.myRating || courseData.price !== null || !courseData.enrolled}
                    />
                  )}
                </div>
                <div className="panel-module__panel_actions___sx8F8">
                  <div className="action-button-module__container___2O6X7 panel-module__action_btn___QJ-s_">
                    <CourseCardActionButton
                      cardData={courseData}
                      isEnrollmentLocked={isEnrollmentLocked}
                      curriculumRequiresPurchase={curriculumRequiresPurchase}
                      isIconRequired
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CourseAction>
      </div>
      {(courseData.selectedSession || courseData.description || (courseData.tags && courseData.tags.length > 0)) && ( // required this to avoid extra div if all conditions are not satisfied
        <div className="panel-module__panel_body___3vUWN panel__panel_body">
          {courseData.selectedSession && <InstructorLedSession instructorLedSessionData={courseData.selectedSession} />}
          {(courseData.description || (courseData.tags && courseData.tags.length > 0)) && (
            <CourseDescription courseData={courseData} />
          )}
        </div>
      )}
    </div>
  );
}
