import React, { ReactElement, useState, useEffect, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputBox } from './InputBox';
import { useSearchContext } from '../../contexts/searchContext';
import { KEY_ENTER } from '../../constants/common';

interface Props {
  label: string;
  placeholder: string;
}

export function GenericNameFilter({ label, placeholder }: Props): ReactElement {
  const { t } = useTranslation();

  const { searchName, setSearchName } = useSearchContext();

  const [name, setName] = useState(searchName);

  useEffect(() => {
    if (searchName !== '') {
      setName(searchName);
    }
  }, [searchName]);

  function onKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === KEY_ENTER) {
      setSearchName(name);
    }
  }

  return (
    <InputBox
      className="filter-panel-module__filter_panel___2xlm8 filter-panel__filter_panel"
      label={t(label)}
      placeholder={t(placeholder)}
      value={name}
      onChangeHandleInput={(e) => setName(e.target.value)}
      onKeyPress={onKeyPress}
      onChangeHandleCrossButton={() => {
        setSearchName('');
        setName('');
      }}
    />
  );
}
