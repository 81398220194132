import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Modal } from './Modal';
import { COPY_TO_CLIPBOARD_RESET_DELAY } from '../constants/common';
import { useModalContext } from '../contexts/modalContext';
import { constructCourseTypeUrl } from '../utils/helper';

interface Props {
  courseId: string;
  courseType: string;
}

export function ShareCourseModal({ courseId, courseType }: Props): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();

  const [urlCopied, setUrlCopied] = useState(false);
  const courseUrl = constructCourseTypeUrl(courseType, courseId);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  function onCloseModalButtonClick() {
    document.body.style.overflow = 'auto';

    closeModal();
  }

  function onCopyUrl() {
    setUrlCopied(true);
    setTimeout(() => setUrlCopied(false), COPY_TO_CLIPBOARD_RESET_DELAY);
  }

  return (
    <Modal closeModal={onCloseModalButtonClick}>
      <div
        className="share-module__container___KbDXE share__container"
        role="dialog"
        aria-label="Share This"
        aria-modal="true"
      >
        <div className="share-module__overlay___12jzP share__overlay" />
        <div className="share-module__share___3sYAv">
          <button
            type="button"
            className="icon-button-module__btn___Zb89b share-module__close_btn___14bAe share__close_btn"
            title={t('Close')}
            onClick={onCloseModalButtonClick}
          >
            <div className="icon icon-x-thin" />
            <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Close')}</span>
          </button>
          <div className="share-module__header___34PNE">
            <div className="icon icon-share-document share-module__icon___1hUBP share__icon" />
            <div className="share-module__title___1MA0h share__title">{t('ShareThis')}</div>
          </div>
          <div className="share-module__content___1c0aK">
            <div className="share-module__description___2GLVv share__descriptions">{t('CopyToShare')}:</div>
            <div className="share-module__input_wrapper___2EomB">
              {urlCopied && (
                <div className="share-module__copy_msg___mv8E4 share-module__copy_success___1wl6U share__copy_success">
                  {t('CopiedToClipboard')}
                </div>
              )}
              <input className="share-module__input___2TJKQ" type="text" defaultValue={courseUrl} />

              <CopyToClipboard text={courseUrl} onCopy={onCopyUrl}>
                <div
                  className="btn share-module__copy_btn___3BEu3 share__copy_btn button-module__btn___1lXcC"
                  role="button"
                >
                  {t('CopyLink')}
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
