import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FileSaver from 'file-saver';
import { InstructorLedSessionData } from '../models/absorb/instructorLed';
import { fetchCalendarLink } from '../services/instructorLed';
import { errorHandler } from '../utils/helper';
import { ExpandableDescription } from './ExpandableDescription';

interface Props {
  instructorLedSession?: InstructorLedSessionData;
  instructorLedCourseId?: string;
}

export function SessionHeader({ instructorLedSession, instructorLedCourseId }: Props): ReactElement {
  const { t } = useTranslation();

  function downloadCalendar() {
    if (instructorLedSession?.id && instructorLedCourseId) {
      fetchCalendarLink(instructorLedCourseId, instructorLedSession?.id)
        .then((data) => {
          const blob = new Blob([data], { type: 'text/calendar; charset=utf-8' });
          FileSaver.saveAs(blob, `${instructorLedSession.courseName}`);
        })
        .catch(errorHandler);
    }
  }

  return (
    <div className="session-details-module__session_wrapper___3HMe2">
      {instructorLedSession && (
        <div className="session-details-module__session_header___1gAk8">
          <div className="session-details-module__session_calendar_icon___28sDf">
            <span className="accessibility-text-module__off_screen_text___FSqhy">
              {` ${moment(instructorLedSession.currentClass.localStartDate).format('LL')} `}
            </span>
            <div aria-hidden="true" className="calendar-icon-module__date_icon___3Qzni">
              <div className="calendar_icon__month_year calendar-icon-module__month_year___2zEoJ">
                {moment(instructorLedSession.currentClass.localStartDate).format('MMMM YYYY')}
              </div>
              <div className="calendar-icon-module__date___3Dy_l calendar_icon__date">
                {moment(instructorLedSession.currentClass.localStartDate).format('DD')}
              </div>
            </div>
          </div>
          <div className="session-details-module__session_info_wrapper___3bzoM">
            <div
              className="session-details-module__session_name___27rww session_details__name"
              title={instructorLedSession.name}
            >
              {instructorLedSession.name}
            </div>
            <div>
              <div className="session-details-module__session_time___3NVKM session_details__time">
                {`${moment(instructorLedSession.currentClass.localStartDate).format('h:mm A')} - ${moment(
                  instructorLedSession.currentClass.localEndDate
                ).format('h:mm A')}`}
              </div>
              <div className="session-details-module__session_timezone___2vmGw session_details__timezone">
                {`(${instructorLedSession.currentClass.timeZoneShort})`}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="session-details-module__buttons___1GomG">
        <button
          type="button"
          aria-disabled="false"
          aria-label={t('AddToOutlook')}
          className="href-button-module__btn___Z9emG btn session-details-module__enroll_button___3MRPY session__add_to_calendar_button"
          onClick={downloadCalendar}
        >
          {t('AddToOutlook')}
        </button>
        {/* TODO: should only be visible to admins, ok to skip implementation for now, as can be managed in absorb admin */}
        {/* <div className="action-button-module__container___2O6X7 session-details-module__enroll_button___3MRPY">
          <button
            type="button"
            aria-disabled="false"
            aria-label={t('ChangeSession')}
            className="action-button-module__btn___3zkzb btn"
          >
            <div className="action-button-module__title___1uZt_">{t('ChangeSession')}</div>
            <span aria-live="polite"/>
          </button>
        </div> */}
      </div>
      <div className="session-details-module__session_body___3X1Ah">
        <div className="session-details-module__session_row___RYkNR">
          <div className="session-details-module__session_row_title___2qqbE">{`${t('Instructor')}:`}</div>
          <div className="instructor-list-module__container___1eO90">
            <Link
              title={`${t('FilterBy')} ${
                instructorLedSession?.instructors ? instructorLedSession.instructors[0].fullName : ''
              }`}
              className="instructor-list-module__link___1LSS9 instructor-list__link"
              to={`/catalog?instructor=${
                instructorLedSession?.instructors ? instructorLedSession.instructors[0].instructorId : ''
              }`}
            >
              {instructorLedSession?.instructors ? instructorLedSession.instructors[0].fullName : ''}
            </Link>
          </div>
        </div>
        {instructorLedSession?.enrollmentLimit && (
          <div className="session-details-module__session_row___RYkNR">
            <div className="session-details-module__session_row_title___2qqbE">{`${t('ClassSize')}:`}</div>
            <div className="session-details-module__session_row_value___DFhEJ session-details__class_size">
              {instructorLedSession.enrollmentLimit}
            </div>
          </div>
        )}
        {instructorLedSession?.description && (
          <div className="session-details-module__session_description___3ZJ83">
            <ExpandableDescription itemName="" description={instructorLedSession.description} />
          </div>
        )}
      </div>
    </div>
  );
}
