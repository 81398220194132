import React, { ReactElement } from 'react';
import { SearchResultsData } from '../models/absorb/search';
import { DisplayContentView } from '../constants/courses';
import { SearchResultCardView } from './SearchResultCardView';
import { SearchResultDetailedView } from './SearchResultDetailedView';
import { SearchResultListView } from './SearchResultListView';

interface Props {
  searchResult: SearchResultsData;
  contentView: DisplayContentView;
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function SearchResultView({ searchResult, contentView, updatePinnedStatus }: Props): ReactElement {
  return (
    <>
      {contentView === DisplayContentView.Cards && (
        <SearchResultCardView searchResults={searchResult.searchResults} updatePinnedStatus={updatePinnedStatus} />
      )}
      {contentView === DisplayContentView.List && (
        <SearchResultListView searchResults={searchResult.searchResults} updatePinnedStatus={updatePinnedStatus} />
      )}
      {contentView === DisplayContentView.Detailed && (
        <SearchResultDetailedView searchResults={searchResult.searchResults} updatePinnedStatus={updatePinnedStatus} />
      )}
    </>
  );
}
