import React, { ReactNode, ReactElement, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { CatalogCourseData } from '../models/absorb/catalog';
import { AbsorbCourseType, AbsorbResourceType } from '../constants/absorb';
import { CourseDetailModal } from '../modals/CourseDetailModal';
import { CurriculumDetailModal } from '../modals/CurriculumDetailModal';
import { useModalContext } from '../contexts/modalContext';
import { FaqModal } from '../modals/FaqModal';
import { CourseDetailClickType } from '../constants/common';
import { constructCourseTypePath } from '../utils/helper';

interface Props {
  cssClass: string;
  children: ReactNode;
  cardData: CatalogCourseData;
  clickType: CourseDetailClickType;
  courseLocked?: boolean;
  updateTilePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function CourseAction({
  cssClass,
  children,
  cardData,
  clickType,
  courseLocked = false,
  updateTilePinnedStatus,
}: Props): ReactElement {
  const navigate = useNavigate();

  const { showModal } = useModalContext();

  function onClickCourse(e: MouseEvent<HTMLElement>, courseDetailData: CatalogCourseData) {
    e.stopPropagation();

    if (courseDetailData.courseType && (courseDetailData?.price !== null || courseDetailData.enrolled === false)) {
      if (courseDetailData.courseType === AbsorbCourseType.Curriculum) {
        showModal(<CurriculumDetailModal updateTilePinnedStatus={updateTilePinnedStatus} courseData={cardData} />);
      } else {
        showModal(<CourseDetailModal updateTilePinnedStatus={updateTilePinnedStatus} courseData={cardData} />);
      }
    } else if (courseDetailData.price === null && courseDetailData.courseType) {
      navigate(constructCourseTypePath(courseDetailData.courseType, courseDetailData.id));
    } else if (courseDetailData.courseType === AbsorbResourceType.Faq || courseDetailData.question !== null) {
      showModal(<FaqModal faqData={courseDetailData} />);
    }
  }

  function getElementTypeOnCourseLock() {
    if (courseLocked) {
      return <div className={cssClass}>{children}</div>;
    }
    return (
      <button className={cssClass} onClick={(e) => !courseLocked && onClickCourse(e, cardData)}>
        {children}
      </button>
    );
  }

  return (
    <>
      {clickType === CourseDetailClickType.Link ? (
        <div className={cssClass} onClick={(e) => !courseLocked && onClickCourse(e, cardData)}>
          {children}
        </div>
      ) : (
        getElementTypeOnCourseLock()
      )}
    </>
  );
}
