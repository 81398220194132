import React, { ReactElement } from 'react';
import { CatalogCourseData } from '../models/absorb/catalog';
import { SearchResultData } from '../models/absorb/search';
import { AbsorbResourceType } from '../constants/absorb';
import { CourseCard } from './CourseCard';
import { FaqCard } from './FaqCard';
import { FaqResourceData, GlobalResourceData } from '../models/absorb/resources';
import { GlobalResourceCard } from './GlobalResourceCard';

interface Props {
  resource: SearchResultData;
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function ResourceCard({ resource, updatePinnedStatus }: Props): ReactElement {
  return (
    <>
      {resource.resourceType === AbsorbResourceType.GlobalResource && (
        <GlobalResourceCard key={resource.resource.id} globalResourceData={resource.resource as GlobalResourceData} />
      )}
      {resource.resourceType === AbsorbResourceType.Course && (
        <CourseCard
          key={resource.resource.id}
          cardData={resource.resource as CatalogCourseData}
          updatePinnedStatus={updatePinnedStatus}
        />
      )}
      {resource.resourceType === AbsorbResourceType.Faq && (
        <FaqCard key={resource.resource.id} faqResourceData={resource.resource as FaqResourceData} />
      )}
    </>
  );
}
