import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, HOUR_MINUTE_FORMAT, MONTH_DAY_YEAR_FORMAT, MONTH_YEAR_FORMAT } from '../constants/common';
import { InstructorDetails, InstructorLedSessionData } from '../models/absorb/catalog';
import { useSearchContext } from '../contexts/searchContext';

interface Props {
  instructorLedSessionData: InstructorLedSessionData;
}

export function InstructorLedSession({ instructorLedSessionData }: Props): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setInstructor } = useSearchContext();

  function filterByInstructor(instructorId: string) {
    setInstructor(instructorId);
    navigate(`/catalog?instructor=${instructorId}`);
  }

  return (
    <div className="instructor-led-course-panel-module__schedule___X6G1l">
      <div className="session-schedule-module__session_title___3Vc-E session-schedule__session_title">
        {t('Session')}:
      </div>
      <div className="session-schedule-module__schedule___3Me13">
        <div className="session-schedule-module__calendar___Gfdyo" aria-hidden="true">
          <div className="session-schedule-module__calendar_month___2cNOl session-schedule__calendar_month">
            {moment(instructorLedSessionData.startDate).format(MONTH_YEAR_FORMAT)}
          </div>

          <div className="session-schedule-module__calendar_date___CFgfh session-schedule__calendar_date">
            {moment(instructorLedSessionData.startDate).format(DATE_FORMAT)}
          </div>
        </div>
        <span className="accessibility-text-module__off_screen_text___FSqhy">
          {moment(instructorLedSessionData.startDate).format(MONTH_DAY_YEAR_FORMAT)}
        </span>

        <div className="session-schedule-module__details___3qtNo">
          <div>
            <span className="session-schedule-module__details_date___3r3B_ session-schedule__date">
              {`${moment(instructorLedSessionData.startDate).format(HOUR_MINUTE_FORMAT)} - ${moment(
                instructorLedSessionData.endDate
              ).format(HOUR_MINUTE_FORMAT)}`}
            </span>
            <span className="session-schedule-module__details_timezone___3YL8B session-schedule__tmz">
              {`(${instructorLedSessionData.timeZoneShort})`}
            </span>
          </div>
          <div className="session-schedule-module__instructor___1CVTu session-schedule__instructor">
            <span className="session-schedule-module__details_label___3GiOC">{t('SessionInstructor')}:</span>
            <div className="instructor-list-module__container___1eO90">
              {instructorLedSessionData.instructors.map((courseResource: InstructorDetails) => (
                <button
                  type="button"
                  className="instructor-list-module__link___1LSS9 instructor-list__link"
                  onClick={() => filterByInstructor(courseResource.instructorId)}
                >
                  {courseResource.fullName}
                </button>
              ))}
            </div>
          </div>
          <div className="session-schedule__location">
            <span className="session-schedule-module__details_label___3GiOC">{t('Location')}: </span>
            <span className="session-schedule__location_text">{instructorLedSessionData.venueName}</span>
            <span className="session-schedule-module__location_url___1M3WI session-schedule__location_url">
              <a href={instructorLedSessionData.venueUrl} target="_blank" rel="noopener noreferrer">
                {instructorLedSessionData.venueUrl}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
