import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardLayout } from '../components/DashboardLayout';
import { useBrandingContext } from '../contexts/brandingContext';
import { useSearchContext } from '../contexts/searchContext';
import { useContentViewContext } from '../contexts/contentViewContext';
import { SideFilter } from '../components/SideFilter';
import { ResourcesCardView } from '../components/ResourcesCardView';
import { ResourcesDetailView } from '../components/ResourcesDetailView';
import { ResourcesListView } from '../components/ResourcesListView';
import { LoadingIndicator } from '../components/Spinner';
import { MessageDisplayView } from '../components/MessageDisplayView';
import { onFetchResourceUrl, fetchResources, fetchMyResourcesCategories } from '../services/resources';
import { GlobalResourceData } from '../models/absorb/resources';
import { errorHandler } from '../utils/helper';
import { DisplayContentView } from '../constants/courses';
import { ROOT_CATEGORY, DisplayMessages, ViewOptionPage } from '../constants/common';
import { ViewOptionsButton } from '../components/ViewOptionsButton';
import { CategoriesData } from '../models/absorb/catalog';
import { Category } from '../components/Category';
import './ResourcesPage.css';
import { Breadcrumb } from '../components/Breadcrumb';
import { PageTitle } from '../components/PageTitle';

export function ResourcesPage({ explicitCategoryId }: { explicitCategoryId?: string }): ReactElement {
  const { categoryId: categoryIdParam } = useParams();
  const categoryId = explicitCategoryId || categoryIdParam;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { brandingCoursesSettings } = useBrandingContext();
  const { currentSearchUri, previousSearchUri, searchFilter, setDisableFilter } = useSearchContext();
  const { contentView, setContentView, setContentViewOnPage, getContentViewOnPage } = useContentViewContext();

  const [showViewOptions, setShowViewOptions] = useState(false);
  const [showRefineSearch, setShowRefineSearch] = useState(true);
  const [resources, setResources] = useState<GlobalResourceData[]>([]);
  const [resourceCategories, setResourceCategories] = useState<CategoriesData[]>([]);
  const [selectedCategoryData, setSelectedCategoryData] = useState<CategoriesData>();
  const [resourceUrl, setResourceUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const selectedCategory = categoryId ?? (searchFilter.showCategories && ROOT_CATEGORY);

  useEffect(() => {
    setIsLoading(true);
    if (currentSearchUri.includes('/resources')) {
      setContentView(getContentViewOnPage(ViewOptionPage.Resources) as DisplayContentView);

      setIsLoading(true);
      setDisableFilter(true);
      fetchResources({ tags: searchFilter.tags, name: searchFilter.name, category: selectedCategory })
        .then((resource) => {
          setResources(resource);
        })
        .catch(errorHandler)
        .finally(() => {
          setIsLoading(false);
          setDisableFilter(false);
        });
    }
  }, [currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentSearchUri.includes('/resources')) {
      fetchMyResourcesCategories({
        ...searchFilter,
        parent: ROOT_CATEGORY,
      })
        .then(setResourceCategories)
        .catch(errorHandler);
    }
  }, [currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filteredCategory = resourceCategories.find((categoryItem) => categoryItem.id === categoryId);
    setSelectedCategoryData(filteredCategory);
  }, [resourceCategories, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resourceUrl) {
      window.open(resourceUrl, '_blank');
      setResourceUrl('');
    }
  }, [resourceUrl]);

  function onChangeContentView(viewChange: DisplayContentView) {
    setContentView(viewChange);
    setContentViewOnPage(ViewOptionPage.Resources, viewChange);
    setShowViewOptions(false);
  }

  function getResourceUrl(data: { id: string }) {
    onFetchResourceUrl(data).then(setResourceUrl).catch(errorHandler);
  }

  function getCardsView() {
    return (
      <div className="resources-module__content___18wl4">
        <div className="resources-module__content_wrapper___2UpbZ resources__content_wrapper">
          <div>
            <div className="resources-module__resources_container___l6Br8">
              <div className="resources-module__cards_container___2BhHy resources__cards_container">
                {resources.map((resource: GlobalResourceData) => (
                  <ResourcesCardView key={resource.id} resourceData={resource} fetchResourceUrl={getResourceUrl} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getDetailedView() {
    return (
      <div className="resources__panels_container">
        {resources.map((resource: GlobalResourceData) => (
          <ResourcesDetailView key={resource.id} resourceData={resource} fetchResourceUrl={getResourceUrl} />
        ))}
      </div>
    );
  }

  function getListView() {
    return <ResourcesListView resourceData={resources || []} fetchResourceUrl={getResourceUrl} />;
  }

  function getContentView(viewType: DisplayContentView): ReactElement {
    const lookup: { [key: string]: ReactElement } = {
      [DisplayContentView.Cards]: getCardsView(),
      [DisplayContentView.Detailed]: getDetailedView(),
      [DisplayContentView.List]: getListView(),
    };

    return lookup[viewType] || <></>;
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('Resources')} />
      <div className="app-module__content___-i9XR app__content">
        <div className="resources-module__container___2moeQ">
          <div aria-live="assertive" role="status" />
          <div
            className="banner-module__banner___IJO0Z banner__banner"
            style={{
              backgroundImage: `url("${encodeURI(brandingCoursesSettings?.myCoursesAndCatalog?.bannerUri || '')}")`,
            }}
          >
            {selectedCategoryData && (
              <button
                type="button"
                aria-label={t('Resources')}
                className="icon icon-back-arrow banner-back-button-module__button___czAfx banner__back_arrow"
                title={t('Resources')}
                onClick={() => navigate(previousSearchUri)}
              />
            )}
            <h1 className="banner-title-module__title___1mbDk banner__title">
              {selectedCategoryData?.name || t('Resources')}
            </h1>
          </div>
          <div className="resources-module__resource___2HbXT">
            <div className="resources-module__header___3VVsF">
              <div
                className={`filter-toggle-module__filter_toggle___zOXqM filter-toggle__filter_toggle ${showRefineSearch
                  ? ''
                  : 'filter-toggle-module__filter_toggle_hidden___3cC5a filter-toggle__filter_toggle_hidden'
                  }`}
              >
                <button
                  type="button"
                  className="icon-button-module__btn___Zb89b filter-toggle-module__filter_toggle_btn___1itNb filter-toggle__filter_toggle_btn"
                  title={t('HideFilters')}
                  onClick={() => setShowRefineSearch(!showRefineSearch)}
                >
                  <div className={`icon ${showRefineSearch ? 'icon-filter-minus' : 'icon-filter-plus'}`} />
                  <span className="accessibility-text-module__off_screen_text___FSqhy">
                    {!showRefineSearch ? t('HideFilters') : ''}
                  </span>
                  <span className="filter-toggle-module__filter_toggle_message___1ucsU filter-toggle__message body-font">
                    {showRefineSearch ? t('HideRefineSearch') : ''}
                  </span>
                </button>
              </div>
              <ViewOptionsButton
                showViewOptions={showViewOptions}
                contentView={contentView}
                onChangeContentView={onChangeContentView}
                setShowViewOptions={setShowViewOptions}
              />
            </div>
            {selectedCategoryData && categoryId && (
              <Breadcrumb
                breadcrumbTitle="Resources"
                selectedCategoryData={selectedCategoryData}
                categoryId={categoryId}
              />
            )}
            <div className="resources-module__content___18wl4">
              <div className="resources-module__content_wrapper___2UpbZ resources__content_wrapper">
                {isLoading ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    {resourceCategories.length > 0 &&
                      searchFilter.showCategories &&
                      resourceCategories.length > 0 &&
                      !categoryId && (
                        <div className="category-list-module__categories_wrapper___YHLXw">
                          {resourceCategories.map((resourceCategory) => (
                            <Category key={resourceCategory.id} catalogCategory={resourceCategory} />
                          ))}
                        </div>
                      )}

                    <div>
                      {resources && resources.length === 0 ? (
                        <MessageDisplayView errorText={DisplayMessages.NoResultsMainText} />
                      ) : (
                        <div className="resources-module__resources_container___l6Br8">
                          {getContentView(contentView)}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {!isLoading && showRefineSearch && <SideFilter />}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
