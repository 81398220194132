import React, { ReactElement, ReactNode, useEffect } from 'react';
import { KEY_ESCAPE } from '../constants/common';

interface Props {
  children?: ReactNode;
  closeModal: () => void;
}

export function Modal({ children, closeModal }: Props): ReactElement {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  window.addEventListener('keydown', (e) => {
    handleKeyPress(e);
  });

  function handleKeyPress(event: KeyboardEvent) {
    if (event.key === KEY_ESCAPE) {
      closeModal();
    }
  }

  return <>{children}</>;
}
