import React, { ReactElement, useState, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useShoppingContext } from '../contexts/shoppingContext';
import { ShoppingCartContainer } from './ShoppingCartContainer';
import { createPaymentIntent, completePaymentAndEnrollCourses } from '../services/payment';
import { CardSection } from './CardSection';
import { SpinnerButton } from './SpinnerButton';
import { Summary } from './Summary';
import { US_DOLLAR_CURRENCY } from '../constants/shoppingCart';
import { errorHandler } from '../utils/helper';
import { PaymentIntentData } from '../models/stripe/clientSecret';
import { useModalContext } from '../contexts/modalContext';
import { PaymentSuccessModal } from '../modals/PaymentSuccessModal';
import { PageTitle } from './PageTitle';
import './Payment.css';

export function Payment(): ReactElement {
  const { t } = useTranslation();

  const { cartData, billingDetailsState, getCartTotal, clearCart } = useShoppingContext();
  const { showModal } = useModalContext();

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentError, setPaymentError] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const paymentIntentData: PaymentIntentData = {
      amount: getCartTotal(),
      currency: US_DOLLAR_CURRENCY,
      courses: cartData.map((course) => course.id),
    };

    setIsLoading(true);
    createPaymentIntent(paymentIntentData)
      .then((data) => {
        setClientSecret(data.clientSecret);
      })
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function changeHandler(e: StripeCardElementChangeEvent) {
    const isCardDetailIncomplete = !e.complete;
    setDisabled(e.empty || isCardDetailIncomplete);
    setPaymentError(e.error?.message || '');
  }

  function handleSubmit(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setPaymentSuccess(false);

    if (!stripe || !elements) {
      return;
    }

    const currentCardElement = elements.getElement(CardElement);

    if (!currentCardElement) {
      setPaymentError('ErrorGeneric');
      return;
    }

    if (clientSecret) {
      setIsLoading(true);

      const paymentDetailsObj = {
        payment_method: {
          card: currentCardElement,
          billing_details: {
            name: `${billingDetailsState.firstName} ${billingDetailsState.lastName}`,
            address: {
              city: billingDetailsState.city,
              country: billingDetailsState.countryCode,
              line1: billingDetailsState.addressLine1,
              line2: billingDetailsState.addressLine2,
              postal_code: billingDetailsState.postalCode,
            },
          },
        },
      };

      stripe
        .confirmCardPayment(clientSecret, paymentDetailsObj)
        .then(async (response) => {
          if (response?.error?.message) {
            setPaymentError(response.error.message);
          } else if (response?.paymentIntent) {
            await completePaymentAndEnrollCourses({ paymentIntentId: response.paymentIntent.id });
          }
          return response;
        })
        .then((response) => {
          if (!response?.error?.message) {
            currentCardElement.update({ disabled: true });
            clearCart();
            setPaymentSuccess(true);
            showModal(<PaymentSuccessModal paymentError={paymentError} />);
          }
        })
        .catch(errorHandler)
        .finally(() => setIsLoading(false));
    } else {
      setPaymentError(t('ErrorGeneric'));
    }
  }

  return (
    <ShoppingCartContainer>
      <PageTitle title={t('Payment')} />
      <div className="cart-payment-module__content___39f6L flex-direction-col">
        <div className="card-module-container">
          <div className="cart-payment-module__options___1c9Ap">
            <CardSection changeHandler={changeHandler} />
          </div>
          <button
            className="btn cart-shipping-module__submit_btn___1ektB cart-shipping__submit_btn button-module__btn___1lXcC"
            disabled={disabled || paymentError.trim() !== '' || isLoading || paymentSuccess}
            onClick={(e) => handleSubmit(e)}
          >
            {isLoading ? <SpinnerButton /> : t('Submit')}
          </button>
          <h3 className="cart-payment-module__options_header___257WE body-font">
            {paymentSuccess ? t('Success') : paymentError}
          </h3>
        </div>
        {!paymentSuccess && <Summary />}
      </div>
    </ShoppingCartContainer>
  );
}
