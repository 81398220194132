import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalResourceData } from '../models/absorb/resources';
import { getResourceIcon } from '../utils/helper';

interface Props {
  resourceData: GlobalResourceData[];
  fetchResourceUrl: (data: { id: string }) => void;
}

export function ResourcesListView({ resourceData, fetchResourceUrl }: Props): ReactElement {
  const { t } = useTranslation();

  function getResourceIconClassName(fileType: string | undefined) {
    return `${getResourceIcon(fileType)} icon resource-list-module__icon___2z9Iw resource__icon`;
  }

  function onClickResource(id: string) {
    const data = {
      id,
    };

    if (fetchResourceUrl) {
      fetchResourceUrl(data);
    }
  }

  return (
    <div className="resources-module__content___18wl4">
      <div className="resources-module__content_wrapper___2UpbZ resources__content_wrapper">
        <div className="resources-module__resources_container___l6Br8">
          <div className="resources-module__list_container___h6yxm">
            <div className="sortable-table-module__table___2epWu sortable-table__table" role="table">
              <div role="rowgroup">
                <div
                  className="sortable-table-module__table_row_header___hu7Ms sortable-table__table_row_header"
                  role="row"
                >
                  <div
                    className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item resource-list-module__name_column___1drNC"
                    role="columnheader"
                  >
                    <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                      {t('ResourceName')}
                    </span>
                  </div>
                  <div
                    className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item resource-list-module__actions_column___2oqgL"
                    role="columnheader"
                  >
                    <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09" />
                  </div>
                </div>
              </div>
              {resourceData &&
                resourceData.map((resource: GlobalResourceData) => (
                  <div key={resource.id} role="rowgroup">
                    <div className="sortable-table-module__table_row_container___8gbRc">
                      <div
                        className="sortable-table-module__table_row___3N1yN sortable-table__table_row resource-list-module__navigable_row___QT_p_"
                        role="row"
                      >
                        <div
                          className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item resource-list-module__name_column___1drNC"
                          role="cell"
                        >
                          <div className="resource-list-module__resource_name_container___3pxgc">
                            <span className={getResourceIconClassName(resource.fileType)} title={t('Other')} />
                            <span
                              className="resource-list-module__resource_name___Q5Z24 resource__name"
                              title={resource.name}
                              onClick={() => onClickResource(resource.id)}
                            >
                              {resource.name}
                            </span>
                          </div>
                        </div>
                        <div
                          className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item resource-list-module__actions_column___2oqgL"
                          role="cell"
                        >
                          <div className="resource-list-module__resource_actions___1LHfJ">
                            <div className="action-button-module__container___2O6X7 resource-list-module__resource_action_btn___3BguX">
                              <button
                                type="button"
                                aria-disabled="false"
                                aria-label={resource.name}
                                className={`action-button-module__btn___3zkzb btn ${
                                  resource.fileType === 'PNG'
                                    ? 'action-button-module__btn_disabled___2bOlE btn__disabled'
                                    : ''
                                }`}
                              >
                                <div
                                  className="action-button-module__title___1uZt_"
                                  onClick={() => onClickResource(resource.id)}
                                >
                                  {t('Open')}
                                </div>
                                <span aria-live="polite" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
