import React, { ReactElement, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { REGEX_PASSWORD_VALIDATION } from '../constants/regex';
import { changePassword } from '../services/profile';
import { ChangePasswordErrorResponse, ChangePasswordValidationErrors } from '../models/absorb/password';
import { useModalContext } from '../contexts/modalContext';
import { doesPasswordsMatchByLength } from '../utils/helper';
import { SpinnerButton } from '../components/SpinnerButton';
import './ChangePasswordModal.css';

export function ChangePasswordModal(): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();

  const [success, setSuccess] = useState(false);
  const [changePasswordInProgress, setChangePasswordInProgress] = useState(false);
  const [firstAttempt, setFirstAttempt] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [newPasswordErrorLatchFlag, setNewPasswordErrorLatchFlag] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [confirmPasswordErrorLatchFlag, setConfirmPasswordErrorLatchFlag] = useState(false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState<ChangePasswordValidationErrors[]>([]);

  useEffect(() => {
    const valid =
      currentPassword !== '' &&
      newPassword !== '' &&
      confirmPassword !== '' &&
      REGEX_PASSWORD_VALIDATION.test(newPassword) &&
      newPassword === confirmPassword;

    if (newPasswordError !== '' || newPasswordErrorLatchFlag) {
      validateNewPassword();
    }

    if (confirmPasswordError !== '' || confirmPasswordErrorLatchFlag || newPasswordError || newPasswordErrorLatchFlag) {
      validatePasswordMatches();
    }

    setIsFormValid(valid);
  }, [firstAttempt, currentPassword, newPassword, confirmPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  function validateNewPassword() {
    const validPassword = REGEX_PASSWORD_VALIDATION.test(newPassword);
    setNewPasswordError(validPassword ? '' : t('MediumPasswordHint'));
    setNewPasswordErrorLatchFlag(validPassword || newPasswordErrorLatchFlag);
  }

  function validatePasswordMatches() {
    if (newPassword.length === confirmPassword.length) {
      updateConfirmPasswordError(newPassword === confirmPassword);
    }
  }

  function onChangeConfirmPassword(e: ChangeEvent<HTMLInputElement>) {
    const tempConfirmPassword = e.target.value;
    updateConfirmPasswordError(doesPasswordsMatchByLength(newPassword, tempConfirmPassword));
    setConfirmPassword(tempConfirmPassword);
  }

  function updateConfirmPasswordError(identical: boolean) {
    setConfirmPasswordError(identical ? '' : t('PasswordsNotMatch'));
    setConfirmPasswordErrorLatchFlag(identical || confirmPasswordErrorLatchFlag);
  }

  function onSubmit() {
    setChangePasswordInProgress(true);
    if (isFormValid) {
      const changePasswordData = {
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
        VerifyNewPassword: confirmPassword,
      };

      setSuccess(false);
      changePassword(changePasswordData)
        .then(() => {
          setServerSideValidationErrors([]);
          setSuccess(true);
        })
        .catch((error: ChangePasswordErrorResponse) => {
          if (error?.response?.data?.validations['0']) {
            setServerSideValidationErrors(error.response.data.validations[0]);
          }
        })
        .finally(() => {
          setFirstAttempt(false);
          setChangePasswordInProgress(false);
        });
    }
  }

  return (
    <div
      className="modal-module__container___sfedc modal__container"
      role="dialog"
      aria-label={t('EditProfile')}
      aria-modal="true"
    >
      <div className="modal-module__blocker___231AR modal__blocker" />
      <div className="modal-module__modal_container___3tUB2 modal__modal_container modal-module__modal_container_top___3NfiM modal__modal_container_top">
        <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
          <button
            type="button"
            className="icon-button-module__btn___Zb89b modal-module__close_btn___1yWvZ modal__close_btn"
            title={t('CloseThisWindow')}
            onClick={closeModal}
          >
            <div className="icon icon-x-thin" />
            <span className="accessibility-text-module__off_screen_text___FSqhy">
              {t('Close')} {t('UpdatePassword')}
            </span>
          </button>
          <div className="modal-module__modal___11QTd modal__modal modal-module__modal_small_size___1dAye modal-module__modal_top_position___Yxl4d modal-module__modal_auto_height___2feYu">
            <div className="edit-profile-module__wrapper___3DPOs">
              <div className="profile-form-module__profile_form_container___24Wwl">
                <form className="profile-form-module__form___1wsMc">
                  <h1 className="profile-form-module__title___tAoGd profile-form__title">{t('UpdatePassword')}</h1>
                  {serverSideValidationErrors?.length > 0 && (
                    <div aria-live="assertive">
                      <div className="form-info-panel-module__error_messages___3YBAs">
                        {serverSideValidationErrors.map((error: ChangePasswordValidationErrors) => (
                          <p
                            key={error.field}
                            className="form-info-panel-module__error_message___3XNfo form__error_message"
                          >
                            {error.message}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}

                  {success ? (
                    <div aria-live="assertive">
                      <div className="form-info-panel-module__success_message___32u7j">
                        {t('PasswordHasBeenSuccessfullyChanged')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="profile-form-module__field_group___2IOMB profile-form__field_group">
                        <div className="profile-form-module__fields___3z5zs profile-form__fields">
                          <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                            <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                              <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                                {t('EditLearnerCurrentPassword')}
                              </label>
                              <div
                                className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                                aria-hidden="true"
                              >
                                <span className="accessibility-text-module__off_screen_text___FSqhy">
                                  {t('Required')}
                                </span>
                                <span aria-hidden="true">{t('Required')}</span>
                              </div>
                            </div>
                            <input name="username" id="hiddenusername" type="text" autoComplete="username" />
                            <input
                              name="password"
                              id="reduxFormInputField34"
                              type="password"
                              step="any"
                              aria-required="true"
                              aria-describedby="errorDiv35"
                              value={currentPassword}
                              onChange={(e) => setCurrentPassword(e.target.value)}
                              autoComplete="current-password"
                            />
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv67" />
                          </div>
                          <div className="redux-form-input-module__field___LP6sO password-form-module__field___2xmt0">
                            <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                              <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                                {t('EditLearnerNewPassword')}
                              </label>
                              <div
                                className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                                aria-hidden="true"
                              >
                                <span className="accessibility-text-module__off_screen_text___FSqhy">
                                  {t('Required')}
                                </span>
                                <span aria-hidden="true">{t('Required')}</span>
                              </div>
                            </div>
                            <input
                              name="newPassword"
                              id="reduxFormInputField36"
                              type="password"
                              step="any"
                              aria-required="true"
                              aria-describedby="errorDiv37"
                              value={newPassword}
                              onBlur={validateNewPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              autoComplete="new-password"
                            />
                            {newPasswordError && (
                              <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv37">
                                <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                                  {newPasswordError}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                            <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                              <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                                {t('EditLearnerVerifyPassword')}
                              </label>
                              <div
                                className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                                aria-hidden="true"
                              >
                                <span className="accessibility-text-module__off_screen_text___FSqhy">
                                  {t('Required')}
                                </span>
                                <span aria-hidden="true">{t('Required')}</span>
                              </div>
                            </div>
                            <input
                              name="verifyPassword"
                              id="reduxFormInputField38"
                              type="password"
                              step="any"
                              aria-required="true"
                              aria-describedby="errorDiv39"
                              value={confirmPassword}
                              onChange={onChangeConfirmPassword}
                              autoComplete="new-password"
                            />
                            {confirmPasswordError && (
                              <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv39">
                                <span className="redux-form-input-field-module__error_message___196Wq form-field__error_message">
                                  {confirmPasswordError}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="password-form-module__btn_group___2DC9A">
                        {!success && (
                          <button
                            type="button"
                            className="btn password-form-module__save_btn___2xkUk password-form__save button-module__btn___1lXcC"
                            title={t('Save')}
                            aria-label={t('Save')}
                            disabled={!isFormValid}
                            onClick={onSubmit}
                          >
                            {changePasswordInProgress ? <SpinnerButton /> : t('Save')}
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn password-form-module__cancel_btn___2x-G0 password-form__cancel button-module__btn___1lXcC btn__cancel"
                          title={t('Cancel')}
                          aria-label={t('Cancel')}
                          onClick={closeModal}
                        >
                          {t('Cancel')}
                        </button>
                      </div>
                    </>
                  )}
                </form>
                <div id="editProfileSubmitButtonDescribedById65" aria-live="assertive" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
