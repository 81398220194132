import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  currentItemsCount: number;
  totalItemsCount: number;
  itemsAreLoading: boolean;
  fetchMoreFunction: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export function LoadMoreActionButton({
  currentItemsCount,
  totalItemsCount,
  itemsAreLoading,
  fetchMoreFunction,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      disabled={itemsAreLoading}
      title={t('LoadMore')}
      className="page-size-button-module__btn___1qGR- page-size-button__btn"
      onClick={() => {
        fetchMoreFunction();
      }}
      aria-label={`${t('LoadMore')} ${currentItemsCount}/${totalItemsCount}`}
      aria-describedby="pageSizeButton6"
    >
      {itemsAreLoading ? (
        <div className="page-size-button-module__loader_container___b-j91">
          <div className="page-size-button-module__loader___7NrhS" />
        </div>
      ) : (
        <>
          {t('LoadMore')}
          <span className="page-size-button-module__size___1fke7">
            {currentItemsCount}/{totalItemsCount}
          </span>
        </>
      )}
    </button>
  );
}
