import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFilterFlags } from '../../models/absorb/search';
import { CheckBox } from './CheckBox';
import { CourseFilterCheckBoxesList } from './CourseFilterCheckBoxesList';
import { useSearchContext } from '../../contexts/searchContext';
import { setAllEntityFlags } from '../../utils/helper';
import { isOnGlobalSearchPage } from '../../constants/common';

export function CourseFilterCheckBoxes(): ReactElement {
  const { t } = useTranslation();

  const { courseEntities, setCourseEntities } = useSearchContext();

  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setAllChecked(isEveryValueTrue(courseEntities));
  }, [courseEntities]); // eslint-disable-line react-hooks/exhaustive-deps

  function toggleCheckBoxes() {
    setCourseEntities(setAllEntityFlags(courseEntities, !allChecked));
  }

  function update(filterFlags: SearchFilterFlags) {
    setCourseEntities(filterFlags);
  }

  function isEveryValueTrue(flags: SearchFilterFlags): boolean {
    return Object.values(flags).every((flag) => flag);
  }

  const checkboxes = <CourseFilterCheckBoxesList courseFilter={courseEntities} update={update} />;

  return isOnGlobalSearchPage() ? (
    <>
      <li>
        <CheckBox
          label={t('Courses')}
          value="Courses"
          isDynamicChecked
          isChecked={allChecked}
          handleCheckbox={toggleCheckBoxes}
        />
      </li>
      <ul className="search-results-type-filter-module__courses___3rwNc">{checkboxes}</ul>
    </>
  ) : (
    checkboxes
  );
}
