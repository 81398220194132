import React, { ReactElement } from 'react';
import './CourseRatingStar.css';

interface Props {
  illuminated: boolean;
  isHalf: boolean;
  disabled?: boolean;
}

export function CourseRatingStar({ illuminated, isHalf, disabled }: Props): ReactElement {
  return (
    <div role="listitem" className="rating-module__star___3Uuus ratings__star">
      <label
        className={`rating-module__star_bg___15itl icon icon-star ratings__star_bg ${disabled ? '' : 'rating-cursor'}`}
        aria-hidden="true"
      />
      {illuminated &&
        (isHalf ? (
          <span
            className="rating-module__star_fg___2gHWO icon icon-star-half ratings__star_fg ratings__star_half"
            aria-hidden="true"
          />
        ) : (
          <span className="rating-module__star_fg___2gHWO icon icon-star ratings__star_fg" aria-hidden="true" />
        ))}
    </div>
  );
}
