import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  clearCourseRating: () => void;
}

export function CourseRatingClearButton({ clearCourseRating }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <label className="course-rating-filter-module__clear_link___34qkF course-rating-filter__clear-link">
      <button
        type="button"
        className="icon-button-module__btn___Zb89b course-rating-filter-module__clear_button___35B4l filter-panel__remove_button"
        title={t('ClearRating')}
        onClick={clearCourseRating}
      >
        <div className="icon icon-x-circle" />
        <span className="accessibility-text-module__off_screen_text___FSqhy"> {t('ClearRating')} </span>
      </button>
      {t('ClearRating')}
    </label>
  );
}
