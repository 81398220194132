import React, { ReactElement, useEffect, useState } from 'react';
import { fetchBranding, fetchBrandingWithAuth, fetchDepartmentTheme } from '../services/branding';
import { BrandingData, CoursesSettingsData, FooterData, LoginPageSettingsData } from '../models/absorb/branding';
import { DEFAULT_LANGUAGE, LocalStorageItem } from '../constants/common';
import { errorHandler } from '../utils/helper';
import { ContextProps, createContext } from '../utils/contextHelper';
import { useLoginContext } from './loginContext';

interface BrandingContextState {
  logoImageUrl?: string;
  loginPageSettings?: LoginPageSettingsData;
  brandingCoursesSettings?: CoursesSettingsData;
  footer?: FooterData;
  theme: string;
  isLoading: boolean;
  getTheme: () => void;
}

const [Provider, useBrandingContext] = createContext<BrandingContextState>(module.filename);

export { useBrandingContext };

export function BrandingProvider({ children }: ContextProps): ReactElement {
  const { loggedIn } = useLoginContext();

  const [branding, setBranding] = useState<BrandingData>();
  const [theme, setTheme] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (loggedIn) {
      // branding response is different with auth vs without
      getBrandingAfterLogin();
    } else {
      clearBrandingDataFromStorage();
      clearDepartmentThemeData();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!loggedIn && !isLoading) {
      getBrandingOnStartUp();
    }
  }, [loggedIn, isLoading]);

  async function getBrandingOnStartUp() {
    try {
      setIsLoading(true);
      let data = getBrandingFromStorage();

      if (!data) {
        data = await fetchBranding(localStorage.getItem(LocalStorageItem.LanguageCode) || DEFAULT_LANGUAGE);
        localStorage.setItem(LocalStorageItem.BrandingData, JSON.stringify(data));
      }

      setBranding(data);
    } catch (e) {
      errorHandler(String(e));
    } finally {
      setIsLoading(false);
    }
  }

  async function getBrandingAfterLogin() {
    try {
      const data = await fetchBrandingWithAuth(localStorage.getItem(LocalStorageItem.LanguageCode) || DEFAULT_LANGUAGE);

      setBranding(data);
    } catch (e) {
      errorHandler(String(e));
    }
  }

  async function getTheme() {
    try {
      setIsLoading(true);
      let data = getDepartmentThemeFromStorage();

      if (!data) {
        data = await fetchDepartmentTheme();
        localStorage.setItem(LocalStorageItem.DepartmentThemeData, data);
      }

      setTheme(data);
    } catch (e) {
      errorHandler(String(e));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Provider
      value={{
        logoImageUrl: branding?.header?.logoImageUri,
        loginPageSettings: branding?.loginPageSettings,
        brandingCoursesSettings: branding?.coursesSettings,
        footer: branding?.footer,
        theme,
        getTheme,
        isLoading,
      }}
    >
      {children}
    </Provider>
  );
}

function getBrandingFromStorage() {
  const brandingData = localStorage.getItem(LocalStorageItem.BrandingData);
  return brandingData ? JSON.parse(brandingData) : null;
}

function clearBrandingDataFromStorage(): void {
  localStorage.removeItem(LocalStorageItem.BrandingData);
}

function getDepartmentThemeFromStorage() {
  return localStorage.getItem(LocalStorageItem.DepartmentThemeData) || null;
}

function clearDepartmentThemeData(): void {
  localStorage.removeItem(LocalStorageItem.DepartmentThemeData);
}
