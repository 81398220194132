import React, { ReactElement, RefObject, createRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { CatalogCourseData } from '../models/absorb/catalog';
import { CourseCard } from './CourseCard';
import 'react-multi-carousel/lib/styles.css';
import { RESPONSIVE_COURSE_RIBBON } from '../constants/courses';
import './CourseRibbon.css';

interface Props {
  courseData: CatalogCourseData[];
  linkTitle: string;
  link: string;
}

export function CourseRibbon({ courseData, linkTitle, link }: Props): ReactElement {
  const { t } = useTranslation();

  const [numVisibleCards, setNumVisibleCards] = useState(0);
  const [courses, setCourses] = useState<CatalogCourseData[]>(courseData);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function updatePinnedStatus(isPinned: boolean, courseId: string) {
    const coursesToUpdate = [...courses];
    const index = coursesToUpdate.findIndex((course) => course.id === courseId);
    if (index > -1) {
      coursesToUpdate[index].isPinned = isPinned;
      setCourses(coursesToUpdate);
    }
  }

  const carousel: RefObject<Carousel> = createRef();

  function handleResize() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= RESPONSIVE_COURSE_RIBBON.superLargeDesktop.breakpoint.min) {
      setNumVisibleCards(7);
    } else if (
      screenWidth >= RESPONSIVE_COURSE_RIBBON.largeDesktop.breakpoint.min &&
      screenWidth < RESPONSIVE_COURSE_RIBBON.largeDesktop.breakpoint.max
    ) {
      setNumVisibleCards(6);
    } else if (
      screenWidth >= RESPONSIVE_COURSE_RIBBON.desktop.breakpoint.min &&
      screenWidth < RESPONSIVE_COURSE_RIBBON.desktop.breakpoint.max
    ) {
      setNumVisibleCards(5);
    } else if (
      screenWidth >= RESPONSIVE_COURSE_RIBBON.smallWindow.breakpoint.min &&
      screenWidth < RESPONSIVE_COURSE_RIBBON.smallWindow.breakpoint.max
    ) {
      setNumVisibleCards(4);
    } else if (
      screenWidth >= RESPONSIVE_COURSE_RIBBON.tablet.breakpoint.min &&
      screenWidth < RESPONSIVE_COURSE_RIBBON.tablet.breakpoint.max
    ) {
      setNumVisibleCards(3);
    } else if (
      screenWidth >= RESPONSIVE_COURSE_RIBBON.largeMobile.breakpoint.min &&
      screenWidth < RESPONSIVE_COURSE_RIBBON.largeMobile.breakpoint.max
    ) {
      setNumVisibleCards(2);
    } else {
      setNumVisibleCards(1);
    }
  }

  return (
    <div className="carousel__carousel">
      <h2 className="carousel__title">
        <Link className="card-carousel-module__title___1oC4_" aria-label={t(linkTitle)} to={link}>
          {t(linkTitle)}
        </Link>
      </h2>
      <div className="card-carousel-module__track_container___2yMYV">
        <div className="card-carousel-module__track___tKeze">
          <div className="card-carousel-module__slider___CD_iL" />
          <Carousel
            ref={carousel}
            responsive={RESPONSIVE_COURSE_RIBBON}
            renderButtonGroupOutside
            slidesToSlide={numVisibleCards}
            showDots
          >
            {courses &&
              courses.map((course: CatalogCourseData) => (
                <div key={course.id} className="card-carousel-module__card___vOE4F carousel__card">
                  <CourseCard cardData={course} updatePinnedStatus={updatePinnedStatus} />
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
