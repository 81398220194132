import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PosterImageVariables } from '../constants/common';
import './PosterImage.css';

interface Props {
  posterImageUrl: string;
}

export function PosterImage({ posterImageUrl }: Props): ReactElement {
  const { t } = useTranslation();
  const {
    Percentage,
    TabletBreakPoint,
    MobileViewPadding,
    DesktopViewPadding,
    DivisionRatio,
    MaxHeight,
    MinHeight,
    MaxWidth,
    MinWidth,
  } = PosterImageVariables;

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleResize() {
    const posterImgContainer = document.querySelector<HTMLElement>('.online-course-module__content_left___3cqlT');
    const img = document.querySelector<HTMLElement>('.custom-poster');
    const screenWidth = window.innerWidth;

    if (posterImgContainer && img) {
      const padding = screenWidth <= TabletBreakPoint ? MobileViewPadding : DesktopViewPadding;
      const calculatedImageWidth = (posterImgContainer.clientWidth - padding) * Percentage;
      const calculatedImageHeight = calculatedImageWidth / DivisionRatio;

      if (calculatedImageWidth > MaxWidth) {
        img.style.width = `${MaxWidth}px`;
        img.style.minWidth = `${MaxWidth}px`;
      } else if (calculatedImageWidth <= MinWidth) {
        img.style.width = `${MinWidth}px`;
        img.style.minWidth = `${MinWidth}px`;
      } else {
        img.style.width = `${calculatedImageWidth}px`;
        img.style.minWidth = `${calculatedImageWidth}px`;
      }

      if (calculatedImageHeight > MaxHeight) {
        img.style.height = `${MaxHeight}px`;
        img.style.minHeight = `${MaxHeight}px`;
      } else if (calculatedImageHeight <= MinHeight) {
        img.style.height = `${MinHeight}px`;
        img.style.minHeight = `${MinHeight}px`;
      } else {
        img.style.height = `${calculatedImageHeight}px`;
        img.style.minHeight = `${calculatedImageHeight}px`;
      }
    }
  }

  return (
    <div className="image-carousel-module__container___3Qma9 image-carousel__container" aria-hidden="true">
      <div className="image-carousel-module__carousel___3lB5V image-carousel__carousel">
        <div className="image-carousel-module__images_track___YpVBj image-carousel__track">
          <div className="image-carousel-module__images_slider___2nH1R">
            <img
              src={posterImageUrl}
              alt={t('Image')}
              className="image-carousel-module__poster_image___uVEIF custom-poster"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
