import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import { DashboardLayout } from '../components/DashboardLayout';
import { LoadingIndicator } from '../components/Spinner';
import { useProfileContext } from '../contexts/profileContext';
import { TranscriptData, TranscriptResponseData } from '../models/absorb/transcripts';
import { fetchMyEnrollments } from '../services/course';
import { getTimeShortDate, getTimeSeconds, errorHandler, getTimeZone, getTimeZoneAbbreviation } from '../utils/helper';
import { PRINT_TRANSCRIPT_LIMIT, SortBy } from '../constants/common';
import './PrintTranscriptsPage.css';
import { TranscriptAssessments } from '../components/TranscriptAssessments';
import { PageTitle } from '../components/PageTitle';

export function PrintTranscriptsPage(): ReactElement {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const [enrollmentData, setEnrollmentData] = useState<TranscriptData[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const sortBy = SortBy.CourseName;
  const ascendingOrder = true;
  const componentRef = useRef(null);

  const getEnrollments = (pageOffset: number) => {
    const sort = ascendingOrder ? sortBy : `-${sortBy}`;
    fetchMyEnrollments(PRINT_TRANSCRIPT_LIMIT, sort, pageOffset)
      .then((response: TranscriptResponseData) => {
        if (response) {
          setIsLoading(false);
          setTotalItems(response.totalItems);
          setEnrollmentData(offset > 0 ? [...enrollmentData, ...response.enrollments] : response.enrollments);
        }
      })
      .catch(errorHandler);
  };

  useEffect(() => {
    if (enrollmentData.length > 0 && enrollmentData.length < totalItems) {
      setOffset(offset + 1);
      getEnrollments(offset);
    }
  }, [enrollmentData, totalItems]); //  eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    getEnrollments(offset);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getHeading() {
    let headerText = t('TranscriptFor');
    if (headerText.includes('{1}') && headerText.includes('{2}')) {
      headerText = headerText.replace('{1}', profile ? profile.firstName : '');
      headerText = headerText.replace('{2}', profile ? profile.lastName : '');
    } else {
      headerText = `Transcript For ${profile?.firstName} ${profile?.lastName}`;
    }
    return headerText;
  }

  function getTimesDisplayTranslation(): string {
    let timesText = t('TimesDisplayedIn');

    if (timesText.includes('{0}')) {
      timesText = timesText.replace('{0}', '');
    }

    return timesText;
  }

  return (
    <DashboardLayout>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div ref={componentRef}>
          <PageTitle title={t('PrintTranscript')} />
          <div role="main" className="app-module__content___-i9XR app__content">
            <div className="print-transcript-module__transcript___30wMN">
              <div className="print-transcript-module__transcript_header___1OhCl">
                <h1 className="print-transcript-module__user_name___2G3o7 print-transcript__user_name">
                  {getHeading()}
                </h1>
                <div className="main-container-share print-transcript">
                  {enrollmentData && (
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <Link
                            title={t('Print')}
                            className="link-button-module__link___3tT4y transcript-module__print_btn___1YpoI banner__btn btn"
                            to="/print-transcript"
                          >
                            {t('Print')}
                          </Link>
                        );
                      }}
                      content={() => componentRef.current}
                    />
                  )}
                </div>
              </div>
              <div className="print-transcript-module__profile___3jxkp print-transcript__profile">
                <div className="print-transcript-module__avatar_container___1JYkq print-transcript__avatar_container">
                  {profile?.avatar ? (
                    <img
                      className="my-profile-module__avatar___1KG5b my-profile-module__avatar_placeholder___pKWYk my-profile__avatar my-profile__avatar_placeholder"
                      src={profile ? profile.avatar : undefined}
                      alt="profile"
                    />
                  ) : (
                    <div className="icon icon-man print-transcript-module__avatar___18BvS print-transcript-module__avatar_placeholder___1xO8x print-transcript__avatar print-transcript__avatar_placeholder" />
                  )}
                </div>
                <div>
                  <div className="print-transcript-module__profile_field___2a9ZR print-transcript__profile_field print-transcript__profile_field_email">
                    <div className="icon icon-at print-transcript-module__profile_field_icon___de8uy print-transcript-module__profile_field_icon_email___24qLa print-transcript__profile_field_icon" />
                    <div className="print-transcript__profile_field_title">{t('EmailAddress')}:</div>
                    <div className="print-transcript-module__profile_field_value___1_suE print-transcript__profile_field_value">
                      {profile && profile.emailAddress}
                    </div>
                  </div>
                  <div className="print-transcript-module__profile_field___2a9ZR print-transcript__profile_field print-transcript__profile_field_department">
                    <div className="icon icon-departments print-transcript-module__profile_field_icon___de8uy print-transcript-module__profile_field_icon_department___odl9i print-transcript__profile_field_icon" />
                    <div className="print-transcript__profile_field_title">{t('Department')}:</div>
                    <div className="print-transcript-module__profile_field_value___1_suE print-transcript__profile_field_value">
                      {profile && profile.departmentName}
                    </div>
                  </div>
                  <div className="print-transcript-module__profile_field___2a9ZR print-transcript__profile_field print-transcript__profile_field_credits">
                    <div className="icon icon-hat print-transcript-module__profile_field_icon___de8uy print-transcript-module__profile_field_icon_credits___3j6Lm print-transcript__profile_field_icon" />
                    <div className="print-transcript__profile_field_title">{t('Credits')}:</div>
                    <div className="print-transcript-module__profile_field_value___1_suE print-transcript__profile_field_value">
                      {profile && profile.totalEducationalCredits}
                    </div>
                  </div>
                </div>
              </div>

              {enrollmentData.length > 0 && (
                <div className="print-transcript-module__enrollments___1jvwB print-transcript__enrollments">
                  {profile && <TranscriptAssessments email={profile.emailAddress} />}
                  <div className="print-transcript-module__header_container___2VSij">
                    <h2 className="print-transcript-module__header___2TLdB print-transcript__header">{t('Courses')}</h2>
                  </div>
                  <div
                    className="sortable-table-module__table___2epWu sortable-table__table print-transcript-module__table___3hfgL"
                    role="table"
                  >
                    <div role="rowgroup">
                      <div
                        className="sortable-table-module__table_row_header___hu7Ms sortable-table__table_row_header"
                        role="row"
                      >
                        <div
                          className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item print-transcript-module__enrollments_name_col___HE5u9"
                          role="columnheader"
                        >
                          <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                            {t('CourseTitle')}
                          </span>
                        </div>
                        <div
                          className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item print-transcript-module__enrollments_status_col___rtLIi"
                          role="columnheader"
                        >
                          <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                            {t('Status')}
                          </span>
                        </div>
                        <div
                          className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item print-transcript-module__enrollments_score_col___nLvGu"
                          role="columnheader"
                        >
                          <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                            {t('Score')}
                          </span>
                        </div>
                        <div
                          className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item print-transcript-module__enrollments_enrollement_date_col___3_eBv"
                          role="columnheader"
                        >
                          <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                            {t('EnrollmentDate')}
                          </span>
                        </div>
                        <div
                          className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item print-transcript-module__enrollments_completion_date_col___2ZHZL"
                          role="columnheader"
                        >
                          <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                            {t('CompletionDate')}
                          </span>
                        </div>
                        <div
                          className="sortable-table-module__table_row_header_item___2fWF6 sortable-table__table_row_header_item print-transcript-module__enrollments_credits_col___3hO35"
                          role="columnheader"
                        >
                          <span className="sortable-table-column-header-module__table_row_header_item_text___3Hx09">
                            {t('Credits')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div role="rowgroup">
                      {enrollmentData &&
                        enrollmentData.map((enrollment: TranscriptData) => (
                          <div key={enrollment.id} className="sortable-table-module__table_row_container___8gbRc">
                            <div
                              className="sortable-table-module__table_row___3N1yN sortable-table__table_row print-transcript__row"
                              role="row"
                            >
                              <div
                                className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item print-transcript-module__enrollments_name_col___HE5u9"
                                role="cell"
                              >
                                <div>
                                  <span className="print-transcript-module__cell_header___1k82T">
                                    {t('CourseTitle')}:
                                  </span>
                                  {enrollment.courseName}
                                </div>
                              </div>
                              <div
                                className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item print-transcript-module__enrollments_status_col___rtLIi"
                                role="cell"
                              >
                                <div>
                                  <span className="print-transcript-module__cell_header___1k82T">{t('Status')}:</span>
                                  {t(enrollment.enrollmentStatus)}
                                </div>
                              </div>
                              <div
                                className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item print-transcript-module__enrollments_score_col___nLvGu"
                                role="cell"
                              >
                                {enrollment.examScore ? (
                                  <div>
                                    <span className="print-transcript-module__cell_header___1k82T">{t('Score')}:</span>
                                    {`${enrollment.examScore}%`}
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item print-transcript-module__enrollments_enrollement_date_col___3_eBv"
                                role="cell"
                              >
                                <div>
                                  <span className="print-transcript-module__cell_header___1k82T">
                                    {t('EnrollmentDate')}:
                                  </span>
                                  <span className="print-transcript-module__datetime___35BKb">
                                    {getTimeShortDate(enrollment.enrollmentDate)}
                                  </span>
                                  <span className="print-transcript-module__datetime___35BKb">
                                    {getTimeSeconds(enrollment.enrollmentDate)}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item print-transcript-module__enrollments_completion_date_col___2ZHZL"
                                role="cell"
                              >
                                {enrollment.completionDate && (
                                  <div>
                                    <span className="print-transcript-module__cell_header___1k82T">
                                      {t('CompletionDate')}:
                                    </span>
                                    <span className="print-transcript-module__datetime___35BKb">
                                      {getTimeShortDate(enrollment.completionDate)}
                                    </span>
                                    <span className="print-transcript-module__datetime___35BKb">
                                      {getTimeSeconds(enrollment.completionDate)}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div
                                className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item print-transcript-module__enrollments_credits_col___3hO35"
                                role="cell"
                              >
                                {enrollment.educationalCredits && (
                                  <div>
                                    <span className="print-transcript-module__cell_header___1k82T">
                                      {t('Credits')}:
                                    </span>
                                    {enrollment.educationalCredits}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <span className="print-transcript-module__time_zone___yJiuE">
                    {getTimesDisplayTranslation()} (UTC{getTimeZone()}) {getTimeZoneAbbreviation()} (
                    {Intl.DateTimeFormat().resolvedOptions().timeZone})
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}
