import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DashboardLayout } from './DashboardLayout';
import { PageTitle } from './PageTitle';

export function NotFound(): ReactElement {
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <PageTitle />
      <div role="main" className="app-module__content___-i9XR app__content">
        <div className="not-found-module__container___3fx3H">
          <h1 className="not-found-module__header___sFQSD">{`${t('Page')}${t('NotFound').replace('{0}', '')}`}</h1>
          <div className="not-found-module__description___31diT">{t('NotFoundDescription')}</div>
          <Link className="not-found-module__link___1v3VO" to="/dashboard">
            {t('ReturnToMyDashboard')}
          </Link>
          <div className="icon icon-unplugged not-found-module__icon___2nbEa" />
        </div>
      </div>
    </DashboardLayout>
  );
}
