import { MessageReadData, MessageResponse } from '../models/absorb/message';
import { apiRequest } from '../utils/axios';

export async function fetchMessages(priority: boolean, offset?: number): Promise<MessageResponse> {
  return apiRequest('GET', `/my-messages/?highPriority=${priority}&_offset=${offset}`);
}

export async function fetchHeaderMessages(priority: boolean): Promise<MessageResponse> {
  return apiRequest('GET', `/my-messages/?_limit=5&read=false&highPriority=${priority}`);
}

export async function fetchMessageCount(): Promise<number> {
  return apiRequest('GET', `/my-messages/count`).then((data) => data.priorityMessageCount);
}

export async function updateReadMessage(payload: MessageReadData, id: string): Promise<boolean> {
  return apiRequest('PUT', `/my-messages/${id}/read/`, payload).then((data) => data.read);
}

export async function updateReadAllMessage(): Promise<void> {
  return apiRequest('POST', `/my-messages/read`);
}
