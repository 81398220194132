import React, { ReactElement } from 'react';
import { CurriculumProgressDetailData, CurriculumProgressDetailsData } from '../models/absorb/course';
import { CurriculumStatusListItem } from './CurriculumStatusListItem';

interface Props {
  curriculumProgressDetails: CurriculumProgressDetailsData;
}

export function CourseCurriculumStatus({ curriculumProgressDetails }: Props): ReactElement {
  return (
    <div className="course-alerts-module__banners___1PhwJ" tabIndex={-1}>
      <div className="course-curricula-status-module__curricula_progress_list___90dJ4">
        {curriculumProgressDetails.curriculaProgressDetails
          .sort((firstCurriculumCourse, secondCurriculumCourse) =>
            firstCurriculumCourse.coursesCompleted < secondCurriculumCourse.coursesCompleted ? 1 : -1
          )
          .map((curriculumProgressDetail: CurriculumProgressDetailData) => (
            <CurriculumStatusListItem
              key={curriculumProgressDetail.curriculumId}
              curriculumProgressDetail={curriculumProgressDetail}
            />
          ))}
      </div>
    </div>
  );
}
