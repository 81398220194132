import React, { ReactElement } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LoginProvider } from './contexts/loginContext';
import { ProfileProvider } from './contexts/profileContext';
import { BrandingProvider } from './contexts/brandingContext';
import { LanguageProvider } from './contexts/languageContext';
import { MessagesProvider } from './contexts/messagesContext';
import { ShoppingProvider } from './contexts/shoppingContext';
import { LoginPage } from './pages/LoginPage';
import { SignUpPage } from './pages/SignUpPage';
import { DashboardPage } from './pages/DashboardPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { UserProfilePage } from './pages/UserProfilePage';
import { TranscriptsPage } from './pages/TranscriptsPage';
import { CourseDetailsPage } from './pages/CourseDetailsPage';
import { MyCoursesPage } from './pages/MyCoursesPage';
import { CatalogPage } from './pages/CatalogPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { GlobalSearchPage } from './pages/GlobalSearchPage';
import { ResourcesPage } from './pages/ResourcesPage';
import { PrintTranscriptsPage } from './pages/PrintTranscriptsPage';
import { PrivateRoute } from './components/PrivateRoute';
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage';
import { FaqsPage } from './pages/FaqsPage';
import { CurriculumPage } from './pages/CurriculumPage';
import { InstructorLedPage } from './pages/InstructorLedPage';
import { ModalProvider } from './contexts/modalContext';
import { SearchProvider } from './contexts/searchContext';
import { ContentViewProvider } from './contexts/contentViewContext';
import { NotFound } from './components/NotFound';
import { CartPage } from './pages/CartPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { PaymentPage } from './pages/PaymentPage';

function App(): ReactElement {
  return (
    <Router>
      <LanguageProvider>
        <I18nextProvider i18n={i18next}>
          <LoginProvider>
            <ProfileProvider>
              <BrandingProvider>
                <MessagesProvider>
                  <ShoppingProvider>
                    <ModalProvider>
                      <SearchProvider>
                        <ContentViewProvider>
                          <Routes>
                            <Route path="/" element={<LoginPage />} />
                            <Route path="/signup" element={<SignUpPage />} />
                            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                            <Route path="/reset-password/:urlToken" element={<ResetPasswordPage />} />
                            <Route path="/terms-and-conditions" element={<PrivateRoute />}>
                              <Route index element={<TermsAndConditionsPage />} />
                            </Route>
                            <Route path="/dashboard" element={<PrivateRoute />}>
                              <Route index element={<DashboardPage />} />
                            </Route>
                            <Route path="/profile" element={<PrivateRoute />}>
                              <Route index element={<UserProfilePage />} />
                            </Route>
                            <Route path="/transcript" element={<PrivateRoute />}>
                              <Route index element={<TranscriptsPage />} />
                            </Route>
                            <Route path="/course/:courseId" element={<PrivateRoute />}>
                              <Route index element={<CourseDetailsPage />} />
                            </Route>
                            <Route path="/curriculum/:courseId" element={<PrivateRoute />}>
                              <Route index element={<CurriculumPage />} />
                            </Route>
                            <Route path="/instructor-led/:courseId" element={<PrivateRoute />}>
                              <Route index element={<InstructorLedPage />} />
                            </Route>
                            <Route path="/my-courses/:categoryId?" element={<PrivateRoute />}>
                              <Route index element={<MyCoursesPage />} />
                            </Route>
                            <Route path="/faqs" element={<PrivateRoute />}>
                              <Route index element={<FaqsPage />} />
                            </Route>
                            <Route path="/catalog/:categoryId?" element={<PrivateRoute />}>
                              <Route index element={<CatalogPage />} />
                            </Route>
                            <Route path="/global-search" element={<PrivateRoute />}>
                              <Route index element={<GlobalSearchPage />} />
                            </Route>
                            <Route path="/print-transcript" element={<PrivateRoute />}>
                              <Route index element={<PrintTranscriptsPage />} />
                            </Route>
                            <Route path="/resources/:categoryId?" element={<PrivateRoute />}>
                              <Route index element={<ResourcesPage />} />
                            </Route>
                            <Route path="/cart" element={<PrivateRoute />}>
                              <Route index element={<CartPage />} />
                            </Route>
                            <Route path="/cart/checkout" element={<PrivateRoute />}>
                              <Route index element={<CheckoutPage />} />
                            </Route>
                            <Route path="/cart/payment" element={<PrivateRoute />}>
                              <Route index element={<PaymentPage />} />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                          </Routes>
                        </ContentViewProvider>
                      </SearchProvider>
                    </ModalProvider>
                  </ShoppingProvider>
                </MessagesProvider>
              </BrandingProvider>
            </ProfileProvider>
          </LoginProvider>
        </I18nextProvider>
      </LanguageProvider>
    </Router>
  );
}

export default App;
