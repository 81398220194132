import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ShoppingCartPreviousStepButton.css';

interface Props {
  goBack?: boolean;
  goToPrevStep?: () => void;
}

export function ShoppingCartPreviousStepButton({ goBack = false, goToPrevStep }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={`cart-review-module__back___1c4Gr ${goBack ? 'padding-left-zero height-40' : ''}`}>
      {goBack && goToPrevStep ? (
        <span className="cart-review-module__back_btn___1Ymzu cart-review__back_btn link-style " onClick={goToPrevStep}>
          <div className="cart-review-module__back_icon___1ekQo icon icon-arrow-left" />
          {t('Previous')}
        </span>
      ) : (
        <Link className="cart-review-module__back_btn___1Ymzu cart-review__back_btn" to="/catalog">
          <div className="cart-review-module__back_icon___1ekQo icon icon-arrow-left" />
          {t('ContinueShopping')}
        </Link>
      )}
    </div>
  );
}
