import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSearchContext } from '../contexts/searchContext';
import { CategoriesData } from '../models/absorb/catalog';
import './Breadcrumb.css';

interface Props {
  breadcrumbTitle: string;
  selectedCategoryData: CategoriesData;
  categoryId: string;
}

export function Breadcrumb({ breadcrumbTitle, selectedCategoryData, categoryId }: Props): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { searchFilter, previousSearchUri, setCategory, resetPageOffset } = useSearchContext();

  function onBreadcrumbClick() {
    setCategory('');
    resetPageOffset();
    navigate(previousSearchUri);
  }

  return (
    <nav aria-label="Breadcrumb" className="breadcrumb-module__breadcrumbs___2Xnm2 catalog-module__breadcrumbs___1CIuh">
      {searchFilter.showCategories && categoryId && (
        <>
          <div className="breadcrumb-module__breadcrumb___2dFes breadcrumb__breadcrumb">
            <div className="breadcrumb-module__link___EBZ_J breadcrumb__link" onClick={() => onBreadcrumbClick()}>
              <span
                title={t(breadcrumbTitle)}
                className="breadcrumb-module__title___3xMPt breadcrumb-module__title_link___2VKck breadcrumb__title"
              >
                {t(breadcrumbTitle)}
              </span>
            </div>
          </div>
          <div className="breadcrumb-module__breadcrumb___2dFes breadcrumb__breadcrumb">
            <span aria-current="page" className="breadcrumb-module__link___EBZ_J">
              <span className="icon icon-breadcrumb breadcrumb-module__icon___3JHpN breadcrumb__icon" />
              <span title={selectedCategoryData.name} className="breadcrumb-module__title___3xMPt breadcrumb__title">
                {selectedCategoryData.name}
              </span>
            </span>
          </div>
        </>
      )}
    </nav>
  );
}
