import React, { ReactElement, useEffect } from 'react';
import Iframe from 'react-iframe';
import { useTranslation } from 'react-i18next';
import { MyLessonData } from '../models/absorb/lessons';
import { CoursePlayerType } from '../constants/absorb';
import { useBrandingContext } from '../contexts/brandingContext';
import { parsedHtml } from '../utils/helper';
import { ObjectLesson } from '../components/ObjectLesson';
import { CoursePlayerLessonWrapper } from '../components/CoursePlayerLessonWrapper';
import { VideoLesson } from '../components/VideoLesson';
import { LessonNotFoundOverlay } from '../components/LessonNotFoundOverlay';

interface Props {
  courseUrl: string;
  isLessonDataLoading: boolean;
  lessonData?: MyLessonData;
  nextLessonData?: MyLessonData;
  openCoursePlayer: (id: string) => void;
  closeCoursePlayer: () => void;
}

export function CoursePlayerPage({
  courseUrl,
  isLessonDataLoading,
  lessonData,
  nextLessonData,
  openCoursePlayer,
  closeCoursePlayer,
}: Props): ReactElement {
  const { t } = useTranslation();

  const { theme, getTheme } = useBrandingContext();

  useEffect(() => {
    if (!theme) {
      getTheme();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function changeView(lessonType: string): ReactElement {
    switch (lessonType) {
      case CoursePlayerType.ThirdPartyLesson:
      case CoursePlayerType.Exam:
      case CoursePlayerType.FlashLesson:
      case CoursePlayerType.TaskLesson: {
        return (
          <Iframe
            url={courseUrl}
            position="absolute"
            width="100%"
            className="iframe-lesson-module__iframe___vGRYm iframe-lesson-player__iframe"
            height="100%"
          />
        );
      }

      case CoursePlayerType.VideoLesson: {
        return lessonData ? (
          <VideoLesson
            lessonData={lessonData}
            nextLessonData={nextLessonData}
            openCoursePlayer={openCoursePlayer}
            closeCoursePlayer={closeCoursePlayer}
          />
        ) : (
          <></>
        );
      }

      case CoursePlayerType.ObjectLesson: {
        return lessonData ? <ObjectLesson myLesson={lessonData} closeCoursePlayer={closeCoursePlayer} /> : <></>;
      }

      case CoursePlayerType.NotFound: {
        return (
          <CoursePlayerLessonWrapper lessonName={t('NotFoundDocumentTitle')} closeCoursePlayer={closeCoursePlayer}>
            <LessonNotFoundOverlay />
          </CoursePlayerLessonWrapper>
        );
      }

      default: {
        return <></>;
      }
    }
  }

  // eslint-disable-next-line no-nested-ternary
  const renderedCoursePlayer: ReactElement = isLessonDataLoading ? (
    <></>
  ) : !lessonData ? (
    changeView(CoursePlayerType.NotFound)
  ) : (
    changeView(lessonData.type)
  );

  return (
    <div className="root__container">
      {theme ? <style>{parsedHtml(theme)}</style> : null}
      {lessonData &&
        (lessonData.type === CoursePlayerType.ObjectLesson ||
          lessonData.type === CoursePlayerType.VideoLesson ||
          lessonData.type === CoursePlayerType.Exam ||
          lessonData.type === CoursePlayerType.ThirdPartyLesson) ? (
        <CoursePlayerLessonWrapper lessonName={lessonData.name} closeCoursePlayer={closeCoursePlayer}>
          {renderedCoursePlayer}
        </CoursePlayerLessonWrapper>
      ) : (
        renderedCoursePlayer
      )}
    </div>
  );
}
