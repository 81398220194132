import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export function LessonNotFoundOverlay(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="modal-message-module__modal_message_wrapper___36OBi modal-message__modal_message_wrapper">
      <div className="modal-message-module__modal_message___3vcVX">
        <div className="lesson-player__error-msg">
          <span className="icon icon-warning" />
          {t('LessonLoadFailureHeader')}
        </div>
        <div>{t('IfTheProblemPersistsPleaseContactYourSystemAdministrator')}</div>
      </div>
    </div>
  );
}
