import React, { ReactElement, useState } from 'react';
import { Modal } from '../modals/Modal';
import { ContextProps, createContext } from '../utils/contextHelper';

interface ModalContextState {
  modal?: ReactElement[];
  showModal: (element: ReactElement) => void;
  closeModal: () => void;
}

const [Provider, useModalContext] = createContext<ModalContextState>(module.filename);

export { useModalContext };

export function ModalProvider({ children }: ContextProps): ReactElement {
  const [modal, setModal] = useState<ReactElement[]>([]);

  function showModal(element: ReactElement) {
    setModal([
      ...modal,
      <Modal key={modal.length} closeModal={closeModal}>
        {element}
      </Modal>,
    ]);
  }

  function closeModal() {
    setModal(modal.slice(0, -1));
  }

  return (
    <Provider
      value={{
        modal,
        showModal,
        closeModal,
      }}
    >
      {children}
    </Provider>
  );
}
