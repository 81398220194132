import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../contexts/modalContext';
import { CatalogCourseData } from '../models/absorb/catalog';
import { FaqResourceData } from '../models/absorb/resources';
import { parsedHtml } from '../utils/helper';

interface Props {
  faqData: FaqResourceData | CatalogCourseData;
}

export function FaqModal({ faqData }: Props): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();

  return (
    <div className="modal-module__container___sfedc modal__container" role="dialog" aria-label="FAQ" aria-modal="true">
      <div className="modal-module__blocker___231AR modal__blocker" />
      <div className="modal-module__modal_container___3tUB2 modal__modal_container">
        <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
          <button
            type="button"
            className="icon-button-module__btn___Zb89b modal-module__close_btn___1yWvZ modal__close_btn"
            title="Close FAQ"
          >
            <div className="icon icon-x-thin" onClick={closeModal} />
            <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Close')}</span>
          </button>
          <div className="modal-module__modal___11QTd modal__modal modal-module__modal_default_size___2ui9v">
            <div className="faq-module__container___MzxWJ">
              <div className="faq-module__faq___1dadM faq__faq">
                <div className="question-answer-module__qa_modal___1jlWU">
                  <div className="question-answer-module__qa_section___2GgzM question-answer__question_section">
                    <div className="question-answer-module__qa_heading___2VpWU">{t('Q')}</div>
                    <div className="question-answer-module__question___3aXAg question-answer__question">
                      {faqData.question}
                    </div>
                  </div>
                  <div className="question-answer-module__qa_section___2GgzM">
                    <div className="question-answer-module__qa_heading___2VpWU">
                      <span aria-hidden="true">{t('A')}</span>
                    </div>
                    <div className="question-answer-module__answer___3beyt">
                      <div className="sanitized_html">{parsedHtml(faqData.answer || '')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
