import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../contexts/modalContext';
import { FaqModal } from '../modals/FaqModal';
import { FaqResourceData } from '../models/absorb/resources';

interface Props {
  faqResourceData: FaqResourceData;
}

export function FaqList({ faqResourceData }: Props): ReactElement {
  const { t } = useTranslation();

  const { showModal } = useModalContext();

  return (
    <div
      className="sortable-table-module__table_row___3N1yN sortable-table__table_row course-list-module__navigable_row___KwmoM"
      role="row"
    >
      <div
        className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__name_column___1ZNQm"
        role="cell"
      >
        <div className="course-list-module__course_name_container___3gF3s">
          <div className="status-module__status___1jZfy status__status">
            <div className="status-module__icon_container___3SHTZ">
              <span
                className="icon icon-question-mark-square-bubble name-column-module__icon___2Qisz faq-name-column__icon"
                title={faqResourceData.question}
              />
            </div>
          </div>
          <button
            className="course-list-module__course_name___2SuOD course-list-module__link___3e9db course-list__course_name"
            title={faqResourceData.question}
            onClick={() => showModal(<FaqModal faqData={faqResourceData} />)}
          >
            {faqResourceData.question}
          </button>
        </div>
      </div>
      <div
        className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__type_column___2EUKC"
        role="cell"
      >
        <div className="course-list-module__course_type___3GyQs course-list__course_type" title={t('FAQs')}>
          {t('FAQs')}
        </div>
      </div>
      <div
        className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__rating_column___3WeuH"
        role="cell"
      />
      <div
        className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__actions_column___25qgm"
        role="cell"
      >
        <div className="action-button-module__container___2O6X7 action-column-module__search_action_btn___25zuX">
          <button
            type="button"
            aria-disabled="false"
            aria-label={faqResourceData.question}
            className="action-button-module__btn___3zkzb btn"
            onClick={() => showModal(<FaqModal faqData={faqResourceData} />)}
          >
            <div className="action-button-module__title___1uZt_">{t('Read')}</div>
            <span aria-live="polite" />
          </button>
        </div>
      </div>
    </div>
  );
}
