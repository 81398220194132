import { CourseResourcesData } from '../models/absorb/course';
import { CurriculumDetailsData, CurriculumData } from '../models/absorb/curriculum';
import { TagData } from '../models/absorb/tags';
import { apiRequest } from '../utils/axios';

export async function fetchCurriculumDetails(courseId: string): Promise<CurriculumDetailsData> {
  return apiRequest('GET', `/curricula/${courseId}`);
}

export async function fetchCurriculum(courseId: string, offset = 0): Promise<CurriculumData> {
  return apiRequest('GET', `/curricula/${courseId}/groups?_offset=${offset}`);
}

export async function fetchCurriculumResources(courseId: string): Promise<CourseResourcesData[]> {
  return apiRequest('GET', `/curricula/${courseId}/resources`).then((data) => data.resources);
}

export async function fetchCurriculumTags(courseId: string): Promise<TagData[]> {
  return apiRequest('GET', `/curricula/${courseId}/tags`).then((data) => data.tags);
}
