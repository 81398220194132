import { apiRequest } from '../utils/axios';
import { AbsorbCourseType } from '../constants/absorb';

export function fetchCourseContent(courseId: string, lessonId: string): Promise<string> {
  return apiRequest('GET', `/content?courseId=${courseId}&lessonId=${lessonId}`);
}

export async function fetchDownloadableFileUrl(courseId: string, courseType: AbsorbCourseType): Promise<string> {
  if (courseType === AbsorbCourseType.Curriculum) {
    return apiRequest('GET', `/curricula/${courseId}/resources/zip`);
  }
  return apiRequest('GET', `/online-courses/${courseId}/resources/zip`);
}
