import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../contexts/modalContext';

interface Props {
  errorMessage: string;
}

export function ErrorModal({ errorMessage }: Props): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();

  return (
    <div
      className="modal-module__container___sfedc modal__container"
      role="dialog"
      aria-label={t('Error')}
      aria-modal="true"
    >
      <div className="modal-module__blocker___231AR modal__blocker" />
      <div className="modal-module__modal_container___3tUB2 modal__modal_container">
        <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
          <button
            type="button"
            className="icon-button-module__btn___Zb89b modal-module__close_btn___1yWvZ modal__close_btn"
            title={t('Close')}
          >
            <div className="icon icon-x-thin" onClick={closeModal} />
            <span className="accessibility-text-module__off_screen_text___FSqhy"> {t('Close')} </span>
          </button>
          <div className="modal-module__modal___11QTd modal__modal modal-module__modal_small_size___1dAye">
            <div className="error-modal-module__modal_container___If1Vy">
              <h1 className="error-modal-module__modal_title___f1CY0">{t('Error')}</h1>
              <div role="alert" className="error-modal-module__modal_message___32tsv error-modal__error_message">
                {t(errorMessage)}
              </div>
              <div className="error-modal-module__modal_footer___36Q5e">
                <button
                  type="button"
                  className="btn button-module__btn___1lXcC button-module__cancel___1pWE5 btn__cancel"
                  onClick={closeModal}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
