import React, { ReactElement } from 'react';
import { ListViewRow } from './ListViewRow';
import { CatalogCourseData } from '../models/absorb/catalog';
import { ListHeaders } from './ListHeaders';

interface Props {
  catalogData: CatalogCourseData[];
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function CourseList({ catalogData, updatePinnedStatus }: Props): ReactElement {
  return (
    <ListHeaders>
      {catalogData.map((catalog: CatalogCourseData) => (
        <ListViewRow key={catalog.id} catalogData={catalog} updatePinnedStatus={updatePinnedStatus} />
      ))}
    </ListHeaders>
  );
}
