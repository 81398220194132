import React, { ReactElement, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseRatingStar } from './CourseRatingStar';
import { AbsorbCourseRating } from '../constants/courses';
import { rateCourse } from '../services/courseRating';
import { createArrayOfNumbers, errorHandler } from '../utils/helper';

interface Props {
  selectedStars: number | null;
  totalRating?: number | null;
  courseId?: string;
  disabled?: boolean;
  isCourseRatingFilter?: boolean;
  subText?: string;
}

export function CourseRating({
  selectedStars,
  totalRating,
  courseId,
  disabled,
  isCourseRatingFilter,
  subText,
}: Props): ReactElement {
  const { t } = useTranslation();

  const totalStars = selectedStars || AbsorbCourseRating.DefaultValue;
  const stars = createArrayOfNumbers(AbsorbCourseRating.MaximumStars);
  const [myRating, setMyRating] = useState(AbsorbCourseRating.DefaultValue);
  const [isLoading, setIsLoading] = useState(false);

  function handleRating(e: MouseEvent<HTMLDivElement>, index: number) {
    e.stopPropagation();

    if (isLoading || disabled) return;

    if (courseId) {
      setMyRating(index + 1);
      const payload = {
        courseId,
        rating: index + 1,
      };

      setIsLoading(true);
      rateCourse(payload)
        .catch(errorHandler)
        .finally(() => setIsLoading(false));
    }
  }

  return (
    <div
      className={`${
        isCourseRatingFilter ? 'course-rating-filter-module__rating___Db_hL' : ''
      } rating-module__rating___3YAZS rating__rating`}
      title={t('StarRating')
        .replace('{0}', myRating ? myRating.toString() : totalStars.toString())
        .replace('{1}', AbsorbCourseRating.MaximumStars.toString())}
    >
      <div className="rating-module__rating___3YAZS rating__rating rating-module__rating_mobile___1lbYb">
        <span className="accessibility-text-module__off_screen_text___FSqhy">{selectedStars}</span>
        <div role="list" className="rating-module__stars___2unTM">
          {stars.map((star, i) => (
            <div key={`star-${star}`} onClick={(e) => handleRating(e, i)}>
              <CourseRatingStar
                illuminated={i < (myRating || totalStars)}
                disabled={disabled}
                isHalf={(selectedStars && selectedStars - i === AbsorbCourseRating.HalfStar) || false}
              />
            </div>
          ))}
        </div>
        {!myRating && !selectedStars && subText && (
          <span className="rating-module__ratings_label___nInnQ ratings__ratings_label" title={t(subText)}>
            {t(subText)}
          </span>
        )}
      </div>
      {disabled && !isCourseRatingFilter && (
        <div className="rating-module__ratings_label___nInnQ ratings__ratings_label">
          <span aria-hidden="true">{`(${totalRating || AbsorbCourseRating.DefaultValue})`}</span>
        </div>
      )}
      {isCourseRatingFilter && ` ${t('AndUp')}`}
    </div>
  );
}
