import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseChaptersData } from '../models/absorb/course';
import { LessonsData } from '../models/absorb/lessons';
import { AbsorbEnrollmentStatus } from '../constants/absorb';
import { LessonCardActionButton } from './LessonCardActionButton';
import { ExpandableDescription } from './ExpandableDescription';
import { getCourseStatusIconClass } from '../utils/helper';
import { RadialProgressIndicatorSmall } from './RadialProgressIndicatorSmall';

interface Props {
  courseChapter: CourseChaptersData;
  openPlayer: (id: string) => void;
  courseRequiresPurchase: boolean;
}

export function LessonCard({ courseChapter, openPlayer, courseRequiresPurchase }: Props): ReactElement {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(true);

  function getLessonTranslation(): string {
    let lessonText = t('Lessons');

    if (lessonText.includes('{0}')) {
      lessonText = lessonText.replace('{0}', '');
    }

    return lessonText;
  }

  return (
    <>
      <div className="chapter-module__chapter___3TwFB chapter__chapter">
        {courseChapter.returnedItems > 0 && (
          <h2 className="chapter-module__header___27h-H body-font">
            <div className="chapter-module__title_bar___36Pd0 chapter__title_bar" aria-expanded="true">
              <div className="chapter-module__title_wrapper___uTD2H" onClick={() => setShowContent(!showContent)}>
                <div
                  className={
                    !showContent
                      ? 'chapter-module__icon___3s6Xw icon icon-arrow-down chapter__icon'
                      : 'chapter-module__icon___3s6Xw icon icon-arrow-up chapter__icon'
                  }
                />
                <div className="chapter-module__title___1qKXa chapter__title" title={courseChapter.name}>
                  {courseChapter.name}
                </div>
              </div>
              <div className="chapter-module__lesson_count___1ZJ1n chapter__lesson_count">
                {courseChapter.returnedItems}
                {getLessonTranslation()}
              </div>
            </div>
          </h2>
        )}

        {courseChapter.lessons.map(
          (lesson: LessonsData) =>
            showContent && (
              <div key={lesson.id} className="panel-module__panel___2njx7 panel__panel">
                <div className="panel-module__panel_header_focus___NaHeg">
                  <div className="panel-module__panel_header___fkAzr lesson-panel-module__panel_header___3vcov panel__panel_header">
                    <div className="panel-module__panel_title___2Xe9e lesson-panel-module__panel_title___1f6sC">
                      <div aria-hidden="true">
                        <div
                          title={t(lesson.enrollment?.status)}
                          className={
                            lesson.enrollment?.status === AbsorbEnrollmentStatus.Complete
                              ? 'status-module__status___1jZfy status__status status-module__success___17sMN status__success'
                              : 'status-module__status___1jZfy status__status'
                          }
                        >
                          <div className="radial-progress-bar__radial_progress_bar">
                            <RadialProgressIndicatorSmall
                              courseRequiresPurchase={courseRequiresPurchase}
                              progress={lesson.enrollment?.progress}
                              enrollmentStatus={lesson.enrollment?.status}
                            />
                          </div>
                          <div className="status-module__icon_container___3SHTZ">
                            <span
                              className={
                                lesson.enrollment?.status ? getCourseStatusIconClass(lesson.enrollment.status) : ''
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        title={lesson.name}
                        className="panel-module__name___3Vd6B lesson-panel-module__name___b8TA2 panel__header_name"
                      >
                        {lesson.name}
                      </div>
                    </div>
                    {lesson.enrollment?.status && (
                      <div className="panel-module__panel_actions___sx8F8">
                        <LessonCardActionButton lessonData={lesson} openPlayer={openPlayer} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="panel-module__panel_body___3vUWN panel__panel_body">
                  {lesson.enrollment && (
                    <div className="lesson-panel-module__attributes___Lnn13 lesson-panel__attributes">
                      <div className="lesson-panel-module__attribute___2IHs3">
                        <label className="lesson-panel-module__attribute_label___kY5Yl lesson-panel__attribute_label">
                          {`${t('Status')}: `}
                        </label>
                        <span className="lesson-panel__status lesson-panel__attribute_value">
                          {t(lesson.enrollment.status)}
                        </span>
                      </div>
                      {lesson.enrollment.maxAttempts === null || lesson.enrollment.maxAttempts === 0 ? null : (
                        <>
                          <div className="lesson-panel-module__attribute___2IHs3">
                            <label className="lesson-panel-module__attribute_label___kY5Yl lesson-panel__attribute_label">
                              {`${t('Attempts')}: `}
                            </label>
                            <span className="lesson-panel__attempts lesson-panel__attribute_value">
                              {lesson.enrollment.attempts}
                            </span>
                          </div>
                          <div className="lesson-panel-module__attribute___2IHs3">
                            <label className="lesson-panel-module__attribute_label___kY5Yl lesson-panel__attribute_label">
                              {`${t('MaxAttempts')}: `}
                            </label>
                            <span className="lesson-panel__max_attempts lesson-panel__attribute_value">
                              {lesson.enrollment.maxAttempts}
                            </span>
                          </div>
                        </>
                      )}
                      {lesson.enrollment.status === AbsorbEnrollmentStatus.Complete && lesson.enrollment.score && (
                        <div className="lesson-panel-module__attribute___2IHs3">
                          <label className="lesson-panel-module__attribute_label___kY5Yl lesson-panel__attribute_label">
                            {`${t('Score')}: `}
                          </label>
                          <span className="lesson-panel__score lesson-panel__attribute_value">
                            {lesson.enrollment.score}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {lesson.description?.length > 0 && (
                    <ExpandableDescription itemName={lesson.name} description={lesson.description} />
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </>
  );
}
