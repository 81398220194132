import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseResourcesData } from '../models/absorb/course';

interface Props {
  resources: CourseResourcesData[];
  usedInModal?: boolean;
  isStructuredLearning?: boolean;
}

export function ResourceLinks({ resources, usedInModal = false, isStructuredLearning = false }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="resource-link-list-module__section___3sYTe">
      <h2 className="resource-link-list-module__section_header___2MfcF resource-link-list__section_header body-font">
        {isStructuredLearning ? t('Experience') : t('Resources')}
      </h2>
      <div className="resource-link-list-module__resource_links___2YAYj">
        {resources.map((resource: CourseResourcesData) => (
          <div key={resource.id} className="resource-link-list-module__link_wrapper___1OJIF resource-section__resource">
            <a
              href={usedInModal ? '# ' : resource.uri}
              className="resource-link-list-module__resource_link___2fjur resource-section__resource_link"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Other Resource Open Data Sheet"
            >
              {resource.name}
            </a>
            <span className="icon icon-arrow-right resource-link-list-module__link_arrow___e4Rq7" />
          </div>
        ))}
      </div>
    </div>
  );
}
