import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../constants/common';
import { ProfileFieldsData } from '../models/absorb/profile';
import { useProfileContext } from '../contexts/profileContext';
import { useModalContext } from '../contexts/modalContext';
import { PageTitle } from '../components/PageTitle';

export function EditProfileModal(): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();
  const { loading, profile, profileFields, updateInProgress, getProfileFields, updateProfile } = useProfileContext();

  const [middleName, setMiddleName] = useState('');
  const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false);

  useEffect(() => {
    if (profileFields) {
      const middleNameObj = profileFields.filter((profileField) => profileField.name === ProfileField.MiddleName);

      if (middleNameObj.length === 1) {
        setMiddleName(middleNameObj[0].value);
      }
    }
  }, [loading, profileFields]);

  async function onEditSubmit() {
    if (profileFields) {
      const updatedProfile: ProfileFieldsData[] = profileFields.map((profileField: ProfileFieldsData) => {
        return profileField.name === ProfileField.MiddleName
          ? { ...profileField, value: middleName || '' }
          : profileField;
      });

      if (await updateProfile(updatedProfile)) {
        setUpdateProfileSuccess(true);

        getProfileFields();
      }
    }
  }

  return (
    <div
      className="modal-module__container___sfedc modal__container"
      role="dialog"
      aria-label={t('EditProfile')}
      aria-modal="true"
    >
      <PageTitle title={t('EditProfile')} />
      <div className="modal-module__blocker___231AR modal__blocker" />
      <div className="modal-module__modal_container___3tUB2 modal__modal_container modal-module__modal_container_top___3NfiM modal__modal_container_top">
        <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
          <button
            type="button"
            className="icon-button-module__btn___Zb89b modal-module__close_btn___1yWvZ modal__close_btn"
            title={t('CloseThisWindow')}
            onClick={closeModal}
          >
            <div className="icon icon-x-thin" />
            <span className="accessibility-text-module__off_screen_text___FSqhy">
              {t('Close')} {t('EditProfile')}
            </span>
          </button>
          <div className="modal-module__modal___11QTd modal__modal modal-module__modal_small_size___1dAye modal-module__modal_top_position___Yxl4d modal-module__modal_auto_height___2feYu">
            <div className="edit-profile-module__wrapper___3DPOs">
              <div className="profile-form-module__profile_form_container___24Wwl">
                <form className="profile-form-module__form___1wsMc">
                  <h1 className="profile-form-module__title___tAoGd profile-form__title">{t('EditProfile')}</h1>
                  {!updateInProgress && updateProfileSuccess ? (
                    <div aria-live="assertive">
                      <div className="form-info-panel-module__success_message___32u7j form__success_message">
                        {t('EditProfileSuccessBlurb')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="profile-form-module__field_group___2IOMB profile-form__field_group">
                        <div className="profile-form-module__fields___3z5zs profile-form__fields">
                          <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                            <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                              <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                                {t('FirstName')}
                              </label>
                              <div
                                className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                                aria-hidden="true"
                              >
                                <span className="accessibility-text-module__off_screen_text___FSqhy">
                                  {t('Required')}
                                </span>
                                <span aria-hidden="true">{t('Required')}</span>
                              </div>
                            </div>
                            <input
                              name="FirstName"
                              id="reduxFormInputField66"
                              type="text"
                              step="any"
                              className="edit-profile"
                              readOnly
                              aria-required="true"
                              aria-describedby="errorDiv67"
                              defaultValue={profile?.firstName}
                            />
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv67" />
                          </div>
                          <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                            <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                              <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                                {t('MiddleName')}
                              </label>
                            </div>
                            <input
                              name="MiddleName"
                              id="reduxFormInputField68"
                              type="text"
                              step="any"
                              aria-required="false"
                              aria-describedby="errorDiv69"
                              defaultValue={middleName}
                              onChange={(e) => setMiddleName(e.target.value)}
                            />
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv69" />
                          </div>
                          <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                            <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                              <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                                {t('LastName')}
                              </label>
                              <div
                                className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                                aria-hidden="true"
                              >
                                <span className="accessibility-text-module__off_screen_text___FSqhy">
                                  {t('Required')}
                                </span>
                                <span aria-hidden="true">{t('Required')}</span>
                              </div>
                            </div>
                            <input
                              name="LastName"
                              id="reduxFormInputField70"
                              type="text"
                              step="any"
                              readOnly
                              aria-required="true"
                              aria-describedby="errorDiv71"
                              defaultValue={profile?.lastName}
                            />
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv71" />
                          </div>
                          <div className="redux-form-input-module__field___LP6sO profile-form-module__field___1Ymic">
                            <div className="redux-form-input-field-module__label_wrapper___DxUtk">
                              <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label">
                                {t('Email')}
                              </label>
                              <div
                                className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
                                aria-hidden="true"
                              >
                                <span className="accessibility-text-module__off_screen_text___FSqhy">
                                  {t('Required')}
                                </span>
                                <span aria-hidden="true">{t('Required')}</span>
                              </div>
                            </div>
                            <input
                              name="EmailAddress"
                              id="reduxFormInputField74"
                              type="text"
                              step="any"
                              readOnly
                              aria-required="true"
                              aria-describedby="errorDiv75"
                              defaultValue={profile?.emailAddress}
                            />
                            <div aria-live="assertive" className="redux-form-input-field__errors" id="errorDiv75" />
                          </div>
                        </div>
                      </div>
                      <div className="profile-form-module__btn_group___1CsPB">
                        <button
                          type="button"
                          className="btn profile-form-module__save_btn___3HTRx button-module__btn___1lXcC"
                          title={t('Save')}
                          aria-label={t('Save')}
                          aria-describedby="editProfileSubmitButtonDescribedById65"
                          onClick={onEditSubmit}
                        >
                          {updateInProgress ? <div className="button-module__loader___1IQHD" /> : t('Save')}
                        </button>
                        <button
                          type="button"
                          className="btn profile-form-module__cancel_btn___13j63 btn__cancel button-module__btn___1lXcC"
                          title={t('Close')}
                          aria-label={t('Close')}
                          onClick={closeModal}
                        >
                          {t('Close')}
                        </button>
                      </div>
                    </>
                  )}
                </form>
                <div id="editProfileSubmitButtonDescribedById65" aria-live="assertive" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
