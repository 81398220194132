import { BrandingData } from '../models/absorb/branding';
import { apiRequest, publicApiRequest } from '../utils/axios';

export async function fetchBranding(languageCode: string | null): Promise<BrandingData> {
  return publicApiRequest('GET', `/branding?languageAbbreviation=${languageCode}`);
}

export async function fetchBrandingWithAuth(languageCode: string | null): Promise<BrandingData> {
  return apiRequest('GET', `/branding?languageAbbreviation=${languageCode}`);
}

export async function fetchDepartmentTheme(): Promise<string> {
  return publicApiRequest('GET', '/branding/department-theme/');
}
