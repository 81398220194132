import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyLessonData } from '../models/absorb/lessons';
import { fetchMyCourseEnrollment } from '../services/course';
import { errorHandler } from '../utils/helper';
import { LinearProgressIndicator } from './LinearProgressIndicator';

interface Props {
  thisLesson: MyLessonData;
  nextLesson?: MyLessonData;
  hideOverlay: () => void;
  openCoursePlayer: (id: string) => void;
  closeCoursePlayer: () => void;
}

export function NextActivityOverlay({
  thisLesson,
  nextLesson,
  hideOverlay,
  openCoursePlayer,
  closeCoursePlayer,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [percentageCompleted, setPercentageCompleted] = useState(0);

  useEffect(() => {
    fetchMyCourseEnrollment(thisLesson.courseId)
      .then((data) => {
        setPercentageCompleted(data.progress);
      })
      .catch(errorHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="next-activity-module__overlay___uosv3 next-activity__overlay">
      <div className="next-activity-module__container_wrapper___3ORNy next-activity__container_wrapper">
        <div className="next-activity-module__container___lcPQW next-activity__container">
          <div className="next-activity-module__modal___2EZTK next-activity__modal">
            <div className="next-activity-module__modal_title___2dYOC next-activity__title">
              {t('ActivityComplete')}
            </div>
            <div className="next-activity-module__modal_body___1oCDi next-activity__modal_body">
              <div className="next-activity-module__progress_text___3AZ8l next-activity__progress_text">
                {t('CourseCompletedPercentage').replace('{0}', percentageCompleted.toString())}
              </div>
              <div className="progress-bar-module__container___315yd progress-bar__bar">
                <LinearProgressIndicator progress={percentageCompleted} />
              </div>
              <div className="next-activity-module__next_lesson_container___DRCA6 next-activity__next_lesson_container">
                {nextLesson && (
                  <>
                    <div className="next-activity-module__next_lesson___2iacF next-activity__next_lesson">
                      {t('NextLesson')}:
                    </div>
                    <div className="next-activity-module__next_lesson_name___2ed3g next-activity__next_lesson_name">
                      {nextLesson.name}
                    </div>
                  </>
                )}
                <div className="action-button-module__container___2O6X7 next-activity-module__next_activity_btn___3kVUi">
                  {nextLesson && (
                    <button
                      type="button"
                      aria-disabled="false"
                      aria-label={t('NextActivity')}
                      onClick={() => {
                        hideOverlay();
                        closeCoursePlayer();
                        openCoursePlayer(nextLesson.id);
                      }}
                      className="action-button-module__btn___3zkzb btn"
                    >
                      <div className="action-button-module__title___1uZt_">{t('NextActivity')}</div>
                      <span aria-live="polite" />
                    </button>
                  )}
                </div>
              </div>
              <div className="action-button-module__container___2O6X7">
                <button
                  type="button"
                  aria-disabled="false"
                  aria-label={t('CloseActivity')}
                  onClick={closeCoursePlayer}
                  className="action-button-module__btn___3zkzb btn action-button-module__highlight___2NMxl btn__highlight"
                >
                  <div className="action-button-module__title___1uZt_">{t('CloseActivity')}</div>
                  <span aria-live="polite" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="next-activity-module__carousel_wrapper___3TcJt" />
      </div>
    </div>
  );
}
