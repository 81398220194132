import { InstructorLedSessionData } from '../models/absorb/instructorLed';
import { ProfileData } from '../models/absorb/profile';
import { EndRecordPlusRequest } from '../models/proctorU/endRecordPlus';
import { StartRecordPlusRequest } from '../models/proctorU/startRecordPlus';

export function isRecordPlusDepartment(departmentName: string): boolean {
  // 'BP Record+': true, etc. etc.
  const lookup: { [key: string]: boolean } = {
    Endeavor: true,
    'Endeavor Infuse Dev': true,
    BP: true,
    'BP - KREW': true,
  };
  return lookup[departmentName];
}

export function createStartRecordPlusRequest(
  profile: ProfileData,
  session: InstructorLedSessionData
): StartRecordPlusRequest {
  const request: StartRecordPlusRequest = {
    student_id: profile.id,
    first_name: profile.firstName,
    last_name: profile.lastName,
    email: profile.emailAddress,
    country: 'CA',
    phone1: '1231231234',
    time_zone_id: 'Central Standard Time',
    description: session.name,
    exam_id: session.id,
    exam_url: 'https://iadc.wellsharp.org',
    duration: 240, // 4 hours in minutes, all exams for record plus are this length
    whitelist_urls:
      // '[{"url": "https://krew.iadc.org", "open_on_start": false}, {"url": "https://iadc.myabsorb.com", "open_on_start": false}, {"url":"https://iadc.wellsharp.org","open_on_start":false}]',
      '[{"url": "https://krew.iadc.org", "open_on_start": "false"}, {"url": "https://iadc.myabsorb.com", "open_on_start": "false"}, {"url": "https://wellconeducation.com/test/login", "open_on_start": "false"}]',
    permitted_resources_list:
      'computer_calculator, formula_sheet, four_function_calculator, printed_notes, scientific_calculator, scratch1, scratch2, scratch_more, pdf_notes, bathroom_breaks, beverages',
    other_resources:
      'Instructor chat is built into krew.iadc.org, which is a permitted site.  Please ensure student has this page open. Technical Data Booklet is permitted',
    // hoping a valid department_id is not required. Getting and matching the department id's between proctorU and absorb is not really possible
    // might have to hard code a table of absorb department names with proctorU department_id's if this is a required value.
    department_id: 10809,
    // address1: '',
    // city: '',
    // state: '',
    // zipcode: '',
    // preset: '',
    active: 'Y',
  };

  return request;
}

// eslint-disable-next-line camelcase
export function createEndRecordPlusRequest(reservation_uuid: string): EndRecordPlusRequest {
  const request: EndRecordPlusRequest = {
    reservation_uuid,
  };

  return request;
}
