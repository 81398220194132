import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { COURSE_NOT_FOUND_PARAMS } from '../constants/common';

export function useCourseAccessErrorRedirect(): [(key: boolean) => void] {
  const navigate = useNavigate();
  const [courseAccessError, setCourseAccessError] = useState(false);

  useEffect(() => {
    if (courseAccessError) {
      navigate(`/catalog?${COURSE_NOT_FOUND_PARAMS}`);
    }
  }, [courseAccessError]); // eslint-disable-line react-hooks/exhaustive-deps

  return [setCourseAccessError];
}
