import React, { ReactElement, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import './TextInput.css';

interface Props {
  labelName: string;
  name: string;
  defaultValue: string;
  id: string;
  errorId: string;
  required?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function TextInput({
  labelName,
  name,
  defaultValue,
  id,
  errorId,
  required = true,
  onChange,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="redux-form-input-module__field___LP6sO cart-shipping-module__field___Lisyr">
      <div className="redux-form-input-field-module__label_wrapper___DxUtk">
        <label className="redux-form-input-field-module__label___e8vRN redux-form-input-field__label" htmlFor={id}>
          {labelName}
        </label>
        {required && (
          <div
            className="redux-form-input-field-module__required_label___13Dbm redux-form-input-field__label"
            aria-hidden="true"
          >
            <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Required')}</span>
            <span aria-hidden="true">{t('Required')}</span>
          </div>
        )}
      </div>
      <input
        className="textInputComp"
        name={name}
        id={id}
        step="any"
        aria-required="true"
        aria-describedby={errorId}
        defaultValue={defaultValue}
        onChange={onChange}
      />
      <div aria-live="assertive" className="redux-form-input-field__errors" id={errorId} />
    </div>
  );
}
