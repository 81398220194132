import { MyLessonData } from '../models/absorb/lessons';
import { apiRequest } from '../utils/axios';

export function fetchLesson(lessonId: string): Promise<MyLessonData> {
  return apiRequest('GET', `/my-lessons/${lessonId}`);
}

export function postLessonAttempt(lessonId: string): Promise<{ attemptId: string }> {
  return apiRequest('POST', `/my-lesson-activities/${lessonId}`);
}

export function updateLessonActivity(
  lessonId: string,
  attemptId: string,
  payload: { [key: string]: string | number }
): Promise<void> {
  return apiRequest('PATCH', `/my-lesson-activities/${lessonId}/attempts/${attemptId}`, payload);
}
