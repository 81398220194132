import React, { ReactElement, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogCourseData } from '../models/absorb/catalog';
import { ShareCourseButton } from './ShareCourseButton';
import { PinCourseButton } from './PinCourseButton';
import { getCourseStatusIconClass } from '../utils/helper';
import { CourseCardActionButton } from './CourseCardActionButton';
import { CourseAction } from './CourseAction';
import { CourseRating } from './CourseRating';
import { RadialProgressIndicatorSmall } from './RadialProgressIndicatorSmall';
import { CourseDetailClickType } from '../constants/common';
import { AbsorbCourseType } from '../constants/absorb';
import './ListViewRow.css';

interface Props {
  catalogData: CatalogCourseData;
  isEnrollmentLocked?: boolean;
  curriculumRequiresPurchase?: boolean;
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function ListViewRow({
  catalogData,
  isEnrollmentLocked = false,
  curriculumRequiresPurchase = false,
  updatePinnedStatus,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [showOptions, setShowOptions] = useState(false);

  const disableEnrollment = isEnrollmentLocked || (curriculumRequiresPurchase && catalogData.enrollmentStatus === null);

  function showActionMenu(e: MouseEvent<HTMLElement>) {
    e.stopPropagation();
    updateShowOptions(!showOptions);
  }

  function updateShowOptions(option: boolean) {
    setShowOptions(option);
  }

  return (
    <div
      className={`sortable-table-module__table_row___3N1yN sortable-table__table_row course-list-module__navigable_row___KwmoM ${
        disableEnrollment ? 'panel-module-default_cursor' : ''
      }`}
      role="row"
    >
      <div
        className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item course-list-module__name_column___1upGg"
        role="cell"
      >
        <div className="course-list-module__course_name_container___3gF3s">
          <div className="status-module__status___1jZfy status__status">
            <RadialProgressIndicatorSmall
              courseRequiresPurchase={curriculumRequiresPurchase}
              progress={catalogData.progress}
              enrollmentStatus={catalogData.enrollmentStatus}
            />
            <div className="status-module__icon_container___3SHTZ">
              <span className={getCourseStatusIconClass(catalogData.enrollmentStatus)} />
            </div>
          </div>
          <CourseAction
            cssClass={`course-list-module__course_name___2SuOD course-list__course_name ${
              !disableEnrollment && 'course-list-module__link___3e9db'
            }`}
            cardData={catalogData}
            clickType={CourseDetailClickType.Button}
            courseLocked={disableEnrollment}
            updateTilePinnedStatus={updatePinnedStatus}
          >
            {catalogData.question ? catalogData.question : catalogData.name}
          </CourseAction>
        </div>
      </div>
      {!curriculumRequiresPurchase && (
        <div
          className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__type_column___2EUKC"
          role="cell"
        >
          <div
            className="course-list-module__course_type___3GyQs course-list__course_type"
            title={t(catalogData.courseType)}
          >
            {catalogData.courseType === AbsorbCourseType.InstructorLedCourse && catalogData.selectedSession !== null
              ? catalogData.selectedSession?.name
              : t(catalogData.courseType)}
          </div>
        </div>
      )}
      {!curriculumRequiresPurchase && (
        <div
          className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__rating_column___3WeuH"
          role="cell"
        >
          {catalogData.isCourseRatingEnabled && (
            <CourseRating
              selectedStars={catalogData.myRating || catalogData.rating}
              totalRating={catalogData.totalRatings}
              courseId={catalogData.id}
              disabled={!catalogData.myRating || catalogData.price !== null || !catalogData.enrolled}
            />
          )}
        </div>
      )}
      <div
        className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item course-list-module__actions_column___1u6Zx"
        role="cell"
      >
        <div className="course-list-module__course_actions___nuprd">
          <div className="action-button-module__container___2O6X7 course-list-module__course_action_btn___872eX">
            <CourseCardActionButton
              cardData={catalogData}
              isEnrollmentLocked={isEnrollmentLocked}
              curriculumRequiresPurchase={curriculumRequiresPurchase}
            />
          </div>
          <div className="options-button-module__container___2AYLC">
            {showOptions && (
              <div className="options-button-module__options_container___1NPht options-button__options_container">
                <div className="course-options-module__course_options___1vuRm" role="menu">
                  {catalogData && (
                    <ShareCourseButton
                      courseId={catalogData.id}
                      courseType={catalogData.courseType}
                      courseName={catalogData.name || ''}
                    />
                  )}
                  {catalogData && !disableEnrollment && (
                    <PinCourseButton
                      catalogCourseData={catalogData}
                      updatePinnedStatus={updatePinnedStatus}
                      showOptions={showOptions}
                      updateShowOptions={updateShowOptions}
                    />
                  )}
                </div>
              </div>
            )}
            <button
              type="button"
              className="options-button-module__btn___1mr7i options-button__btn"
              title={t('CourseOptions')}
              aria-label={t('CourseOptions')}
              aria-haspopup="true"
              onClick={(event) => showActionMenu(event)}
            >
              <span className="icon icon-options" />
            </button>
            {showOptions && (
              <div
                className="options-button-module__options_overlay___1J0nV options-button__options_overlay"
                onClick={(event) => showActionMenu(event)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
