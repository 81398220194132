import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CatalogCourseData } from '../models/absorb/catalog';
import { AbsorbEnrollmentStatus, AbsorbCourseType } from '../constants/absorb';
import { CourseCardActionButton } from './CourseCardActionButton';
import { CourseRating } from './CourseRating';
import { ShareCourseButton } from './ShareCourseButton';
import { PinCourseButton } from './PinCourseButton';
import { CourseAction } from './CourseAction';
import { LinearProgressIndicator } from './LinearProgressIndicator';
import { CourseDetailClickType, HOUR_MINUTE_FORMAT, MONTH_DAY_YEAR_FORMAT } from '../constants/common';
import { getCourseIcon } from '../utils/helper';
import './CourseCard.css';

interface Props {
  cardData: CatalogCourseData;
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function CourseCard({ cardData, updatePinnedStatus }: Props): ReactElement {
  const { t } = useTranslation();

  function getCourseIconClassName(courseType: string) {
    return `${getCourseIcon(courseType)}
      thumbnail-module__container___14C5O icon card-module__thumbnail___pvU81 card__thumbnail thumbnail-module__no_image___1ASug thumbnail__container`;
  }

  return (
    <div className="card-module__card___Y3Vej card__card">
      <CourseAction
        cssClass="card-top-module__card_top___1iJXr card-top__card_top card-top-module__card_top_link___15N9A"
        cardData={cardData}
        clickType={CourseDetailClickType.Link}
        updateTilePinnedStatus={updatePinnedStatus}
      >
        <span className="accessibility-text-module__off_screen_text___FSqhy">` ${cardData.name}`</span>
        {cardData.imageUrl ? (
          <div className="thumbnail-module__container___14C5O icon icon-courses card-module__thumbnail___pvU81 card__thumbnail thumbnail__container">
            <img src={cardData.imageUrl} className="thumbnail-module__img___3IYNh" alt={cardData.name} />
          </div>
        ) : (
          <div className={getCourseIconClassName(cardData.courseType)} />
        )}
        {cardData.enrollmentStatus === AbsorbEnrollmentStatus.Complete && (
          <div className="hat-module__hat___Z2yiQ card-module__hat___vWYvS hat__hat">
            <span className="hat-module__bg___3DXee hat__bg hat-module__bg_success___2GP_d hat__success" />
            <span className="icon icon-check-mark hat-module__icon___3-vsJ hat__icon" />
          </div>
        )}
        {cardData.isPinned ? <span className="icon icon-pin card-module__pin___1Hm9- card__pin" /> : null}
        {cardData.courseType === AbsorbCourseType.InstructorLedCourse && cardData.selectedSession === null && (
          <span className="pill-module__pill___1PAoi instructor-led-course-card-module__available_sessions___3HmbN instructor-led-course-card__available_sessions">
            <span className="label-primary" aria-hidden="true">
              {cardData.sessionCount || '0'}
            </span>
          </span>
        )}
        <div className="card-module__overlay___2fRZX online-course-card__overlay">
          {cardData.courseType === AbsorbCourseType.Curriculum && (
            <div className="curriculum-card-module__info___3ITdh">
              <div className="curriculum-card-module__info_course_count___140m- curriculum-card__info_course_count">
                {cardData.courseCount || '0'}
              </div>
              <div className="curriculum-card-module__info_course_count_title___OcHHV curriculum-card__info_course_count_title">
                {t('Courses')}
              </div>
            </div>
          )}
          {cardData.courseType === AbsorbCourseType.InstructorLedCourse && (
            <div className="instructor-led-course-card-module__info___2wQ0Y">
              <div
                className={
                  cardData.selectedSession != null
                    ? 'instructor-led-course-card-module__info_session_title___2tFQj instructor-led-course-card__info_session_title'
                    : 'instructor-led-course-card-module__info_session_count___dcseW instructor-led-course-card__info_session_count'
                }
              >
                {cardData.selectedSession !== null ? t('MySession') : cardData.sessionCount}
              </div>
              <div
                className={
                  cardData.selectedSession !== null
                    ? 'instructor-led-course-card-module__info_session_time___2ThXT instructor-led-course-card__info_session_time'
                    : 'instructor-led-course-card-module__info_session_count_title___2yixG instructor-led-course-card__info_session_count_title'
                }
              >
                {cardData.selectedSession !== null && cardData.selectedSession !== undefined
                  ? `${moment(cardData.selectedSession.startDate).format(MONTH_DAY_YEAR_FORMAT)}  
                     ${moment(cardData.selectedSession.startDate).format(HOUR_MINUTE_FORMAT)} ${
                      cardData.selectedSession.timeZoneShort
                    }`
                  : t('Sessions')}
              </div>
            </div>
          )}
          <div className="course-options-module__course_options___1vuRm card-module__options___1q-7Z" role="menu">
            {cardData && (
              <ShareCourseButton
                courseId={cardData.id}
                courseType={cardData.courseType}
                courseName={cardData.name || ''}
              />
            )}
            {cardData && <PinCourseButton catalogCourseData={cardData} updatePinnedStatus={updatePinnedStatus} />}
          </div>
        </div>
      </CourseAction>
      <div className="progress-bar-module__container___315yd progress-bar__bar">
        <LinearProgressIndicator progress={cardData.progress} />
      </div>
      <div className="card-module__card_bottom___2pcXZ card__card_bottom">
        <CourseAction
          cssClass="card-module__name___1kcxc card__name"
          cardData={cardData}
          clickType={CourseDetailClickType.Link}
          updateTilePinnedStatus={updatePinnedStatus}
        >
          {cardData.name}
        </CourseAction>
        <div className="card-module__type___3Pm9I card__type" title={cardData.courseType}>
          {cardData.courseType === AbsorbCourseType.InstructorLedCourse && cardData.selectedSession !== null
            ? cardData.selectedSession?.name
            : t(cardData.courseType)}
        </div>
        {cardData.isCourseRatingEnabled && (
          <CourseRating
            selectedStars={cardData.myRating || cardData.rating}
            totalRating={cardData.totalRatings}
            courseId={cardData.id}
            disabled={!cardData.myRating || cardData.price !== null || !cardData.enrolled}
          />
        )}
        <div className="card-module__card_action___1qfit">
          <div className="action-button-module__container___2O6X7">
            <CourseCardActionButton cardData={cardData} isIconRequired />
          </div>
        </div>
      </div>
    </div>

    // TODO:  E1-522 re-enroll logic is pending here
  );
}
