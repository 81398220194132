import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '../contexts/loginContext';
import { useModalContext } from '../contexts/modalContext';
import { Modal } from './Modal';

export function TimeoutModal(): ReactElement {
  const { t } = useTranslation();

  const { signOut } = useLoginContext();
  const { closeModal } = useModalContext();

  function closeAction() {
    closeModal();
    signOut();
  }

  return (
    <Modal closeModal={closeModal}>
      <div
        className="modal-module__container___sfedc modal__container"
        role="dialog"
        aria-label="Inbox"
        aria-modal="true"
      >
        <div className="modal-module__blocker___231AR modal__blocker" />
        <div className="modal-module__modal_container___3tUB2 modal__modal_container">
          <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
            <div className="modal-module__modal___11QTd modal__modal modal-module__modal_small_size___1dAye">
              <div className="message-modal-module__message_container___1gNBV">
                <div className="message-modal-module__icon___1fpDn icon icon-clock-simple message-modal__icon" />
                <h1 className="message-modal-module__header___PJRE9 message-modal__header">{t('Timeout')}</h1>
                <div className="message-modal-module__message___1vQll">{t('TimeoutPostMessage')}</div>
                <button
                  type="button"
                  onClick={closeAction}
                  className="btn message-modal-module__ok_btn___2H5AW message-modal__ok_btn button-module__btn___1lXcC"
                >
                  {t('OK')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
