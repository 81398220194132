export const ACCESS_MODE = 'API';

export enum UserType {
  Learner = 'Learner',
  Instructor = 'Instructor',
  Admin = 'Admin',
}

export enum AbsorbTileType {
  Catalog = 'catalog',
  Messages = 'messages',
  Resume = 'resume',
  Courses = 'courses',
  Resources = 'resources',
  Faq = 'faq',
  Hyperlink = 'hyperlink',
  Welcome = 'welcome',
}

export enum RibbonType {
  Resume = 'Resume',
  MyCourses = 'MyCourses',
  catalog = 'catalog',
  MyCatalog = 'MyCatalog',
  Featured = 'Featured',
}

export enum AlignmentType {
  Left = 'Left',
  Centered = 'Centered',
  Right = 'Right',
}

export enum AbsorbResourceType {
  GlobalResource = 'GlobalResource',
  Course = 'Course',
  NewsArticle = 'NewsArticle',
  Poll = 'Poll',
  Faq = 'Faq',
  Lesson = 'Lesson',
}

export enum AbsorbCourseType {
  Undefined = 'Undefined',
  OnlineCourse = 'OnlineCourse',
  InstructorLedCourse = 'InstructorLedCourse',
  Curriculum = 'Curriculum',
  CourseBundle = 'CourseBundle',
}

export enum AbsorbVenueType {
  Unknown = 'Unknown',
  Classroom = 'Classroom',
  Virtual = 'Virtual',
}

export const ABSORB_TAGS = 'Tags';

export const ABSORB_VENUE_LOCATION = 'VenueLocation';
export const ABSORB_VENUE_TYPE = 'VenueType';
export const ABSORB_VENUE_TYPES = 'VenueTypes';
export const ABSORB_VENDOR = 'Vendor';

export enum AbsorbEnrollmentStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  PendingApproval = 'PendingApproval',
  Complete = 'Complete',
  NotComplete = 'NotComplete',
  Failed = 'Failed',
  Declined = 'Declined',
  PendingEvaluationRequired = 'PendingEvaluationRequired',
  OnWaitlist = 'OnWaitlist',
  Absent = 'Absent',
  NotApplicable = 'NotApplicable',
  PendingProctor = 'PendingProctor',
  Null = 'null',
}

export enum CoursePlayerType {
  VideoLesson = 'VideoLesson',
  ObjectLesson = 'ObjectLesson',
  FlashLesson = 'FlashLesson',
  TaskLesson = 'TaskLesson',
  ThirdPartyLesson = 'ThirdPartyLesson',
  Exam = 'Exam',
  NotFound = 'NotFound',
}

export const COURSE_PLAYER_NOT_FOUND = 'Not found';
export const PROCTOR_U_CUSTOM_FIELD_INDEX = 16;
