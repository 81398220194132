import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ABSORB_VENUE_TYPE, AbsorbVenueType } from '../../constants/absorb';
import { useSearchContext } from '../../contexts/searchContext';
import { CheckBox } from './CheckBox';

export function VenueTypeFilter(): ReactElement {
  const { t } = useTranslation();

  const { advancedFilter, setAdvancedFilter, removeAdvancedFilter } = useSearchContext();
  const [classroomChecked, setClassroomChecked] = useState(false);
  const [virtualChecked, setVirtualChecked] = useState(false);

  useEffect(() => {
    if (advancedFilter[ABSORB_VENUE_TYPE]) {
      setClassroomChecked((advancedFilter[ABSORB_VENUE_TYPE] as string).includes(AbsorbVenueType.Classroom));
      setVirtualChecked((advancedFilter[ABSORB_VENUE_TYPE] as string).includes(AbsorbVenueType.Virtual));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const selectedVenueTypes = [];

    if (classroomChecked) {
      selectedVenueTypes.push(AbsorbVenueType.Classroom);
    }

    if (virtualChecked) {
      selectedVenueTypes.push(AbsorbVenueType.Virtual);
    }
    if (selectedVenueTypes.length) {
      setAdvancedFilter({ ...advancedFilter, [ABSORB_VENUE_TYPE]: selectedVenueTypes.join(',') });
    } else if (advancedFilter && advancedFilter[ABSORB_VENUE_TYPE]) {
      setAdvancedFilter({ ...advancedFilter, [ABSORB_VENUE_TYPE]: '' });
    }
  }, [classroomChecked, virtualChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  function clearVenueTypeData() {
    removeAdvancedFilter(ABSORB_VENUE_TYPE);
  }

  return (
    <div className="filter-panel-module__filter_panel___2xlm8 filter-panel__filter_panel">
      <button
        type="button"
        className="icon-button-module__btn___Zb89b filter-panel-module__remove_button___1970P filter-panel__remove_button"
      >
        <div className="icon icon-x-circle" title={t(ABSORB_VENUE_TYPE)} onClick={clearVenueTypeData} />
        <span className="accessibility-text-module__off_screen_text___FSqhy">
          {t('Remove')} {t('VenueType')} {t('Filter')}
        </span>
      </button>
      <fieldset className="venue-type-filter-module__fieldset___2czWy">
        <legend className="venue-type-filter-module__title___3iVhx venue-type-filter__title">{t('VenueType')}:</legend>
        <CheckBox
          label={t('Classroom')}
          value={AbsorbVenueType.Classroom}
          isDynamicChecked
          isChecked={classroomChecked}
          handleCheckbox={() => setClassroomChecked(!classroomChecked)}
        />
        <CheckBox
          label={t('Virtual')}
          value={AbsorbVenueType.Virtual}
          isDynamicChecked
          isChecked={virtualChecked}
          handleCheckbox={() => setVirtualChecked(!virtualChecked)}
        />
      </fieldset>
    </div>
  );
}
