import React, { ReactElement, useState, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { KEY_ENTER } from '../constants/common';
import { REGEX_SEARCH_FILTER_NO_SPECIAL_CHARS } from '../constants/regex';
import { useLoginContext } from '../contexts/loginContext';
import { useSearchContext } from '../contexts/searchContext';

export function SearchBar(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loggedIn } = useLoginContext();
  const { searchTerm, setSearchTerm, resetPageOffset } = useSearchContext();

  const [term, setTerm] = useState(searchTerm);

  function onKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === KEY_ENTER) {
      if (loggedIn) {
        setSearchTerm(term);
        resetPageOffset();
        navigate(`/global-search?term=${searchTerm}`);
      }
    }
  }

  return (
    <div className="search-header-module__search_container___3N2Fe search-header__search_container">
      <div
        className="search-header-module__search_input_container___R6lGR search-header__search_input_container"
        role="search"
      >
        <div className="icon icon-lens search-header-module__search_icon___3nzQj search-header__search_icon" />
        <input
          id="search"
          className="search-header-module__search_input___1qJuL search-header__search_input"
          placeholder={t('Search')}
          aria-label="Search"
          defaultValue={searchTerm}
          onChange={(e) => setTerm(e.target.value.replace(REGEX_SEARCH_FILTER_NO_SPECIAL_CHARS, ''))}
          onKeyPress={(e) => onKeyPress(e)}
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
        />
      </div>
    </div>
  );
}
