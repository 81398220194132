import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function EmptyCart(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="cart-review-module__empty___sUnoa cart-review__empty">
      <div className="cart-review-module__empty_title___3xA7t">{t('ShoppingCartEmpty')}</div>
      <Link to="/catalog">{t('ContinueShopping')}</Link>
      <span>{` ${t('ToAddItemsToCart')}`}</span>
    </div>
  );
}
