import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import { CourseResourcesData } from '../models/absorb/course';
import { fetchDownloadableFileUrl } from '../services/courseDetails';
import { AbsorbCourseType } from '../constants/absorb';
import { useModalContext } from '../contexts/modalContext';
import { ErrorModal } from '../modals/ErrorModal';
import { parsedHtml } from '../utils/helper';

interface Props {
  courseResourcesData: CourseResourcesData[];
  courseId: string;
  courseType?: AbsorbCourseType;
  showResourceAsExperience?: boolean;
}

export function CourseDetailResourceView({
  courseResourcesData,
  courseId,
  courseType = AbsorbCourseType.OnlineCourse,
  showResourceAsExperience = false,
}: Props): ReactElement {
  const { t } = useTranslation();

  const { showModal } = useModalContext();

  const [isDownloading, setIsDownloading] = useState(false);

  function openResourceURL(url: string) {
    window.open(url, '_blank');
  }

  function downloadAllFiles() {
    setIsDownloading(true);
    fetchDownloadableFileUrl(courseId, courseType)
      .then((data) => {
        const blob = new Blob([data], { type: 'application/zip' });
        FileSaver.saveAs(blob, 'Resources.zip');
      })
      .catch(() => showModal(<ErrorModal errorMessage="CourseResourcesErrorMessage" />))
      .finally(() => setIsDownloading(false));
  }

  return courseResourcesData.length !== 0 ? (
    <>
      <div className="course-resources-module__header___R5WHv">
        <h2 className="course-resources-module__title___1gfM7 course-resources__content_title">
          {showResourceAsExperience ? t('Experience') : t('Resources')}
        </h2>

        <div className="course-resources-module__button_container___1WynE">
          <div className="action-button-module__container___2O6X7 course-resources-module__download_all_button___3fwv6">
            <button
              type="button"
              aria-disabled="false"
              aria-label={t('DownloadAll')}
              className="action-button-module__btn___3zkzb btn action-button-module__danger___3XnaV btn__danger"
              onClick={downloadAllFiles}
            >
              {isDownloading ? (
                <div className="page-size-button-module__loader_container___b-j91">
                  <div className="page-size-button-module__loader___7NrhS" />
                </div>
              ) : (
                <>
                  <div className="action-button-module__title___1uZt_">{t('DownloadAll')}</div>
                  <span aria-live="polite" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="course-resources-module__container___2oiwU">
        {courseResourcesData &&
          courseResourcesData.map((courseResource: CourseResourcesData) => (
            <div
              key={courseResource.id}
              className="course-resource-module__container___32hLf course-resources-module__resource___1laTs"
            >
              <div className="course-resource-module__header___2RZNu">
                <div className="course-resource-module__icon_container___2wGl2 course-resource__icon_container">
                  <span className="icon icon-resource-other course-resource-module__icon___1ecj9" />
                </div>
                <div
                  title={courseResource.name}
                  className="course-resource-module__title___3tx2O course-resource__title"
                >
                  {courseResource.name}
                </div>
                <div className="action-button-module__container___2O6X7">
                  <button
                    type="button"
                    aria-disabled="false"
                    aria-label="Other Resource Open test Resources"
                    className="action-button-module__btn___3zkzb btn"
                    onClick={() => openResourceURL(courseResource.uri)}
                  >
                    <div className="action-button-module__title___1uZt_">{t('Open')}</div>
                    <span aria-live="polite" />
                  </button>
                </div>
              </div>
              {courseResource.description && (
                <div className="expandable-text-module__container___2kVAW course-resource-module__description_container___23VSB course-resource__description_container">
                  <div
                    className="expandable-text__content_container expandable-text-module__content_container___1fcz4"
                    aria-hidden="false"
                    style={{ maxHeight: 90 }}
                  >
                    <div className="course-resource-module__description___1H-qU course-resource__description sanitized_html">
                      {parsedHtml(courseResource.description)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  ) : (
    <></>
  );
}
