import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './assets/css/main.css';
import './assets/css/global.css';
import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker';
import App from './App';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </StrictMode>
  );

}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
