import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaqResourceData } from '../models/absorb/resources';
import { FaqModal } from '../modals/FaqModal';
import { useModalContext } from '../contexts/modalContext';

interface Props {
  faqResourceData: FaqResourceData;
}

export function FaqCard({ faqResourceData }: Props): ReactElement {
  const { t } = useTranslation();

  const { showModal } = useModalContext();

  return (
    <div
      className="card-module__card___Y3Vej card__card"
      onClick={() => showModal(<FaqModal faqData={faqResourceData} />)}
    >
      <div className="card-top-module__card_top___1iJXr faq-card-module__card_top___LRSS3 card-top__card_top card-top-module__card_top_link___15N9A">
        <span className="accessibility-text-module__off_screen_text___FSqhy"> {faqResourceData.name}</span>
        <div className="thumbnail-module__container___14C5O icon icon-question-mark-square-bubble card-module__thumbnail___pvU81 card__thumbnail thumbnail-module__no_image___1ASug thumbnail__container" />
      </div>
      <div className="card-module__card_bottom___2pcXZ card__card_bottom">
        <button className="card-module__name___1kcxc card-module__link___1D5F- card__name" title={faqResourceData.name}>
          {faqResourceData.name}
        </button>
        <span className="card-module__name___1kcxc card__name">{faqResourceData.question}</span>
        <div className="card-module__type___3Pm9I card__type">{t('FAQs')}</div>
        <div className="card-module__card_action___1qfit faq-card__card_action">
          <div className="action-button-module__container___2O6X7">
            <button
              type="button"
              aria-disabled="false"
              aria-label={faqResourceData.question}
              className="action-button-module__btn___3zkzb btn"
            >
              <div className="action-button-module__title___1uZt_">{t('Read')}</div>
              <span aria-live="polite" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
