/* eslint-disable no-param-reassign */
import React, { ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DashboardLayout } from '../components/DashboardLayout';
import { useBrandingContext } from '../contexts/brandingContext';
import {
  ViewOptionPage,
  SECOND_URL_INDEX,
  STRUCTURED_LEARNING_TAG,
  SECOND_CURRICULUM_GROUP_MONTHS_TO_WAIT,
  SUBSEQUENT_CURRICULUM_GROUP_MONTHS_TO_WAIT,
  CURRICULUM_GROUP_COUNTER_INITIAL_VALUE,
  CURRICULUM_GROUP_COUNTER_SECOND_GROUP,
} from '../constants/common';
import { useContentViewContext } from '../contexts/contentViewContext';
import { errorHandler } from '../utils/helper';
import { CurriculumGroupView } from '../views/CurriculumGroupView';
import { CurriculumDetailsData, CurriculumData, CurriculumGroupData } from '../models/absorb/curriculum';
import {
  fetchCurriculumDetails,
  fetchCurriculum,
  fetchCurriculumResources,
  fetchCurriculumTags,
} from '../services/curriculum';
import { fetchMyCatalogCourse } from '../services/catalog';
import { fetchMyCourseEnrollment } from '../services/course';
import { DisplayContentView, TabView } from '../constants/courses';
import { CatalogCourseData } from '../models/absorb/catalog';
import { AbsorbCourseType } from '../constants/absorb';
import { ConditionalWrapper } from '../components/ConditionalWrapper';
import { CourseCardActionButton } from '../components/CourseCardActionButton';
import { CourseDetailResourceView } from '../components/CourseDetailResourceView';
import { CourseEnrollmentData, CourseResourcesData } from '../models/absorb/course';
import { ExpandableDescription } from '../components/ExpandableDescription';
import { LessonOptionViewsButton } from '../components/LessonOptionViewsButton';
import { LoadingIndicator } from '../components/Spinner';
import { PageTitle } from '../components/PageTitle';
import { PinCourseButton } from '../components/PinCourseButton';
import { PosterImage } from '../components/PosterImage';
import { RadialProgressIndicatorLarge } from '../components/RadialProgressIndicatorLarge';
import { ResourceLinks } from '../components/ResourceLinks';
import { ShareCourseButton } from '../components/ShareCourseButton';
import { TagData } from '../models/absorb/tags';
import { TagList } from '../components/TagList';
import { useCourseAccessErrorRedirect } from '../hooks/useCourseAccessErrorRedirect';
import './CurriculumPage.css';

interface Props {
  courseDataForModalPresentation?: CatalogCourseData;
  updateTilePinnedStatus?: (isPinned: boolean, courseId: string) => void;
  isModal?: boolean;
}

export function CurriculumPage({
  courseDataForModalPresentation,
  updateTilePinnedStatus,
  isModal = false,
}: Props): ReactElement {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const selectedCoursePath = path.split('/');
  // TODO: fix potential buffer overrun, URL_INDEX naming, passing in string that was manipulated outside the component
  const courseId = selectedCoursePath[SECOND_URL_INDEX];
  const { brandingCoursesSettings } = useBrandingContext();
  const {
    modalContentView,
    contentView,
    setContentView,
    setModalContentView,
    setContentViewOnPage,
    getContentViewOnPage,
  } = useContentViewContext();

  const [curriculumDetails, setCurriculumDetails] = useState<CurriculumDetailsData>();
  const [myCourseEnrollment, setMyCourseEnrollment] = useState<CourseEnrollmentData>();
  const [catalogCourse, setCatalogCourse] = useState<CatalogCourseData>();
  const [curriculum, setCurriculum] = useState<CurriculumData>();
  const [curriculumResources, setCurriculumResources] = useState<CourseResourcesData[]>([]);
  const [curriculumTags, setCurriculumTags] = useState<TagData[]>([]);
  const [tabView, setTabView] = useState(TabView.Course);
  const [showViewOptions, setShowViewOptions] = useState(false);
  const [totalRequiredCourses, setTotalRequiredCourses] = useState(0);
  const [totalCompletedCourses, setTotalCompletedCourses] = useState(0);
  const [loadingInProgress, setLoadingInProgress] = useState(true);
  const [setCourseAccessError] = useCourseAccessErrorRedirect();
  const courseIdToUse = courseDataForModalPresentation?.id || courseId;
  const viewType = isModal ? modalContentView : contentView;

  useEffect(() => {
    if (!isModal) {
      setContentView(getContentViewOnPage(ViewOptionPage.Curriculum) as DisplayContentView);
    }
  });

  useEffect(() => {
    setLoadingInProgress(true);

    fetchCurriculumDetails(courseIdToUse)
      .then((curriculumDetailsData) => {
        setCurriculumDetails(curriculumDetailsData);

        Promise.all([
          fetchMyCatalogCourse(courseIdToUse).then(setCatalogCourse),
          fetchMyCourseEnrollment(courseIdToUse).then(setMyCourseEnrollment),
          fetchCurriculum(courseIdToUse).then(setCurriculum),
          fetchCurriculumResources(courseIdToUse).then(setCurriculumResources),
          fetchCurriculumTags(courseIdToUse).then(setCurriculumTags),
        ])
          .catch(errorHandler)
          .finally(() => setLoadingInProgress(false));
      })
      .catch((err) => {
        errorHandler(err);
        setLoadingInProgress(false);
        setCourseAccessError(true);
      });

    if (courseDataForModalPresentation) {
      setCatalogCourse(courseDataForModalPresentation);
    }
  }, [courseIdToUse, courseDataForModalPresentation, isModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (curriculum?.curriculumGroups) {
      setTotalRequiredCourses(
        curriculum.curriculumGroups.reduce((total, group) => group?.requiredCourseCount + total, 0) || 0
      );
      setTotalCompletedCourses(
        curriculum.curriculumGroups.reduce((total, group) => group.enrollment?.completedCourseCount + total, 0) || 0
      );
    }
  }, [curriculum]);

  useEffect(() => {
    // calculates curriculum group unlocking only for courses with tag === 'Structured Learning'
    // isLocked is set to false by absorb system when previous curriculum group has been completed
    // algorithm below keeps the group locked until enough time has also gone by ('today' is far enough in the future from the enrollment date)
    if (myCourseEnrollment && curriculum && curriculumTags.some((tagItem) => tagItem.id === STRUCTURED_LEARNING_TAG)) {
      let groupCounter = CURRICULUM_GROUP_COUNTER_INITIAL_VALUE;
      curriculum.curriculumGroups.forEach((curriculumGroup) => {
        if (!curriculumGroup.enrollment.isLocked) {
          if (groupCounter === CURRICULUM_GROUP_COUNTER_SECOND_GROUP) {
            // keep lock on 2nd group until SECOND_CURRICULUM_GROUP_MONTHS_TO_WAIT months after enrollment date
            const enrollmentDatePlusSixMonths = moment(myCourseEnrollment.enrollmentDate)
              .add(SECOND_CURRICULUM_GROUP_MONTHS_TO_WAIT, 'months')
              .utc(true);
            if (moment(Date()) < enrollmentDatePlusSixMonths) {
              curriculumGroup.enrollment.isLocked = true;
            }
          }

          if (groupCounter > CURRICULUM_GROUP_COUNTER_SECOND_GROUP) {
            // keep lock on 3rd group until SUBSEQUENT_CURRICULUM_GROUP_MONTHS_TO_WAIT months after 2nd group unlock time,
            // 4th group until SUBSEQUENT_CURRICULUM_GROUP_MONTHS_TO_WAIT months after 3rd group and so on.
            // (groupCounter - 1) so multiplication factor works correctly in a zero based array (so not really a 'magic number').
            const enrollmentDatePlusThreeMoreMonths = moment(myCourseEnrollment.enrollmentDate)
              .add(
                SECOND_CURRICULUM_GROUP_MONTHS_TO_WAIT +
                  SUBSEQUENT_CURRICULUM_GROUP_MONTHS_TO_WAIT * (groupCounter - 1),
                'months'
              )
              .utc(true);
            if (moment(Date()) < enrollmentDatePlusThreeMoreMonths) {
              curriculumGroup.enrollment.isLocked = true;
            }
          }
        }
        groupCounter += 1; // increment counter, so not really a 'magic number' as lint rules prohibit groupCounter++ syntax.
      });
    }
  }, [myCourseEnrollment, curriculum, curriculumTags]);

  function onChangeContentView(viewChange: DisplayContentView) {
    if (isModal) {
      setModalContentView(viewChange);
    } else {
      setContentView(viewChange);
    }
    setContentViewOnPage(ViewOptionPage.Curriculum, viewChange);
    setShowViewOptions(false);
  }

  function updatePinnedStatus() {
    if (curriculumDetails) {
      setCurriculumDetails({ ...curriculumDetails, isPinned: !curriculumDetails.isPinned });
    }
  }

  // TODO: implement 'load more action button' in case the number of course groups goes past _limit
  return (
    <>
      <ConditionalWrapper condition={!isModal} wrapper={(children) => <DashboardLayout>{children}</DashboardLayout>}>
        <div className="curriculum-module__container___hDVui">
          <PageTitle title={t('Curriculum')} />
          <div aria-live="assertive" role="status" />
          <div
            className="course-detail-header-module__container___1uS5v course-detail-header__container"
            style={{
              backgroundImage: `url("${encodeURI(brandingCoursesSettings?.coursesDetails?.bannerUri || '')}")`,
            }}
          >
            {curriculumDetails && (
              <div className="course-detail-header-module__section_left___tdoyN" style={{ display: 'unset' }}>
                <div className="course-evaluation-module__question_title___CbTGT">
                  <h1
                    className="course-detail-header-module__name___3GuSs course-detail-header__name"
                    title={curriculumDetails.name}
                  >
                    {curriculumDetails.name}
                  </h1>

                  <div className="course-detail-header-module__type___3rQql">{t(curriculumDetails.courseType)}</div>
                </div>
              </div>
            )}

            <div>
              {catalogCourse && (catalogCourse.price !== null || catalogCourse.enrollmentStatus === null) && (
                <div className="action-button-module__container___2O6X7 course-detail-header-module__action_btn___3v1Zn">
                  <CourseCardActionButton cardData={catalogCourse} isIconRequired isModal />
                </div>
              )}
              <div className="course-options-module__course_options___1vuRm" role="menu">
                {curriculumDetails && (
                  <ShareCourseButton
                    courseId={curriculumDetails.id}
                    courseType={curriculumDetails.courseType}
                    courseName={curriculumDetails.name}
                  />
                )}
                {curriculumDetails && (
                  <PinCourseButton
                    updatePinnedStatus={updatePinnedStatus}
                    updateTilePinnedStatus={updateTilePinnedStatus}
                    curriculumData={curriculumDetails}
                  />
                )}
              </div>
            </div>
          </div>
          {loadingInProgress ? (
            <LoadingIndicator />
          ) : (
            <>
              <div className="course-alerts-module__banners___1PhwJ">
                <div className="course-curricula-status-module__curricula_progress_list___90dJ4" />
              </div>
              <nav>
                <div className="tab-list-module__tabs___39I9A">
                  <div
                    className={`tab-list-module__tab___1ZDk5 tab-list__tab ${
                      tabView === TabView.Course ? 'tab-list-module__tab_active___1lSYg tab-list__tab_active' : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="tab-list-module__tab_btn___31NOt tab-list__tab_btn"
                      aria-current="page"
                      onClick={() => (tabView === TabView.Course ? null : setTabView(TabView.Course))}
                    >
                      <span aria-hidden="true">{t('CourseContent')}</span>
                    </button>
                  </div>
                  {curriculumResources && curriculumResources.length > 0 && !isModal && (
                    <div
                      className={`tab-list-module__tab___1ZDk5 tab-list__tab ${
                        tabView === TabView.Resource ? 'tab-list-module__tab_active___1lSYg tab-list__tab_active' : ''
                      }`}
                    >
                      <button
                        type="button"
                        className="tab-list-module__tab_btn___31NOt tab-list__tab_btn"
                        aria-current="page"
                        onClick={() => (tabView === TabView.Resource ? null : setTabView(TabView.Resource))}
                      >
                        <span aria-hidden="true">
                          {curriculumTags.some((item) => item.id === STRUCTURED_LEARNING_TAG)
                            ? t('Experience')
                            : t('Resources')}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </nav>
              <div className="online-course-module__content___C7nkp" aria-hidden="false">
                <div className="online-course-module__content_left___3cqlT">
                  {myCourseEnrollment?.enrollmentStatus ? (
                    <RadialProgressIndicatorLarge
                      totalCompleted={totalCompletedCourses}
                      totalRequired={totalRequiredCourses}
                      progress={myCourseEnrollment?.progress}
                      courseType={curriculumDetails?.courseType}
                      enrollmentStatus={myCourseEnrollment?.enrollmentStatus || ''}
                      showProgressInTopView
                    />
                  ) : null}
                  {tabView === TabView.Course && (
                    <>
                      {curriculumDetails?.posterUris[0] && (
                        <PosterImage posterImageUrl={curriculumDetails.posterUris[0]} />
                      )}
                      <div className="course-description-module__description_container___ooKcP course-description__description_container">
                        <ExpandableDescription
                          itemName={curriculumDetails?.name || ''}
                          description={curriculumDetails?.description || ''}
                        />
                      </div>
                      <LessonOptionViewsButton
                        showViewOptions={showViewOptions}
                        contentView={viewType}
                        courseRequiresPurchase={isModal}
                        onChangeContentView={onChangeContentView}
                        onChangeContentViewClick={() => setShowViewOptions(!showViewOptions)}
                        setShowViewOptions={() => setShowViewOptions(!showViewOptions)}
                      />

                      <div>
                        <div className="online-course-syllabus-module__content_header___1c8_p">
                          <h2 className="online-course-syllabus-module__content_title___32_Qr online-course-syllabus__content_title">
                            {t('CourseContent')}
                          </h2>
                        </div>

                        <div>
                          <div className="curriculum-course-groups-module__groups___2NEH3 curriculum-course-groups__groups">
                            {curriculum?.curriculumGroups &&
                              curriculum.curriculumGroups.map((curriculumGroup: CurriculumGroupData) => (
                                <div
                                  key={curriculumGroup.id}
                                  className="curriculum-group-module__group_container___1M-An"
                                >
                                  <CurriculumGroupView
                                    curriculumGroup={curriculumGroup}
                                    curriculumRequiresPurchase={
                                      isModal ||
                                      (catalogCourse?.price !== null && catalogCourse?.price !== undefined && true)
                                    }
                                    contentView={viewType}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {tabView === TabView.Resource && (
                    <CourseDetailResourceView
                      courseResourcesData={curriculumResources}
                      courseId={courseId}
                      courseType={AbsorbCourseType.Curriculum}
                      showResourceAsExperience={curriculumTags.some((item) => item.id === STRUCTURED_LEARNING_TAG)}
                    />
                  )}
                </div>

                <div className="online-course-module__content_right___YJr4_">
                  {myCourseEnrollment?.enrollmentStatus ? (
                    <RadialProgressIndicatorLarge
                      totalCompleted={totalCompletedCourses}
                      totalRequired={totalRequiredCourses}
                      progress={myCourseEnrollment?.progress}
                      courseType={curriculumDetails?.courseType}
                      enrollmentStatus={myCourseEnrollment?.enrollmentStatus || ''}
                    />
                  ) : null}
                  {curriculumResources.length > 0 && (
                    <ResourceLinks
                      resources={curriculumResources}
                      isStructuredLearning={curriculumTags.some((item) => item.id === STRUCTURED_LEARNING_TAG)}
                    />
                  )}
                  {curriculumTags.length > 0 && (
                    <div className="tag-list-module__tags___O_Obx course-sidebar-module__tags___1n_Oi">
                      <h2 className="tag-list-module__title___3nUy0 tag-list__title body-font">{t('Tags')}</h2>
                      <TagList tags={curriculumTags} />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </ConditionalWrapper>
    </>
  );
}
