import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  title?: string;
}

export function PageTitle({ title }: Props): ReactElement {
  return (
    <Helmet defaultTitle="IADC" titleTemplate="%s | IADC">
      <title>{title}</title>
    </Helmet>
  );
}
