import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogCourseData } from '../models/absorb/catalog';
import { OnlineCourseData } from '../models/absorb/course';
import { CurriculumDetailsData } from '../models/absorb/curriculum';
import { InstructorLedDetailsData } from '../models/absorb/instructorLed';
import { pinCourse, unPinCourse } from '../services/course';
import { errorHandler } from '../utils/helper';

interface Props {
  catalogCourseData?: CatalogCourseData;
  courseData?: OnlineCourseData;
  curriculumData?: CurriculumDetailsData;
  instructorLedDetails?: InstructorLedDetailsData;
  showOptions?: boolean;
  updateTilePinnedStatus?: (isPinned: boolean, courseId: string) => void;
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
  updateShowOptions?: (option: boolean) => void;
}

export function PinCourseButton({
  catalogCourseData,
  courseData,
  curriculumData,
  instructorLedDetails,
  showOptions,
  updateTilePinnedStatus,
  updatePinnedStatus,
  updateShowOptions,
}: Props): ReactElement {
  const { t } = useTranslation();

  const pinData = courseData || catalogCourseData || curriculumData || instructorLedDetails;
  const courseName = pinData?.name || '';
  const courseId = pinData?.id || '';
  const isPinned = pinData?.isPinned;

  const [pinned, setPinned] = useState(isPinned);

  useEffect(() => {
    setPinned(isPinned);
  }, [isPinned]);

  function onChangePinStatus() {
    const apiUrl = isPinned ? unPinCourse(courseId) : pinCourse(courseId);

    apiUrl
      .then(() => {
        const updatedPinStatus = !isPinned;
        if (updatePinnedStatus) {
          updatePinnedStatus(updatedPinStatus, courseId);
          if (updateTilePinnedStatus) {
            updateTilePinnedStatus(updatedPinStatus, courseId); // this is to update the background page pin/unpin status on pin/unpin changes in popup/unenrolled course/curriculum
          }
        }
      })
      .catch(errorHandler);

    if (updateShowOptions) {
      updateShowOptions(!showOptions);
    }
  }

  return (
    <button
      type="button"
      className={`icon-button-module__btn___Zb89b course-options-module__option___1x_R5 course-options__option card-module__options_btn___tWD0o card__options_btn course-options__option_pin ${
        pinned
          ? 'course-options-module__option_active___3Ox5H course-options__option_active card-module__options_btn_active___3drGh card__options_btn_active course-options__option_pin_active'
          : ''
      }`}
      title={
        pinned
          ? t('UnpinAccessibilityText').replace('{0}', courseName || '')
          : t('PinAccessibilityText').replace('{0}', courseName || '')
      }
      role="menuitem"
      onClick={(e) => {
        e.stopPropagation();
        if (onChangePinStatus) {
          onChangePinStatus();
        }
      }}
    >
      <div className="icon icon-pin" />
      <span className="accessibility-text-module__off_screen_text___FSqhy">{` ${courseName} `}</span>
    </button>
  );
}
