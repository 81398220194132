import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalResourceData } from '../models/absorb/resources';
import { onFetchResourceUrl } from '../services/resources';
import { errorHandler } from '../utils/helper';

interface Props {
  globalResourceData: GlobalResourceData;
}

export function GlobalResourceList({ globalResourceData }: Props): ReactElement {
  const { t } = useTranslation();

  function openResourceURL() {
    onFetchResourceUrl({ id: globalResourceData.id })
      .then((url) => {
        window.open(url, '_blank');
      })
      .catch(errorHandler);
  }

  return (
    <div role="rowgroup">
      <div className="sortable-table-module__table_row_container___8gbRc">
        <div
          className="sortable-table-module__table_row___3N1yN sortable-table__table_row search-result-list-items-module__navigable_row___32nQo"
          role="row"
        >
          <div
            className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__name_column___1ZNQm"
            role="cell"
          >
            <div className="name-column-module__search_name_container___2IN1C resource-name-column__search_name_container">
              <span
                className="icon icon-resource-pdf name-column-module__icon___2Qisz resource-name-column__icon"
                title={globalResourceData.name}
              />
              <button
                className="name-column-module__search_name_link___2BiPB name-column-module__btn_link___1cG3l search-result-list-items-module__search_link___2R057 name-column-module__search_btn_name___B_0eR resource-name-column__search_name"
                title={globalResourceData.name}
                aria-label={globalResourceData.name}
              >
                {globalResourceData.name}
              </button>
            </div>
          </div>
          <div
            className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__type_column___2EUKC"
            role="cell"
          >
            <div
              className="search-result-list-items-module__search_type___1W1lR search-list_items__search_type"
              title={t('Resource')}
            >
              {t('Resource')}
            </div>
          </div>
          <div
            className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__rating_column___3WeuH"
            role="cell"
          />
          <div
            className="sortable-table-module__table_row_item___1wFbP sortable-table__table_row_item search-result-list-items-module__actions_column___25qgm"
            role="cell"
          >
            <div className="action-column-module__search_actions___1anJD resource-action-column__search_actions">
              <div className="action-button-module__container___2O6X7 action-column-module__search_action_btn___25zuX">
                <button
                  type="button"
                  aria-disabled="false"
                  aria-label={globalResourceData.name}
                  className="action-button-module__btn___3zkzb btn"
                  onClick={() => openResourceURL()}
                >
                  <div className="action-button-module__title___1uZt_">{t('Open')}</div>
                  <span aria-live="polite" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
