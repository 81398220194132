import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayContentView } from '../constants/courses';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

interface ViewOptionsButtonProps {
  showViewOptions: boolean;
  contentView: DisplayContentView;
  onChangeContentView: (viewChange: DisplayContentView) => void;
  setShowViewOptions: (show: boolean) => void;
}

export function ViewOptionsButton({
  showViewOptions,
  contentView,
  onChangeContentView,
  setShowViewOptions,
}: ViewOptionsButtonProps): ReactElement {
  const { t } = useTranslation();

  const viewOptionRef = useRef(null);
  useOnClickOutside(viewOptionRef, handleOutsideClicks);

  function handleOutsideClicks() {
    setShowViewOptions(false);
  }

  return (
    <div className="view-options-module__view_options___3-fnT catalog-module__view_options___8zYVF catalog__view_options">
      {showViewOptions && (
        <div ref={viewOptionRef} className="view-options-module__container___13ZzL view-options__container">
          <div className="view-options-module__options____1XHW view-options__options" role="menu">
            <button
              type="button"
              className={`icon-button-module__btn___Zb89b view-options-module__option___3gz7Z view-options__option view-options__option_cards  ${
                contentView === DisplayContentView.Cards ? 'view-options__option_active' : ''
              }`}
              title={t('CardView')}
              role="menuitem"
              onClick={() => onChangeContentView(DisplayContentView.Cards)}
            >
              <div className="icon icon-view-cards" />
            </button>
            <button
              type="button"
              className={`icon-button-module__btn___Zb89b view-options-module__option___3gz7Z view-options__option view-options__option_detailed ${
                contentView === DisplayContentView.Detailed ? 'view-options__option_active' : ''
              }`}
              title={t('DetailView')}
              role="menuitem"
              onClick={() => onChangeContentView(DisplayContentView.Detailed)}
            >
              <div className="icon icon-view-detailed" />
            </button>
            <button
              type="button"
              className={`icon-button-module__btn___Zb89b view-options-module__option___3gz7Z view-options__option view-options__option_list ${
                contentView === DisplayContentView.List ? 'view-options__option_active' : ''
              }`}
              title={t('ListView')}
              role="menuitem"
              onClick={() => onChangeContentView(DisplayContentView.List)}
            >
              <div className="icon icon-view-list" />
            </button>
            {/* TODO: E1-518 commented for future use as we are not implementing calender in current phase */}
            {/* <button type="button" className="icon-button-module__btn___Zb89b view-options-module__option___3gz7Z view-options__option view-options__option_calendar" title="Calendar View" role="menuitem" onClick={() => onChangeView('calendar')}>
          <div className="icon icon-view-calendar"/>
        </button> */}
          </div>
        </div>
      )}
      <button
        type="button"
        className="view-options-module__btn___o29AD view-options__btn"
        title={t('ChooseView')}
        aria-label={t('ChooseView')}
        aria-describedby="chooseViewDescribedBy10"
        aria-expanded="true"
        onClick={() => setShowViewOptions(!showViewOptions)}
      >
        {contentView === DisplayContentView.Cards && <span className="icon icon-view-cards" />}
        {contentView === DisplayContentView.Detailed && <span className="icon icon-view-detailed" />}
        {contentView === DisplayContentView.List && <span className="icon icon-view-list" />}
      </button>
      <span id="chooseViewDescribedBy10" className="accessibility-text-module__off_screen_text___FSqhy">
        {t('CardView')} {t('Selected')}
      </span>
    </div>
  );
}
