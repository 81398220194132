import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseChaptersData } from '../models/absorb/course';
import { LessonsData } from '../models/absorb/lessons';
import { LessonCardActionButton } from './LessonCardActionButton';
import { getCourseStatusIconClass } from '../utils/helper';
import { RadialProgressIndicatorSmall } from './RadialProgressIndicatorSmall';

interface Props {
  courseChapter: CourseChaptersData;
  openPlayer: (id: string) => void;
  courseRequiresPurchase: boolean;
}

export function LessonList({ courseChapter, openPlayer, courseRequiresPurchase }: Props): ReactElement {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(true);

  function getLessonTranslation(): string {
    let lessonText = t('Lessons');

    if (lessonText.includes('{0}')) {
      lessonText = lessonText.replace('{0}', '');
    }

    return lessonText;
  }

  return (
    <>
      <div className="chapter-module__chapter___3TwFB chapter__chapter">
        {courseChapter.returnedItems > 0 && (
          <h2 className="chapter-module__header___27h-H body-font">
            <div className="chapter-module__title_bar___36Pd0 chapter__title_bar" aria-expanded="true">
              <div className="chapter-module__title_wrapper___uTD2H" onClick={() => setShowContent(!showContent)}>
                <div
                  className={`chapter-module__icon___3s6Xw icon ${
                    showContent ? 'icon-arrow-up' : 'icon-arrow-down'
                  } chapter__icon`}
                />
                <div className="chapter-module__title___1qKXa chapter__title" title={courseChapter.name}>
                  {courseChapter.name}
                </div>
              </div>
              <div className="chapter-module__lesson_count___1ZJ1n chapter__lesson_count">
                {courseChapter.returnedItems}
                {getLessonTranslation()}
              </div>
            </div>
          </h2>
        )}

        {showContent &&
          courseChapter.lessons.map((lesson: LessonsData) => (
            <div key={lesson.id} className="lesson-item-module__item___3MOh_">
              <div className="lesson-item-module__title_bar___3B1za">
                <div className="lesson-item-module__radial_progress___32zDr" aria-hidden="true">
                  <div title={t(lesson.enrollment?.status)} className="status-module__status___1jZfy status__status">
                    <div className="radial-progress-bar__radial_progress_bar">
                      <RadialProgressIndicatorSmall
                        courseRequiresPurchase={courseRequiresPurchase}
                        progress={lesson.enrollment?.progress}
                        enrollmentStatus={lesson.enrollment?.status}
                      />
                    </div>
                    <div className="status-module__icon_container___3SHTZ">
                      <span
                        className={lesson.enrollment?.status ? getCourseStatusIconClass(lesson.enrollment.status) : ''}
                      />
                    </div>
                  </div>
                </div>
                <div title={lesson.name} className="lesson-item-module__title___3kQ0l lesson-item__title">
                  {lesson.name}
                </div>
              </div>
              {lesson.enrollment?.status && (
                <div className="action-button-module__container___2O6X7">
                  <LessonCardActionButton lessonData={lesson} openPlayer={openPlayer} />
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
}
