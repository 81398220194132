import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MyLessonData } from '../models/absorb/lessons';
import { postLessonAttempt, updateLessonActivity } from '../services/lesson';
import { errorHandler } from '../utils/helper';

interface Props {
  myLesson: MyLessonData;
  closeCoursePlayer: () => void;
}

export function ObjectLesson({ myLesson, closeCoursePlayer }: Props): ReactElement {
  const { t } = useTranslation();

  function onDownloadLessonObject() {
    postLessonAttempt(myLesson.id)
      .then((data) => {
        updateLessonActivity(myLesson.id, data.attemptId, { verb: 'Finish', status: 'Complete' });
      })
      .catch(errorHandler);
    return true;
  }

  return (
    <div className="object-lesson-player-module__container___aCCyF">
      <div className="modal-message-module__modal_message_wrapper___36OBi modal-message__modal_message_wrapper">
        <div className="modal-message-module__modal_message___3vcVX">
          <span className="icon icon-download file-download-lesson-module__download_icon___39OhG" />
          {t('RequiresDownloadFile')}
          <div>
            <a
              className="file-download-lesson-module__download_link___3iu2B file-download-lesson__download_link"
              onClick={onDownloadLessonObject}
              href={myLesson.source}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('DownloadFile')}
            </a>
          </div>
          <div>
            <span
              className="file-download-lesson-module__close_window_link___2qsUR file-download-lesson__close_window_link"
              onClick={closeCoursePlayer}
            >
              <span className="icon icon-close-browsers file-download-lesson-module__close_window_icon___3KJs4" />
              {t('CloseThisWindow')}
            </span>
            {` ${t('OnceCompletedActivity')} `}
          </div>
        </div>
      </div>
    </div>
  );
}
