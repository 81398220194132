import React, { ReactElement } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useLoginContext } from '../contexts/loginContext';
import { TermsAndConditionsPage } from '../pages/TermsAndConditionsPage';


export function PrivateRoute(): ReactElement {
  const { loggedIn, termsAndConditions } = useLoginContext();
  const navigate = useNavigate();

  if (loggedIn) {
    if (termsAndConditions && !termsAndConditions.currentUserMustAcceptTermsAndConditions) {
      return (
        <Outlet />
      );
    }

    return (<TermsAndConditionsPage />);
  }

  // state={{ from: props.location }}

  navigate('/');

  return <></>;
}

