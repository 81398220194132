import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from '../contexts/modalContext';
import { Modal } from './Modal';

interface Props {
  paymentError?: string;
}

export function PaymentSuccessModal({ paymentError }: Props): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { closeModal } = useModalContext();

  function onClickCatalog() {
    navigate('/catalog');
    closeModal();
  }

  function onClickCourse() {
    navigate('/my-courses');
    closeModal();
  }

  return (
    <Modal closeModal={closeModal}>
      <div
        className="modal-module__container___sfedc modal__container"
        role="dialog"
        aria-label={t('EditProfile')}
        aria-modal="true"
      >
        <div className="modal-module__blocker___231AR modal__blocker" />
        <div className="modal-module__modal_container___3tUB2 modal__modal_container modal-module__modal_container_top___3NfiM modal__modal_container_top">
          <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
            <div className="modal-module__modal___11QTd modal__modal modal-module__modal_small_size___1dAye modal-module__modal_top_position___Yxl4d modal-module__modal_auto_height___2feYu">
              <div className="edit-profile-module__wrapper___3DPOs">
                <div className="profile-form-module__profile_form_container___24Wwl">
                  <div className="message-modal-module__message_container___1gNBV_payment_success">
                    <div className="message-modal-module__icon___1fpDn_payment_success icon icon-check-mark" />
                    <h1 className="message-modal-module__header___PJRE9_payment_success message-modal__header">
                      {paymentError || `${t('Success')}`}
                    </h1>
                    <h1 className="message-modal-module__header___PJRE9_payment_success message-modal__header">
                      {!paymentError ? `${t('BrowseMyCoursesBlurb')}` : ''}
                    </h1>
                    <div className="password-form-module__btn_group___2DC9A_payment_success">
                      <button
                        type="button"
                        className="btn password-form-module__save_btn___2xkUk password-form__save button-module__btn___1lXcC"
                        style={{ margin: '10px', width: '170px' }}
                        title={t('Catalog')}
                        onClick={onClickCatalog}
                      >
                        {t('Catalog')}
                      </button>

                      <button
                        type="button"
                        className="btn password-form-module__save_btn___2xkUk password-form__save button-module__btn___1lXcC"
                        style={{ margin: '10px', width: '170px' }}
                        title={t('MyCourses')}
                        onClick={onClickCourse}
                      >
                        {t('MyCourses')}
                      </button>
                    </div>
                  </div>

                  <div id="editProfileSubmitButtonDescribedById65" aria-live="assertive" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
