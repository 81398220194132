import React, { ReactElement, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLoginContext } from './loginContext';
import { CatalogCourseData } from '../models/absorb/catalog';
import { CENTS_PER_DOLLAR, SALES_TAX_MULTIPLIER, SALES_TAX_RATE, ShoppingCartStep } from '../constants/shoppingCart';
import { LocalStorageItem } from '../constants/common';
import { ContextProps, createContext } from '../utils/contextHelper';
import { errorHandler } from '../utils/helper';
import { BillingDetailsStateData } from '../models/stripe/billingDetails';

interface ShoppingContextState {
  cartData: CatalogCourseData[];
  currentCartStep: number;
  billingDetailsState: BillingDetailsStateData;
  setBillingDetailsState: (billingDetails: BillingDetailsStateData) => void;
  goToFirstStep: () => void;
  goToNextStep: (path: string) => void;
  goToPrevStep: (path: string) => void;
  getCartSubTotal: () => number;
  getCartTax: () => number;
  getCartTotal: () => number;
  addToCart: (cartData: CatalogCourseData[]) => void;
  removeFromCart: (cartData: CatalogCourseData[], index: number) => void;
  clearCart: () => void;
}

const [Provider, useShoppingContext] = createContext<ShoppingContextState>(module.filename);

export { useShoppingContext };

export function ShoppingProvider({ children }: ContextProps): ReactElement {
  const { loggedIn } = useLoginContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [cartData, setCartData] = useState<CatalogCourseData[]>([]);
  const [currentCartStep, setCurrentCartStep] = useState(ShoppingCartStep.One);
  const [billingDetailsState, setBillingDetailsState] = useState<BillingDetailsStateData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    addressLine1: '',
    countryCode: '',
    provinceCode: '',
    city: '',
    postalCode: '',
  });

  const taxMultiplier = SALES_TAX_MULTIPLIER;
  const taxRate = SALES_TAX_RATE;

  useEffect(() => {
    if (loggedIn) {
      setCartData(getStoredCartData());
    }
  }, [loggedIn]);

  useEffect(() => {
    setCurrentCartStep(getStepStatus());
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  function getStepStatus() {
    const lookup: { [key: string]: ShoppingCartStep } = {
      '/cart': ShoppingCartStep.One,
      '/cart/checkout': ShoppingCartStep.Two,
      '/cart/payment': ShoppingCartStep.Three,
    };

    return lookup[location.pathname];
  }

  function goToFirstStep() {
    if (currentCartStep > ShoppingCartStep.One) {
      setCurrentCartStep(ShoppingCartStep.One);
      navigate('/cart');
    }
  }

  function goToNextStep(path: string) {
    if (currentCartStep < ShoppingCartStep.Three) {
      setCurrentCartStep(currentCartStep + 1);
      navigate(path);
    }
  }

  function goToPrevStep(path: string) {
    if (currentCartStep > ShoppingCartStep.One) {
      setCurrentCartStep(currentCartStep - 1);
      navigate(path);
    }
  }

  function addToCart(data: CatalogCourseData[]) {
    try {
      updateCart(data);
    } catch (e) {
      errorHandler(String(e));
      clearCart();
    }
  }

  function removeFromCart(data: CatalogCourseData[], index: number) {
    try {
      data.splice(index, 1);
      updateCart(data);
    } catch (e) {
      errorHandler(String(e));
      clearCart();
    }
  }

  function clearCart() {
    updateCart([]);
  }

  function updateCart(newCartData: CatalogCourseData[]) {
    const storedUserData = getUserData();
    localStorage.setItem(getHash(), JSON.stringify({ ...storedUserData, cartData: newCartData }));
    setCartData(newCartData);
  }

  function getCartTotal(): number {
    return Math.round(getCartSubTotal() * taxMultiplier);
  }

  function getCartTax(): number {
    return Math.round(getCartSubTotal() * taxRate);
  }

  function getCartSubTotal(): number {
    return cartData.reduce((acc: number, currValue: CatalogCourseData) => acc + currValue.price, 0) * CENTS_PER_DOLLAR;
  }

  return (
    <Provider
      value={{
        cartData,
        currentCartStep,
        billingDetailsState,
        setBillingDetailsState,
        goToFirstStep,
        goToNextStep,
        goToPrevStep,
        getCartSubTotal,
        getCartTax,
        getCartTotal,
        addToCart,
        removeFromCart,
        clearCart,
      }}
    >
      {children}
    </Provider>
  );
}

function getStoredCartData() {
  const storedUserData = getUserData();
  return storedUserData.cartData || [];
}

function getUserData() {
  return JSON.parse(localStorage.getItem(getHash()) || '{}');
}

function getHash() {
  return localStorage.getItem(LocalStorageItem.UserId) || '';
}
