import React, { ReactElement } from 'react';
import { SearchResultData } from '../models/absorb/search';
import { ResourceList } from './ResourceList';

interface Props {
  searchResults: SearchResultData[];
  updatePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function SearchResultListView({ searchResults, updatePinnedStatus }: Props): ReactElement {
  return (
    <div className="seach-results__panels_container">
      <ResourceList searchResults={searchResults} updatePinnedStatus={updatePinnedStatus} />;
    </div>
  );
}
