import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useBrandingContext } from '../contexts/brandingContext';
import { useModalContext } from '../contexts/modalContext';
import { parsedHtml } from '../utils/helper';

export function PrivacyModal(): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();
  const { footer } = useBrandingContext();

  return (
    <div
      className="prompt-module__container___1pgoY prompt__container"
      role="dialog"
      aria-label="Privacy Policy"
      aria-modal="true"
    >
      <div className="prompt-module__overlay___27qQL" />
      <div className="prompt-module__prompt_container___13IQn">
        <div className="prompt-module__prompt_inner_container___3F98Q">
          <div className="prompt-module__prompt___WIbhc">
            <div className="prompt-module__header___1m4To prompt__header">
              <button
                type="button"
                className="icon-button-module__btn___Zb89b prompt-module__close_btn___Q2Tp0 prompt__close_btn"
                onClick={closeModal}
              >
                <div className="icon icon-x-thin" />
                <span className="accessibility-text-module__off_screen_text___FSqhy">
                  {t('Close')} {t('PrivacyPolicy')}
                </span>
              </button>
              <div className="prompt__title">{t('PrivacyPolicy')}</div>
            </div>
            <div className="prompt-module__body___1v59Y prompt__body sanitized_html">
              {parsedHtml(footer?.privacyPolicy ? footer?.privacyPolicy : '')}
            </div>
            <div className="prompt-module__footer___30ch5">
              <button
                type="button"
                className="btn prompt-module__footer_btn___37m34 prompt__footer_btn button-module__btn___1lXcC"
                aria-label="Close Privacy Policy"
                onClick={closeModal}
              >
                {t('Close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
