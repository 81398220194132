import React, { ReactElement, useEffect, useState } from 'react';
import { ContextProps, createContext } from '../utils/contextHelper';
import { fetchLocalization, fetchLanguages } from '../services/localization';
import { DEFAULT_LANGUAGE, LocalStorageItem } from '../constants/common';
import { LanguageData } from '../models/absorb/language';
import { errorHandler, setMomentLocaleLanguage } from '../utils/helper';
import i18n from '../utils/i18n';

type LocalizationData = { [key: string]: { [key: string]: { [key: string]: string } } };

interface LanguageContextState {
  language: string;
  languages: LanguageData[];
  setLanguage: (langauge: string) => void;
}

const [Provider, useLanguageContext] = createContext<LanguageContextState>(module.filename);

export { useLanguageContext };

export function LanguageProvider({ children }: ContextProps): ReactElement {
  const [language, setLanguage] = useState(getStoredLanguageCode());
  const [languages, setLanguages] = useState<LanguageData[]>(getStoredLanguages());
  const [localizationData, setLocalizationData] = useState<LocalizationData>(getStoredLocalizationLanguage());

  useEffect(() => {
    if (languages.length === 0) {
      fetchLanguages()
        .then((data) => {
          localStorage.setItem(LocalStorageItem.Languages, JSON.stringify(data));
          setLanguages(data);
        })
        .catch(errorHandler);
    }

    if (Object.keys(localizationData).length === 0) {
      updateLocalizationData(DEFAULT_LANGUAGE);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateLocalizationData(language);
    setMomentLocaleLanguage(language);
  }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

  function updateLocalizationData(languageCode: string) {
    if (localizationData[languageCode]) {
      i18n.changeLanguage(languageCode);
      localStorage.setItem(LocalStorageItem.LanguageCode, languageCode);
    } else {
      fetchLocalization(languageCode || DEFAULT_LANGUAGE)
        .then((terms) => {
          localizationData[languageCode] = { translation: {} };
          terms.forEach((term: { id: string; value: string }) => {
            localizationData[languageCode].translation[term.id] = term.value;
          });

          i18n.addResources(languageCode, 'translation', localizationData[languageCode].translation);
          i18n.changeLanguage(language);

          localStorage.setItem(LocalStorageItem.I18nData, JSON.stringify(localizationData));
          localStorage.setItem(LocalStorageItem.LanguageCode, languageCode);

          setLocalizationData(localizationData);
          setLanguage(languageCode);
        })
        .catch(errorHandler);
    }
  }

  return (
    <Provider
      value={{
        language,
        languages,
        setLanguage,
      }}
    >
      {children}
    </Provider>
  );
}

function getStoredLocalizationLanguage() {
  const i18nData = localStorage.getItem(LocalStorageItem.I18nData);
  return i18nData ? JSON.parse(i18nData) : {};
}

function getStoredLanguageCode(): string {
  return localStorage.getItem(LocalStorageItem.LanguageCode) || DEFAULT_LANGUAGE;
}

function getStoredLanguages(): LanguageData[] {
  const languageList = localStorage.getItem(LocalStorageItem.Languages);
  return languageList ? JSON.parse(languageList) : [];
}
