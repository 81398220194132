import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CART_STEP_CLASS, ACTIVE_CART_STEP_CLASS, COMPLETED_CART_STEP_CLASS } from '../constants/styling';
import { useShoppingContext } from '../contexts/shoppingContext';
import { ShoppingCartStep } from '../constants/shoppingCart';

export function CartSteps(): ReactElement {
  const { t } = useTranslation();
  const { currentCartStep } = useShoppingContext();

  function getStepStatus(step: number): string {
    if (currentCartStep > step) {
      return COMPLETED_CART_STEP_CLASS;
    }

    const lookup: { [key: string]: string } = {
      [step]: ACTIVE_CART_STEP_CLASS,
    };

    return lookup[currentCartStep] || DEFAULT_CART_STEP_CLASS;
  }

  return (
    <div className="steps-module__steps___3FIPi">
      <div className={getStepStatus(ShoppingCartStep.One)} aria-current="step">
        <span className="accessibility-text-module__off_screen_text___FSqhy">
          {`${t('Step')} 1: ${t('ShoppingCart')}`}
        </span>
        <span aria-hidden="true">1: {t('ShoppingCart')}</span>
      </div>
      <div className={getStepStatus(ShoppingCartStep.Two)}>
        <span className="accessibility-text-module__off_screen_text___FSqhy">
          {`${t('Step')} 2: ${t('BillingInformation')}`}
        </span>
        <span aria-hidden="true">2: {t('BillingInformation')}</span>
      </div>
      <div className={getStepStatus(ShoppingCartStep.Three)}>
        <span className="accessibility-text-module__off_screen_text___FSqhy">{`${t('Step')} 3: ${t('Payment')}`}</span>
        <span aria-hidden="true">3: {t('Payment')}</span>
      </div>
    </div>
  );
}
