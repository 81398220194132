import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaqResourceData } from '../models/absorb/resources';
import { FaqModal } from '../modals/FaqModal';
import { useModalContext } from '../contexts/modalContext';
import { parsedHtml } from '../utils/helper';

interface Props {
  faqResourceData: FaqResourceData;
}

export function FaqDetail({ faqResourceData }: Props): ReactElement {
  const { t } = useTranslation();

  const { showModal } = useModalContext();

  return (
    <div className="panel-module__panel___2njx7 panel__panel">
      <div className="panel-module__panel_header_focus___NaHeg">
        <div
          role="button"
          className="panel-module__panel_header___fkAzr panel-module__panel_header_link___j5xTN panel-module__news_panel_header___17wOm panel__panel_header"
          onClick={() => showModal(<FaqModal faqData={faqResourceData} />)}
        >
          <div className="panel-module__panel_title___2Xe9e">
            <div className="panel-module__thumbnail___1UH4o">
              <span className="icon icon-question-mark-square-bubble panel-module__item_icon___Bw_O_ panel__icon" />
            </div>
            <div className="panel-module__title_info_wrapper___3brXS panel-module__news_title_info_wrapper___JrcOs">
              <div className="panel-module__title_wrapper___26Xj2 panel-module__news_title_wrapper___3lUT5">
                <div title={faqResourceData.question} className="panel-module__name___3Vd6B">
                  {faqResourceData.question}
                </div>
              </div>
              <div className="panel-module__news_panel_actions___7JPUE">
                <div className="action-button-module__container___2O6X7 panel-module__action_btn___QJ-s_">
                  <button
                    type="button"
                    aria-disabled="false"
                    aria-label={faqResourceData.question}
                    className="action-button-module__btn___3zkzb btn"
                  >
                    <div className="action-button-module__title___1uZt_">{t('Read')}</div>
                    <span aria-live="polite" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="panel-module__panel_body___3vUWN panel__panel_body">
        <div className="panel-module__panel_main___2poim">
          <div className="panel-module__panel_description___3xfj7 panel__panel_description">
            <div className="expandable-text-module__container___2kVAW panel-module__description___3oKEP panel__description">
              <div
                className="expandable-text__content_container expandable-text-module__content_container___1fcz4"
                aria-hidden="false"
              >
                <div className="panel-module__faq_section___36GC5">
                  <div className="panel-module__faq_label___2jifY">Q:</div>
                  <div> {faqResourceData.question}</div>
                </div>
                <div className="panel-module__faq_section___36GC5">
                  <div className="panel-module__faq_label___2jifY">A:</div>
                  <div className="sanitized_html">{parsedHtml(faqResourceData.answer || '')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
