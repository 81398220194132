import React, { ReactElement, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import {
  BASE_10,
  FIRST_URL_INDEX,
  isOnGlobalSearchPage,
  isOnMyCoursesPage,
  SEARCH_DEFAULT_PAGE_OFFSET,
  SECOND_URL_INDEX,
} from '../constants/common';
import { SortCoursesBy } from '../constants/courses';
import { SearchFilterFlags, SearchFilterParams } from '../models/absorb/search';
import { ContextProps, createContext } from '../utils/contextHelper';
import { appendUrlParams, getSearchSwitch, getTypeOfSearchSwitch, setAllEntityFlags } from '../utils/helper';
import { ABSORB_TAGS, ABSORB_VENDOR, ABSORB_VENUE_LOCATION, ABSORB_VENUE_TYPE } from '../constants/absorb';

interface SearchContextState {
  currentSearchUri: string;
  previousSearchUri: string;
  searchFilter: SearchFilterParams;
  pageOffset: number;
  searchTerm: string;
  searchSwitches: SearchFilterFlags;
  courseEntities: SearchFilterFlags;
  otherEntities: SearchFilterFlags;
  courseRating: number;
  searchName: string;
  advancedFilterOptions: string[];
  advancedFilter: SearchFilterParams;
  instructor: string;
  disableFilter: boolean;
  category: string;
  sortBy: string;
  setSearchFilter: (filter: SearchFilterParams) => void;
  setPageOffset: (pageOffset: number) => void;
  setSearchTerm: (term: string) => void;
  setSearchSwitches: (switches: SearchFilterFlags) => void;
  setCourseEntities: (entities: SearchFilterFlags) => void;
  setOtherEntities: (entities: SearchFilterFlags) => void;
  setCourseRating: (rating: number) => void;
  setSearchName: (name: string) => void;
  setAdvancedFilterOptions: (option: string[]) => void;
  setAdvancedFilter: (filter: SearchFilterParams) => void;
  setInstructor: (instructor: string) => void;
  setDisableFilter: (value: boolean) => void;
  setCategory: (category: string) => void;
  removeAdvancedFilter: (filter: string) => void;
  resetSearchFilter: () => void;
  resetPageOffset: () => void;
  incrementPageOffset: () => void;
  setSortBy: (sortBy: SortCoursesBy) => void;
  isOnPageToPreserveSearchCapabilities: () => boolean;
  isOnPageWithSearchCapabilities: () => boolean;
  getSearchSwitchInitialValue: () => void;
  setSearchSwitch(switchValue: string, isCompletedSwitch?: boolean): void;
}

const [Provider, useSearchContext] = createContext<SearchContextState>(module.filename);

export { useSearchContext };

export function SearchProvider({ children }: ContextProps): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const [lastLocationStored, setLastLocationStored] = useState(location);
  const lastLocation = lastLocationStored;
  useEffect(() => {
    setLastLocationStored(location);
  }, [location])

  const [currentSearchUri, setCurrentSearchUri] = useState('');
  const [previousSearchUri, setPreviousSearchUri] = useState('');
  const [nextSearchUri, setNextSearchUri] = useState('');
  const [searchFilter, setSearchFilter] = useState<SearchFilterParams>({});
  const [pageOffset, setPageOffset] = useState(SEARCH_DEFAULT_PAGE_OFFSET);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchSwitches, setSearchSwitches] = useState<SearchFilterFlags>({
    showCompleted: getSearchSwitchInitialValue(),
    showCategories: getSearchSwitchInitialValue(true),
  });
  const [courseEntities, setCourseEntities] = useState<SearchFilterFlags>({
    Curriculum: false,
    OnlineCourse: false,
    InstructorLedCourse: false,
  });
  const [otherEntities, setOtherEntities] = useState<SearchFilterFlags>({
    Lesson: false,
    GlobalResource: false,
    NewsArticle: false,
    Poll: false,
    Faq: false,
  });
  const [courseRating, setCourseRating] = useState(0);
  const [searchName, setSearchName] = useState('');
  const [advancedFilterOptions, setAdvancedFilterOptions] = useState<string[]>([]);
  const [advancedFilter, setAdvancedFilter] = useState<SearchFilterParams>({
    [ABSORB_TAGS]: '',
    [ABSORB_VENDOR]: '',
    [ABSORB_VENUE_TYPE]: '',
  });
  const [instructor, setInstructor] = useState('');
  const [disableFilter, setDisableFilter] = useState(true);
  const [category, setCategory] = useState('');
  const [sortBy, setSortBy] = useState('');

  useEffect(() => {
    if (nextSearchUri !== currentSearchUri && isOnPageWithSearchCapabilities()) {
      if (!isOnPageToPreserveSearchCapabilities()) {
        setPreviousSearchUri(buildPreviousUri());
      }

      if (nextSearchUri !== '') {
        try {
          setCurrentSearchUri(nextSearchUri);
          setCategory('');
          navigate(nextSearchUri, { replace: true });
        } catch { } // eslint-disable-line no-empty
      }
    }
  }, [nextSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      category === '' &&
      location.pathname.split('/')[FIRST_URL_INDEX] !== lastLocation?.pathname.split('/')[FIRST_URL_INDEX] &&
      !isOnPageToPreserveSearchCapabilities()
    ) {
      resetSearchFilter();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateAllSearchFilterParamsOnPageLoad();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateSearchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchTerm,
    searchSwitches,
    courseEntities,
    otherEntities,
    courseRating,
    searchName,
    instructor,
    sortBy,
    advancedFilter,
  ]);

  useEffect(() => {
    updateUri();
    resetPageOffset();
  }, [searchFilter, category]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOnPageToPreserveSearchCapabilities()) {
      updateAllSearchFilterParamsOnPageLoad();
    }
  }, [lastLocation?.pathname, currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  function updateAllSearchFilterParamsOnPageLoad() {
    if (isOnPageWithSearchCapabilities()) {
      const searchParams = decodeURI(location.search);
      const queryParams = QueryString.parse(location.search);
      const completeUrl = location.pathname.split('/');
      setSearchTerm(queryParams.term?.toString() || '');
      setSearchSwitches(extractFlagsFromGlobalSearchUrl(searchSwitches, searchParams));
      setCourseEntities(extractFlagsFromGlobalSearchUrl(courseEntities, searchParams));
      setOtherEntities(extractFlagsFromGlobalSearchUrl(otherEntities, searchParams));
      setCourseRating(parseInt(queryParams.courseRating?.toString() || '0', BASE_10));
      setSearchName(queryParams.name?.toString() || '');
      setInstructor(queryParams.instructor?.toString() || '');
      setSortBy(queryParams.sort as SortCoursesBy);
      setAdvancedFilterOptions(extractAdvancedFilterOptionsFromQueryParams(queryParams));
      setAdvancedFilter(extractAdvancedFilterFromQueryParams(queryParams));
      if (completeUrl[SECOND_URL_INDEX]) {
        setCategory(completeUrl[SECOND_URL_INDEX]);
      }
    }
  }

  function updateSearchFilter() {
    setSearchFilter({
      term: searchTerm,
      name: searchName,
      ...extractSearchSwitchesThatAreTrue(),
      entityTypes: isOnGlobalSearchPage() ? buildEntitySearchString({ ...courseEntities, ...otherEntities }) : '',
      courseTypes: !isOnGlobalSearchPage() ? buildEntitySearchString(courseEntities) : '',
      courseRating,
      tags: advancedFilter[ABSORB_TAGS],
      vendor: advancedFilter[ABSORB_VENDOR],
      venueTypes: advancedFilter[ABSORB_VENUE_TYPE],
      country: advancedFilter.country,
      province: advancedFilter.province,
      city: advancedFilter.city,
      sort: sortBy,
      instructor,
    });
  }

  function resetSearchFilter() {
    resetPageOffset();
    setCurrentSearchUri('');
    setNextSearchUri('');
    if (lastLocation?.pathname !== '/global-search' && !isOnGlobalSearchPage()) {
      setSearchTerm('');
    }
    setSearchSwitches({
      showCompleted: isOnMyCoursesPage() ? getSearchSwitchInitialValue(true) : false,
      showCategories: getSearchSwitchInitialValue(),
    });
    setCourseEntities(setAllEntityFlags(courseEntities, false));
    setOtherEntities(setAllEntityFlags(otherEntities, false));
    setCourseRating(0);
    setSearchName('');
    if (lastLocation?.pathname !== '/my-courses' && instructor !== '') {
      setInstructor('');
    }
    setAdvancedFilterOptions([]);
    setAdvancedFilter({});
    setDisableFilter(true);
    setCategory('');
    setSortBy('');
  }

  function buildEntitySearchString(entities: SearchFilterFlags): string {
    const checkedEntities = Object.keys(entities).filter((key) => entities[key]);
    return checkedEntities.length ? `${checkedEntities.join(',')}` : '';
  }

  function resetPageOffset() {
    setPageOffset(SEARCH_DEFAULT_PAGE_OFFSET);
  }

  function incrementPageOffset() {
    setPageOffset(pageOffset + 1);
  }

  function updateUri() {
    setNextSearchUri(buildUri());
  }

  function buildUri() {
    const params: SearchFilterParams = {};

    Object.keys(searchFilter).forEach((key) => {
      if (searchFilter[key]) {
        params[key] = searchFilter[key];
      }
    });

    let urlPath = location.pathname;

    if (category !== '' && searchFilter?.showCategories) {
      urlPath = `${urlPath}/${category}`;
    } else if (location.pathname.split('/')[SECOND_URL_INDEX] && searchFilter.showCategories === undefined) {
      urlPath = `/${location.pathname.split('/')[FIRST_URL_INDEX]}`;
    } else {
      setCategory('');
    }

    return appendUrlParams(`${urlPath}`, QueryString.stringify(params), '?');
  }

  function buildPreviousUri() {
    const params: SearchFilterParams = {};

    Object.keys(searchFilter).forEach((key) => {
      if (searchFilter[key]) {
        params[key] = searchFilter[key];
      }
    });

    return appendUrlParams(
      `/${lastLocation?.pathname.split('/')[FIRST_URL_INDEX]}`,
      QueryString.stringify(params),
      '?'
    );
  }

  function extractAdvancedFilterOptionsFromQueryParams(queryParams: QueryString.ParsedQuery): string[] {
    const lookup: { [key: string]: string } = {
      tags: ABSORB_TAGS,
      vendor: ABSORB_VENDOR,
      venueTypes: ABSORB_VENUE_TYPE,
      country: ABSORB_VENUE_LOCATION,
      province: ABSORB_VENUE_LOCATION,
      city: ABSORB_VENUE_LOCATION,
    };

    const options: string[] = [];

    Object.keys(lookup).forEach((key) => {
      if (Object.keys(queryParams).includes(key)) options.push(lookup[key]);
    });

    const uniqueOptions = options.filter((option, pos) => {
      return options.indexOf(option) === pos;
    });

    return uniqueOptions;
  }

  function extractAdvancedFilterFromQueryParams(queryParams: QueryString.ParsedQuery): SearchFilterParams {
    return {
      ...extractAdvancedFilterItem(ABSORB_TAGS, queryParams.tags),
      ...extractAdvancedFilterItem(ABSORB_VENDOR, queryParams.vendor),
      ...extractAdvancedFilterItem(ABSORB_VENUE_TYPE, queryParams.venueTypes),
      ...extractAdvancedFilterItem('country', queryParams.country),
      ...extractAdvancedFilterItem('province', queryParams.province),
      ...extractAdvancedFilterItem('city', queryParams.city),
      ...extractAdvancedFilterItem('tags', queryParams.tags),
    };
  }

  function extractAdvancedFilterItem(key: string, value: string | (string | null)[] | null): SearchFilterParams {
    return value ? { [key]: value.toString() } : {};
  }

  function removeAdvancedFilter(filterType: string) {
    setAdvancedFilterOptions(advancedFilterOptions.filter((option) => option !== filterType));

    if (filterType === ABSORB_VENUE_LOCATION) {
      const { [filterType]: PropValue, country, province, city, ...rest } = advancedFilter;
      setAdvancedFilter(rest);
    } else {
      const { [filterType]: propValue, ...rest } = advancedFilter;
      setAdvancedFilter(rest);
    }
  }

  function extractSearchSwitchesThatAreTrue() {
    return Object.fromEntries(Object.entries(searchSwitches).filter(([, v]) => v));
  }

  function extractFlagsFromGlobalSearchUrl(entities: SearchFilterFlags, url: string): SearchFilterFlags {
    const result: SearchFilterFlags = {};

    Object.keys(entities).forEach((key) => {
      result[key] = url.includes(key);
    });

    return result;
  }

  function isOnPageWithSearchCapabilities() {
    return (
      location.pathname === '/global-search' ||
      location.pathname.includes('/resources') ||
      location.pathname.includes('/my-courses') ||
      location.pathname.includes('/catalog')
    );
  }

  const isOnPageToPreserveSearchCapabilities = () =>
    lastLocation?.pathname.includes('/course') ||
    lastLocation?.pathname.includes('/curriculum') ||
    lastLocation?.pathname.includes('/instructor-led') ||
    false;

  function getSearchSwitchInitialValue(isCompletedSwitch?: boolean): boolean {
    const currentSwitch = getTypeOfSearchSwitch(isCompletedSwitch);
    return getSearchSwitch(currentSwitch);
  }

  function setSearchSwitch(switchValue: string, isCompletedSwitch?: boolean): void {
    const currentSwitch = getTypeOfSearchSwitch(isCompletedSwitch);
    localStorage.setItem(currentSwitch, switchValue);
  }

  return (
    <Provider
      value={{
        currentSearchUri,
        previousSearchUri,
        searchFilter,
        pageOffset,
        searchTerm,
        searchSwitches,
        courseEntities,
        otherEntities,
        courseRating,
        searchName,
        advancedFilterOptions,
        advancedFilter,
        instructor,
        disableFilter,
        category,
        sortBy,
        setSearchFilter,
        setPageOffset,
        setSearchTerm,
        setSearchSwitches,
        setCourseEntities,
        setOtherEntities,
        setCourseRating,
        setSearchName,
        setAdvancedFilterOptions,
        setAdvancedFilter,
        setInstructor,
        setDisableFilter,
        setCategory,
        removeAdvancedFilter,
        resetSearchFilter,
        resetPageOffset,
        incrementPageOffset,
        setSortBy,
        isOnPageToPreserveSearchCapabilities,
        isOnPageWithSearchCapabilities,
        getSearchSwitchInitialValue,
        setSearchSwitch,
      }}
    >
      {children}
    </Provider>
  );
}
