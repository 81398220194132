import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageModal } from '../modals/MessageModal';
import { useMessagesContext } from '../contexts/messagesContext';
import { useModalContext } from '../contexts/modalContext';
import { convertToTimeAgoDate, parsedHtml } from '../utils/helper';
import { MessageData } from '../models/absorb/message';

interface Props {
  message: MessageData;
}

export function MessageList({ message }: Props): ReactElement {
  const { t } = useTranslation();

  const { removeMessage } = useMessagesContext();
  const { showModal } = useModalContext();

  return (
    <div className="messages-menu-module__panel_content___3dPjr messages-menu__panel_content">
      <div className="messages-menu-module__message_item___e97o0 messages-menu__message_item">
        <span
          className={`icon ${
            message.isPriority
              ? 'icon-exclamation-mark messages-menu-module__priority_icon___5ILTG messages-menu__message_priority_icon'
              : 'icon-profile messages-menu-module__system_icon___jIhEr messages-menu__message_system_icon'
          }`}
        />
        <button
          type="button"
          className="messages-menu-module__message_details___3U4jD messages-menu__message_button"
          onClick={() => showModal(<MessageModal messageData={message} />)}
        >
          <div>
            <div className="messages-menu-module__message_sender___30Rxn messages-menu__message_sender" />
            <p
              className="messages-menu-module__message_subject___22sYA messages-menu__message_subject"
              title={message.subject}
            >
              {parsedHtml(message.subject)}
            </p>
            <div
              className="messages-menu-module__message_date___22RAo messages-menu__message_date"
              title={new Date(message.sendDate).toDateString()}
            >
              {convertToTimeAgoDate(message.sendDate)}
            </div>
          </div>
        </button>
        <button
          type="button"
          className="messages-menu-module__dismiss_btn___3phFA icon icon-x-thin messages-menu__dismiss_btn"
          aria-label={`${t('Dismiss')} ${message.subject}`}
          onClick={() => removeMessage(message)}
        />
      </div>
    </div>
  );
}
