import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurriculumProgressDetailData } from '../models/absorb/course';

interface Props {
  curriculumProgressDetail: CurriculumProgressDetailData;
}

export function CurriculumStatusListItem({ curriculumProgressDetail }: Props): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onClickCurriculum() {
    navigate(`/curriculum/${curriculumProgressDetail.curriculumId}`);
  }

  return (
    <div className="course-curricula-status-module__curriculum_progress___2EdwH">
      <button
        type="button"
        aria-label={curriculumProgressDetail.curriculumName}
        title={curriculumProgressDetail.curriculumName}
        onClick={() => onClickCurriculum()}
        className="icon icon-back-arrow course-curricula-status-module__back_button___2qIqg course-curricula-status__back_button"
      />
      <div className="course-curricula-status-module__curriculum_header___34U2F">
        <div className="course-curricula-status-module__curriculum_text___3R8p0">
          {t('CourseIsPartOfCurriculum')}
          <div className="course-curricula-status-module__curriculum_name___29mJT course-curricula-status__curriculum_name">
            {curriculumProgressDetail.curriculumName}
          </div>
          <div className="course-curricula-status-module__curriculum_fraction___38Npw course-curricula-status__curriculum_fraction">
            {`${curriculumProgressDetail.coursesCompleted} / ${curriculumProgressDetail.coursesTotal} ${t('Courses')}`}
          </div>
        </div>
        <div className="progress-bar-module__container___315yd course-curricula-status-module__curriculum_progress_bar___2gKYZ progress-bar__bar">
          <div
            className="progress-bar-module__fill___JVvXF progress-bar__fill"
            style={{
              width: `${(curriculumProgressDetail.coursesCompleted / curriculumProgressDetail.coursesTotal) * 100}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
}
