import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaqData } from '../models/absorb/faqs';
import { parsedHtml } from '../utils/helper';

interface Props {
  faqData: FaqData;
}

export function FaqDetailView({ faqData }: Props): ReactElement {
  const { t } = useTranslation();

  const [isAnswerVisible, setIsAnswerVisible] = useState(false);

  return (
    <div className="question-answer-module__qa___9uYgw">
      <div
        className="question-answer-module__qa_section___2GgzM question-answer__question_section"
        role="heading"
        aria-level={1}
        aria-label={faqData.question}
      >
        <div className="question-answer-module__qa_heading___2VpWU">{t('Q')}</div>
        <div className="question-answer-module__question___3aXAg question-answer__question">{faqData.question}</div>
      </div>
      <div className="expandable-text-module__container___2kVAW question-answer__answer">
        <button
          onClick={() => setIsAnswerVisible(!isAnswerVisible)}
          type="button"
          className="icon-button-module__btn___Zb89b expandable-text-module__toggle_btn___1ZTu2 expandable-text__toggle_btn"
          title={
            isAnswerVisible
              ? t('CollapseButtonAccessibilityText').replace('{0}', faqData.question)
              : t('ExpandButtonAccessibilityText').replace('{0}', faqData.question)
          }
        >
          <div className={isAnswerVisible ? 'icon icon-arrow-up' : 'icon icon-arrow-down'} />
        </button>
        <div
          style={{ maxHeight: isAnswerVisible ? 'none' : 0 }}
          className="expandable-text__content_container expandable-text-module__content_container___1fcz4 expandable-text-module__content_container_collapsed___3tdss"
          aria-hidden="false"
        >
          <div className="question-answer-module__qa_section___2GgzM">
            <div className="question-answer-module__qa_heading___2VpWU">
              <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Answer')}:</span>
              <span aria-hidden="true">{t('A')}</span>
            </div>
            <div className="question-answer-module__answer___3beyt">{parsedHtml(faqData.answer || '')}</div>
          </div>
          <div className="expandable-text-module__content_overlay___1yrtg expandable-text__content_overlay question-answer-module__overlay___6y4KP question-answer__overlay" />
        </div>
      </div>
    </div>
  );
}
