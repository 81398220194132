import React, { ReactElement, useState, useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '../contexts/loginContext';
import { useShoppingContext } from '../contexts/shoppingContext';
import {
  STATUS_ICON_SHOPPING_CART_ADDED,
  STATUS_ICON_SHOPPING_CART,
  STATUS_ICON_COURSE_ENROLL,
} from '../constants/styling';
import { formatMoney } from '../utils/currencyCodes';
import { CatalogCourseData } from '../models/absorb/catalog';
import { AbsorbCourseType, AbsorbEnrollmentStatus, AbsorbResourceType } from '../constants/absorb';
import { enrollInCourse, fetchMyCourseEnrollment, reEnrollInCourse } from '../services/course';
import { constructCourseTypePath, errorHandler, getCourseStatusIcon, getEnrollmentStatus } from '../utils/helper';
import { SpinnerButton } from './SpinnerButton';
import { useModalContext } from '../contexts/modalContext';

interface Props {
  cardData: CatalogCourseData;
  isIconRequired?: boolean;
  isEnrollmentLocked?: boolean;
  curriculumRequiresPurchase?: boolean;
  isModal?: boolean;
}

export function CourseCardActionButton({
  cardData,
  isIconRequired,
  isEnrollmentLocked,
  curriculumRequiresPurchase,
  isModal = false,
}: Props): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loggedIn } = useLoginContext();
  const { closeModal } = useModalContext();
  const { cartData, addToCart } = useShoppingContext();

  const disableEnrollment = isEnrollmentLocked || (curriculumRequiresPurchase && cardData.enrollmentStatus === null);

  const [addedToCart, setAddedToCart] = useState(false);
  const [courseEnrollmentStatus, setCourseEnrollmentStatus] = useState(cardData.enrollmentStatus);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const filteredData = cartData.filter((item) => item.id === cardData.id);

    setAddedToCart(filteredData.length > 0);
  }, [cartData, cartData.length, cardData.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getButtonIcon();
  }, [cardData.enrollmentStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  function addCourseToCart(data: CatalogCourseData) {
    if (addedToCart || !loggedIn) return;
    addToCart([...cartData, data]);
  }

  function getButtonText() {
    if (cardData.price) {
      return addedToCart ? `${t('AddedToCart')}` : formatMoney(cardData.currencyAbbreviation, cardData.price);
    }

    if (cardData.courseType === AbsorbCourseType.InstructorLedCourse) {
      return `${t('LaunchInstructorLedCourse')}`;
    }

    if (checkForReEnrollment()) {
      return t('Reenroll');
    }

    if (courseEnrollmentStatus) {
      return t(getEnrollmentStatus(courseEnrollmentStatus));
    }

    if (!cardData.courseType || cardData.courseType === AbsorbResourceType.Faq) {
      return t('Read');
    }

    if (cardData?.price !== null) {
      return formatMoney(cardData?.currencyAbbreviation, cardData?.price);
    }

    return t('Enroll');
  }

  function checkForReEnrollment() {
    return (
      courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete &&
      cardData._links !== undefined && // eslint-disable-line no-underscore-dangle
      cardData._links.hasOwnProperty('reEnroll') // eslint-disable-line no-underscore-dangle, no-prototype-builtins
    );
  }

  function getButtonColor() {
    let color = '';

    if (courseEnrollmentStatus === AbsorbEnrollmentStatus.Complete) {
      color = 'action-button-module__success___3TuKz btn__success';
    }

    return color;
  }

  function getButtonIcon() {
    let icon = STATUS_ICON_SHOPPING_CART;

    if (cardData.price) {
      icon = addedToCart ? STATUS_ICON_SHOPPING_CART_ADDED : STATUS_ICON_SHOPPING_CART;
    } else if (checkForReEnrollment()) {
      icon = STATUS_ICON_COURSE_ENROLL;
    } else {
      icon = getCourseStatusIcon(cardData.enrollmentStatus);
    }

    return icon;
  }

  function enrollUserInCourse(courseId: string) {
    setIsLoading(true);
    enrollInCourse({ courseId })
      .then(() => fetchMyCourseEnrollment(courseId))
      .then((data) => {
        setIsLoading(false);

        if (isModal) {
          closeModal();
          navigate(constructCourseTypePath(cardData.courseType, cardData.id));
        }
        Object.assign(cardData, { enrollmentStatus: data.enrollmentStatus, enrolled: true });
        setCourseEnrollmentStatus(data.enrollmentStatus);
      })
      .catch((error) => {
        errorHandler(error);
        setIsLoading(false);
      });
  }

  function reEnrollUserInCourse(courseId: string) {
    setIsLoading(true);
    reEnrollInCourse(courseId)
      .then(() => fetchMyCourseEnrollment(courseId))
      .then((data) => {
        setCourseEnrollmentStatus(data.enrollmentStatus);
      })
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }

  function onClickCourse(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    if (addedToCart) {
      closeModal();
      navigate('/cart');
    } else if (cardData.price) {
      addCourseToCart(cardData);
    } else if (cardData?.enrollmentStatus === null) {
      enrollUserInCourse(cardData?.id);
    } else if (checkForReEnrollment()) {
      reEnrollUserInCourse(cardData?.id);
    } else {
      closeModal();
      navigate(constructCourseTypePath(cardData.courseType, cardData.id));
    }
  }

  return (
    <div className="action-button-module__container___2O6X7 course-list-module__course_action_btn___872eX">
      <button
        type="button"
        aria-disabled={disableEnrollment ? 'true' : 'false'}
        aria-label={cardData.name}
        disabled={isLoading}
        className={`${getButtonColor()} btn action-button-module__btn___3zkzb
        ${isLoading ? 'button-module__btn___1lXcC' : ''} ${disableEnrollment && 'action-button-module__btn_disabled___2bOlE btn__disabled'
          }`}
        onClick={(e) => (disableEnrollment ? null : onClickCourse(e))}
      >
        {isLoading ? (
          <SpinnerButton />
        ) : (
          <>
            <div className="action-button-module__content_container___25EH-">
              <div className="action-button-module__title___1uZt_">{getButtonText()}</div>
              <div className="action-button-module__split___3zuBM" />
              {isIconRequired && <div className={`${getButtonIcon()} icon action-button-module__icon___1A_Mv`} />}
            </div>
            <span aria-live="polite" />
          </>
        )}
      </button>
    </div>
  );
}
