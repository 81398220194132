import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { MessageModal } from '../modals/MessageModal';
import { PRIORITY_MESSAGE, SYSTEM_MESSAGE } from '../constants/messages';
import { useMessagesContext } from '../contexts/messagesContext';
import { MessageData } from '../models/absorb/message';
import { convertToTimeAgoDate, parsedHtml } from '../utils/helper';
import { ProfileTab } from '../constants/common';
import { LoadMoreActionButton } from '../components/LoadMoreActionButton';
import { useModalContext } from '../contexts/modalContext';
import { PageTitle } from '../components/PageTitle';
import './MessagesPage.css';

export function MessagesPage(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { showModal } = useModalContext();
  const {
    lowPriorityMessages,
    highPriorityMessages,
    highPriorityMessagesTotalItems,
    lowPriorityMessagesTotalItems,
    readAllMessagesSuccess,
    messageReadAll,
    fetchMessagesData,
  } = useMessagesContext();

  const [selectedType, setSelectedType] = useState(QueryString.parse(location.search).messageType || PRIORITY_MESSAGE);
  const [highPriorityMessagesOffset, setHighPriorityMessagesOffset] = useState(0);
  const [lowPriorityMessagesOffset, setLowPriorityMessagesOffset] = useState(0);
  const [highPriorityMessagesIsLoading, setHighPriorityMessagesIsLoading] = useState(false);
  const [lowPriorityMessagesIsLoading, setLowPriorityMessagesIsLoading] = useState(false);

  useEffect(() => {
    if (selectedType === SYSTEM_MESSAGE) {
      setHighPriorityMessagesOffset(0);
      fetchMessagesData(false, lowPriorityMessagesOffset);
    } else if (selectedType === PRIORITY_MESSAGE) {
      setLowPriorityMessagesOffset(0);
      fetchMessagesData(true, highPriorityMessagesOffset);
    }
  }, [selectedType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (readAllMessagesSuccess) {
      fetchMessagesData(false, 0);
      fetchMessagesData(true, 0);
    }
  }, [readAllMessagesSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedType(QueryString.parse(location.search).messageType || PRIORITY_MESSAGE);
  }, [location.search]);

  function readAllMessages() {
    messageReadAll();
  }

  function onSelectedTypeTab(selected: string) {
    navigate(`/profile?tab=${ProfileTab.Messages}&messageType=${selected}`);
    setSelectedType(selected);
  }

  async function fetchMoreHighPriorityMessages() {
    const highPriorityMessagesOffsetCounter = highPriorityMessagesOffset + 1;
    setHighPriorityMessagesOffset(highPriorityMessagesOffsetCounter);
    setHighPriorityMessagesIsLoading(true);
    await fetchMessagesData(true, highPriorityMessagesOffsetCounter);
    setHighPriorityMessagesIsLoading(false);
  }

  async function fetchMoreLowPriorityMessages() {
    const lowPriorityMessagesOffsetCounter = lowPriorityMessagesOffset + 1;
    setLowPriorityMessagesOffset(lowPriorityMessagesOffsetCounter);
    setLowPriorityMessagesIsLoading(true);
    await fetchMessagesData(false, lowPriorityMessagesOffsetCounter);
    setLowPriorityMessagesIsLoading(false);
  }

  return (
    <div className="my-inbox-module__wrapper___1J8wx">
      <PageTitle title={t('Inbox')} />
      <div className="my-inbox-module__header___2Ss4Y">
        <nav>
          <div className="tab-list-module__tabs___39I9A my-inbox-module__tab_list___3fLO4">
            <div
              className={`tab-list-module__tab___1ZDk5 my-inbox-module__tab___3todE my-inbox__tab ${selectedType === PRIORITY_MESSAGE
                ? 'tab-list-module__tab_active___1lSYg tab-list__tab_active my-inbox-module__active_tab___2a0cT my-inbox__active_tab'
                : ''
                } tab-list__tab`}
              id="priority"
              onClick={() => onSelectedTypeTab(PRIORITY_MESSAGE)}
            >
              <button type="button" className="tab-list-module__tab_btn___31NOt tab-list__tab_btn" aria-current="page">
                <span className="accessibility-text-module__off_screen_text___FSqhy">{t('PriorityMessages')}</span>
                <span aria-hidden="true">{t('PriorityMessages')}</span>
              </button>
            </div>
            <div
              className={`tab-list-module__tab___1ZDk5 my-inbox-module__tab___3todE my-inbox__tab ${selectedType === SYSTEM_MESSAGE
                ? 'tab-list-module__tab_active___1lSYg tab-list__tab_active my-inbox-module__active_tab___2a0cT my-inbox__active_tab'
                : ''
                } tab-list__tab`}
              id="system"
              onClick={() => onSelectedTypeTab(SYSTEM_MESSAGE)}
            >
              <button type="button" className="tab-list-module__tab_btn___31NOt tab-list__tab_btn" aria-current="false">
                <span className="accessibility-text-module__off_screen_text___FSqhy">{t('SystemMessages')}</span>
                <span aria-hidden="true">{t('SystemMessages')}</span>
              </button>
            </div>
          </div>
        </nav>
        <button
          type="button"
          className="my-inbox-module__mark_all_btn___1SqCp my-inbox__mark_all"
          onClick={readAllMessages}
        >
          {t('MarkAllMessagesAsRead')}
        </button>
      </div>
      {selectedType === PRIORITY_MESSAGE && highPriorityMessages && (
        <>
          {highPriorityMessages.map((highPriorityMessage: MessageData) => (
            <button
              key={`high${highPriorityMessage.id}`}
              className={`message-item-module__message___37aIB message-item__message ${highPriorityMessage.isRead ? 'message-item-module__message_read___1-fuH' : ''
                }`}
              onClick={() => {
                showModal(<MessageModal messageData={highPriorityMessage} />);
              }}
            >
              <div className="icon icon-profile message-item-module__no_avatar___2ZhQB message-item__no_avatar" />
              <div className="message-item-module__high_priority___YCGCP icon icon-exclamation-mark-small message-item__high_priority" />
              <div className="message-item-module__message_content___elQQb message-item__content">
                <div className="message-item-module__subject___3HNaN message-item__subject">
                  {parsedHtml(highPriorityMessage.subject)}
                </div>
                <div className="message-item-module__body___23bIl message-item__body">
                  {parsedHtml(highPriorityMessage.message)}
                </div>
              </div>
              <div
                className="message-item-module__date___2vtjr message-item__date"
                title={new Date(highPriorityMessage.sendDate).toDateString()}
              >
                {convertToTimeAgoDate(highPriorityMessage.sendDate)}
              </div>
            </button>
          ))}

          {highPriorityMessagesTotalItems && highPriorityMessages.length < highPriorityMessagesTotalItems ? (
            <LoadMoreActionButton
              currentItemsCount={highPriorityMessages.length}
              totalItemsCount={highPriorityMessagesTotalItems}
              itemsAreLoading={highPriorityMessagesIsLoading}
              fetchMoreFunction={fetchMoreHighPriorityMessages}
            />
          ) : null}
        </>
      )}

      {selectedType === SYSTEM_MESSAGE && lowPriorityMessages && (
        <>
          {lowPriorityMessages.map((lowPriorityMessage: MessageData) => (
            <button
              key={`low${lowPriorityMessage.id}`}
              className={`message-item-module__message___37aIB message-item__message ${lowPriorityMessage.isRead ? 'message-item-module__message_read___1-fuH' : ''
                }`}
              onClick={() => {
                showModal(<MessageModal messageData={lowPriorityMessage} />);
              }}
            >
              <div className="icon icon-profile message-item-module__no_avatar___2ZhQB message-item__no_avatar" />
              <div className="message-item-module__message_content___elQQb message-item__content">
                <div className="message-item-module__subject___3HNaN message-item__subject">
                  {parsedHtml(lowPriorityMessage.subject)}
                </div>
                <div className="message-item-module__body___23bIl message-item__body">
                  {parsedHtml(lowPriorityMessage.message)}
                </div>
              </div>
              <div
                className="message-item-module__date___2vtjr message-item__date"
                title={new Date(lowPriorityMessage.sendDate).toDateString()}
              >
                {convertToTimeAgoDate(lowPriorityMessage.sendDate)}
              </div>
            </button>
          ))}

          {lowPriorityMessagesTotalItems && lowPriorityMessages.length < lowPriorityMessagesTotalItems ? (
            <LoadMoreActionButton
              currentItemsCount={lowPriorityMessages.length}
              totalItemsCount={lowPriorityMessagesTotalItems}
              itemsAreLoading={lowPriorityMessagesIsLoading}
              fetchMoreFunction={fetchMoreLowPriorityMessages}
            />
          ) : null}
        </>
      )}
    </div>
  );
}
