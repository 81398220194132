import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchGlobalSearchResults } from '../services/search';
import { useBrandingContext } from '../contexts/brandingContext';
import { useSearchContext } from '../contexts/searchContext';
import { useContentViewContext } from '../contexts/contentViewContext';
import { SearchResultsData } from '../models/absorb/search';
import { DashboardLayout } from '../components/DashboardLayout';
import { SideFilter } from '../components/SideFilter';
import { SearchResultView } from '../components/SearchResultView';
import { LoadMoreActionButton } from '../components/LoadMoreActionButton';
import { LoadingIndicator } from '../components/Spinner';
import { MessageDisplayView } from '../components/MessageDisplayView';
import { DisplayContentView, SortCoursesBy } from '../constants/courses';
import { SEARCH_DEFAULT_PAGE_OFFSET, DisplayMessages, ViewOptionPage } from '../constants/common';
import { errorHandler } from '../utils/helper';
import { ViewOptionsButton } from '../components/ViewOptionsButton';
import { AbsorbResourceType } from '../constants/absorb';
import { CatalogCourseData } from '../models/absorb/catalog';
import { PageTitle } from '../components/PageTitle';

export function GlobalSearchPage(): ReactElement {
  const { t } = useTranslation();

  const { brandingCoursesSettings } = useBrandingContext();
  const { currentSearchUri, searchFilter, pageOffset, setDisableFilter, incrementPageOffset } = useSearchContext();
  const { contentView, setContentView, setContentViewOnPage, getContentViewOnPage } = useContentViewContext();

  const [showViewOptionButton, setShowViewOptionButton] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(true);
  const [loadingMoreInProgress, setLoadingMoreInProgress] = useState(false);
  const [showRefineSearch, setShowRefineSearch] = useState(true);
  const [globalSearchResults, setGlobalSearchResults] = useState<SearchResultsData>();

  useEffect(() => {
    if (currentSearchUri.includes('/global-search')) {
      setContentView(getContentViewOnPage(ViewOptionPage.SearchResults) as DisplayContentView);
      setGlobalSearchResults(undefined);
      fetchSearchData();
    }
  }, [currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pageOffset !== SEARCH_DEFAULT_PAGE_OFFSET) {
      fetchSearchData();
    }
  }, [pageOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setGlobalSearchResults(globalSearchResults);
  }, [globalSearchResults]); // eslint-disable-line react-hooks/exhaustive-deps

  function fetchSearchData() {
    if (!searchFilter.term) {
      return;
    }

    if (!loadingMoreInProgress) {
      setSearchInProgress(true);
    }

    setDisableFilter(true);

    fetchGlobalSearchResults({ ...searchFilter, sortBy: SortCoursesBy.Relevance }, pageOffset)
      .then((data) => {
        if (globalSearchResults && pageOffset > SEARCH_DEFAULT_PAGE_OFFSET) {
          setGlobalSearchResults({
            ...globalSearchResults,
            searchResults: [...globalSearchResults.searchResults, ...data.searchResults],
          });
        } else {
          setGlobalSearchResults(data);
        }
      })
      .catch(errorHandler)
      .finally(() => {
        setSearchInProgress(false);
        setDisableFilter(false);
        setLoadingMoreInProgress(false);
      });
  }

  function loadMore() {
    setLoadingMoreInProgress(true);
    incrementPageOffset();
  }

  function onChangeContentView(viewChange: DisplayContentView) {
    setContentView(viewChange);
    setContentViewOnPage(ViewOptionPage.SearchResults, viewChange);
    setShowViewOptionButton(false);
  }

  function getSearchResultHeading(): string {
    const searchHeading = t('TotalSearchResultsFor');
    const resultCount = globalSearchResults?.totalItems?.toString() || '0';
    const resultFor = (searchFilter.term as string) || '';
    return searchHeading.replace('{0}', resultCount).replace('{1}', resultFor);
  }

  function renderSearchResultStatus() {
    if (searchInProgress) {
      return <LoadingIndicator />;
    }

    if ((globalSearchResults === undefined || globalSearchResults.totalItems === 0) && !searchInProgress) {
      return (
        <MessageDisplayView
          errorText={DisplayMessages.NoResultsMainText}
          subErrorText={DisplayMessages.NoResultsSubText}
        />
      );
    }

    function updatePinnedStatus(isPinned: boolean, courseId: string) {
      if (globalSearchResults) {
        setDisableFilter(true); // this is actually not needed here according to flow but for the card view we don't have any other option to force refresh the UI like show options in list and detailed view so forcefully added it and enabled filter again at the bottom
        const index = globalSearchResults.searchResults.findIndex((resource) => resource.resource.id === courseId);
        if (globalSearchResults.searchResults[index].resourceType === AbsorbResourceType.Course) {
          if (index > -1) {
            const filteredResource = globalSearchResults.searchResults[index].resource as CatalogCourseData;
            filteredResource.isPinned = isPinned;
            setGlobalSearchResults(globalSearchResults);
          }
        }
        setDisableFilter(false);
      }
    }

    return (
      <div className="search-results-module__search_container___cDL47 search-results__search_container">
        {globalSearchResults && (
          <SearchResultView
            searchResult={globalSearchResults}
            contentView={contentView}
            updatePinnedStatus={updatePinnedStatus}
          />
        )}
        {globalSearchResults && globalSearchResults.totalItems > globalSearchResults.searchResults.length ? (
          <LoadMoreActionButton
            currentItemsCount={globalSearchResults.searchResults.length || 0}
            totalItemsCount={globalSearchResults.totalItems}
            itemsAreLoading={loadingMoreInProgress}
            fetchMoreFunction={loadMore}
          />
        ) : null}
      </div>
    );
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('Search')} />
      <div className="search-results-module__container___1nYvN">
        <div
          className="banner-module__banner___IJO0Z banner__banner"
          style={{
            backgroundImage: `url("${encodeURI(brandingCoursesSettings?.myCoursesAndCatalog?.bannerUri || '')}")`,
          }}
        >
          <h1 className="banner-title-module__title___1mbDk banner__title">{t('SearchResults')}</h1>
        </div>
        <div className="search-results-module__search_results___3PbnX">
          <div className="search-results-module__header___2XEym">
            <div
              className={`filter-toggle-module__filter_toggle___zOXqM filter-toggle__filter_toggle ${
                showRefineSearch
                  ? ''
                  : 'filter-toggle-module__filter_toggle_hidden___3cC5a filter-toggle__filter_toggle_hidden'
              }`}
            >
              <button
                type="button"
                className="icon-button-module__btn___Zb89b filter-toggle-module__filter_toggle_btn___1itNb filter-toggle__filter_toggle_btn"
                title={showRefineSearch ? t('HideFilters') : ''}
                onClick={() => setShowRefineSearch(!showRefineSearch)}
              >
                {showRefineSearch ? (
                  <>
                    <div className="icon icon-filter-minus" />
                    <span className="filter-toggle-module__filter_toggle_message___1ucsU filter-toggle__message body-font">
                      {t('HideRefineSearch')}
                    </span>
                    <span className="accessibility-text-module__off_screen_text___FSqhy">{t('HideFilters')}</span>
                  </>
                ) : (
                  <div className="icon icon-filter-plus" />
                )}
              </button>
            </div>
            {globalSearchResults &&
              (searchInProgress ? null : (
                <div className="search-results-module__search_header_controls___2NIFe">
                  {globalSearchResults.totalItems && searchFilter.term ? (
                    <div className="search-results-module__search_info___1nFy3 search-information-module__info___2qMlt search-information__info">
                      {getSearchResultHeading()}
                    </div>
                  ) : null}
                </div>
              ))}
            <ViewOptionsButton
              showViewOptions={showViewOptionButton}
              contentView={contentView}
              onChangeContentView={onChangeContentView}
              setShowViewOptions={setShowViewOptionButton}
            />
          </div>
          <div className="search-results-module__content___2SFXE">
            <div
              className={`search-results-module__content_wrapper___1J5ZK search-results__content_wrapper ${
                searchInProgress ? 'global-page-loader' : ''
              }`}
            >
              {renderSearchResultStatus()}
            </div>
            {showRefineSearch && <SideFilter />}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
