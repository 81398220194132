import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '../../contexts/searchContext';
import { Slider } from './Slider';

export function ShowCompletedSwitch(): ReactElement {
  const { t } = useTranslation();

  const { searchSwitches, setSearchSwitches, setSearchSwitch } = useSearchContext();

  function onToggleCompletedSwitch() {
    setSearchSwitches({ ...searchSwitches, showCompleted: !searchSwitches.showCompleted });
    setSearchSwitch((!searchSwitches.showCompleted).toString(), true);
  }

  return (
    <Slider
      label={t('ShowCompleted')}
      isChecked={searchSwitches.showCompleted}
      toggleCheckbox={() => onToggleCompletedSwitch()}
    />
  );
}
