import React, { ReactElement, useState } from 'react';
import { ViewOptionPage } from '../constants/common';
import { DisplayContentView } from '../constants/courses';
import { ContextProps, createContext } from '../utils/contextHelper';

interface ContentViewContextState {
  contentView: DisplayContentView;
  modalContentView: DisplayContentView;
  setContentView: (contentView: DisplayContentView) => void;
  setModalContentView: (contentView: DisplayContentView) => void;
  setContentViewOnPage: (page: ViewOptionPage, contentView: DisplayContentView) => void;
  getContentViewOnPage: (displayViewOnPage: ViewOptionPage) => string;
}

const [Provider, useContentViewContext] = createContext<ContentViewContextState>(module.filename);

export { useContentViewContext };

export function ContentViewProvider({ children }: ContextProps): ReactElement {
  const [selectedContentView, setSelectedContentView] = useState(DisplayContentView.Cards);
  const [selectedContentModalView, setSelectedContentModalView] = useState(DisplayContentView.Detailed);

  function setContentView(contentView: DisplayContentView) {
    setSelectedContentView(
      contentView.charAt(0).toUpperCase() + contentView.slice(1) in DisplayContentView
        ? contentView
        : DisplayContentView.Cards
    );
  }

  function setModalContentView(contentView: DisplayContentView) {
    setSelectedContentModalView(contentView);
  }

  function setContentViewOnPage(page: ViewOptionPage, contentView: DisplayContentView) {
    localStorage.setItem(page, contentView);
  }

  function getContentViewOnPage(displayViewOnPage: ViewOptionPage): string {
    const lookup: { [key: string]: string | null } = {
      [ViewOptionPage.MyCourse]: localStorage.getItem('MyCourse'),
      [ViewOptionPage.Curriculum]: localStorage.getItem('Curriculum') || DisplayContentView.List,
      [ViewOptionPage.Catalog]: localStorage.getItem('Catalog'),
      [ViewOptionPage.Resources]: localStorage.getItem('Resources') || DisplayContentView.List,
      [ViewOptionPage.SearchResults]: localStorage.getItem('SearchResults'),
      [ViewOptionPage.CourseDetails]: localStorage.getItem('CourseDetails') || DisplayContentView.List,
    };
    return lookup[displayViewOnPage] || DisplayContentView.Cards;
  }

  return (
    <Provider
      value={{
        contentView: selectedContentView,
        modalContentView: selectedContentModalView,
        setContentView,
        setModalContentView,
        setContentViewOnPage,
        getContentViewOnPage,
      }}
    >
      {children}
    </Provider>
  );
}
