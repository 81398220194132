import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { DashboardLayout } from '../components/DashboardLayout';
import { CourseCard } from '../components/CourseCard';
import { LoadingIndicator } from '../components/Spinner';
import { CourseList } from '../components/CourseList';
import { CourseDetail } from '../components/CourseDetail';
import { SideFilter } from '../components/SideFilter';
import { useContentViewContext } from '../contexts/contentViewContext';
import { useSearchContext } from '../contexts/searchContext';
import { MessageDisplayView } from '../components/MessageDisplayView';
import { LoadMoreActionButton } from '../components/LoadMoreActionButton';
import { CategoriesData, CatalogCourseData } from '../models/absorb/catalog';
import { fetchMyCatalogCourseCategories, fetchMyCatalogCourses } from '../services/catalog';
import { COURSE_NOT_FOUND_PARAMS, ROOT_CATEGORY, ViewOptionPage, DisplayMessages } from '../constants/common';
import { DisplayContentView, SortCoursesBy } from '../constants/courses';
import { useBrandingContext } from '../contexts/brandingContext';
import { errorHandler } from '../utils/helper';
import { Breadcrumb } from '../components/Breadcrumb';
import { ViewOptionsButton } from '../components/ViewOptionsButton';
import { Category } from '../components/Category';
import { PageTitle } from '../components/PageTitle';
import './CatalogPage.css';


export function CatalogPage(): ReactElement {
  const { categoryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { brandingCoursesSettings } = useBrandingContext();
  const { contentView, setContentView, setContentViewOnPage, getContentViewOnPage } = useContentViewContext();
  const {
    searchFilter,
    pageOffset,
    sortBy,
    currentSearchUri,
    previousSearchUri,
    setSortBy,
    setDisableFilter,
    resetPageOffset,
    incrementPageOffset,
  } = useSearchContext();

  const [showViewOptions, setShowViewOptions] = useState(false);
  const [showRefineSearch, setShowRefineSearch] = useState(true);
  const [catalogData, setCatalogData] = useState<CatalogCourseData[]>([]);
  const [catalogCategories, setCatalogCategories] = useState<CategoriesData[]>([]);
  const [selectedCategoryData, setSelectedCategoryData] = useState<CategoriesData>();
  const [isLoading, setIsLoading] = useState(true);
  const [moreIsLoading, setMoreIsLoading] = useState(false);
  const [totalCourses, setTotalCourses] = useState(0);
  const [showCourseErrorModal, setShowCourseErrorModal] = useState(false);

  const sort = `${SortCoursesBy.Ispinned},${sortBy || SortCoursesBy.Name}`;
  const selectedCategory = categoryId ?? (searchFilter.showCategories && ROOT_CATEGORY);

  useEffect(() => {
    setContentView(getContentViewOnPage(ViewOptionPage.Catalog) as DisplayContentView);

    if (currentSearchUri.includes('/catalog')) {
      if (!moreIsLoading) {
        setIsLoading(true);
        setDisableFilter(true);
      }

      fetchMyCatalogCourses(
        {
          _sort: sort,
          ...searchFilter,
          category: selectedCategory,
        },
        pageOffset
      )
        .then((data) => {
          setCatalogData(pageOffset ? [...catalogData, ...data.courses] : data.courses);
          setTotalCourses(data.totalItems);
        })
        .catch(errorHandler)
        .finally(() => {
          setIsLoading(false);
          setDisableFilter(false);
          setMoreIsLoading(false);
        });
    }
  }, [currentSearchUri, pageOffset, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentSearchUri.includes('/catalog')) {
      fetchMyCatalogCourseCategories({
        _sort: sort,
        ...searchFilter,
        parent: ROOT_CATEGORY,
      })
        .then(setCatalogCategories)
        .catch(errorHandler);
    }
  }, [currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filteredCategory = catalogCategories.find((categoryItem) => categoryItem.id === categoryId);
    setSelectedCategoryData(filteredCategory);
  }, [catalogCategories, categoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.search === `${'?'}${COURSE_NOT_FOUND_PARAMS}`) {
      setShowCourseErrorModal(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function onChangeContentView(viewChange: DisplayContentView) {
    setContentView(viewChange);
    setContentViewOnPage(ViewOptionPage.Catalog, viewChange);
    setShowViewOptions(false);
  }

  function onChangeFilter(filterValue: SortCoursesBy) {
    resetPageOffset();
    setCatalogData([]);
    setSortBy(filterValue);
  }

  function loadMore() {
    setMoreIsLoading(true);
    incrementPageOffset();
  }

  function updatePinnedStatus(isPinned: boolean, courseId: string) {
    const coursesToUpdate = [...catalogData];
    const index = coursesToUpdate.findIndex((course) => course.id === courseId);
    if (index > -1) {
      coursesToUpdate[index].isPinned = isPinned;
      setCatalogData(coursesToUpdate);
    }
  }

  return (
    <DashboardLayout>
      <PageTitle title={t('Catalog')} />
      <div className="catalog-module__container___1shwX">
        <div aria-live="assertive" role="status" />
        <div
          className="banner-module__banner___IJO0Z banner__banner"
          style={{
            backgroundImage: `url("${encodeURI(brandingCoursesSettings?.myCoursesAndCatalog?.bannerUri || '')}")`,
          }}
        >
          {selectedCategoryData && (
            <button
              type="button"
              aria-label={t('Catalog')}
              className="icon icon-back-arrow banner-back-button-module__button___czAfx banner__back_arrow"
              title={t('Catalog')}
              onClick={() => navigate(previousSearchUri)}
            />
          )}

          <h1 className="banner-title-module__title___1mbDk banner__title">
            {selectedCategoryData?.name || t('Catalog')}
          </h1>
        </div>
        <div className="catalog-module__catalog___3V16B">
          {showCourseErrorModal && (
            <div className="catalog-module__error_message_container___1V9mL">
              <div className="catalog-module__error_message_blocker___A5G-f" />
              <div className="catalog-module__error_message___2OKA_ catalog__error_message">
                <div className="catalog-module__error_message_text___2RPj6 catalog__error_message_text">
                  {t('NoResultsID')}
                </div>
                <button
                  type="button"
                  className="icon-button-module__btn___Zb89b catalog-module__error_message_dismiss_btn___2thad catalog__error_message_dismiss_btn"
                  title={t('Close')}
                  onClick={() => setShowCourseErrorModal(false)}
                >
                  <div className="icon icon-x-thin" />
                  <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Close')}</span>
                </button>
              </div>
            </div>
          )}
          <div className="catalog-module__header___CV89E">
            <div
              className={`filter-toggle-module__filter_toggle___zOXqM filter-toggle__filter_toggle ${showRefineSearch
                ? ''
                : 'filter-toggle-module__filter_toggle_hidden___3cC5a filter-toggle__filter_toggle_hidden'
                }`}
            >
              <button
                type="button"
                className="icon-button-module__btn___Zb89b filter-toggle-module__filter_toggle_btn___1itNb filter-toggle__filter_toggle_btn"
                title={t('HideFilters')}
                onClick={() => setShowRefineSearch(!showRefineSearch)}
              >
                <div className={`icon ${showRefineSearch ? 'icon-filter-minus' : 'icon-filter-plus'}`} />
                <span className="accessibility-text-module__off_screen_text___FSqhy">
                  {showRefineSearch ? t('HideFilters') : ''}
                </span>
                <span className="filter-toggle-module__filter_toggle_message___1ucsU filter-toggle__message body-font">
                  {showRefineSearch ? t('HideRefineSearch') : ''}
                </span>
              </button>
            </div>

            {selectedCategoryData && categoryId && (
              <Breadcrumb
                breadcrumbTitle="Catalog"
                selectedCategoryData={selectedCategoryData}
                categoryId={categoryId}
              />
            )}

            <div className="catalog-module__header_controls___1UVuX">
              <div className="catalog-module__header_controls___1UVuX">
                <div>
                  <div className="dropdown-module__field___5TFai catalog-module__sort_dropdown___PhEJQ catalog__sort_dropdown">
                    <select
                      name="CatalogSortDropDown"
                      id="CatalogSortDropDown"
                      aria-label="Sort By"
                      value={sortBy}
                      onChange={(e) => onChangeFilter(e.target.value as SortCoursesBy)}
                    >
                      <option value="name">{t('Alphabetical')}</option>
                      <option value="-rating">{t('Rating')}</option>
                      <option value="-creationdate">{t('Newest')}</option>
                      <option value="-ismandatory">{t('Mandatory')}</option>
                      <option value="-price">{t('PriceHighToLow')}</option>
                      <option value="price">{t('PriceLowToHigh')}</option>
                    </select>
                    <span className="dropdown-module__dropdown_arrow___9etxZ dropdown__arrow icon icon-arrow-down" />
                  </div>
                </div>
                <ViewOptionsButton
                  showViewOptions={showViewOptions}
                  contentView={contentView}
                  onChangeContentView={onChangeContentView}
                  setShowViewOptions={setShowViewOptions}
                />
              </div>
            </div>
          </div>
          <div className="catalog-module__content___2vysm">
            <div className="catalog-module__content_wrapper___2EG6P catalog__content_wrapper">
              {
                // eslint-disable-next-line no-nested-ternary
                isLoading ? (
                  <div className="catalog-module__loader_wrapper___1L0o-">
                    <LoadingIndicator />
                  </div>
                ) : catalogData && catalogData.length === 0 ? (
                  <MessageDisplayView errorText={DisplayMessages.NoResultsMainText} />
                ) : (
                  <>
                    {catalogCategories.length > 0 && searchFilter.showCategories && !categoryId && (
                      <div className="category-list-module__categories_wrapper___YHLXw">
                        {catalogCategories.map((catalogCategory) => (
                          <Category key={catalogCategory.id} catalogCategory={catalogCategory} />
                        ))}
                      </div>
                    )}
                    <div className="catalog-module__courses_container___1jzcE">
                      {contentView === DisplayContentView.Cards && (
                        <div className="catalog-module__cards_container___35rgq catalog__cards_container">
                          {catalogData.map((catalogCourse: CatalogCourseData) => (
                            <div
                              key={catalogCourse.id}
                              className="card-carousel-module__card___vOE4F carousel__card course-card-margin"
                            >
                              <CourseCard cardData={catalogCourse} updatePinnedStatus={updatePinnedStatus} />
                            </div>
                          ))}
                        </div>
                      )}
                      {contentView === DisplayContentView.Detailed && (
                        <div className="catalog__panels_container">
                          {catalogData.map((catalogCourse: CatalogCourseData) => (
                            <CourseDetail
                              key={catalogCourse.id}
                              courseData={catalogCourse}
                              updatePinnedStatus={updatePinnedStatus}
                            />
                          ))}
                        </div>
                      )}

                      {contentView === DisplayContentView.List && (
                        <CourseList catalogData={catalogData} updatePinnedStatus={updatePinnedStatus} />
                      )}
                      {catalogData && catalogData.length < totalCourses ? (
                        <LoadMoreActionButton
                          currentItemsCount={catalogData.length}
                          totalItemsCount={totalCourses}
                          itemsAreLoading={moreIsLoading}
                          fetchMoreFunction={loadMore}
                        />
                      ) : null}
                    </div>
                  </>
                )
              }
            </div>
            {showRefineSearch && <SideFilter />}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
