import React, { ReactElement, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { MyLessonData } from '../models/absorb/lessons';
import { postLessonAttempt, updateLessonActivity } from '../services/lesson';
import { errorHandler } from '../utils/helper';
import { NextActivityOverlay } from './NextActivityOverlay';
import { VIDEO_LESSON_PROGRESS_UPDATE_FREQUENCY } from '../constants/common';

interface Props {
  lessonData: MyLessonData;
  nextLessonData?: MyLessonData;
  openCoursePlayer: (id: string) => void;
  closeCoursePlayer: () => void;
}

export function VideoLesson({ lessonData, nextLessonData, openCoursePlayer, closeCoursePlayer }: Props): ReactElement {
  const [showNextActivity, setShowNextActivity] = useState(false);

  const target = useRef(null);

  let isPlaying = false;
  let attemptId = '';

  function playerOnStart() {
    isPlaying = true;
    postLessonAttempt(lessonData.id)
      .then((data) => {
        attemptId = data.attemptId;
      })
      .catch(errorHandler);
  }

  function playerOnEnded() {
    isPlaying = false;
    if (attemptId.length > 0) {
      updateLessonActivity(lessonData.id, attemptId, { verb: 'Finish', status: 'Complete' })
        .then(() => {
          attemptId = '';
          setShowNextActivity(true);
        })
        .catch(errorHandler);
    }
  }

  function playerOnProgress(playedSeconds: number) {
    if (isPlaying && attemptId.length > 0) {
      updateLessonActivity(lessonData.id, attemptId, {
        verb: 'RecordVideoTimeSpent',
        time: Math.floor(playedSeconds * 1000),
      }).catch(errorHandler);
    }
  }

  return (
    <>
      <ReactPlayer
        ref={target}
        key={lessonData.sources[0].url}
        url={lessonData.sources[0].url}
        width="100%"
        height="100%"
        onStart={playerOnStart}
        onEnded={playerOnEnded}
        progressFrequency={VIDEO_LESSON_PROGRESS_UPDATE_FREQUENCY}
        onProgress={(data) => playerOnProgress(data.playedSeconds)}
        controls
        config={{
          file: {
            attributes: {
              disablePictureInPicture: true,
              controlsList: 'nodownload nofullscreen noremoteplayback',
            },
          },
        }}
      />
      <Overlay target={target.current} show={showNextActivity}>
        <NextActivityOverlay
          thisLesson={lessonData}
          nextLesson={nextLessonData}
          hideOverlay={() => setShowNextActivity(false)}
          openCoursePlayer={openCoursePlayer}
          closeCoursePlayer={closeCoursePlayer}
        />
      </Overlay>
    </>
  );
}
