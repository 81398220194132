import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '../../contexts/searchContext';
import { InstructorData } from '../../models/absorb/instructorLed';
import { fetchInstructor } from '../../services/instructorLed';
import { errorHandler } from '../../utils/helper';

export function InstructorNameFilter(): ReactElement {
  const { t } = useTranslation();

  const { instructor, setInstructor } = useSearchContext();

  const [instructorData, setInstructorData] = useState<InstructorData[]>();

  useEffect(() => {
    if (instructor !== '') {
      fetchInstructor([instructor])
        .then((data) => {
          if (data.length > 0) {
            setInstructor(instructor);
            setInstructorData(data);
          } else {
            setInstructor('');
          }
        })
        .catch(errorHandler);
    }
  }, [instructor]); // eslint-disable-line react-hooks/exhaustive-deps

  function onChangeHandleCrossButton() {
    setInstructor('');
  }

  return instructorData ? (
    <div className="filter-panel-module__filter_panel___2xlm8 filter-panel__url_filters">
      <button
        type="button"
        className="icon-button-module__btn___Zb89b filter-panel-module__remove_button___1970P filter-panel__remove_button"
        title={t('Remove')}
        onClick={() => onChangeHandleCrossButton()}
      >
        <div className="icon icon-x-circle" />
        <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Remove')}</span>
      </button>
      <div className="url-filter-module__container___jLRk7">
        <label className="url-filter-module__title___3OAZ9 url-filter__title" title={t('InstructorName')}>
          {t('InstructorName')} :
        </label>
        <div className="url-filter-module__selected_list___2NzIe">
          <div>
            <div className="url-filter-module__selected_value___1bZiK url-filter__selected_value">
              {instructorData && instructorData[0]?.fullName}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
