import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseRatingClearButton } from './CourseRatingClearButton';
import { CourseRatingRow } from './CourseRatingRow';
import { useSearchContext } from '../../contexts/searchContext';
import { AbsorbCourseRating } from '../../constants/courses';

export function CourseRatingFilter(): ReactElement {
  const { t } = useTranslation();

  const { courseRating, setCourseRating } = useSearchContext();

  function clearRatingData() {
    setCourseRating(0);
  }

  const ratings = [AbsorbCourseRating.Four, AbsorbCourseRating.Three, AbsorbCourseRating.Two, AbsorbCourseRating.One];

  return (
    <div className="filter-panel-module__filter_panel___2xlm8 filter-panel__filter_panel">
      <fieldset className="course-rating-filter-module__fieldset___19zNu">
        <legend className="course-rating-filter-module__title___3wRyo course-rating-filter__title">
          <span className="accessibility-text-module__off_screen_text___FSqhy">
            {t('FilterBy')} {t('CourseRating')}
          </span>
          <span aria-hidden="true">{t('CourseRating')}</span>
        </legend>
        {ratings.map((rating) => (
          <div key={rating}>
            <CourseRatingRow courseRating={rating} checked={rating === courseRating} />
          </div>
        ))}
        {courseRating > 0 && <CourseRatingClearButton clearCourseRating={clearRatingData} />}
      </fieldset>
    </div>
  );
}
