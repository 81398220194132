import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../contexts/modalContext';
import { CatalogCourseData } from '../models/absorb/catalog';
import { CourseDetailsPage } from '../pages/CourseDetailsPage';

interface Props {
  courseData: CatalogCourseData;
  updateTilePinnedStatus?: (isPinned: boolean, courseId: string) => void;
}

export function CourseDetailModal({ courseData, updateTilePinnedStatus }: Props): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();

  useEffect(() => {
    window.onpopstate = () => {
      closeModal();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="modal-module__container___sfedc modal__container"
      role="dialog"
      aria-label={t(courseData?.courseType)}
      aria-modal="true"
    >
      <div className="modal-module__blocker___231AR modal__blocker" />
      <div className="modal-module__modal_container___3tUB2 modal__modal_container modal-module__modal_container_top___3NfiM modal__modal_container_top">
        <div className="modal-module__modal_inner_container___2oKaM modal__modal_inner_container">
          <button
            type="button"
            className="icon-button-module__btn___Zb89b modal-module__close_btn___1yWvZ modal__close_btn"
            onClick={closeModal}
          >
            <div className="icon icon-x-thin" />
          </button>
          <div className="modal-module__modal___11QTd modal__modal modal-module__modal_default_size___2ui9v modal-module__modal_top_position___Yxl4d">
            <CourseDetailsPage
              courseDataForModalPresentation={courseData}
              updateTilePinnedStatus={updateTilePinnedStatus}
              isModal
            />
          </div>
        </div>
      </div>
    </div>
  );
}
