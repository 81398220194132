import { ThreeDots } from 'react-loader-spinner';
import React, { ReactElement } from 'react';

interface Props {
  size?: number;
  color?: string;
}

export function LoadingIndicator({ size = 100, color = '#303335' }: Props): ReactElement {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ThreeDots color={color} height={size} width={size} />
    </div>
  );
}
