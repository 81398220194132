import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useBrandingContext } from '../contexts/brandingContext';

export function CartSubHeader(): ReactElement {
  const { t } = useTranslation();

  const { brandingCoursesSettings } = useBrandingContext();

  return (
    <div
      className="banner-module__banner___IJO0Z banner__banner"
      style={{
        backgroundImage: `url("${encodeURI(brandingCoursesSettings?.myCoursesAndCatalog?.bannerUri || '')}")`,
      }}
    >
      <h1 className="banner-title-module__title___1mbDk banner__title">{t('ShoppingCart')}</h1>
    </div>
  );
}
