import React, { ReactElement } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { AbsorbEnrollmentStatus } from '../constants/absorb';

interface Props {
  courseRequiresPurchase: boolean;
  progress: number;
  enrollmentStatus?: string;
}

export function RadialProgressIndicatorSmall({
  courseRequiresPurchase,
  progress = 0,
  enrollmentStatus,
}: Props): ReactElement {
  return (
    <CircularProgressbar
      value={courseRequiresPurchase ? 0 : progress}
      styles={buildStyles({
        // TODO: not a best way but don't have alternative as of now so hardcoding color for krew
        pathColor: enrollmentStatus === AbsorbEnrollmentStatus.Complete ? '#8CC555' : '#AB192D',
        trailColor: enrollmentStatus === AbsorbEnrollmentStatus.Complete ? '#8CC555' : '#B3B3B3',
        strokeLinecap: 'butt',
      })}
    />
  );
}
