import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AbsorbEnrollmentStatus } from '../constants/absorb';
import { LessonsData } from '../models/absorb/lessons';
import { getEnrollmentStatus } from '../utils/helper';

interface Props {
  lessonData: LessonsData;
  openPlayer: (id: string) => void;
}

export function LessonCardActionButton({ lessonData, openPlayer }: Props): ReactElement {
  const { t } = useTranslation();

  function getButtonText() {
    return t(getEnrollmentStatus(lessonData.enrollment.status));
  }

  function getButtonColor() {
    let color = 'action-button-module__btn___3zkzb btn ';

    if (lessonData.enrollment?.status === AbsorbEnrollmentStatus.Complete) {
      color += 'action-button-module__success___3TuKz btn__success ';
    }

    if (!isButtonEnabled()) {
      color += 'action-button-module__btn_disabled___2bOlE btn__disabled ';
    }

    return color;
  }

  function isButtonEnabled() {
    const { enrollment } = lessonData;

    return (
      enrollment?.isAllowedRetake &&
      (enrollment?.maxAttempts === null ||
        (enrollment?.attempts !== undefined &&
          enrollment?.maxAttempts !== undefined &&
          enrollment.attempts < enrollment.maxAttempts))
    );
  }

  return (
    <div className="action-button-module__container___2O6X7">
      <button
        type="button"
        aria-disabled="false"
        aria-label={lessonData.name}
        disabled={!isButtonEnabled()}
        onClick={() => openPlayer(lessonData.id)}
        className={getButtonColor()}
      >
        <div className="action-button-module__title___1uZt_">{getButtonText()}</div>
        <span aria-live="polite" />
      </button>
    </div>
  );
}
