import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../contexts/modalContext';

export function CourseUploadModal(): ReactElement {
  const { t } = useTranslation();

  const { closeModal } = useModalContext();

  // TODO: E1-574 Needed to check api is there for uploading file in backend or not
  // async function uploadProfilePic(e: ChangeEvent<HTMLInputElement>) {
  //   if (e.target.files) {
  //     setImage(URL.createObjectURL(e.target.files[0]));

  //     setPicture(e.target.files[0].name);

  //     let formData = new FormData();

  //     formData.append('name', e.target.files[0].name);
  //     uploadPicture(formData);
  //   }
  // }

  // async function savePic() {
  //   if (!imageFileId) {
  //     return;
  //   }

  //   let fieldData = {
  //     fileId: imageFileId,
  //   };

  //   updatePicture(fieldData);
  // }

  return (
    <div
      className="share-module__container___KbDXE share__container"
      role="dialog"
      aria-label="Share This"
      aria-modal="true"
    >
      <div className="share-module__overlay___12jzP share__overlay" />
      <div className="share-module__share___3sYAv">
        <button
          type="button"
          className="icon-button-module__btn___Zb89b share-module__close_btn___14bAe share__close_btn"
          title={t('Close')}
          onClick={closeModal}
        >
          <div className="icon icon-x-thin" />
          <span className="accessibility-text-module__off_screen_text___FSqhy">{t('Close')}</span>
        </button>
        <div className="modal-module__modal___11QTd modal__modal modal-module__modal_small_size___1dAye">
          <div className="course-upload-modal-module__container___3ONdT">
            <h1 className="course-upload-modal-module__header___32YpO course-upload-modal__header">Upload File</h1>
            <form>
              <div className="course-upload-modal-module__form_inner___3mNle">
                <h2 className="course-upload-modal-module__title___1Bhpd course-upload-modal__title">test label</h2>
                <div className="course-upload-modal-module__field_wrapper___3LoUA">
                  <div className="redux-form-file-upload-module__field___1nkiT">
                    <div className="redux-form-file-upload-module__label_wrapper___3QWCi">
                      <label className="redux-form-file-upload-module__label___3H6Dg redux-form-file-upload__label">
                        {t('Upload')}
                      </label>
                      <div
                        className="redux-form-file-upload-module__required_label___R2U9v redux-form-file-upload__label"
                        aria-hidden="true"
                      >
                        {t('Required')}
                      </div>
                    </div>
                    <div className="redux-form-image-upload__input_field redux-form-file-upload-module__input_field___2xwq5">
                      <input name="uploadFileId" id="uploadFileId143" type="hidden" value="" />
                      <input
                        id="uploadValidationField144"
                        type="file"
                        accept="application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/png,image/x-png,image/gif,image/jpeg,image/pjpeg,application/pdf,audio/mpeg,video/mp4,video/quicktime,audio/wav,audio/m4a,text/plain"
                        className="redux-form-file-upload-module__input_file___2_aD1 redux-form-image-upload__input"
                        aria-hidden="true"
                      />
                      <button
                        className="redux-form-image-upload__upload_image_wrapper redux-form-file-upload-module__upload_image_wrapper___5-QeS"
                        type="button"
                      >
                        <div className="redux-form-image-upload__upload_image_text redux-form-file-upload-module__upload_image_text___1E-mZ course-upload-modal-module__input_wrapper___1JzT9">
                          {t('UploadAFile')}
                        </div>
                        <div className="redux-form-image-upload__upload_image_span redux-form-file-upload-module__upload_image_span___wjzOz">
                          <span className="redux-form-file-upload-module__upload_image_button_text___w0o-k">
                            {t('Browse')}
                          </span>
                          <span className="redux-form-image-upload__upload_image_icon redux-form-file-upload-module__upload_image_icon___2MlEi icon icon-resource-image" />
                        </div>
                      </button>
                      <div aria-live="assertive" />
                    </div>
                    <div aria-live="polite" />
                  </div>
                </div>
                <div className="course-upload-modal-module__field_wrapper___3LoUA">
                  <div className="redux-form-textarea-module__field___DhFB_">
                    <label className="redux-form-textarea-module__label___2C_o5 redux-form-textarea__label">
                      {t('Notes')}
                    </label>
                    <textarea
                      name="notes"
                      className="redux-form-textarea-module__text_area___1WWtm"
                      placeholder="Add Additional Notes"
                      id="reduxFormTextarea145"
                    />
                  </div>
                </div>
                <div aria-live="assertive" />
              </div>
            </form>
            <div className="course-upload-modal-module__footer___167bO">
              <div className="course-upload-modal-module__footer_buttons___GoBjo">
                <button
                  type="submit"
                  className="btn course-upload-modal-module__form_btn___1mQ9Y course-upload-modal__submit_btn button-module__btn___1lXcC"
                  title="Submit Course Upload"
                  aria-label="Submit Course Upload"
                  aria-describedby="coureUploadModalSubmitButtonDescribedById2"
                >
                  {t('Save')}
                </button>
                <button
                  type="button"
                  className="btn course-upload-modal-module__form_btn___1mQ9Y course-upload-modal__cancel_btn button-module__btn___1lXcC button-module__cancel___1pWE5 btn__cancel"
                  title="Cancel Course Upload"
                  aria-label="Cancel Course Upload"
                  onClick={closeModal}
                >
                  {t('Cancel')}
                </button>
              </div>
            </div>

            <div id="coureUploadModalSubmitButtonDescribedById2" aria-live="assertive" />
          </div>
        </div>
      </div>
    </div>
  );
}
