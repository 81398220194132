import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ABSORB_TAGS } from '../../constants/absorb';
import { SideFilterOptionData } from '../../models/absorb/search';
import { fetchMyCatalogTags } from '../../services/catalog';
import { fetchResourcesTags } from '../../services/resources';
import { fetchMyCoursesTags } from '../../services/course';
import { fetchGlobalSearchTags } from '../../services/search';
import { TagData } from '../../models/absorb/tags';
import { errorHandler } from '../../utils/helper';
import { useSearchContext } from '../../contexts/searchContext';
import { isOnGlobalSearchPage, isOnMyCoursesPage, isOnResourcesPage } from '../../constants/common';

export function TagFilter(): ReactElement {
  const { t } = useTranslation();

  const { currentSearchUri, advancedFilter, searchTerm, setAdvancedFilter, removeAdvancedFilter } = useSearchContext();

  const [tags, setTags] = useState<TagData[]>([]);
  const [selectedTags, setSelectedTags] = useState<SideFilterOptionData[]>([]);

  useEffect(() => {
    if (isOnResourcesPage()) {
      fetchResourcesTags().then(setTags).catch(errorHandler);
    } else if (isOnMyCoursesPage()) {
      fetchMyCoursesTags().then(setTags).catch(errorHandler);
    } else if (isOnGlobalSearchPage()) {
      fetchGlobalSearchTags(searchTerm).then(setTags).catch(errorHandler);
    } else {
      fetchMyCatalogTags().then(setTags).catch(errorHandler);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (advancedFilter[ABSORB_TAGS]) {
      const selectedData = tags.filter((tag) => (advancedFilter[ABSORB_TAGS] as string).includes(tag.id));
      setSelectedTags(selectedData ? selectedData.map((tag: TagData) => ({ value: tag.id, label: tag.name })) : []);
    }
  }, [tags, currentSearchUri]); // eslint-disable-line react-hooks/exhaustive-deps
  // REASON for currentSearchUri: we need to add currentSearchUri here if we selected certain tag and then changing tag on another tag  by clicking course detail card then we need to update selected tag in sidebar filter also

  useEffect(() => {
    if (tags.length && selectedTags.length) {
      setAdvancedFilter({
        ...advancedFilter,
        [ABSORB_TAGS]: selectedTags.map((tag: SideFilterOptionData) => tag.value).join(','),
      });
    }
  }, [selectedTags]); // eslint-disable-line react-hooks/exhaustive-deps

  function clearTagsData() {
    setTags([]);
    setSelectedTags([]);
    removeAdvancedFilter(ABSORB_TAGS);
  }

  return tags.length > 0 ? (
    <>
      <label className="text-filter-module__title___2zlYI text-filter__title">{`${t('Tags')}:`}</label>
      <div className="filter-panel-module__filter_panel___2xlm8 filter-panel__filter_panel">
        {!isOnResourcesPage() && (
          <button
            type="button"
            className="remove-tag-button icon-button-module__btn___Zb89b filter-panel-module__remove_button___1970P filter-panel__remove_button"
          >
            <div className="icon icon-x-circle" onClick={clearTagsData} title={t(ABSORB_TAGS)} />
            <span className="accessibility-text-module__off_screen_text___FSqhy">
              {t('RemoveTagFilterAccessibilityText')}
            </span>
          </button>
        )}

        <Select
          noOptionsMessage={() => null}
          value={selectedTags}
          options={tags.map((tag: TagData) => ({ value: tag.id, label: tag.name }))}
          placeholder={t('Tags')}
          onChange={(value) => setSelectedTags((value as SideFilterOptionData[]) || [])}
          isSearchable
          className="select-class-tags"
          isMulti
        />
      </div>
    </>
  ) : (
    <></>
  );
}
