import React, { ReactElement } from 'react';
import { useSearchContext } from '../../contexts/searchContext';

interface Props {
  label: string;
  isChecked?: boolean;
  toggleCheckbox?: () => void;
}

export function Slider({ label, isChecked, toggleCheckbox }: Props): ReactElement {
  const { disableFilter } = useSearchContext();

  return (
    <div className="filter-panel-module__filter_panel___2xlm8 filter-panel__filter_panel">
      <div className="toggle-module__toggle___1ECOf">
        <label className="toggle-module__switch___3WgMO">
          <input
            type="checkbox"
            className="toggle-module__checkbox___17FoT toggle__checkbox"
            aria-checked="true"
            aria-label={label}
            value={isChecked ? 'true' : 'false'}
            checked={isChecked}
            disabled={disableFilter}
            onChange={toggleCheckbox}
          />

          <div className="toggle-module__slider___kdSRD toggle-module__round___N3YSO toggle__slider">
            <span className="icon icon-check-mark toggle-module__check_mark___1p1Dw" />
          </div>
        </label>
        <label className="toggle-module__title___EGjXC toggle__title" title={label}>
          {label}
        </label>
      </div>
    </div>
  );
}
